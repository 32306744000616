import {useState, useEffect} from "react";
import "./styles.scss";
import { ApiCall, ApiMethod } from '../../services/AxiosInstance';
import { Dropdown, Menu, MenuButton, MenuItem } from "@mui/base";
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import Logo from '../../assets/images/logo1.png';
import { Button } from "@material-ui/core";
import { getImageUpdload } from "../../utilities";
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import { deepOrange, deepPurple } from '@mui/material/colors';
import EventEmitter from "reactjs-eventemitter";
export default function AppRestoBar({
  containerStyle,
  restaurantData,
  handleLogout = () => {}
}) {

  const [companyLogo,setCompLogo] = useState()
  const [renderApp,setRenderApp] = useState({'update':false})

  console.log("thisss is the restauranttt dataaa",restaurantData)

  const getRestaurantLogo = async() =>{
    // try{
    //     const resDetails = await ApiCall({
    //         apiEndpoint:`/items/restaurant/${restaurantData?.id}`,
    //         method:ApiMethod.GET,
    //         service:true //need change
    //     })

    //     const data = resDetails.data.data
    //     const image = getImageUpdload(data.res_logo)
    //     setCompLogo(image)
    //     console.log("thiss is the dataaaaaaaaaaa++++++++++++++++++++++++++++",image)

    // }catch(err){
    //     console.log("getRestaurantDetails err",err)
    // }
}

useEffect(()=>{
  // getRestaurantDetails()
  const image = getImageUpdload(restaurantData?.resDetails.res_logo)
  setCompLogo(image)
},[JSON.stringify(renderApp)])

//   React.useEffect(() => {
        
//     ApiCall({
//         apiEndpoint: 'https://api.publicapis.org/entries', 
//         method: ApiMethod.GET
//     })
//     .then((response) => {
//         console.log(response.data)
//     })
//     .catch((error) => {
//         console.log(error)
//     })

// },[])
EventEmitter.subscribe('renderRestaurant', event => { 
  setRenderApp({'update':!renderApp.update})
 })
const stringAvatar=(name)=>{
  return {
    sx: {
      bgcolor: "#f0522b",
    },
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
  };
}
const stringToColor=(string)=> {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}
  return (
   <section id="AppRestoBar" style={containerStyle}>
    <div className="container">
      {
        restaurantData?.resDetails.res_logo ?  
        <div className="logo_con">
          <div className="logoImg" style={{backgroundImage:`url(${companyLogo})`}}></div>
        </div>
       
        // <img className="logoImg" src={companyLogo}  />
        : 
        <Avatar {...stringAvatar(`${restaurantData.userDetails?.first_name} ${restaurantData.userDetails?.last_name}`)} />
      }
   
    {/* <img className="logoImg" src={restaurantData?.resDetails.res_logo ? companyLogo : Logo}  /> */}
      <div className="profileHolder">
        <h3 className="title">Hi! {restaurantData.userDetails?.first_name} {restaurantData.userDetails?.last_name}</h3>
        <Button className="btnLogout" onClick={handleLogout}>Log Out</Button>
      </div>
      
    </div>
   </section>
  )
}