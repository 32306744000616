import * as React from 'react';
import "./styles.scss";
import { Button, Typography } from '@mui/material';
import TextareaAutosize from 'react-textarea-autosize';
import compLogo from "../../assets/images/company_logo.png"
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { useNavigate } from "react-router-dom";
import AppTabIndicator from '../AppTabIndicator';
import { appTheme } from '../../constants';
import imagePrev from "../../assets/images/prev.png"
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';    
import { useSelector } from 'react-redux';
import { ApiCall, ApiMethod } from '../../services/AxiosInstance';
import { useEffect } from 'react';
import { useState } from 'react';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import Badge from '@mui/material/Badge';
import { getImageUpdload } from '../../utilities';

const AppTabBar = ({isMain,isReview,isPayment,isPreview, tabIndex,cartLength}) => {
  const navigate = useNavigate()
  const themeCurrent = useSelector((state)=>state.user.currentTheme)
  const restaurant_id = useSelector((state)=>state.user.restaurant_id)
  const [compLogo,setCompLogo] = React.useState()

  const app_theme = appTheme(themeCurrent) 
  const addOns = [{desc:"Add Extra Rice",price:1},{desc:"Add Extra Sauce",price:1}]
  const tabCount = [0,1,2]
  const currentIndex = 0

 useEffect(()=>{
    getRestaurantDetails()
 },[])


  const getRestaurantDetails = async() =>{
        try{
            const resDetails = await ApiCall({
                apiEndpoint:`/items/restaurant/${restaurant_id}`,
                method:ApiMethod.GET,
                service:true
            })

            const data = resDetails.data.data
            const image = getImageUpdload(data.res_logo)
            setCompLogo(image)
            console.log("thiss is the dataaaaaaaaaaa++++++++++++++++++++++++++++",image)

        }catch(err){
            console.log("getRestaurantDetails err",err)
        }
  }



  return (
    <div className='main_appbar_container' style={{backgroundImage: `linear-gradient(to bottom right, ${app_theme.themeGradient.primary.color} ${app_theme.themeGradient.primary.percent}, ${app_theme.themeGradient.secondary.color} ${app_theme.themeGradient.secondary.percent})`,height:isPreview && '40vh'}}>
     <div className='overflow-header' style={{backgroundColor:app_theme.themeGradient.secondary.color}}></div>
     <div className='appTB_container'>
     <AppTabIndicator tabIndex={tabIndex}/>
     </div>
        <div className='appTB_second_container'>
            <div >
            <div className='image_container' style={{backgroundImage: `url(${compLogo})`, width: '60px', height: '60px'}}></div>
                {/* <div className='image_container' style={{backgroundImage:`url(${compLogo})`}}>
                <img src={compLogo}/>
                </div> */}
                
                <footer className="appTB_label_container">
                   <Typography className="first" >
                    Powered by
                   </Typography>
                    <Typography className='second'>
                      EZ ODA
                    </Typography>
                </footer>
            </div>
            <div className='appTB_guest_container'>
             <Typography><b>Hi, Guest </b></Typography>
             <Badge badgeContent={cartLength} color="error" style={{marginTop:"15px"}}>
                    <ShoppingCartIcon onClick={()=>navigate('/customer-review-order')} style={{fontSize:"24px",color:"rgb(255, 154, 3)",cursor:"pointer"}}/>
             </Badge>
             
                {/* <Typography><b>Hi, Guest #1</b></Typography> */}
            </div>
        </div>

        {isMain &&   <div className="cm_body">

            <Typography className="cm_body_label">Make Your Selection</Typography>
            <div className="cm_status_label">
            <div className="cm_status_label_left">
                {/* <Typography className='first'>You’re in</Typography>
                <Typography className='second'>Left, 3rd Table</Typography> */}
            </div>
            <div className="cm_status_label_right">
                <div className='first'></div>
                <Typography className='second'>{cartLength ? cartLength : "0"} Orders</Typography>
            </div>
            </div>
            {/* <p>LogoIpsum</p> */}

        </div>}
        

        {isReview && <div className='ro_head_container'>
            <Typography className='first'>Review & Order</Typography>
            <Typography className='second' onClick={()=>navigate(-1)} style={{color:app_theme?.appBtn[2]?.textColor}}>Back To Menu</Typography>
        </div>}

        {isPayment && <div className='ro_payment_container'>
               <div style={{display:"flex",alignItems:'center'}}>
                    <KeyboardArrowLeftIcon style={{marginRight:'3px',color:app_theme?.appBtn[2]?.textColor}}/>
                    <Typography className='back' style={{color:app_theme?.appBtn[2]?.textColor}}  onClick={()=>navigate(-1)}>Back</Typography>
                </div>
                <div><Typography className='payment_label'>Payment</Typography></div>
                
                <div></div>
        </div>}
        
    </div>
  );
}

export default AppTabBar;