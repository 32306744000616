import { useEffect, useState } from "react";
import "./styles.scss";
import { Button } from "@material-ui/core";
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import { deepOrange, deepPurple } from '@mui/material/colors';
import EventEmitter from "reactjs-eventemitter";
import { getImageUpdload } from "../../utilities";
import { Typography } from "@mui/material";

export default function AppStaffBar({
  data,
  containerStyle,
  SetCurrentSelection,
  handleLogout = () => {}
}) {
  const [activeOption, setActiveOption] = useState(0);

  const stringAvatar = (name) => {
    if(name){
      return {
        sx: {
          bgcolor: "#f0522b",
        },
        children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
      };
    }
    
  }
  const listOptions =  ["Reservations","Tables", "Order"]  //["Tables", "Reservations", "Order", "EOD Sales"];
  
  const handleOptionClick = (index) => {
    SetCurrentSelection(listOptions[index])
    setActiveOption(index);
  };


  useEffect(()=>{
    if(listOptions){
      SetCurrentSelection(listOptions[0])
    }
  },[])
 

  return (
    <section id="AppRestoBar_1" style={containerStyle}>
      <div className="container">
        {data?.restaurant.res_logo && (
          <div className="logo_container">
             <div className="logoImg" style={{ backgroundImage: `url(${getImageUpdload(data?.restaurant.res_logo)})` }}></div>
          </div>
         
        )}
        <div className="profileHolder">
          <Typography className="title">Hi! {data?.staff_name} </Typography>
          <Button className="btnLogout" onClick={handleLogout}><Typography>Log Out</Typography></Button>
        </div>
      </div>
      <ul className="container_list">
        {listOptions.map((option, index) => (
          <li key={index} className={`individual_list ${index === activeOption ? 'active' : ''}`} onClick={() => handleOptionClick(index)}>
            {option}
          </li>
        ))}
      </ul>
    </section>
  );
}
