import * as React from 'react';
import "./styles.scss";
import { Button, Typography } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
const AppSnackBar = ({
  snackConfig,
  position = 'bottom'
}) => {
  const [open, setOpen] = React.useState(false);

 
  const handleClick = () => {
    setOpen(true);
  };
  
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
  React.useEffect(()=>{

    if( snackConfig !== undefined && snackConfig.message !==null){
      setOpen(true);
    }
  },[snackConfig])
  return (
    <>
    {
      snackConfig !== undefined &&
      <Snackbar
      open={open}
      autoHideDuration={2500}
      onClose={handleClose}
      message={snackConfig.message}
      anchorOrigin={{ vertical:position , horizontal: 'center' }}
     
    >
       <Alert
          severity={snackConfig.type}
          variant="filled"
          sx={{ width: '100%' }}
        >
         {snackConfig.message}
        </Alert>
      </Snackbar>
    }
    </>
    
  );
}

export default AppSnackBar;