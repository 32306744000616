import { combineReducers } from 'redux';
import User from './User';
import Restaurant from './Restaurant';
import Kitchen from './Kitchen';
const reducer = combineReducers({
    user: User,
    restaurant:Restaurant,
    kitchen:Kitchen
});

export default reducer;