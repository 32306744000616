import React, { useEffect, useState } from 'react';
import "./styles.scss";
import { useNavigate } from "react-router-dom";
import { Button, Input } from "@material-ui/core";
import Logo from "../../../assets/images/logo1.png"
import { appTheme } from "../../../constants";
import { useSelector } from 'react-redux';
import { ApiCall,ApiMethod } from '../../../services/AxiosInstance';
import { useDispatch } from 'react-redux';
import { setRestaurantData } from '../../../redux/reducer/Restaurant';
import {AppSnackBar,AppLoader} from '../../../components';
export default function RestaurantLoginPage() {
  const restaurantData = useSelector(state => state.restaurant.restaurantData);
  const navigate = useNavigate();
  const themeCurrent = useSelector((state)=>state.user.currentTheme)
  const theme = appTheme(themeCurrent);
  const dispatch = useDispatch()
  const [callingAPI,setCallingAPI]=useState(false)
  const [snackConfig,setSnackConfig]= useState({
    message:null,
    type:"success",//success,error
    show:false
  })
  if(restaurantData){
    window.location.href = "/restaurant-main-page";
    //navigate("/restaurant-main-page");
  }
  useEffect(()=>{

  },[JSON.stringify(snackConfig)])
  return (
   <section id="restoLoginPage">
    <div className="my_container" style={{backgroundImage: `url("${theme?.appBg}")`, backgroundSize: 'cover', backgroundPosition: 'center center'}}>
      <img src={Logo} className="imageLogo" onClick={()=>navigate("/")} style={{cursor:'pointer'}} />
      <form
      className="content"
      onSubmit={async(event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const formJson = Object.fromEntries((formData).entries());
        if(!formJson.userName){
          setSnackConfig({
            message:"Please provide your username",
            show:true,
            type:"error"
          })
        }else if(!formJson.password){
          setSnackConfig({
            message:"Please provide your password",
            show:true,
            type:"error"
          })
        }else{
          setCallingAPI(true)
          await ApiCall({
            apiEndpoint:"/users",
            method:ApiMethod.GET,
            service:true,
            filter:{
                  user_name:{
                    _eq:formJson.userName
                  }
            }
          }).then(async(checkUserName)=>{
            const checkUserNumData = checkUserName.data.data
            if(checkUserNumData.length >=1){
              await ApiCall({
                apiEndpoint:"/auth/login",
                method:ApiMethod.POST,
                apiData:{
                  email:checkUserNumData[0].email,
                  password:formJson.password ,
                }
              }).then(async(authLogin)=>{
               
                  await ApiCall({
                    apiEndpoint:"/users/me",
                    fields:"*.*",
                    method: ApiMethod.GET,
                    token: authLogin.data.data.access_token,
                  }).then(async(userMe)=>{
                    const resDetail = await ApiCall({
                      apiEndpoint:"/items/restaurant",
                      method:ApiMethod.GET,
                      token: authLogin.data.data.access_token,
                      filter:{
                        user_id:{
                          _eq:userMe.data.data.id
                        }
                      }
                    })
                    const userAccess = authLogin.data.data
                    const userDetails = userMe.data.data
                    const resDetails = resDetail.data.data[0]
                    const userDetailsAcess = { ...{userAccess:userAccess}, ...{userDetails:userDetails},...{resDetails:resDetails} }
                    dispatch(setRestaurantData(userDetailsAcess))
                    //window.location = "/restaurant-main-page";      
                    //navigate("/restaurant-main-page");
                  }).catch((errUserMe)=>{
                      setCallingAPI(false)
                      setSnackConfig({
                        message:"Failed: Something went wrong!",
                        show:true,
                        type:"error"
                      })
                      console.log("err User Me",errUserMe)
                  })
              }).catch((errAuthLogin)=>{
                  setCallingAPI(false)
                  setSnackConfig({
                    message:"Invalid username or password!",
                    show:true,
                    type:"error"
                  })
                  console.log("err Auth Login",errAuthLogin)
              })
            }else{
              console.log("error")
              setCallingAPI(false)
              setSnackConfig({
                message:"Invalid username/email or password!",
                show:true,
                type:"error"
              })
            }
          }).catch((err)=>{
            setCallingAPI(false)
            setSnackConfig({
              message:"Username/Email not  found!",
              show:true,
              type:"error"
            })
            console.log("username not found",err)
          })
        }
      }}
      >
        <h1 className="title">Sign In</h1>
        <input name="userName" className="inputs" placeholder="Username/Email" />
        <input name="password" type="password" className="inputs" placeholder="Password" />
        <Button className="btnLogin" type="submit"  onClick={() => {}}>Login</Button>
        <p className="signup">Not Registered Yet? <a onClick={()=>navigate("/restaurant-signup")} style={{cursor:'pointer'}}>Register Now</a></p>
      </form>
    </div>
    {
      callingAPI && <AppLoader/>
    }
    <AppSnackBar snackConfig={snackConfig}/>
   </section>
  )
}