import React,{useState, useEffect} from "react";
import "./styles.scss";
import { ApiCall, ApiMethod } from '../../services/AxiosInstance';
import {AppRestoSubMenu, AppDropDown} from "../";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import Product from '../../assets/images/product.jpg';
import ImageIcon from '@mui/icons-material/Image';
import { Button, Stack, TextField, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { getImageUpdload } from "../../utilities";
import EventEmitter from "reactjs-eventemitter";
import Switch from "react-switch";
import {AppSnackBar,AppLoader,AppModal} from '../../components';
import { useNavigate } from "react-router-dom";
import uuid from 'react-uuid';
import Autocomplete from '@mui/material/Autocomplete';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
export default function AppRestoForm({
  containerStyle,
  isHideMenu = false,
  isSendTo,
  isEdit,
  title,
  dataToUpdate
}) {
  const restaurantData = useSelector(state => state.restaurant.restaurantData);
  const navigate = useNavigate()
  const [callingAPI,setCallingAPI]=useState(false)
  const [snackConfig,setSnackConfig]= useState({
    message:null,
    type:"error",//success,error
    show:false
  })
  const handleChangeImage = (e) => {
    if (e.target.files) {
      const file = e.target.files[0];
      const reader = new FileReader();
      const newData = { ...subMenuInput };
      reader.onloadend = () => {
        const base64String = reader.result;
        setSelectedImg(base64String);
      }
      reader.readAsDataURL(file);
      setSelectedFile(e.target.files[0])
      
      
    }
   console.log(e.target.files[0]);
  };
  const [statusType,setStatusType] = useState([])
  const [statusTypeSelected,setStatusTypeSelected] = useState()
  const [category,setCategory] = useState([])
  const [categorySelected,setCategorySelected] = useState()
  const [selectedImg,setSelectedImg] = useState()
  const [selectedFile,setSelectedFile] = useState()
  const [menuName,setMenuName] = useState()
  const [menuPrice,setMenuPrice] = useState()
  const [menuType,setMenuType]= useState()
  const [menuTypeDef,setMenuTypeDef]= useState()
  const [menuDesc,setMenuDesc]= useState()
  const [menuCat,setMenuCat]= useState()
  const [menuCatDef,setMenuCatDef]= useState()
  const [currMenu,setCurrMenu]= useState()
  const [subMenu,setSubMenu]= useState([])
  const [subMenuDef,setSubMenuDef]= useState([])
  const [addSubMenu,setAddSubMenu]= useState([])
  const [addSubMenuOption,setAddSubMenuOption]= useState([])
  const [subMenus,setSubMenus]= useState([])
  const [timeprep,setTimepRep]= useState({
    startTime:"",
    startTimeType:"",
    endTime:"",
    endTimeType:""
  })
  const [servings,setServings]= useState()
  const [station,setStation]= useState([])
  const [selectedstation,setSelectedStation]= useState()
  const [optionName,setOptionName]= useState()
  const [checkHasData,setCheckhasData]= useState(false)
  const [renderApp,setRenderApp] =useState({'update':false})
  const [foodIsSub,setFoodIsSub]=useState(false)
  const [isDoneFetching,setIsDoneFetching] = useState(false)
  const [showModal,setShowModal]= useState(false);
  const [modalMode,setModalMode]= useState("");
  const [selectedImgSub,setSelectedImgSub] = useState()
  const [selectedFileSub,setSelectedFileSub] = useState()
  const [loader, setLoader] = useState(false);
  const [subMenuInput,setSubMenuInput] =useState({
    name:"",
    optionName:"",
    desc:"",
    price:null,
    type:null
  })
  const handleChangeImageSubMenu = (e) => {
    console.log("selectedFileSub",selectedFileSub)
    if (e.target.files) {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result;
        setSelectedImgSub(base64String);
      }
      reader.readAsDataURL(file);
      setSelectedFileSub(e.target.files[0])
      //console.log(e.target.files[0]);
    }
  };
  const getStatusTypes = async()=>{
    await ApiCall({
      apiEndpoint:'/items/menus_status_type',
      method:ApiMethod.GET,
      token:restaurantData.userAccess.access_token,
      sort:'-date_created',
      filter:{
        restaurant_id:{
          _eq:restaurantData.resDetails.id
        }
      }
    }).then((res)=>
    {
      let statusType = []
      const menusStatusType = res.data.data
      if(menusStatusType.length >0){
        menusStatusType.map((item)=>
        statusType.push({
            id: item.id,
            label: item.description,
            icon: null,
            value:item.description
        })
        )
        statusType.push({
          description: "",
          id: "e17af48e-e01e-11ee-8e34-fced4b776b4b",
          label:"+ New Status Type",
          name:"",
          option_name:"",
          placeholder:"",
          price:"",
          value:"+ New Status Type",
      })
        setStatusType(statusType)
        setIsDoneFetching(!isDoneFetching)
      }      
    }      
    ).catch((err)=>
      console.log(err)
    )
  }
  EventEmitter.subscribe('updateAddFood', event => { 
    console.log("update")
    setRenderApp({'update':!renderApp.update})
   })
  const getCategory = async()=>{
    await ApiCall({
      apiEndpoint:'/items/category',
      method:ApiMethod.GET,
      token:restaurantData.userAccess.access_token,
      sort:'-date_created',
      filter:{
        restaurant_id:{
          _eq:restaurantData.resDetails.id
        }
      }
    }).then((res)=>
    {
      let categoryLists = []
      const categoryData = res.data.data
      if(categoryData.length >0){
        categoryData.map((item)=>
        categoryLists.push({
            id: item.id,
            label: item.description,
            icon: null,
            value:item.description,
            
        })
        )
        categoryLists.push({
          description: "",
          id: "e17af48e-e01e-11ee-8e34-fced4b776b4b",
          label:"+ New Category",
          name:"",
          option_name:"",
          placeholder:"",
          price:"",
          value:"+ New Category",
      })
        setCategory(categoryLists)
        setIsDoneFetching(!isDoneFetching)
      }      
    }      
    ).catch((err)=>
      console.log(err)
    )
  }
  const getStations = async()=>{
    await ApiCall({
      apiEndpoint:'/items/stations',
      method:ApiMethod.GET,
      token:restaurantData.userAccess.access_token,
      sort:'-date_created',
      filter:{
        restaurant_id:{
          _eq:restaurantData.resDetails.id
        }
      }
    }).then((res)=>
    {
      let stations = []
      const stationData = res.data.data
      if(stationData.length >0){
        stationData.map((item)=>
        stations.push({
            id: item.id,
            label: item.description,
            icon: null,
            value:item.description,
            
        })
        )
        stations.push({
          description: "",
          id: "e17af48e-e01e-11ee-8e34-fced4b776b4b",
          label:"+ New Station",
          name:"",
          option_name:"",
          placeholder:"",
          price:"",
          value:"+ New Station",
      })
        setStation(stations)
        setIsDoneFetching(!isDoneFetching)
      }else{
        setStation([{
          description: "",
          id: "e17af48e-e01e-11ee-8e34-fced4b776b4b",
          label:"+ New Station",
          name:"",
          option_name:"",
          placeholder:"",
          price:"",
          value:"+ New Station",
      }])
      }      
    }      
    ).catch((err)=>
      console.log(err)
    )
  }


  const getStatusType=(data)=>{
    setMenuType(data.id)
  }
  const getCat=(data)=>{
    setMenuCat(data.id)
  }
  const getSubmenu = (data)=>{
    console.log("getSubmenu",data)
    let submenus =[]
    data.map((item)=>{
      if(item.id){
        submenus.push(item.id)
      }
    })   

    setSubMenus(data)
  }
  const getTimePrep= (data)=>{
     setTimepRep(data)
  }
  // const getServings =(data)=>{
  //   console.log("getServings",data)
  //   setServings(data)
  // }
  // const getStation =(data)=>{
  //   setStation(data.id)
  // }
  const checkHasSubMenu = (data)=>{
      setCheckhasData(data)
  }
const addMenu =async(status='draft')=>{
    const fileFormData = new FormData();
    fileFormData.append("folder", restaurantData.resDetails.folder_id);
    fileFormData.append("file", selectedFile);
    console.log(selectedFile)
    let timePrepJson = {}
    try {
        timePrepJson = JSON.parse(timeprep)
    } catch (error) {
      console.log(timeprep)
    }
    //check
    if(!selectedFile){
      setSnackConfig({
        message:"Please add food image",
        show:true,
        type:"error"
      })
    }else if(!menuName){
      setSnackConfig({
        message:"Please add food name",
        show:true,
        type:"error"
      })
    }else if(!menuPrice){
      setSnackConfig({
        message:"Please add food price",
        show:true,
        type:"error"
      })
    }else if(!statusTypeSelected){
      setSnackConfig({
        message:"Please add food status",
        show:true,
        type:"error"
      })
    }else if(!menuDesc){
      setSnackConfig({
        message:"Please add food description",
        show:true,
        type:"error"
      })
    }else if(!categorySelected){
      setSnackConfig({
        message:"Please add food category",
        show:true,
        type:"error"
      })
    }else if(!timeprep.startTime || !timeprep.startTimeType || !timeprep.endTime || !timeprep.startTimeType){
      setSnackConfig({
        message:"Please fill food preparation",
        show:true,
        type:"error"
      })
    }else if(!servings){
      setSnackConfig({
        message:"Please add food servings",
        show:true,
        type:"error"
      })
    }else if(!selectedstation){
      setSnackConfig({
        message:"Please add food station",
        show:true,
        type:"error"
      })
    }else{
      setCallingAPI(true)
      await ApiCall({
        apiEndpoint: "/files",
        method: ApiMethod.POST,
        apiContentType: "multipart/form-data",
        token:restaurantData.userAccess.access_token,
        apiData: fileFormData,
      }).then(async(imgUp)=>{
        let payload = {
            image:imgUp.data.data.id,
            name:menuName,
            price:menuPrice,
            status_type:statusTypeSelected,
            description:menuDesc,
            category:categorySelected,
            time_prep:timeprep,
            serving_count:servings,
            stations:selectedstation,
            restaurant:restaurantData.resDetails.id,
            status:status
        }
        console.log(payload)
        await ApiCall({
          apiEndpoint:'/items/menus',
          method:ApiMethod.POST,
          token:restaurantData.userAccess.access_token,
          apiData:payload
        }).then(async(newMenu)=>{
          const newMenuID = newMenu.data.data.id
          if(addSubMenu.length>0){
            await Promise.all(
            addSubMenu.map(async (item) => {
                if(item.id ){
                  return await  ApiCall({
                    apiEndpoint:'/items/menu_submenu',
                    method:ApiMethod.POST,
                    token:restaurantData.userAccess.access_token,
                    apiData:{
                      sub_menu:item.id,
                      menu:newMenuID,
                      price:item.price
                    }
                  })
                } 
              })
              
              
            );
          }
          setCallingAPI(false)
          setSnackConfig({
            message:"Add new menu successfully",
            show:true,
            type:"success"
          })
          setMenuName(null)
          setSelectedFile(null)
          setCategorySelected(null)
          setMenuPrice(null)
          setStatusTypeSelected(null)
          setMenuDesc(null)
          setTimepRep({ startTime:"",
          startTimeType:"",
          endTime:"",
          endTimeType:""})
          setServings(null)
          setSelectedStation(null)
          setTimeout(() => {
           
            navigate("/restaurant-main-page");
          }, 500);
          
        }).catch((errNewmenu)=>{
          setCallingAPI(false)
          console.log("Error New Menu",errNewmenu)
          setSnackConfig({
            message:"Add new menu failed",
            show:true,
            type:"error"
          })
        })
      }
      ).catch((errUpImg)=> {
        setCallingAPI(false)
        console.log("Error in image",errUpImg)
        setSnackConfig({
          message:"Something went wrong",
          show:true,
          type:"error"
        })
      })
    }
        
     
     
      // }
    
    }
  const updateMenu =async()=>{
    console.log("subMenus",subMenus)
   
  }
  const getSubMenu = async(menu_id)=>{
    // if(menu_id){

    await ApiCall({
        apiEndpoint:`/items/sub_menu`,  //get sub menu of the restaurant_id   ?fields=*.*,filter[restaurant_id][_eq]=${restaurantData.resDetails.id}
        method:ApiMethod.GET,
        token:restaurantData.userAccess.access_token,
        sort:'-date_created',
        fields:"*.*",
        filter:{
          restaurant_id:{
            _eq:restaurantData.resDetails.id
          }
          // menu_id:{
          //   _eq:menu_id
          // }
        }
      }).then((res)=>
      {
      
        let subMenus = []
        const subMenuData = res.data.data
        console.log("thiss is teh submenuuuu detaaaaaa",subMenuData)
        if(subMenuData.length >0){
          subMenuData.map((item,index)=>
          subMenus.push({
              id: item.id,
              label: item.option_name,
              value:item.option_name,
              price:item.price,
              option_name:item.option_name,
              description:item.description,
              option_name:item.option_name,
              name:item.name
          })
          )
          subMenus.push({
            description: "",
            id: "e17af48e-e01e-11ee-8e34-fced4b776b4b",
            label:"+ New Sub Menu",
            name:"",
            option_name:"",
            placeholder:"",
            price:"",
            value:"+ New Sub Menu",
        })
        console.log("subMenus",subMenus)
          setAddSubMenuOption(subMenus)
        }else{
          setAddSubMenuOption([{
            description: "",
            id: "e17af48e-e01e-11ee-8e34-fced4b776b4b",
            label:"+ New Sub Menu",
            name:"",
            option_name:"",
            placeholder:"",
            price:"",
            value:"+ New Sub Menu",
          }])
        }      
      }      
      ).catch((err)=>
        console.log(err)
      )
    // }else{
    //   setAddSubMenuOption([{
    //     description: "",
    //     id: "e17af48e-e01e-11ee-8e34-fced4b776b4b",
    //     label:"+ Add More",
    //     name:"",
    //     option_name:"",
    //     placeholder:"",
    //     price:"",
    //     value:"+ Add More",
    //   }])
    // }
    
  }
  const updateObjectByIndex =(array, index, newObject)=> {
      return array.map((item, i) => {
          if (i === index) {
              return newObject;
          } else {
              return item;
          }
      });
  }
  const filterOptions = (options, selected) => {
    console.log("filterOptions")
    console.log(options,selected)
    const opt = options.filter(item => item.id !== selected.id);
    console.log("opt",opt)
    return opt
  };
  // const filterOptions = (options, selected) => {
  //   console.log("selected",selected.length)
  //   if(selected.length>0){
  //     const selectedIds = selected.map((item) => item.id);
  //     const opt =  options.filter((option) => !selectedIds.includes(option.id));
  //     return opt
  //   }else{
  //     return options
  //   }
    
  // };

  useEffect(()=>{
    getSubMenu()
  },[])
  useEffect(()=>{
   
    if(isEdit){
      console.log("edit",dataToUpdate)
      console.log("dataToUpdate.sub_menus",dataToUpdate.sub_menus)
      setSelectedImg(getImageUpdload(dataToUpdate.image.id))
      setMenuName(dataToUpdate.name)
      setMenuPrice(parseFloat(dataToUpdate.price).toFixed(2))
      //({"id":dataToUpdate.status_type.id,"label":dataToUpdate.status_tsetMenuTypeDefype.description,"value":dataToUpdate.status_type.description})//dataToUpdate.status_type
      setMenuDesc(dataToUpdate.description)
      setMenuCatDef({"id":dataToUpdate.category.id,"label":dataToUpdate.category.description,"value":dataToUpdate.category.description})//dataToUpdate.category
      //setTimepRep(dataToUpdate.time_prep)
      setServings(dataToUpdate.serving_count)
      const subMenusNotarchived = dataToUpdate.menu_submenus.filter(function(item) {
        return item.status !== 'archived';
      });
      //restructure to look like the sub_menus
       const newData =  subMenusNotarchived.map((item)=>{
            return {...item.sub_menu}
      })

      setSubMenu(newData)
      setSubMenuDef(newData)
    }
  },[isEdit])
  useEffect(()=>{
    getStatusTypes()
    getCategory()
    getStations()
  },[JSON.stringify(statusType),JSON.stringify(category),JSON.stringify(timeprep),JSON.stringify(renderApp),JSON.stringify(menuTypeDef),JSON.stringify(menuCatDef)])
  return (
    <>
    <div id="AppRestoForm" className="content-modal" style={containerStyle}>
        <div className="head-modal">
          <p className="title">{title}</p>
          {
            isHideMenu &&(<a className="hideMenu" href="javascript:void(0)" onClick={async() => {
              const updateStat = dataToUpdate.status =='published' ? 'draft':'published'
              await ApiCall({
                apiEndpoint:`/items/menus/${dataToUpdate.id}`,
                method:ApiMethod.PATCH,
                token:restaurantData.userAccess.access_token,
                apiData:{
                  status:updateStat
                }
              })
              window.location.href = "/restaurant-main-page";
            }}><RemoveRedEyeIcon /> Hide From Menu </a>)
          }
        </div>
        <div className="content">
          <div className="imgSection">
            <label className="imgTitle">Food Image</label>
            <div className="imgHolder">
              {
                isEdit ?<img src={selectedImg} className="modalImgProd"/>: selectedImg && <img src={selectedImg} className="modalImgProd"/> 
              }
              
              <div className="changeImage">
                  <div className="imgIcon">
                    <ImageIcon />
                  </div>
                  <div className="imgContentHolder" style={{position:'relative'}}>
                    <a href="javascript:void(0)" className="changeLabel">{isEdit ? "Change Food Image":"Add Food Image"}</a>
                    <input id="file" type="file" accept="image/*" onChange={handleChangeImage} style={{position:'absolute',opacity:0,height:'100%',width:'100%'}}/>
                    <p className="changeDesc">(Max. File size: 25 MB)</p>
                  </div>
              </div>
            </div>
          </div>
          {/* {
            !isEdit && <div className="switch">
            
            <p className="title">Create sub menu</p>
            <Switch
              className="customSwitch"
              onChange={() => setFoodIsSub(!foodIsSub)}
              checked={foodIsSub}
              onColor="#DEDEDE"
              offColor="#DEDEDE"
              onHandleColor="#F0522B"
              offHandleColor="#CACACA"
              uncheckedIcon={false}
              checkedIcon={false}
            />
          </div>
          } */}
          
          <div className="inputsHolder">
            <input className="inputField" style={{backgroundColor:"white",border:"1px solid gray"}} defaultValue={menuName} onChange={(e) => {
              setMenuName(e.nativeEvent.target.value)
            }}  placeholder="Food Name" />
            <div className="inputIconHolder">
              <AttachMoneyIcon className="dollarIcon" />
              <input className="inputField" style={{backgroundColor:"white",border:"1px solid gray"}} type="number" defaultValue={menuPrice} onChange={(e) => {
              setMenuPrice(e.nativeEvent.target.value)
            }}placeholder="Price"/>
            </div>
          </div>
          {/* {
            foodIsSub &&<div className="inputsHolder">
            <input className="inputField"  style={{backgroundColor:"white",border:"1px solid gray"}}defaultValue={optionName} onChange={(e) => {
              setOptionName(e.nativeEvent.target.value)
            }}  placeholder="Option Name" />
          </div>
          } */}
            <Autocomplete
              disablePortal
              size="small"
              disableClearable
              options={statusType}
              getOptionLabel={(option) => option?.label}
              value={statusTypeSelected}
              // sx={{ width: 300 }}
              onChange={(event, newValue) => 
                setStatusTypeSelected(newValue)
              }
              renderInput={(params) => <TextField {...params}  placeholder="Status Type" />}
              renderOption={(props, option) =>
                          <Stack sx={{backgroundColor:'#fffdff'}}>
                            {
                              option?.label === '+ New Status Type' ? (
                                <Button
                                  fullWidth
                                  onClick={()=> {
                                    setShowModal(true)
                                    setModalMode("add")
                                    setSubMenuInput({
                                      desc:"",
                                      type:'status type'
                                    })
                                  }}
                                >
                                  <Typography sx={{color:"#F0522B",textAlign:'left',width:"100%"}}>
                                    {option.label}
                                  </Typography>
                                
                                </Button>
                              ) : (
                               <li style={{paddingLeft:5,paddingRight:5,cursor:'pointer',border:"1px solid #eeeae9"}} onClick={()=>{
                               }}>
                                <Stack sx={{flexDirection:'row',alignItems:'center',justifyContent:'space-between',width:'100%'}}>
                                  <span {...props} style={{flex:1}}>{option?.label} </span>
                                </Stack>
                                </li>
                              )
                            }
                          </Stack>
            }
            />
             {/* <AppDropDown className="inputField" addExtra={true} isDoneFetching={isDoneFetching} onDataReceived={getStatusType} defaultValue={menuTypeDef} data={[...statusType,{"id":"e17af48e-e01e-11ee-8e34-fced4b776b4b","label":"+ Add More","value":"+ Add More"}]} collection={"menus_status_type"}  placeholder={"Status Type"}/> */}
           
             <div className="textAreaHolder">
                <textarea  className="inputField" maxLength={500}  defaultValue={menuDesc} style={{height: 300, resize: 'none',backgroundColor:"white",border:"1px solid gray"}} onChange={(e) => {
                  if(menuDesc && menuDesc.length>499){
                    setSnackConfig({
                      message:"Description charater limit reached",
                      show:true,
                      type:"error"
                    })
                  }else{
                    setMenuDesc(e.nativeEvent.target.value)
                  }
                 
                }} placeholder="Description" />
                <label>{menuDesc?menuDesc.length:0} / 500</label>
              </div>
            
              <Autocomplete
              disablePortal
              size="small"
              disableClearable
              options={category}
              getOptionLabel={(option) => option?.label}
              value={categorySelected}
              onChange={(event, newValue) => 
                setCategorySelected(newValue)
              }
              // sx={{ width: 300 }}
              renderInput={(params) => <TextField {...params}  placeholder="Category" />}
              renderOption={(props, option) =>
                          <Stack sx={{backgroundColor:'#fffdff'}}>
                            {
                              option?.label === '+ New Category' ? (
                                <Button
                                  fullWidth
                                  onClick={()=> {
                                    setShowModal(true)
                                    setModalMode("add")
                                    setSubMenuInput({
                                      desc:"",
                                      type:'category'
                                    })
                                  }}
                                >
                                  <Typography sx={{color:"#F0522B",textAlign:'left',width:"100%"}}>
                                    {option.label}
                                  </Typography>
                                
                                </Button>
                              ) : (
                               <li style={{paddingLeft:5,paddingRight:5,cursor:'pointer',border:"1px solid #eeeae9"}} onClick={()=>{
                               }}>
                                <Stack sx={{flexDirection:'row',alignItems:'center',justifyContent:'space-between',width:'100%'}}>
                                  <span {...props} style={{flex:1}}>{option?.label} </span>
                                </Stack>
                                </li>
                              )
                            }
                          </Stack>
            }
            />
            {/* <AppDropDown className="inputField" addExtra={true} defaultValue={menuCatDef} isDoneFetching={isDoneFetching} onDataReceived={getCat} data={[...category,{"id":"e17af48e-e01e-11ee-8e34-fced4b776b4b","label":"+ Add More","value":"+ Add More"}]}  placeholder={"Category"} collection={'category'}/> */}
            <Stack sx={{flexDirection:'row',alignItems:'center',justifyContent:"space-between",mt:3,mb:2}}>
              <p className="title" style={{fontSize:'18px'}}>Sub Menu</p>
             
            </Stack>
            
              {
                addSubMenu.length>0 &&
                addSubMenu.map((item,index)=>{
                  return <Stack  sx={{flexDirection:'row',alignItems:'stretch',justifyContent:'flex-start'}} className="addSub">
                      <Autocomplete
                        size="small"
                        disablePortal
                        // disableClearable
                        options={addSubMenuOption}
                        getOptionLabel={(option) => option?.name}
                        value={item}
                        //options={filterOptions(addSubMenuOption, item)}
                        sx={{ flex:1}}
                        onChange={(event, newValue) => {
                          const newObjects =  {
                            description: newValue !==null?newValue.description:"",
                            id: newValue !==null?newValue.id:"",
                            label:newValue !==null?newValue.name:"",
                            name:newValue !==null?newValue.name:"",
                            option_name:newValue !==null?newValue.option_name:"",
                            placeholder:newValue !==null?newValue.name:"",
                            price:newValue!==null ?newValue.price:""
                          }
                          const  updateObjects = updateObjectByIndex(addSubMenu,index,newObjects)
                          setAddSubMenu(updateObjects)
                          // const idExists = addSubMenu.some(item => item.id === newValue?.id);
                          
                          // if(idExists){
                          //   setSnackConfig({
                          //     message:`${newValue.option_name} sub menu already selected`,
                          //     show:true,
                          //     type:"error"
                          //   })
                          // }else{
                          //   const newObjects =  {
                          //     description: newValue !==null?newValue.description:"",
                          //     id: newValue !==null?newValue.id:"",
                          //     label:newValue !==null?newValue.name:"",
                          //     name:newValue !==null?newValue.name:"",
                          //     option_name:newValue !==null?newValue.option_name:"",
                          //     placeholder:newValue !==null?newValue.name:"",
                          //     price:newValue!==null ?newValue.price:""
                          //   }
                          //   const  updateObjects = updateObjectByIndex(addSubMenu,index,newObjects)
                          //   setAddSubMenu(updateObjects)
                          // }
                          
                          
                        }}
                    
                        renderInput={(params) => <TextField {...params}  placeholder="search/choose sub menu" />}
                        renderOption={(props, option) =>
                          <Stack sx={{backgroundColor:'#fffdff'}}>
                            {
                              option?.label === '+ New Sub Menu' ? (
                                <Button
                                  fullWidth
                                  onClick={()=> {
                                    setShowModal(true)
                                    setModalMode("add")
                                    setSubMenuInput({
                                      name:"",
                                      desc:"",
                                      price:null,
                                      type:'sub menu'
                                    })
                                  }}
                                >
                                  <Typography sx={{color:"#F0522B",textAlign:'left',width:"100%"}}>
                                    {option?.label}
                                  </Typography>
                                
                                </Button>
                              ) : (
                               <li style={{paddingLeft:5,paddingRight:5,cursor:'pointer',border:"1px solid #eeeae9"}} onClick={()=>{
                               }}>
                                <Stack sx={{flexDirection:'row',alignItems:'center',justifyContent:'space-between',width:'100%'}}>
                                  <span {...props} style={{flex:1}}>{option?.name} </span>
                                  <Stack sx={{flexDirection:'row',alignItems:'center',ml:2}}>
                                      <IconButton aria-label="edit" onClick={()=>{}}>
                                        <EditIcon  sx={{color:'red',fontSize:18}} fontSize="large"  onClick={()=> {
                                           setModalMode("edit")
                                            setShowModal(true)
                                            setSubMenuInput({
                                              name:option.name,
                                              desc:option.description,
                                              price:option.price,
                                              id:option.id,
                                              type:'sub menu'
                                            })
                                          }}/>
                                      </IconButton>
                                      <IconButton aria-label="delete" onClick={()=>{}}>
                                          <DeleteIcon  sx={{color:'red',fontSize:18}} fontSize="large" onClick={async()=>{
                                            console.log(option.id)
                                            try {
                                              setCallingAPI(true)
                                             const data =  await ApiCall({
                                                apiEndpoint:`/items/menu_submenu`,
                                                method:ApiMethod.GET,
                                                token:restaurantData.userAccess.access_token,
                                                filter:{
                                                  sub_menu:{
                                                    _eq:option.id
                                                  }
                                                 
                                                }
                                              })
                                              const datas = data.data.data
                                              if(datas.length>0){
                                                setSnackConfig({
                                                  message:"Unable to delete. Sub menu already in used",
                                                  show:true,
                                                  type:"error"
                                                })
                                                setCallingAPI(false)
                                              }else{
                                                await ApiCall({
                                                  apiEndpoint:`/items/sub_menu/${option.id}`,
                                                  method:ApiMethod.DELETE,
                                                  token:restaurantData.userAccess.access_token,
                                                }).then(async(res)=>{
                                                  console.log(res)
                                                  await getSubMenu()
                                                  setSnackConfig({
                                                    message:"Sub menu already deleted",
                                                    show:true,
                                                    type:"success"
                                                  })
                                                  setCallingAPI(false)
                                                }).catch((err)=>{
                                                  console.log(err)
                                                  setSnackConfig({
                                                    message:"Something went wrong.Unable to delete Sub menu",
                                                    show:true,
                                                    type:"error"
                                                  })
                                                  setCallingAPI(false)
                                                })
                                               
                                              }
                                            } catch (error) {
                                              console.log("Error checking sub menu in menu submenu",error)
                                              setCallingAPI(false)
                                            }
                                            
                                            //check if sub menu id existing in  menu sub menu
                                            //else delete
                                          }}/>
                                      </IconButton>
                                  </Stack>
                                
                                </Stack>
                                </li>
                              )
                            }
                          </Stack>
                          
                        }
                      />
                      <Stack sx={{flexDirection:'row',alignItems:'stretch',ml:2}}>
                        <Stack sx={{justifyContent:'center'}}>
                          <AttachMoneyIcon className="label labelIcon" style={{fontSize:22,color:"#959595"}} />
                        </Stack>
                        {/* <h1>{item.price}</h1> */}
                        <TextField className="addSubMenuPrice" variant="outlined" sx={{marginLeft:1,marginRight:1,width:100}}  size="small" placeholder="Price" value={item.price} onChange={(e)=>{
                          const newValue = e.target.value
                          const newObjects =  {
                            ...item,
                            price:newValue!==null ?newValue:""
                          }
                          const  updateObjects = updateObjectByIndex(addSubMenu,index,newObjects)
                          setAddSubMenu(updateObjects)
                        }}/>
                      </Stack>
                      
                      <Stack sx={{justifyContent:'center'}}>
                        {/* note : Add prompt delete modal */}
                        <DeleteIcon  sx={{color:'red',fontSize:20,cursor:'pointer'}} fontSize="large" onClick={()=>{
                          const updatedItems = addSubMenu.filter((_, indexA) => indexA !== index);
                          const addSubMenuOptionAvail =  addSubMenuOption
                          // const updatedItemsOptions = addSubMenuOptionAvail.filter(options => options.id !== item.id);
                          //const updatedItemsOptions = addSubMenuOptionAvail.splice(index, 1)
                          setAddSubMenu(updatedItems)
                          // setAddSubMenuOption(updatedItemsOptions)
                        }}/>
                      </Stack>
                   
                  </Stack>
                })
              }
               <a className="addVariation" style={
                {
                  textDecoration: "none",
                  fontFamily: "Montserrat",
                  fontSize: "14px",
                  fontWeight: "400",
                  lineHeight: "17px",
                  letterSpacing: "0em",
                  color:" #F0522B",
                  width:'100%',
                  textAlign:'center',
                  marginTop:10
                }
                } href="javascript:void(0)" onClick={()=>{
                setAddSubMenu([...addSubMenu,{"id":"","label":"","placeholder":"","value":"","price":null,"description":null,"option_name":"","name":""}])

                }}>+ Add Sub Menu</a>
                {/* <button onClick={()=>console.log("check timeprep",timeprep)}>Check timeprep</button> */}
              <div className="timePrep">
                <p className="label">Time Preparation</p>
                <div className="TimePrepContent" >
                  <div className="TimePrepHolder" style={{display:'flex',alignItems:'stretch'}}>
                    <div>
                      <input className="time" style={{backgroundColor:"white",border:"1px solid gray",height:'100%'}} placeholder="time" type="number" defaultValue={timeprep.startTime} onChange={(e)=>
                      setTimepRep({...timeprep,startTime:e.nativeEvent.target.value})
                      }/>
                    </div>
                     <Autocomplete
                     size="small"
                      disablePortal
                      disableClearable
                      value={timeprep.startTimeType}
                      options={[{ label: 'min/s', id: 0 },
                      { label: 'hr/s', id: 2 },]}
                      sx={{ width: 300 }}
                      onChange={(event, newValue) => {
                        console.log(newValue)
                        setTimepRep({...timeprep,startTimeType:newValue})
                      }}
                      renderInput={(params) => <TextField {...params} placeholder="Unit" />}
                    />
                  </div>
                  <div className="dash" />
                  <div className="TimePrepHolder" style={{display:'flex',alignItems:'stretch'}}>
                    <div>
                      <input className="time" style={{backgroundColor:"white",border:"1px solid gray",height:'100%'}} placeholder="time" type="number" defaultValue={timeprep.endTime} onChange={(e)=>
                      setTimepRep({...timeprep,endTime:e.nativeEvent.target.value})
                      } />
                    </div>
                    <Autocomplete
                     size="small"
                      disablePortal
                      disableClearable
                      value={timeprep.endTimeType}
                      options={[{ label: 'min/s', id: 0 },
                      { label: 'hr/s', id: 2 },]}
                      sx={{ width: 300 }}
                      onChange={(event, newValue) => {
                        console.log(newValue)
                        setTimepRep({...timeprep,endTimeType:newValue})
                      }}
                      renderInput={(params) => <TextField {...params} placeholder="Unit" />}
                    />
                  </div>
                </div>
              </div>
              <div className="servings">
        
          <p className="label">Servings</p>
            <div className="" style={{flexDirection:'row',display:'flex',alignItems:'center',flexWrap:'wrap'}}>
              <input className="servingNumber inputField" style={{backgroundColor:"white",border:"1px solid gray",width:230}} placeholder="2-3" value={servings} onChange={(e)=>{
                  // setServings(e.nativeEvent.target.value)
                  // personServings(e.nativeEvent.target.value)
                  setServings(e.nativeEvent.target.value)
              } }/>
              <p className="servingPersons" style={{marginLeft:10}}>Persons Per Serving</p>
            </div>
            <div className="sendTo">
              <p className="label">Send Order To</p>
              <Autocomplete
              disablePortal
              size="small"
              disableClearable
              options={station}
              getOptionLabel={(option) => option?.label}
              value={selectedstation}
              // sx={{ width: 300 }}
              //const [selectedstation,setSelectedStation]= useState()
              onChange={(event, newValue) => {
                console.log("event",event)
                setSelectedStation(newValue)
              }}
              renderInput={(params) => <TextField {...params}  placeholder="Station" />}
              renderOption={(props, option) =>
                          <Stack sx={{backgroundColor:'#fffdff'}}>
                            {
                              option?.label === '+ New Station' ? (
                                <Button
                                  fullWidth
                                  onClick={()=> {
                                    setShowModal(true)
                                    setModalMode("add")
                                    setSelectedImgSub(null)
                                    setSelectedFileSub(null)
                                    setSubMenuInput({
                                      type:'station',
                                      name:null,
                                      selectedImage:null,
                                      selectedFile:null
                                    })
                                  }}
                                >
                                  <Typography sx={{color:"#F0522B",textAlign:'left',width:"100%"}}>
                                    {option.label}
                                  </Typography>
                                
                                </Button>
                              ) : (
                               <li style={{paddingLeft:5,paddingRight:5,cursor:'pointer',border:"1px solid #eeeae9"}} onClick={()=>{
                               }}>
                                <Stack sx={{flexDirection:'row',alignItems:'center',justifyContent:'space-between',width:'100%'}}>
                                  <span {...props} style={{flex:1}}>{option?.label} </span>
                                </Stack>
                                </li>
                              )
                            }
                          </Stack>
            }
            />
              {/* <AppDropDown addExtra={true} addExtraType={"add_station"}  className="inputField" onDataReceived={getStation} collection={"stations"} placeholder={"Stations"}  data={stations}/> */}
            </div>
          </div>
            {/* <AppRestoSubMenu setSubMenuDef={setSubMenuDef} getSelectedSubmenu={setSubMenus} addExtra={true} hasSubMenu={checkHasSubMenu}foodIsSub={foodIsSub}  defaultValueSm={subMenu} defaultValueTp={timeprep} isEdit={isEdit} onDataSMReceived={getSubmenu} station={getStation} timePrep={getTimePrep} defaultValueServ={servings}personServings={setServings}isSendTo={isSendTo}/> */}
            {
            !isEdit ?
            <Stack className="btn_action">
              <Button variant="contained" onClick={()=>addMenu('published')}>Add To Menu</Button>
              <Button variant="outlined" onClick={()=>addMenu()}>Save to Draft</Button>
            </Stack>
            : <div className="modalFooter">
            <Button className="btnSave" variant="contained" onClick={() => {updateMenu()}}>Update</Button>
            <Button className="btnDelete" variant="outlined" onClick={async() => 
              await ApiCall({
                apiEndpoint:`/items/menus/${dataToUpdate.id}`,
                method:ApiMethod.PATCH,
                token:restaurantData.userAccess.access_token,
                apiData:{
                  status:'archived'
                }
            }).then((newMenu)=>{
              window.location.href = "/restaurant-main-page";
            }).catch((errNewmenu)=>
              console.log("Error New Menu",errNewmenu)
            )
            }>Delete</Button>
          </div>
          }
          
         
        </div>
       
        
      </div>
      <AppModal visible={showModal}>
          <Stack sx={{flexDirection:'row',justifyContent:"space-between",alignItems:"center"}}>
              <Typography sx={{flex:1}}>{modalMode=='edit'? "Edit": "Add New" } <span style={{textTransform:'capitalize'}}>{subMenuInput.type}</span></Typography>
              <IconButton aria-label="close" onClick={()=>setShowModal(!showModal)} >
                <CloseIcon/>
              </IconButton>
          </Stack>
          {
            subMenuInput.type=='status type' ? 
            <Stack sx={{mt:1.5}}>
            <TextField style={{backgroundColor:"white",border:"1px solid gray"}} className='form_fields' placeholder={'Name'}  variant="outlined" size="medium" defaultValue={subMenuInput.name} onChange={(e)=>{
                const newData = { ...subMenuInput };
                newData.name = e.nativeEvent.target.value
                setSubMenuInput(newData)
              }} />
          </Stack> :subMenuInput.type=='category' ? 
            <Stack sx={{mt:1.5}}>
            <TextField style={{backgroundColor:"white",border:"1px solid gray"}} className='form_fields' placeholder={'Name'}  variant="outlined" size="medium" defaultValue={subMenuInput.name} onChange={(e)=>{
                const newData = { ...subMenuInput };
                newData.name = e.nativeEvent.target.value
                setSubMenuInput(newData)
              }} />
          </Stack> :subMenuInput.type=='station' ? 
            <>
            <Stack sx={{mt:1.5}}>
                <div className="imgSection">
                  <div className="imgHolder">
                    {
                      selectedImgSub && <img src={selectedImgSub} className="modalImgProd"/>
                    }
                    <div className="changeImage">
                        <div className="imgIcon">
                          <ImageIcon />
                        </div>
                        <div className="imgContentHolder" style={{position:'relative'}}>
                          <a href="javascript:void(0)" className="changeLabel">{"Add sub menu image"}</a>
                          
                          <p className="changeDesc">(Max. File size: 25 MB)</p>
                        </div>
                        <input id="file" type="file" accept="image/*" onChange={handleChangeImageSubMenu} style={{position:'absolute',opacity:0,height:'100%',width:'100%'}}/>
                    </div>
                  </div>
                </div>
              </Stack>
              <Stack sx={{mt:1.5}}>
              <TextField style={{backgroundColor:"white",border:"1px solid gray"}} className='form_fields' placeholder={'Name'}  variant="outlined" size="medium" defaultValue={subMenuInput.name} onChange={(e)=>{
                  const newData = { ...subMenuInput };
                  newData.name = e.nativeEvent.target.value
                  setSubMenuInput(newData)
                }} />
            </Stack>
            </>
           :
          subMenuInput.type=='sub menu' ? 
            <>
              <Stack sx={{mt:1.5}}>
                <TextField style={{backgroundColor:"white",border:"1px solid gray"}} className='form_fields' placeholder={'Name'}  variant="outlined" size="medium" defaultValue={subMenuInput.name} onChange={(e)=>{
                  const newData = { ...subMenuInput };
                  newData.name = e.nativeEvent.target.value
                    setSubMenuInput(newData)
                  }} />
              </Stack>
              <Stack sx={{mt:1.5}}>
                <TextField  style={{backgroundColor:"white",border:"1px solid gray"}}multiline  className='form_fields' placeholder={'Description'}  variant="outlined" size="medium" defaultValue={subMenuInput.desc} onChange={(e)=>{
                  const newData = { ...subMenuInput };
                  newData.desc = e.nativeEvent.target.value
                  setSubMenuInput(newData)
                }} />
              </Stack>
              <Stack sx={{mt:1.5}}>
                <TextField style={{backgroundColor:"white",border:"1px solid gray"}} type="number"  className='form_fields' placeholder={'Price'}  variant="outlined" size="medium" defaultValue={subMenuInput.price} onChange={(e)=>{
                  const newData = { ...subMenuInput };
                  newData.price = e.nativeEvent.target.value
                  setSubMenuInput(newData)
                }} />
              </Stack>
            </>:null
          }
          
          <Stack sx={{mt:2}}>
              <Button variant="contained saveNew" onClick={async()=>{
                //check
                if(subMenuInput.type=='status type'){
                  if(!subMenuInput.name){
                    setSnackConfig({
                      message:"Please provide status name",
                      show:true,
                      type:"error"
                    })
                   
                  }else{
                    setCallingAPI(true)
                    await ApiCall({
                      apiEndpoint:'/items/menus_status_type',
                      method:ApiMethod.POST,
                      token:restaurantData.userAccess.access_token,
                      apiData:{
                        description:subMenuInput.name,
                        restaurant_id:restaurantData.resDetails.id,
                        status:'published',
                      }
                    })
                    .then(async(res)=>{
                      const data = res.data.data
                        const newObjects =  {
                        description: data.description,
                      }
                      const updatedItems = [...statusType]
                      updatedItems.splice(statusType.length - 1, 0, newObjects);
                      setStatusType(updatedItems)
                      await getStatusTypes()
                      setSnackConfig({
                        message:"New status type add successfully",
                        show:true,
                        type:"success"
                      })
                      setCallingAPI(false)
                      setShowModal(false)
                    })
                    .catch((err)=>{
                      setSnackConfig({
                        message:"Failed to add new status type",
                        show:true,
                        type:"error"
                      })
                      setCallingAPI(false)
                    })
                  }
                }else if(subMenuInput.type=='category'){
                  if(!subMenuInput.name){
                    setSnackConfig({
                      message:"Please provide category name",
                      show:true,
                      type:"error"
                    })
                   
                  }else{
                    setCallingAPI(true)
                    await ApiCall({
                      apiEndpoint:'/items/category',
                      method:ApiMethod.POST,
                      token:restaurantData.userAccess.access_token,
                      apiData:{
                        description:subMenuInput.name,
                        restaurant_id:restaurantData.resDetails.id,
                        status:'published',
                      }
                    })
                    .then(async(res)=>{
                      const data = res.data.data
                        const newObjects =  {
                        description: data.description,
                      }
                      const updatedItems = [...category]
                      updatedItems.splice(category.length - 1, 0, newObjects);
                      setCategory(updatedItems)
                      await getCategory()
                      setSnackConfig({
                        message:"New category added successfully",
                        show:true,
                        type:"success"
                      })
                      setCallingAPI(false)
                      setShowModal(false)
                    })
                    .catch((err)=>{
                      setSnackConfig({
                        message:"Failed to add new category",
                        show:true,
                        type:"error"
                      })
                      setCallingAPI(false)
                    })
                  }
                }else if(subMenuInput.type=='station'){
                const fileFormData = new FormData();
                fileFormData.append("folder", restaurantData.resDetails.folder_id);
                fileFormData.append("file", selectedFileSub);
                  if(!selectedFileSub){
                    setSnackConfig({
                      message:"Please provide station icon",
                      show:true,
                      type:"error"
                    })
                  }else  if(!subMenuInput.name){
                    setSnackConfig({
                      message:"Please station name",
                      show:true,
                      type:"error"
                    })
                   
                  }else{
                    setCallingAPI(true)
                    await ApiCall({
                      apiEndpoint: "/files",
                      method: ApiMethod.POST,
                      apiContentType: "multipart/form-data",
                      token:restaurantData.userAccess.access_token,
                      apiData: fileFormData,
                    }).then(async(imgUp)=>{
                      await ApiCall({
                        apiEndpoint:'/items/stations',
                        method:ApiMethod.POST,
                        token:restaurantData.userAccess.access_token,
                        apiData:{
                          icon:imgUp.data.data.id,
                          description:subMenuInput.name,
                          restaurant_id:restaurantData.resDetails.id,
                          status:'published'
                        }
                      }).then(async(newStation)=>{
                        setShowModal(!showModal)
                        EventEmitter.dispatch('updateAddFood')
                        setSnackConfig({
                          message:"New station added successfully.",
                          show:true,
                          type:"success"
                        })
                        setCallingAPI(false)
                      }).catch((err)=>{
                        console.log("failed to add new station",err)
                        setSnackConfig({
                          message:"Failed to add new station.",
                          show:true,
                          type:"error"
                        })
                        setCallingAPI(false)
                      })
                    }).catch((err)=>{
                      console.log("Error upload image of station icon",err)
                      setSnackConfig({
                        message:"Something went wrong.",
                        show:true,
                        type:"error"
                      })
                      setCallingAPI(false)
                    })
                  }
                }else if(subMenuInput.type=='sub menu'){
                  if(!subMenuInput.name){
                    setSnackConfig({
                      message:"Please provide sub menu name",
                      show:true,
                      type:"error"
                    })
                   
                  }
                  else if(!subMenuInput.desc){
                    setSnackConfig({
                      message:"Please provide sub menu description",
                      show:true,
                      type:"error"
                    })
                  }else if(isNaN(parseFloat(subMenuInput.price))){
                    setSnackConfig({
                      message:"Please provide sub menu valid price",
                      show:true,
                      type:"error"
                    })
                  }else{
                    setCallingAPI(true)
                    modalMode=='edit'?               
                    await ApiCall({
                      apiEndpoint:`/items/sub_menu/${subMenuInput.id}`,
                      method:ApiMethod.PATCH,
                      token:restaurantData.userAccess.access_token,
                      apiData:{
                        name:subMenuInput.name,
                        price:subMenuInput.price,
                        description:subMenuInput.desc,
                      }
                    }).then(async(res)=>{
                      await getSubMenu()
                      setShowModal(false)
                      setCallingAPI(false)
                      setSnackConfig({
                        message:"Sub menu updated successfully",
                        show:true,
                        type:"success"
                      })
                      
                      let updatedArray = addSubMenu.map(obj => {
                        if (obj.id === subMenuInput.id) {
                            obj.name = subMenuInput.name;
                        }
                        return obj;
                      });
                      setSubMenu(updatedArray)
                    }).catch((err)=>{
                      console.log("Error Updating sub menu",err)
                      setCallingAPI(false)
                      setSnackConfig({
                        message:"Something went wrong sub menu failed to update",
                        show:true,
                        type:"success"
                      })
                    })
                    :
                    await ApiCall({
                      apiEndpoint:'/items/sub_menu',
                      method:ApiMethod.POST,
                      token:restaurantData.userAccess.access_token,
                      apiData:{
                        name:subMenuInput.name,
                        price:subMenuInput.price,
                        description:subMenuInput.desc,
                        restaurant_id:restaurantData.resDetails.id,
                        status:'published',
                      }
                    }).then((res)=>{
                      const data = res.data.data
                        const newObjects =  {
                        description: data.description,
                        id: data.id,
                        label:data.description,
                        name:data.name,
                        placeholder:data.name,
                        price:data.price
                      }
                      const updatedItems = [...addSubMenuOption]
                      updatedItems.splice(addSubMenuOption.length - 1, 0, newObjects);
                      setAddSubMenuOption(updatedItems)
                      setShowModal(false)
                      setCallingAPI(false)
                    }).catch((err)=>{
                      console.log(err)
                      setShowModal(false)
                      setCallingAPI(false)
                    })
                    
                  }
                }
              
              }
              }><b>{modalMode=='edit'?"Save":"Add"} {subMenuInput.type}</b></Button>
            </Stack>
        </AppModal>
        {
          callingAPI && <AppLoader/>
        }
        <AppSnackBar snackConfig={snackConfig}/>
      </>
  )
}