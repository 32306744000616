import {useState, useEffect} from "react";
import "./styles.scss";
import { json, useNavigate } from "react-router-dom";
import { AppRestoBar, AppMenuBarList, AppPager, AppRestoCard, AppSearch, AppModal, AppFilter, AppRestoForm, AppRestoSubMenu, AppDropDown } from "../../../components";
import { appTheme } from "../../../constants";
import { Button, Stack, TextField, Typography } from "@mui/material";
import { ApiCall, ApiMethod } from "../../../services/AxiosInstance";
import { useSelector } from "react-redux";
import {AppLoader} from '../../../components';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ImageIcon from '@mui/icons-material/Image';
import { Box, FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { getImageUpdload } from "../../../utilities";
import Autocomplete from '@mui/material/Autocomplete';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import {AppSnackBar} from "../../../components";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import EventEmitter from "reactjs-eventemitter";
import { current } from "@reduxjs/toolkit";
export default function RestaurantMainPage() {
  const navigate = useNavigate();
  const [callingAPI,setCallingAPI]=useState(false)
  const [editModal, setEditModal] = useState({data:null,show:false});
  const [categories,setCategories]= useState([])
  const [menus,setMenus]= useState([])
  const [activeCat,setActiveCat]= useState({});
  const [curPage,setCurPage]= useState(1)
  const [currLimit,setCurrLimit]= useState(10)
  const [filter,setFilter]= useState('price');
  const [showLoadMore,setShowLoadMore]=useState(false)
  const [searchInt,setSearchInt]= useState(null);
  const [subMenus,setSubMenus] = useState([])
  const [category,setCategory] = useState([])
  const [statusType,setStatusType] = useState([])
  const [selectedImg,setSelectedImg] = useState()
  const [selectedFile,setSelectedFile] = useState()
  const [selectedIndex,setSelectedIndex] = useState()
  const [showModal,setShowModal]= useState(false);
  const [modalMode,setModalMode]= useState("");
  const [selectedImgSub,setSelectedImgSub] = useState()
  const [selectedFileSub,setSelectedFileSub] = useState()
  const [addSubMenu,setAddSubMenu]= useState([])
  const [addSubMenuOption,setAddSubMenuOption]= useState([])
  const [showDialog, setShowDialog] = useState({show:false,details:null,ind:null});
  const [subMenuInput,setSubMenuInput] =useState({
    name:"",
    optionName:"",
    desc:"",
    price:null,
    type:null
  })
  const [timeprep,setTimepRep]= useState({
    startTime:"",
    startTimeType:"",
    endTime:"",
    endTimeType:""
  })
  const [snackConfig,setSnackConfig]= useState({
    message:null,
    type:"error",//success,error
    show:false
  })
  const updateObjectByIndex =(array, index, newObject)=> {
    return array.map((item, i) => {
        if (i === index) {
            return newObject;
        } else {
            return item;
        }
    });
}
  const handleChangeImageSubMenu = (e) => {
    console.log("selectedFileSub",selectedFileSub)
    if (e.target.files) {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result;
        setSelectedImgSub(base64String);
      }
      reader.readAsDataURL(file);
      setSelectedFileSub(e.target.files[0])
      //console.log(e.target.files[0]);
    }
  };
  const restaurantData = useSelector(state => state.restaurant.restaurantData);
  const getMenus = async(data,sort,searchInt)=>{
    console.log("searchInt",searchInt)
    setCurPage(1)
    let filters =[{
      restaurant:{
        id:{
          _eq:restaurantData.resDetails.id
        }
      }
    }]
    if(data){
      filters.push({
        category:{
          id:{
            _eq:data.id
          }
        }
      })
    }
    if(searchInt){
      filters.push({
        name:{
          _icontains:searchInt
        }
      })
    }
    filters.push({
      status:{
        _neq:'archive'
      }
    })
 
    setCallingAPI(true)
    await ApiCall({
      apiEndpoint:'/items/menus?fields=*,menu_submenus.*,menu_submenus.sub_menu.*',
      method:ApiMethod.GET,
      token:restaurantData.userAccess.access_token,
      fields:'*.*',
      sort:`${sort?sort:filter}`,
      limit:currLimit,
      page:1,
      filter:{
        _and:filters
      }
    }).then(async(menus)=>{
      setCallingAPI(false)
     const menuslength = await ApiCall({
        apiEndpoint:'/items/menus?fields=*,menu_submenus.*,menu_submenus.sub_menu.*',
        method:ApiMethod.GET,
        token:restaurantData.userAccess.access_token,
        fields:'*.*',
        sort:`${sort?sort:filter}`,
        filter:{
          _and:filters
        }
      })
      setMenus(menus.data.data)
      if(menuslength.data.data.length>10){
        setShowLoadMore(true)
      }
      
      
    }).catch((errMenus)=>{
      setCallingAPI(false)
      console.log("Error Getting Menus getMenus",errMenus)
    })
  }
  const getMenusLimit = async(data,sort,searchInt)=>{
    let filters =[{
      restaurant:{
        id:{
          _eq:restaurantData.resDetails.id
        }
       
      }
    }]
    if(data){
      filters.push({
        category:{
          id:{
            _eq:data.id
          }
          
        }
      })
    }
    if(searchInt){
      filters.push({
        name:{
          _icontains:searchInt
        }
      })
    }
    filters.push({
      status:{
        _neq:'archive'
      }
    })
    await ApiCall({
      apiEndpoint:'/items/menus',
      method:ApiMethod.GET,
      token:restaurantData.userAccess.access_token,
      fields:'*.*',
      sort:`${sort?sort:filter}`,
      filter:{
        _and:filters
      }
    }).then((menus)=>{
      const menudata =menus.data.data
      setCurrLimit(menudata)
    }).catch((errMenus)=>{
      console.log("Error Getting Menus getMenusLimit",errMenus)
    })
  }


  const getSubMenus = async() =>{
      try{

       const result = await ApiCall({
          apiEndpoint:`/items/sub_menu?fields=*,filter[restaurant_id][_eq]=${restaurantData.resDetails.id}`,  //get sub menu of the restaurant_id 
          method:ApiMethod.GET,
          token:restaurantData.userAccess.access_token,
          sort:'-date_created',
        })
        let subMenus = []
        const subMenuData = result.data.data
        console.log("thiss is teh submenuuuu detaaaaaa",subMenuData)
        if(subMenuData.length >0){
          subMenuData.map((item,index)=>
          subMenus.push({
              id: item.id,
              label: item.option_name,
              value:item.option_name,
              price:item.price,
              option_name:item.option_name,
              description:item.description,
              option_name:item.option_name,
              name:item.name
          })
          )
          subMenus.push({
            description: "",
            id: "e17af48e-e01e-11ee-8e34-fced4b776b4b",
            label:"+ New Sub Menu",
            name:"",
            option_name:"",
            placeholder:"",
            price:"",
            value:"+ New Sub Menu",
        })
        console.log("subMenus",subMenus)
          //setAddSubMenuOption(subMenus)
          setSubMenus(subMenus)
        }else{
          setAddSubMenuOption([{
            description: "",
            id: "e17af48e-e01e-11ee-8e34-fced4b776b4b",
            label:"+ New Sub Menu",
            name:"",
            option_name:"",
            placeholder:"",
            price:"",
            value:"+ New Sub Menu",
          }])
        }  





     
      }catch(err){

      }
  }


  const getCategories = async()=>{
    await ApiCall({
      apiEndpoint:'/items/category',
      method:ApiMethod.GET,
      token:restaurantData.userAccess.access_token,
      fields:'*.*',
      filter:{
        restaurant_id:{
          id:{
            _eq:restaurantData.resDetails.id
          }
        
        }
      }
    }).then((categories)=>{
      setCategories(categories.data.data)
    }).catch((errCategories)=>{
      console.log("Error Getting Categories",errCategories)
    })
  }
  const getCurrCat =(data)=>{
    setActiveCat(data)
    getMenus(data,filter,searchInt)
    //check here
  }
  const foodSearch = (data,filters,searchInt)=>{
    getMenus(data,filters,searchInt)
  }
  const getSearchRes =(data)=>{
    getMenus(activeCat,filter,data)
  }
  const getFilter =(data)=>{
    let filters = filter
    if(data=="Price"){
      filters ='price'
    }else if(data =="Status"){
      filters ='status_type.description'
    }else if(data =="Name"){
      filters ='name'
    }
    setFilter(filters)
    getMenus(activeCat,filters,searchInt)
   
  }
  const getSearchVal =(data)=>{
    getMenus(activeCat,filter,data)
    setSearchInt(data)
  }
  const getCurrPage = (data)=>{
    setCurPage(data)
    getMenus(activeCat,filter,searchInt,data)

    // getMenus()
  }
  useEffect(()=>{
    // getMenus()
    getStatusTypes()
    getCategory()
    getSubMenus()
    getCategories()
  },[])

 const removeSubMenu =( id )=>{
         console.log("passsedd id",id)
         console.log("editModal.data.menu_submenus",editModal.data.menu_submenus)
           const duplicate = [...editModal.data.menu_submenus]
           const filterSubMenu =  duplicate.filter((item)=>{
                return item.id !== id
           })
           console.log("duplication",filterSubMenu)
           const originalData = editModal.data
           originalData.menu_submenus = filterSubMenu

           setEditModal({data:originalData,show:true});
 }


 const getStatusTypes = async()=>{
  await ApiCall({
    apiEndpoint:'/items/menus_status_type',
    method:ApiMethod.GET,
    token:restaurantData.userAccess.access_token,
    sort:'-date_created',
    filter:{
      restaurant_id:{
        _eq:restaurantData.resDetails.id
      }
    }
  }).then((res)=>
  {
    let statusType = []
    const menusStatusType = res.data.data
    if(menusStatusType.length >0){
      menusStatusType.map((item)=>
      statusType.push({...item,
          label: item.description,
          icon: null,
          value:item.description
          
      })
      // statusType.push(item)
      )
      statusType.push({
        description: "",
        id: "e17af48e-e01e-11ee-8e34-fced4b776b4b",
        label:"+ New Status Type",
        name:"",
        option_name:"",
        placeholder:"",
        price:"",
        value:"+ New Status Type",
    })
      setStatusType(statusType)
    }      
  }      
  ).catch((err)=>
    console.log(err)
  )
}

const handleChangeImage = (e) => {
  if (e.target.files) {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64String = reader.result;
      setSelectedImg(base64String);
    }
    reader.readAsDataURL(file);
    setSelectedFile(e.target.files[0])
    //console.log(e.target.files[0]);
  }
};

 const updateMenu = async() =>{
  //add validations todo
        try{
           console.log("data",editModal.data)
          //  return

          let params = {}

          if(selectedFile){

            const fileFormData = new FormData();
            fileFormData.append("folder", restaurantData.resDetails.folder_id);
            fileFormData.append("file", selectedFile);
 
            const imageResult = await ApiCall({
             apiEndpoint: "/files",
             method: ApiMethod.POST,
             apiContentType: "multipart/form-data",
             token:restaurantData.userAccess.access_token,
             apiData: fileFormData,
           })
 
           const imageID = imageResult.data.data.id
            params = {
              description:editModal.data.description,
              name:editModal.data.name,
              price:editModal.data.price,
              image:imageID,
              status_type:editModal.data.status_type,
              time_prep:editModal.data.time_prep,
              serving_count:editModal.data.serving_count,
              category:editModal.data.category.id,
              status_type:editModal.data.status_type.id
           }
          }else{
            params = {
              description:editModal.data.description,
              name:editModal.data.name,
              price:editModal.data.price,
              status_type:editModal.data.status_type,
              time_prep:editModal.data.time_prep,
              serving_count:editModal.data.serving_count,
              category:editModal.data.category.id,
              status_type:editModal.data.status_type.id
           }
          }
         if(!editModal.data.name){
            setSnackConfig({
              message:"Please add food name",
              show:true,
              type:"error"
            })
          }else if(!editModal.data.price){
            setSnackConfig({
              message:"Please add food price",
              show:true,
              type:"error"
            })
          }else if(!editModal.data.status_type){
            setSnackConfig({
              message:"Please add food status",
              show:true,
              type:"error"
            })
          }else if(!editModal.data.description){
            setSnackConfig({
              message:"Please add food description",
              show:true,
              type:"error"
            })
          }else if(!editModal.data.category.id){
            setSnackConfig({
              message:"Please add food category",
              show:true,
              type:"error"
            })
          }else if(!editModal.data.time_prep.startTime || !editModal.data.time_prep.startTimeType || !editModal.data.time_prep.endTime || !editModal.data.time_prep.startTimeType){
            setSnackConfig({
              message:"Please fill food preparation",
              show:true,
              type:"error"
            })
          }else if(!editModal.data.serving_count){
            setSnackConfig({
              message:"Please add food servings",
              show:true,
              type:"error"
            })
          }else{
            setCallingAPI(true)
            await ApiCall({
              apiEndpoint:`/items/menus/${editModal.data.id}`,  
              method:ApiMethod.PATCH,
              token:restaurantData.userAccess.access_token,
              apiData:{
                  ...params
              }
            })
            console.log("check data", editModal.data)
            if(editModal.data.menu_submenus.length>0){
              Promise.all(
                editModal.data.menu_submenus.map(async(item)=>{
                    if(item.id){
                      console.log("update")
                      //check if have id > update the price,sub menu
                      await  ApiCall({
                        apiEndpoint:`/items/menu_submenu/${item.id}`,
                        method:ApiMethod.PATCH,
                        token:restaurantData.userAccess.access_token,
                        apiData:{
                          sub_menu:item.sub_menu.id,
                          price:item.price
                        }})
                    }else{
                      console.log("add",item)
                      await  ApiCall({
                        apiEndpoint:'/items/menu_submenu',
                        method:ApiMethod.POST,
                        token:restaurantData.userAccess.access_token,
                        apiData:{
                          sub_menu:item.sub_menu.id,
                          menu:editModal.data.id,
                          price:item.price
                        }
                      })
                      //if dont have id > add
                    }
                })
              )
            }
            console.log("category id",editModal.data.category.id)
           //categoryid
            //getCurrCat(editModal.data.category)
            const updatedCat = editModal.data.category
            // setActiveCat({...activeCat,updatedCat})
            // EventEmitter.dispatch('UpdateMenuByCat',editModal.data.category.id)
            setCallingAPI(false)
            setEditModal({data:null,show:false})
            setSnackConfig({
              message:"Food menu updated successfully",
              show:true,
              type:"success"
            })
            EventEmitter.dispatch('selectedCat',updatedCat)
            //setCallingAPI(false)
          }
          
        }catch(err){
          console.log("update errr",err)
          setCallingAPI(false)
          setSnackConfig({
              message:"Food menu failed to update",
              show:true,
              type:"error"
          })
        }
 }
 EventEmitter.subscribe('UpdateMenuByCat', async(event) => {
  await getMenus(event,filter,"",curPage)
})
 const getCategory = async()=>{
      await ApiCall({
        apiEndpoint:'/items/category',
        method:ApiMethod.GET,
        token:restaurantData.userAccess.access_token,
        sort:'-date_created',
        filter:{
          restaurant_id:{
            _eq:restaurantData.resDetails.id
          }
        }
      }).then((res)=>
      {
        let categoryLists = []
        const categoryData = res.data.data
        if(categoryData.length >0){
          categoryData.map((item)=>
          categoryLists.push({
            ...item,
              id: item.id,
              label: item.description,
              icon: null,
              value:item.description,
          })
          )
          categoryLists.push({
            description: "",
            id: "e17af48e-e01e-11ee-8e34-fced4b776b4b",
            label:"+ New Category",
            name:"",
            option_name:"",
            placeholder:"",
            price:"",
            value:"+ New Category",
        })
          setCategory(categoryLists)
        }      
      }      
      ).catch((err)=>
        console.log(err)
      )
}

  return (
   <section id="restoMainPage">
    <div className="my_container" >
      
      <div className="content-container">
        {/* <span>{JSON.stringify(activeCat)}</span> */}
        <AppMenuBarList data={categories} onDataReceived={getCurrCat}containerStyle={{height: 60, display: 'flex', alignItems: 'center', padding: '0px 20px'}}/>
        <AppSearch page={curPage} containerStyle={{marginBottom: 10}} searchVal={getSearchVal} onDataReceived={getSearchRes} handleSearch={foodSearch} sort={filter} activeCat={activeCat} restaurantData={restaurantData}/>
        <AppFilter title={activeCat?.description} onDataReceived={getFilter} />
        {
          callingAPI ? <AppLoader/> :
          <div className="content">
            {
              menus.length>0 ?
              menus.map((item, index) => {
                return <AppRestoCard
                  isModal={editModal.show}
                  restaurantData={restaurantData}
                  key={index}
                  data={item}
                  handleDelete={async()=>
                    {
                      await ApiCall({
                        apiEndpoint:`/items/menus/${item.id}`,
                        method:ApiMethod.PATCH,
                        token:restaurantData.userAccess.access_token,
                        apiData:{
                          status:'archived'
                        }
                      })
                      getMenus(activeCat,filter,searchInt)
                      getMenusLimit(activeCat,filter,searchInt)
                    }
                  
                  }
                  handleEdit={() => {
                    console.log("Edit items",item)
                    setEditModal({data:item,show:true});
                  }}
                />
              })
              :
              <Stack>
                {/* {
                  menus.length==0 && <Typography sx={{textAlign:'center',textDecoration:'underline'}} onClick={()=> window.location.href = "/restaurant-add-food-page"}>Add Menu</Typography>
                } */}
                <Typography sx={{mt:10,color:'red'}}>No {menus.length>0 ?activeCat?.description?.toLowerCase():"data"} found!</Typography>
              </Stack>
              
          }
          {/* <AppPager limit={currLimit.length} itemList={currLimit} onDataReceived={getCurrPage}/> */}
          {
            menus.length>0 && showLoadMore && <Button
            fullWidth
            sx={{mt:2}}
            onClick={async()=> {
              console.log("more",activeCat)
              setCurPage(curPage+1)
              // setCurrLimit(currLimit+2)

              let filters =[{
                restaurant:{
                  id:{
                    _eq:restaurantData.resDetails.id
                  }
                }
              }]
              if(activeCat){
                filters.push({
                  category:{
                    id:{
                      _eq:activeCat.id
                    }
                  }
                })
              }
              if(searchInt){
                filters.push({
                  name:{
                    _icontains:searchInt
                  }
                })
              }
              filters.push({
                status:{
                  _neq:'archive'
                }
              })
          
              // setCallingAPI(true)
              await ApiCall({
                apiEndpoint:'/items/menus?fields=*,menu_submenus.*,menu_submenus.sub_menu.*',
                method:ApiMethod.GET,
                token:restaurantData.userAccess.access_token,
                fields:'*.*',
                sort:filter,
                limit:currLimit,
                page:curPage+1,
                filter:{
                  _and:filters
                }
              }).then(async(menusData)=>{
                if(menusData.data.data.length>0){
                  const menuslength = await ApiCall({
                    apiEndpoint:'/items/menus?fields=*,menu_submenus.*,menu_submenus.sub_menu.*',
                    method:ApiMethod.GET,
                    token:restaurantData.userAccess.access_token,
                    fields:'*.*',
                    sort:filter,
                    filter:{
                      _and:filters
                    }
                  })
                  if(menuslength.data.data.length>10*(curPage+1)){
                    setShowLoadMore(true)
                  }else{
                    setShowLoadMore(false)
                  }
                  const newMenusData = [...menus,...menusData.data.data]
                  setMenus(newMenusData)
                }else{
                  setShowLoadMore(false)
                }
                
                // setCallingAPI(false)
              }).catch((errMenus)=>{
                // setCallingAPI(false)
                console.log("Error Getting Menus getMenus",errMenus)
              })


            //  setCurrLimit(currLimit+10)
            //  await getMenus()
           // setMenus
            }}
          >
            <Typography sx={{color:"#F0522B",textAlign:'center',width:"100%"}}>
              Load More
            </Typography>
          
          </Button>
          }
          

        </div>
        }
      </div>
    </div>
    <AppModal
      visible={editModal.show}
      handleClose={async() => {
        //await getMenus(activeCat)
        const updatedCat = editModal.data.category
        EventEmitter.dispatch('selectedCat',updatedCat)
        setEditModal({data:null,show:false})
      }}
    >
       <div id="AppRestoForm" className="content-modal" >
          <div className="head-modal" >
              <p className="title">{"Edit Details"}</p>
              <a style={{display:"flex",alignItems:'center' ,fontSize:"10px"}}><RemoveRedEyeIcon onClick={()=> setEditModal({data:null,show:false})} /></a>
          </div>

          <div className="content">
              <div className="imgSection">
                 <label className="imgTitle">Food Image</label>
                
                 <div className="imgHolder">
                  {
                    selectedImg ? <img src={selectedImg} className="modalImgProd"/>  :  editModal.data?.image && < img src={getImageUpdload(editModal.data?.image?.id)}  className="modalImgProd"/>
                   
                  }
              
                  <div className="changeImage">
                      <div className="imgIcon">
                        <ImageIcon />
                      </div>
                      <div className="imgContentHolder" style={{position:'relative'}}>
                        <a href="javascript:void(0)" className="changeLabel">{"Change Food Image"}</a>
                                                         
                        <input id="file" type="file" accept="image/*" onChange={handleChangeImage} style={{position:'absolute',opacity:0,height:'100%',width:'100%'}}/> 
                        <p className="changeDesc">(Max. File size: 25 MB)</p>
                      </div>
                  </div>
                </div>
              </div>  

              <div className="inputsHolder">
                <input className="inputField" style={{backgroundColor:"white",border:"1px solid gray"}} defaultValue={editModal.data?.name} onChange={(e) => {
                  // setMenuName(e.nativeEvent.target.value)
                  setEditModal({data:{...editModal.data,name:e.nativeEvent.target.value},show:true})
                }}  placeholder="Food Name" />
                <div className="inputIconHolder">
                  <AttachMoneyIcon className="dollarIcon" />
                  <input className="inputField" style={{backgroundColor:"white",border:"1px solid gray"}} type="number" defaultValue={editModal.data?.price} onChange={(e) => {
                  setEditModal({data:{...editModal.data,price:e.nativeEvent.target.value},show:true})
                  // setMenuPrice(e.nativeEvent.target.value)
                }}placeholder="Price"/>
                </div>
              </div>

              {/* //status typee diri */}
              <Autocomplete
              disablePortal
              size="small"
              disableClearable
              options={statusType}
              getOptionLabel={(option) => option?.label?option.label:""}
              value={{id:editModal.data?.status_type.id,label:editModal.data?.status_type.description,value:editModal.data?.status_type.description}}
              onChange={(event, newValue) => {
                console.log(newValue)
                //console.log("editModal",editModal.data)
                setEditModal({data:{...editModal.data,status_type:newValue},show:true})
              }}
              renderInput={(params) => <TextField {...params}  placeholder="Status Type" />}
              renderOption={(props, option) =>
                          <Stack sx={{backgroundColor:'#fffdff'}}>
                            {
                              option?.label === '+ New Status Type' ? (
                                <Button
                                  fullWidth
                                  onClick={()=> {
                                    setShowModal(true)
                                    setModalMode("add")
                                    setSubMenuInput({
                                      desc:"",
                                      type:'status type'
                                    })
                                  }}
                                >
                                  <Typography sx={{color:"#F0522B",textAlign:'left',width:"100%"}}>
                                    {option.label}
                                  </Typography>
                                
                                </Button>
                              ) : (
                               <li style={{paddingLeft:5,paddingRight:5,cursor:'pointer',border:"1px solid #eeeae9"}} onClick={()=>{
                               }}>
                                <Stack sx={{flexDirection:'row',alignItems:'center',justifyContent:'space-between',width:'100%'}}>
                                  <span {...props} style={{flex:1}}>{option?.label} </span>
                                </Stack>
                                </li>
                              )
                            }
                    </Stack>
                  }
            />
              {/* <AppDropDown className="inputField" addExtra={true}  onDataReceived={(data)=>{
                console.log("thiss is the dataaa",data)
                setEditModal({data:{...editModal.data,status_type:data},show:true})

              }} 
              defaultValue={{id:editModal.data?.status_type.id,label:editModal.data?.status_type.description,value:editModal.data?.status_type.description}} 
              data={statusType} collection={"menus_status_type"}  
              placeholder={"Status Type"}
              /> */}


              <div className="textAreaHolder">
                <textarea className="inputField" maxLength={500}  defaultValue={editModal.data?.description} style={{height: 300, resize: 'none',backgroundColor:"white",border:"1px solid gray"}} onChange={(e) => {
                  setEditModal({data:{...editModal.data,description:e.nativeEvent.target.value},show:true})

                  // if(menuDesc && menuDesc.length>499){
                  //   setSnackConfig({
                  //     message:"Description charater limit reached",
                  //     show:true,
                  //     type:"error"
                  //   })
                  // }else{
                  //   // setMenuDesc(e.nativeEvent.target.value)
                  // }
                 
                }} placeholder="Description" />
                {/* //{menuDesc?menuDesc.length:0} */}
                <label>0 / 500</label>
              </div>
                
            {/* //category diri */}
            <Autocomplete
              disablePortal
              size="small"
              disableClearable
              options={category}
              getOptionLabel={(option) => option?.label?option.label:""}
              value={{id:editModal.data?.category.id,label:editModal.data?.category?.description,value:editModal.data?.category?.description}}
              onChange={(event, newValue) => {
                console.log(newValue)
                //console.log("editModal",editModal.data)
                setEditModal({data:{...editModal.data,category:newValue},show:true})
              }}
              renderInput={(params) => <TextField {...params}  placeholder="Status Type" />}
              renderOption={(props, option) =>
                <Stack sx={{backgroundColor:'#fffdff'}}>
                  {
                    option?.label === '+ New Category' ? (
                      <Button
                        fullWidth
                        onClick={()=> {
                          setShowModal(true)
                          setModalMode("add")
                          setSubMenuInput({
                            desc:"",
                            type:'category'
                          })
                        }}
                      >
                        <Typography sx={{color:"#F0522B",textAlign:'left',width:"100%"}}>
                          {option.label}
                        </Typography>
                      
                      </Button>
                    ) : (
                     <li style={{paddingLeft:5,paddingRight:5,cursor:'pointer',border:"1px solid #eeeae9"}} onClick={()=>{
                     }}>
                      <Stack sx={{flexDirection:'row',alignItems:'center',justifyContent:'space-between',width:'100%'}}>
                        <span {...props} style={{flex:1}}>{option?.label} </span>
                      </Stack>
                      </li>
                    )
                  }
                </Stack>
  }
            />
            {/* <AppDropDown className="inputField" addExtra={true}  
             defaultValue={{id:editModal.data?.category.id,label:editModal.data?.category.description,value:editModal.data?.category.description}}
             onDataReceived={(data)=>{
               console.log("recieevedd dataaaaa",data)
               setEditModal({data:{...editModal.data,category:data},show:true})
            }} data={category}  placeholder={"Category"} collection={'category'}/> */}


            <div>
             <Typography style={{fontWeight:"700", marginBottom:'10px',marginTop:"15px",fontSize:"24px"}} onClick={()=>{
              // setSnackConfig({
              //   message:"Unable to delete. Sub menu already in used",
              //   show:true,
              //   type:"error"
              // })
              console.log(editModal?.data?.menu_submenus)
             }
              
            }>Sub Menu</Typography>
              {/* {editModal?.data  && editModal?.data.menu_submenus.map((data,index)=>{
                const restuctureDefaultData = {
                    ...data.sub_menu
                }
          
              return <div style={{display:'flex',flexDirection:"row",display:'flex',alignItems:'center',flexWrap:"wrap",padding:"20px"}}>
                    
                    <BasicSelect dropDownData={subMenus} index={index}  currentSelected={(item,index)=>{
                        //update editModal?.data.menu_submenus
                        console.log("thisss is theee current selectedd dataaa",data,index) //get current index then update the corresponding menu_submenus
                        const duplicate = [...editModal.data.menu_submenus] 
                        duplicate[index] = { id:data?.id , sub_menu:item}
                      
                        const originalData = editModal.data
                        originalData.menu_submenus = duplicate
                        console.log("thisss is the updateeeddd oneeeeee",originalData)
                        // setEditModal({data:originalData,show:true});
                      }} 
                    defaultValue={restuctureDefaultData}/>
                    <div>
                      <DeleteOutlineIcon onClick={()=>removeSubMenu(data.id)}/>
                    </div>
                  
              </div> 
            })} */}
            {
                editModal?.data?.menu_submenus.length>0 &&
                editModal?.data?.menu_submenus.map((item,index)=>{
                  return <Stack  sx={{flexDirection:'row',alignItems:'stretch',justifyContent:'flex-start',mt:1,mb:1}} className="addSub">
                   
                      <Autocomplete
                        size="small"
                        disablePortal
                        // disableClearable
                        options={subMenus}
                        getOptionLabel={(option) => option?.sub_menu?.name?option.sub_menu.name:""}
                        value={item}
                        //options={filterOptions(addSubMenuOption, item)}
                        sx={{ flex:1}}
                        onChange={(event, newValue) => {
                          const newObjects =  {
                            ...item,
                            price:newValue!==null ?newValue.price:"",
                            sub_menu:{
                              description: newValue !==null?newValue.description:"",
                              id: newValue !==null?newValue.id:"",
                              label:newValue !==null?newValue.name:"",
                              name:newValue !==null?newValue.name:"",
                              option_name:newValue !==null?newValue.option_name:"",
                              placeholder:newValue !==null?newValue.name:"",
                              price:newValue!==null ?newValue.price:""
                            }
                            
                          }
                          const  updateObjects = updateObjectByIndex(editModal?.data?.menu_submenus,index,newObjects)
                          console.log("updateObjects",updateObjects)
                          setEditModal({data:{...editModal.data,menu_submenus:updateObjects},show:true})
                        }}
                    
                        renderInput={(params) => <TextField {...params}  placeholder="search/choose sub menu" />}
                        renderOption={(props, option) =>
                          <Stack sx={{backgroundColor:'#fffdff'}}>
                            {
                              option?.label === '+ New Sub Menu' ? (
                                <Button
                                  fullWidth
                                  onClick={()=> {
                                    setShowModal(true)
                                    setModalMode("add")
                                    setSubMenuInput({
                                      name:"",
                                      desc:"",
                                      price:null,
                                      type:'sub menu'
                                    })
                                  }}
                                >
                                  <Typography sx={{color:"#F0522B",textAlign:'left',width:"100%"}}>
                                    {option?.label}
                                  </Typography>
                                
                                </Button>
                              ) : (
                               <li style={{paddingLeft:5,paddingRight:5,cursor:'pointer',border:"1px solid #eeeae9"}} onClick={()=>{
                               }}>
                                <Stack sx={{flexDirection:'row',alignItems:'center',justifyContent:'space-between',width:'100%'}}>
                                  <span {...props} style={{flex:1}}>{option?.name} </span>
                                  <Stack sx={{flexDirection:'row',alignItems:'center',ml:2}}>
                                      <IconButton aria-label="edit" onClick={()=>{}}>
                                        <EditIcon  sx={{color:'red',fontSize:18}} fontSize="large"  onClick={()=> {
                                           setModalMode("edit")
                                            setShowModal(true)
                                            setSubMenuInput({
                                              name:option.name,
                                              desc:option.description,
                                              price:option.price,
                                              id:option.id,
                                              type:'sub menu'
                                            })
                                          }}/>
                                      </IconButton>
                                      <IconButton aria-label="delete" onClick={()=>{}}>
                                          <DeleteIcon  sx={{color:'red',fontSize:18}} fontSize="large" onClick={async()=>{
                                            try {
                                              setCallingAPI(true)
                                             const data =  await ApiCall({
                                                apiEndpoint:`/items/menu_submenu`,
                                                method:ApiMethod.GET,
                                                token:restaurantData.userAccess.access_token,
                                                filter:{
                                                  sub_menu:{
                                                    _eq:option.id
                                                  }
                                                 
                                                }
                                              })
                                              const datas = data.data.data
                                              if(datas.length>0){
                                                setSnackConfig({
                                                  message:"Unable to delete. Sub menu already in used",
                                                  show:true,
                                                  type:"error"
                                                })
                                                setCallingAPI(false)
                                              }else{
                                                await ApiCall({
                                                  apiEndpoint:`/items/sub_menu/${option.id}`,
                                                  method:ApiMethod.DELETE,
                                                  token:restaurantData.userAccess.access_token,
                                                }).then(async(res)=>{
                                                  console.log(res)
                                                  await getSubMenus()
                                                  setSnackConfig({
                                                    message:"Sub menu already deleted",
                                                    show:true,
                                                    type:"success"
                                                  })
                                                  setCallingAPI(false)
                                                }).catch((err)=>{
                                                  console.log(err)
                                                  setSnackConfig({
                                                    message:"Something went wrong.Unable to delete Sub menu",
                                                    show:true,
                                                    type:"error"
                                                  })
                                                  setCallingAPI(false)
                                                })
                                               
                                              }
                                            } catch (error) {
                                              console.log("Error checking sub menu in menu submenu",error)
                                              setCallingAPI(false)
                                            }
                                            
                                            //check if sub menu id existing in  menu sub menu
                                            //else delete
                                          }}/>
                                      </IconButton>
                                  </Stack>
                                
                                </Stack>
                                </li>
                              )
                            }
                          </Stack>
                          
                        }
                      />
                     
                      <Stack sx={{flexDirection:'row',alignItems:'stretch',ml:2}}>
                        <Stack sx={{justifyContent:'center'}}>
                          <AttachMoneyIcon className="label labelIcon" style={{fontSize:22,color:"#959595"}} />
                        </Stack>
                        {/* <h1>{item.price}</h1> */}
                        <TextField className="addSubMenuPrice" variant="outlined" sx={{marginLeft:1,marginRight:1,width:100}}  size="small" placeholder="Price" value={item.price && parseFloat(item.price).toFixed(2)} onChange={(e)=>{
                          const newValue = e.target.value
                          const newObjects =  {
                            ...item,
                            price:newValue!==null ?newValue:""
                          }
                          const  updateObjects = updateObjectByIndex(editModal?.data?.menu_submenus,index,newObjects)
                          //setAddSubMenu(updateObjects)
                          setEditModal({data:{...editModal.data,menu_submenus:updateObjects},show:true})
                        }}/>
                      
                      </Stack>
                      
                      <Stack sx={{justifyContent:'center'}}>
                        {/* note : Add prompt delete modal */}
                        <DeleteIcon  sx={{color:'red',fontSize:20,cursor:'pointer'}} fontSize="large" onClick={()=>{
                          console.log(index)
                         setShowDialog({show:true,details:editModal.data.menu_submenus[index],ind:index})
                         
                        }}/>
                        <Dialog
                          open={showDialog.show}
                          onClose={async()=>{
                            //await getMenus(activeCat)
                            const updatedCat = editModal.data.category
                            EventEmitter.dispatch('selectedCat',updatedCat)
                            setShowDialog({show:false,details:null,ind:null})
                          }}
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description"
                        >
                          <DialogTitle id="alert-dialog-title">
                            {`Are you sure you want to delete ${showDialog.details?.sub_menu?.name} under menu of ${editModal.data?.name}?`}
                            {/* editModal.data?.name */}
                          </DialogTitle>
                          <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                              {/* {JSON.stringify(showDialog.details)} */}
                            </DialogContentText>
                          </DialogContent>
                          <DialogActions>

                          <Button variant="outlined" onClick={()=>setShowDialog({show:false,details:null})}>No</Button>
                            <Button variant="contained" onClick={async()=>{
                              setShowDialog({show:false,details:null})
                              const updatedItems = editModal?.data?.menu_submenus.filter((_, indexA) => indexA !== index);
                              setEditModal({data:{...editModal.data,menu_submenus:updatedItems},show:true})
                              try {
                                setCallingAPI(true)
                              if(showDialog.details.id){
                                await ApiCall({
                                  apiEndpoint:`/items/menu_submenu/${showDialog.details.id}`,
                                  method:ApiMethod.DELETE,
                                  token:restaurantData.userAccess.access_token,
                                })
                                ///renderRestaurant
                                // EventEmitter.dispatch('renderRestaurant')
                                // await getMenus(activeCat)
                                setCallingAPI(false)
                                setSnackConfig({
                                  message:"Sub menu already deleted",
                                  show:true,
                                  type:"success"
                                })
                              }else{
                                setCallingAPI(false)
                                setSnackConfig({
                                  message:"Sub menu already deleted",
                                  show:true,
                                  type:"success"
                                })
                              }
                              } catch (error) {
                                setCallingAPI(false)
                                setSnackConfig({
                                  message:"Something went wrong",
                                  show:true,
                                  type:"error"
                                })
                              }
                              
                             
                            }}>
                              Yes
                            </Button>
                          </DialogActions>
                        </Dialog>
                      </Stack>
                   
                  </Stack>
                })
              }
            <a className="addVariation" style={
                {
                  textDecoration: "none",
                  fontFamily: "Montserrat",
                  fontSize: "14px",
                  fontWeight: "400",
                  lineHeight: "17px",
                  letterSpacing: "0em",
                  color:" #F0522B",
                  width:'100%',
                  textAlign:'center',
                  display:'block',
                  marginTop:"20px"
                }
                } href="javascript:void(0)" onClick={()=>{
                //setAddSubMenu([...addSubMenu,{"id":"","label":"","placeholder":"","value":"","price":null,"description":null,"option_name":"","name":""}])
                setEditModal({data:{...editModal.data,menu_submenus:[...editModal.data.menu_submenus,{"id":"","label":"","placeholder":"","value":"","price":null,"description":null,"option_name":"","name":""}]},show:true})
                }}>+ Add Sub Menu</a>

          <div className="timePrep">
                <p className="label">Time Preparation</p>
                <div className="TimePrepContent" >
                  <div className="TimePrepHolder" style={{display:'flex',alignItems:'stretch'}}>
                    <div>
                      <input className="time" style={{backgroundColor:"white",border:"1px solid gray",height:'100%'}} placeholder="time" type="number" defaultValue={editModal.data?.time_prep?.startTime} onChange={(e)=>
                      // setTimepRep({...timeprep,startTime:e.nativeEvent.target.value})
                      setEditModal({data:{ ...editModal.data,time_prep:{...editModal.data.time_prep,startTime:e.nativeEvent.target.value}},show:true})
                      }/>
                    </div>
                     <Autocomplete
                     size="small"
                      disablePortal
                      disableClearable
                      value={editModal.data?.time_prep?.startTimeType}
                      options={[{ label: 'min/s', id: 0 },
                      { label: 'hr/s', id: 2 },]}
                      sx={{ width: 300 }}
                      onChange={(event, newValue) => {
                        console.log(newValue)
                        ///setTimepRep({...timeprep,startTimeType:newValue})
                        setEditModal({data:{ ...editModal.data,time_prep:{...editModal.data.time_prep,startTimeType:newValue}},show:true})
                      }}
                      renderInput={(params) => <TextField {...params} placeholder="Unit" />}
                    />
                  </div>
                  <div className="dash" />
                  <div className="TimePrepHolder" style={{display:'flex',alignItems:'stretch'}}>
                    <div>
                      <input className="time" style={{backgroundColor:"white",border:"1px solid gray",height:'100%'}} placeholder="time" type="number" defaultValue={editModal.data?.time_prep?.endTime} onChange={(e)=>
                      //setTimepRep({...timeprep,endTime:e.nativeEvent.target.value})
                      setEditModal({data:{ ...editModal.data,time_prep:{...editModal.data.time_prep,endTime:e.nativeEvent.target.value}},show:true})
                      } />
                    </div>
                    <Autocomplete
                     size="small"
                      disablePortal
                      disableClearable
                      value={editModal.data?.time_prep?.endTimeType}
                      options={[{ label: 'min/s', id: 0 },
                      { label: 'hr/s', id: 2 },]}
                      sx={{ width: 300 }}
                      onChange={(event, newValue) => {
                        console.log(newValue)
                        //setTimepRep({...timeprep,endTimeType:newValue})
                        setEditModal({data:{ ...editModal.data,time_prep:{...editModal.data.time_prep,endTimeType:newValue}},show:true})
                      }}
                      renderInput={(params) => <TextField {...params} placeholder="Unit" />}
                    />
                  </div>
                </div>
              </div>
                <div className="servings">
                  <p className="label">Servings</p>
                  <div className="" style={{flexDirection:'row',display:'flex',alignItems:'center',flexWrap:'wrap'}}>
                    <input className="servingNumber inputField" style={{width:"50%",backgroundColor:"white",border:"1px solid gray"}} placeholder="2-3" value={editModal.data?.serving_count} onChange={(e)=>{
                      setEditModal({data:{...editModal.data,serving_count:e.nativeEvent.target.value},show:true})
                        // setServings(e.nativeEvent.target.value)
                        // personServings(e.nativeEvent.target.value)
                    } }/>
                    <p className="servingPersons" style={{marginLeft:10}}>Persons Per Serving</p>
                  </div>
                </div>

            

                  <div className="modalFooter" style={{marginTop:"50px"}}>
                  <Button className="btnSave" variant="contained" onClick={() => {updateMenu()}}>Update</Button>
                  <Button className="btnDelete" variant="outlined" onClick={async() => 
                  //  setShowDialog({show:true,details:editModal.data})
                  {
                              setCallingAPI(true)
                              await ApiCall({
                                  apiEndpoint:`/items/menus/${editModal.data.id}`,
                                  method:ApiMethod.DELETE,
                                  token:restaurantData.userAccess.access_token,
                              }).then((newMenu)=>{
                                setEditModal({data:null,show:false})
                                setCallingAPI(false)
                                setSnackConfig({
                                  message:"Sub menu already deleted",
                                  show:true,
                                  type:"success"
                                })
                                const updatedCat = editModal.data.category
                                EventEmitter.dispatch('selectedCat',updatedCat)
                              }).catch((errNewmenu)=>{
                                console.log("Error New Menu",errNewmenu)
                                setCallingAPI(false)
                                setSnackConfig({
                                  message:"Sub menu already deleted",
                                  show:true,
                                  type:"success"
                                })
                              }
                               
                              )
                  }
                    
                  }>Delete</Button>
                 
                  </div>
            </div>
          
          </div>
       </div>

     

    
    </AppModal>
    <AppModal visible={showModal}>
          <Stack sx={{flexDirection:'row',justifyContent:"space-between",alignItems:"center"}}>
              <Typography sx={{flex:1}}>{modalMode=='edit'? "Edit": "Add New" } <span style={{textTransform:'capitalize'}}>{subMenuInput.type}</span></Typography>
              <IconButton aria-label="close" onClick={()=>setShowModal(!showModal)} >
                <CloseIcon/>
              </IconButton>
          </Stack>
          {
            subMenuInput.type=='status type' ? 
            <Stack sx={{mt:1.5}}>
            <TextField style={{backgroundColor:"white",border:"1px solid gray"}} className='form_fields' placeholder={'Name'}  variant="outlined" size="medium" defaultValue={subMenuInput.name} onChange={(e)=>{
                const newData = { ...subMenuInput };
                newData.name = e.nativeEvent.target.value
                setSubMenuInput(newData)
              }} />
          </Stack> :subMenuInput.type=='category' ? 
            <Stack sx={{mt:1.5}}>
            <TextField style={{backgroundColor:"white",border:"1px solid gray"}} className='form_fields' placeholder={'Name'}  variant="outlined" size="medium" defaultValue={subMenuInput.name} onChange={(e)=>{
                const newData = { ...subMenuInput };
                newData.name = e.nativeEvent.target.value
                setSubMenuInput(newData)
              }} />
          </Stack> :subMenuInput.type=='station' ? 
            <>
            <Stack sx={{mt:1.5}}>
                <div className="imgSection">
                  <div className="imgHolder">
                    {
                      selectedImgSub && <img src={selectedImgSub} className="modalImgProd"/>
                    }
                    <div className="changeImage">
                        <div className="imgIcon">
                          <ImageIcon />
                        </div>
                        <div className="imgContentHolder" style={{position:'relative'}}>
                          <a href="javascript:void(0)" className="changeLabel">{"Add sub menu image"}</a>
                          
                          <p className="changeDesc">(Max. File size: 25 MB)</p>
                        </div>
                        <input id="file" type="file" accept="image/*" onChange={handleChangeImageSubMenu} style={{position:'absolute',opacity:0,height:'100%',width:'100%'}}/>
                    </div>
                  </div>
                </div>
              </Stack>
              <Stack sx={{mt:1.5}}>
              <TextField style={{backgroundColor:"white",border:"1px solid gray"}} className='form_fields' placeholder={'Name'}  variant="outlined" size="medium" defaultValue={subMenuInput.name} onChange={(e)=>{
                  const newData = { ...subMenuInput };
                  newData.name = e.nativeEvent.target.value
                  setSubMenuInput(newData)
                }} />
            </Stack>
            </>
           :
          subMenuInput.type=='sub menu' ? 
            <>
              <Stack sx={{mt:1.5}}>
                <TextField style={{backgroundColor:"white",border:"1px solid gray"}} className='form_fields' placeholder={'Name'}  variant="outlined" size="medium" defaultValue={subMenuInput.name} onChange={(e)=>{
                  const newData = { ...subMenuInput };
                  newData.name = e.nativeEvent.target.value
                    setSubMenuInput(newData)
                  }} />
              </Stack>
              <Stack sx={{mt:1.5}}>
                <TextField  style={{backgroundColor:"white",border:"1px solid gray"}}multiline  className='form_fields' placeholder={'Description'}  variant="outlined" size="medium" defaultValue={subMenuInput.desc} onChange={(e)=>{
                  const newData = { ...subMenuInput };
                  newData.desc = e.nativeEvent.target.value
                  setSubMenuInput(newData)
                }} />
              </Stack>
              <Stack sx={{mt:1.5}}>
                <TextField style={{backgroundColor:"white",border:"1px solid gray"}} type="number"  className='form_fields' placeholder={'Price'}  variant="outlined" size="medium" defaultValue={subMenuInput.price} onChange={(e)=>{
                  const newData = { ...subMenuInput };
                  newData.price = e.nativeEvent.target.value
                  setSubMenuInput(newData)
                }} />
              </Stack>
            </>:null
          }
          
          <Stack sx={{mt:2}}>
              <Button variant="contained saveNew" onClick={async()=>{
                //check
                if(subMenuInput.type=='status type'){
                  if(!subMenuInput.name){
                    setSnackConfig({
                      message:"Please provide status name",
                      show:true,
                      type:"error"
                    })
                   
                  }else{
                    setCallingAPI(true)
                    await ApiCall({
                      apiEndpoint:'/items/menus_status_type',
                      method:ApiMethod.POST,
                      token:restaurantData.userAccess.access_token,
                      apiData:{
                        description:subMenuInput.name,
                        restaurant_id:restaurantData.resDetails.id,
                        status:'published',
                      }
                    })
                    .then(async(res)=>{
                      const data = res.data.data
                        const newObjects =  {
                        description: data.description,
                      }
                      const updatedItems = [...statusType]
                      updatedItems.splice(statusType.length - 1, 0, newObjects);
                      setStatusType(updatedItems)
                      await getStatusTypes()
                      setSnackConfig({
                        message:"New status type add successfully",
                        show:true,
                        type:"success"
                      })
                      setCallingAPI(false)
                      setShowModal(false)
                    })
                    .catch((err)=>{
                      setSnackConfig({
                        message:"Failed to add new status type",
                        show:true,
                        type:"error"
                      })
                      setCallingAPI(false)
                    })
                  }
                }
                else if(subMenuInput.type=='category'){
                  if(!subMenuInput.name){
                    setSnackConfig({
                      message:"Please provide category name",
                      show:true,
                      type:"error"
                    })
                   
                  }else{
                    setCallingAPI(true)
                    await ApiCall({
                      apiEndpoint:'/items/category',
                      method:ApiMethod.POST,
                      token:restaurantData.userAccess.access_token,
                      apiData:{
                        description:subMenuInput.name,
                        restaurant_id:restaurantData.resDetails.id,
                        status:'published',
                      }
                    })
                    .then(async(res)=>{
                      const data = res.data.data
                        const newObjects =  {
                        description: data.description,
                      }
                      const updatedItems = [...category]
                      updatedItems.splice(category.length - 1, 0, newObjects);
                      setCategory(updatedItems)
                      await getCategory()
                      setSnackConfig({
                        message:"New category added successfully",
                        show:true,
                        type:"success"
                      })
                      setCallingAPI(false)
                      setShowModal(false)
                    })
                    .catch((err)=>{
                      setSnackConfig({
                        message:"Failed to add new category",
                        show:true,
                        type:"error"
                      })
                      setCallingAPI(false)
                    })
                  }
                }
                //else if(subMenuInput.type=='station'){
                // const fileFormData = new FormData();
                // fileFormData.append("folder", restaurantData.resDetails.folder_id);
                // fileFormData.append("file", selectedFileSub);
                //   if(!selectedFileSub){
                //     setSnackConfig({
                //       message:"Please provide station icon",
                //       show:true,
                //       type:"error"
                //     })
                //   }else  if(!subMenuInput.name){
                //     setSnackConfig({
                //       message:"Please station name",
                //       show:true,
                //       type:"error"
                //     })
                   
                //   }else{
                //     setCallingAPI(true)
                //     await ApiCall({
                //       apiEndpoint: "/files",
                //       method: ApiMethod.POST,
                //       apiContentType: "multipart/form-data",
                //       token:restaurantData.userAccess.access_token,
                //       apiData: fileFormData,
                //     }).then(async(imgUp)=>{
                //       await ApiCall({
                //         apiEndpoint:'/items/stations',
                //         method:ApiMethod.POST,
                //         token:restaurantData.userAccess.access_token,
                //         apiData:{
                //           icon:imgUp.data.data.id,
                //           description:subMenuInput.name,
                //           restaurant_id:restaurantData.resDetails.id,
                //           status:'published'
                //         }
                //       }).then(async(newStation)=>{
                //         setShowModal(!showModal)
                //         EventEmitter.dispatch('updateAddFood')
                //         setSnackConfig({
                //           message:"New station added successfully.",
                //           show:true,
                //           type:"success"
                //         })
                //         setCallingAPI(false)
                //       }).catch((err)=>{
                //         console.log("failed to add new station",err)
                //         setSnackConfig({
                //           message:"Failed to add new station.",
                //           show:true,
                //           type:"error"
                //         })
                //         setCallingAPI(false)
                //       })
                //     }).catch((err)=>{
                //       console.log("Error upload image of station icon",err)
                //       setSnackConfig({
                //         message:"Something went wrong.",
                //         show:true,
                //         type:"error"
                //       })
                //       setCallingAPI(false)
                //     })
                //   }
                // }
                else if(subMenuInput.type=='sub menu'){
                  if(!subMenuInput.name){
                    setSnackConfig({
                      message:"Please provide sub menu name",
                      show:true,
                      type:"error"
                    })
                   
                  }
                  else if(!subMenuInput.desc){
                    setSnackConfig({
                      message:"Please provide sub menu description",
                      show:true,
                      type:"error"
                    })
                  }else if(isNaN(parseFloat(subMenuInput.price))){
                    setSnackConfig({
                      message:"Please provide sub menu valid price",
                      show:true,
                      type:"error"
                    })
                  }else{
                    setCallingAPI(true)
                    modalMode=='edit'?               
                    await ApiCall({
                      apiEndpoint:`/items/sub_menu/${subMenuInput.id}`,
                      method:ApiMethod.PATCH,
                      token:restaurantData.userAccess.access_token,
                      apiData:{
                        name:subMenuInput.name,
                        price:subMenuInput.price,
                        description:subMenuInput.desc,
                      }
                    }).then(async(res)=>{
                      await getSubMenus()
                      setShowModal(false)
                      setCallingAPI(false)
                      setSnackConfig({
                        message:"Sub menu updated successfully",
                        show:true,
                        type:"success"
                      })
                      
                      // const updatedArray = subMenus.map(obj => {
                      //   if (obj.id === subMenuInput.id) {
                      //       obj.name = subMenuInput.name;
                      //   }
                      //   return obj;
                      // });
                      // setSubMenus(updatedArray)
                      // console.log("updatedArray",updatedArray)
                    }).catch((err)=>{
                      console.log("Error Updating sub menu",err)
                      setCallingAPI(false)
                      setSnackConfig({
                        message:"Something went wrong sub menu failed to update",
                        show:true,
                        type:"success"
                      })
                    })
                    :
                    await ApiCall({
                      apiEndpoint:'/items/sub_menu',
                      method:ApiMethod.POST,
                      token:restaurantData.userAccess.access_token,
                      apiData:{
                        name:subMenuInput.name,
                        price:subMenuInput.price,
                        description:subMenuInput.desc,
                        restaurant_id:restaurantData.resDetails.id,
                        status:'published',
                      }
                    }).then((res)=>{
                      const data = res.data.data
                        const newObjects =  {
                        description: data.description,
                        id: data.id,
                        label:data.description,
                        name:data.name,
                        placeholder:data.name,
                        price:data.price
                      }
                      const updatedItems = [...subMenus]
                      updatedItems.splice(0, 0, newObjects);
                      setSubMenus(updatedItems)
                      setShowModal(false)
                      setCallingAPI(false)
                    }).catch((err)=>{
                      console.log(err)
                      setShowModal(false)
                      setCallingAPI(false)
                    })
                    
                  }
                }
              
              }
              }><b>{modalMode=='edit'?"Save":"Add"} {subMenuInput.type}</b></Button>
            </Stack>
        </AppModal>
        <AppSnackBar snackConfig={snackConfig}/>
   </section>
  )
}

function BasicSelect({dropDownData,defaultValue,currentSelected,index}) {


  useEffect(()=>{
    
      setSelectedValue(defaultValue)
  },[])

  const [selectedValue, setSelectedValue] = useState('');

  const handleChange = (event) => {  
    setSelectedValue(event.target.value);
    currentSelected(event.target.value,index)
  };

  const renderValue = (value) => {
    if (value === '') {
      return 'Select';
    }
    return `${value.option_name}`;
  };

  return (
    <Box sx={{ minWidth: 120,display:"flex",alignItems:"center",justifyContent:"space-between",width:"95%" }}>
      <FormControl style={{width:"60%"}}>
        <InputLabel id="demo-simple-select-label">Sub menu</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectedValue}
          label="Range"
          onChange={handleChange}
          renderValue={renderValue}
        >
          {dropDownData.map((item,index) => (
            <MenuItem key={item.id} value={item} style={{width:"100%"}}>
              {`${item.option_name}`}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    
      <Typography style={{fontSize:"20px",color:"black"}}>${selectedValue?.price}</Typography>
    </Box>
  );
}