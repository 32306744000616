import { useEffect, useState } from "react";
import { useSelector,useDispatch } from 'react-redux';
import ActivationNavigation from "./ActivationNavigation";
import KitchenNavigation from "./KitchenNavigation";
import RestaurantNavigation from "./RestaurantNavigation";
import StaffPageNavigation from "./StaffPageNavigation";
import CustomerNavigation from "./CustomerNavigation";
import { Alert } from "@mui/material";
import { setOrderID ,setTableID,setRestaurentId, setListOrderId, setType, setStaffData, setBranchId} from "../redux/reducer/User";
import { getEnvVars } from "../services/Environment";
import { ApiCall,ApiMethod } from "../services/AxiosInstance";
import { GenerateOrderID } from "../utilities";
import { AppLoader } from "../components";

export default function AppRoutes() {
  const { redirect_url } = getEnvVars();
  const dispatch = useDispatch();
  const state = useSelector((state) => state.user);
  const userType = useSelector((state) => state.user.user_type);
  const [extraction, setExtraction] = useState(false);

  useEffect(() => {
    const initialize = async () => {
      const pathname = window.location.href;
      // let pathParts = pathname.split(`${redirect_url}?data=`); 
      const pathParts = pathname.split(new RegExp(`[?/]data=`));
      console.log("pathPartspathPartspathParts",pathParts)
      const params = pathParts[1];
      console.log("paramsparamsparamsparamsparamsparams type",params)
      if (params) {
        const decoded = decodeURIComponent(params);
     
        const parseParams = JSON.parse(decoded);
        const user_type = parseParams?.user_type;

        if (user_type === 'staff') {
          dispatch(setType(user_type));
          dispatch(setStaffData(parseParams));
          return;
        }

        await checkLicenseAndOrder(parseParams);
      } else {
        setExtraction(false);
      }
    };

    initialize();
  }, []);

  const checkLicenseAndOrder = async (parseParams) => {
    try {
      const res = await ApiCall({
        apiEndpoint: '/items/license',
        method: ApiMethod.GET,
        service: true,
        filter: {
          _and: [
            { restauarant_id: { _eq: parseParams.restaurant_id } },
            { license_status: 1 },
          ],
        },
      });

      dispatch(setType(parseParams.user_type));
      const result = await getOrderID(parseParams.restaurant_id);
      dispatch(
        setOrderID({
          order_id: result.id,
          sequence: result.sequence,
        })
      );
      dispatch(
        setBranchId(parseParams?.branch)
      )
      dispatch(
        setTableID({
          table_id: parseParams.table_id,
          table_name: parseParams.table_name,
        })
      );

      dispatch(setRestaurentId(parseParams.restaurant_id));
      setExtraction(true);
    } catch (err) {
      setExtraction(false);
    }
  };

  const getOrderID = async (res_id) => {
    const data = await GenerateOrderID(res_id, ApiMethod, ApiCall);
    return data;
  };

  return (
    <>
    {/* <RestaurantNavigation/> */}
      {userType === 'staff' ? (
        <StaffPageNavigation />
      ) : userType === null ? (
        <ActivationNavigation />
      ) : userType === 'kitchen' ? (
        <KitchenNavigation />
      ) : userType === 'restaurant' ? (
        <RestaurantNavigation />
      ) : extraction ? (
        <CustomerNavigation />
      ) : state.order_id && state.table_id ? (
        <CustomerNavigation />
      ) : (
        <p
          style={{
            color: 'red',
            textAlign: 'center',
            height: '100vh',
            fontSize: '20px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontWeight: 700,
          }}
        >
          Please scan the qr code
        </p>
      )}
    </>
  );
}

