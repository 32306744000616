import {useState, useEffect} from "react";
import "./styles.scss";
import { ApiCall, ApiMethod } from '../../services/AxiosInstance';
import { Dropdown, Menu, MenuButton, MenuItem } from "@mui/base";
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';

export default function AppFilter({
  containerStyle,
  title,
  onDataReceived
}) {
  const [value, setValue] = useState('Price');
  const [display, setDisplay] = useState(false);

//   React.useEffect(() => {
        
//     ApiCall({
//         apiEndpoint: 'https://api.publicapis.org/entries', 
//         method: ApiMethod.GET
//     })
//     .then((response) => {
//         console.log(response.data)
//     })
//     .catch((error) => {
//         console.log(error)
//     })

// },[])

const handleSelect = (data) => {
  setValue(data);
  setDisplay(!display);
  onDataReceived(data)
}

  return (
   <section id="AppFilter" style={containerStyle}>
    <div className="container">
      <h3 className="title">{title}</h3>
      <Dropdown>
        <div className="sortHolder">
          <p>Sort by</p>
          <MenuButton onClick={() => setDisplay(!display)} className="navigation">{value} <ArrowDropDown/></MenuButton>
          {
            display ? (
              <div className="listHolder">
                <a href="javascript:void(0)" onClick={() => handleSelect('Price')}>Price</a>
                <a href="javascript:void(0)" onClick={() => handleSelect('Status')}>Status</a>
                <a href="javascript:void(0)" onClick={() => handleSelect('Name')}>Name</a>
              </div>
            ) : null
          }
          
        </div>
      </Dropdown>
    </div>
   </section>
  )
}