export default async function getCartData(ApiCall,ApiMethod,order_id,table_id){
    try{
      const cartdata =  await ApiCall({
        apiEndpoint:`/items/carts?fields=*,table_id.*,items.*,items.menu.*,items.add_ons.sub_menu.*,items.add_ons.menu_submenu.*,items.add_ons.menu_submenu.sub_menu.*,items.add_ons.*&filter[order_id][_eq]=${order_id}&filter[table_id][_eq]=${table_id}&filter[status][_eq]=draft&deep[items][_filter][status][_eq]=draft`,
        method:ApiMethod.GET,
        service:true   
      })
      console.log("responseeee",cartdata.data.data)
     
      if(cartdata.data.data.length > 0){
        return cartdata.data.data[0]
      }else{
        return []
      }
    }catch(err){
      console.log("getCartData err",err)
    }
}