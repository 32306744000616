import {useState, useEffect} from "react";
import "./styles.scss";
import { ApiCall, ApiMethod } from '../../services/AxiosInstance';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

const style = {
  position: 'absolute',
  top: '40%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  borderRadius: 1,
  bgcolor: 'background.paper',
  pt: 2,
  px: 2,
  pb: 3,
  marginTop: 10,
  marginBottom: 10
};

export default function AppModal({
  containerStyle,
  visible,
  handleClose,
  children
}) {

//   React.useEffect(() => {
        
//     ApiCall({
//         apiEndpoint: 'https://api.publicapis.org/entries', 
//         method: ApiMethod.GET
//     })
//     .then((response) => {
//         console.log(response.data)
//     })
//     .catch((error) => {
//         console.log(error)
//     })

// },[])

  return (
    <Modal
      open={visible}
      onClose={handleClose}
    >
      <Box sx={[{ ...style, width: '90%', maxWidth: 900, maxHeight: 550, overflowY: 'auto' }, containerStyle]}>
        {children}
      </Box>
    </Modal>
  )
}