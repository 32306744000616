import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  restaurant_id:null,
}

const slice = createSlice({
  name: 'kitchen',
  initialState,
  reducers: {
    setKitRestaurentId : (state,action) =>{
      state.restaurant_id = action.payload
    }
  },
})

export const { setKitRestaurentId} = slice.actions

export default slice.reducer