import * as React from "react";
import "./styles.scss";
import { useNavigate } from "react-router-dom";
import { AppRestoForm } from "../../../components";
import { appTheme } from "../../../constants";
import { useSelector } from 'react-redux';

export default function RestaurantAddFoodPage() {
  const navigate = useNavigate();
  const themeCurrent = useSelector((state)=>state.user.currentTheme)
  const theme = appTheme(themeCurrent);

  return (
   <section id="RestaurantAddFoodPage">
    <div className="my_container">
      <AppRestoForm title={"Add Food Details"} isSendTo={true} containerStyle={{backgroundColor: '#ffffff', padding: 20}}/>
    </div>
   </section>
  )
}