import React from 'react'
import { Routes, Route, useNavigate } from "react-router-dom";
import { ProtectedRoute } from "./ProtectedRoute";
import { KitchenMainPage,KitchenTestPage } from "../pages/KitchenPages";
import { ErrorPage,ActivationPage } from "../pages";
export default function KitchenNavigation() {
const navigate = useNavigate();
  return (
   <Routes navigate={navigate}>
        <Route exact path="/" element={<ProtectedRoute><KitchenMainPage/></ProtectedRoute> }/>
        <Route path="/activate/:MOD/:UUID" element={<ActivationPage />} />
        <Route exact path="/kitchen-test-page" element={<ProtectedRoute><KitchenTestPage/></ProtectedRoute> }/>
         {/* if path not found */}
         <Route path="*" element={<KitchenMainPage />} />  
    </Routes>
  )
}
