import React, { useEffect, useState } from 'react';
import "./RestaurantNavigation.scss";
import { ApiCall, ApiMethod } from '../services/AxiosInstance';
import { Routes, Route, useNavigate } from "react-router-dom";
import EventEmitter from "reactjs-eventemitter";
import { useDispatch } from 'react-redux';
import { ProtectedRoute } from "./ProtectedRoute";
import { RestaurantLoginPage, RestaurantMainPage,RestaurantSignupPage,RestaurantTestPage, RestaurantAccountPage,RestaurantSettingsPage } from "../pages/RestaurantPages";
import { ErrorPage,ActivationPage } from "../pages";
import { AppMenuBarList, AppRestoBar } from '../components';
import { appTheme } from '../constants';
import { useSelector } from 'react-redux';
import RestaurantAddFoodPage from '../pages/RestaurantPages/RestaurantAddFoodPage';
import { setRestaurantData } from '../redux/reducer/Restaurant';;


const navItems = [
  {
    title: 'Menu Lists',
    link: '/restaurant-main-page'
  },
  {
    title: 'Add Menu',
    link: '/restaurant-add-food-page'
  },
  {
    title: 'Order Setup',
    link: '/order-setup'
  },
  {
    title: 'Account',
    link: '/account'
  }
];


export default function RestaurantNavigation() {
const [renderApp,setRenderApp] = useState({'update':false})
const navigate = useNavigate();
const themeCurrent = useSelector((state)=>state.user)
const theme = appTheme(themeCurrent);
const [isDisplay, setIsDisplay] = useState(false);
const [activeMenu, setActiveMenu] = useState(null);
const restaurantData = useSelector(state => state.restaurant.restaurantData);
console.log(restaurantData)
const dispatch = useDispatch()
let pathname = window.location.pathname;
console.log("pathname",pathname)
pathname = pathname.split("/");
useEffect(() => {
  window.scrollTo(0, 0);
  
  if(pathname[1] === 'restaurant-login' || pathname[1] === 'restaurant-signup' || pathname[1] === ''){
    setIsDisplay(false);
  }else{
    setIsDisplay(true);
    switch (pathname[1]) {
      case 'restaurant-add-food-page':
        setActiveMenu(1)
        break;
      case 'order-setup':
        setActiveMenu(2)
        break;
      case 'account':
        setActiveMenu(3)
        break;
      default:
        setActiveMenu(0)
        break;
    }
  }
}, [JSON.stringify(renderApp),JSON.stringify(pathname)])
EventEmitter.subscribe('renderRestaurant', event => { 
  setRenderApp({'update':!renderApp.update})
 })
 EventEmitter.subscribe('api401', event => { 
  dispatch(setRestaurantData(null))
 })
  return (
   <div id="routes">
    {
      restaurantData? (
        <div className='routes-header'>
          <div className='my_container' style={{backgroundColor: '#380306'}}>
            <AppRestoBar handleLogout={() => {
              dispatch(setRestaurantData(null))
              navigate('/')
              //window.location = "restaurant-login";             
            }} restaurantData={restaurantData} />
            <div className='nav'>
              {
                navItems.map((item, index) => {
                  const active_menu = activeMenu === index ? 'active' : null;
                  return (
                    <div className={`menuHolder ${active_menu}`} key={index}>
                      {/* <a className='menu' href={item.link}>{item.title}</a> */}
                      <a className='menu'  style ={{cursor:'pointer'}}onClick={()=>{
                          setActiveMenu(index)
                          navigate(item.link)
                        }}>{item.title}</a>
                    </div>
                  )
                })
              }
            </div>
          </div>
        </div>
      ) : null
    }
    {
      restaurantData === null ? 
      <Routes navigate={navigate}>
        <Route exact path="/" element={<ProtectedRoute><RestaurantLoginPage/></ProtectedRoute> }/>
        <Route path="/activate/:MOD/:UUID" element={<ActivationPage />} />
        <Route exact path="/restaurant-signup" element={<ProtectedRoute><RestaurantSignupPage/></ProtectedRoute> }/>
        <Route exact path="/restaurant-login" element={<ProtectedRoute><RestaurantLoginPage/></ProtectedRoute> }/>
        {/* if path not found */}
        <Route path="*" element={<RestaurantLoginPage />} />  
      </Routes> :
      <Routes navigate={navigate}>
        <Route exact path="/" element={<ProtectedRoute><RestaurantLoginPage/></ProtectedRoute> }/>
        <Route exact path="/restaurant-add-food-page" element={<ProtectedRoute><RestaurantAddFoodPage/></ProtectedRoute> }/>
        <Route exact path="/account" element={<ProtectedRoute><RestaurantAccountPage/></ProtectedRoute> }/>
        <Route exact path="/restaurant-main-page" element={<ProtectedRoute><RestaurantMainPage/></ProtectedRoute> }/>
        <Route exact path="/restaurant-login" element={<ProtectedRoute><RestaurantLoginPage/></ProtectedRoute> }/>
        <Route exact path="/restaurant-signup" element={<ProtectedRoute><RestaurantSignupPage/></ProtectedRoute> }/>
        <Route exact path="/order-setup" element={<ProtectedRoute><RestaurantSettingsPage/></ProtectedRoute> }/>
        {/* if path not found */}
        <Route path="*" element={<RestaurantLoginPage />} />  
    </Routes>
    }
   
   </div>
  )
}
