import React, { useEffect, useState } from 'react'
import "./styles.scss"
import { Button, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { ApiCall ,ApiMethod} from '../../../services/AxiosInstance'
const LandingPage = () => {
  const navigate = useNavigate() 
  const staff_data = useSelector((state)=>state.user.staff_data) // {staff_id:'72b93eeb-4c3e-4564-a522-9d87a85fb25d'} 
  const [staffDetails,setStaffDetails] = useState()
  console.log("thiss is the stafff_dataaaaa",staff_data?.staff_id)

  //get staff details
  useEffect(()=>{
    if(staff_data?.staff_id){
       getStaffDetails()
    }
   
  },[JSON.stringify(staff_data)])

  const getStaffDetails = async()=>{
    try{
        const response = await ApiCall({
          apiEndpoint:`/items/staff/${staff_data?.staff_id}?fields=*,restaurant.*,restaurant.page_setting.*`,
          method:ApiMethod.GET,
          service:true
        })
        const result = response.data.data
        console.log("this is the resulttt",result)
        setStaffDetails(result)
        
    }catch(err){
      console.log("getStaffDetails",err)
    }
 }

 const splitString = (id)=>{
    if(id){
      const firstPart = id.split("-")[0];
      return firstPart  
    }
 
 }


  return (
    <div style={{display:"flex",justifyContent:"center"}}>
      <div className='landing_page_container'>
         <div className='logo'>
            
         </div>
         {staffDetails && <>
          <Typography className='sucess_login'>Login Successfully</Typography>
         <div className='staff_info'>
            <Typography className='staff_name'>{staffDetails?.staff_name}</Typography>
            <Typography className='staff_id'>{splitString(staffDetails?.id)}</Typography>
            <Button className='staff_btn'><Typography>{staffDetails?.staff_type}</Typography></Button>
         </div>
         <div className='submit_btn'>
            <Button className='btn' onClick={()=>navigate(`/staff-homepage/${staffDetails?.id}/${staffDetails?.staff_name}`)}><Typography>Continue Dashboard</Typography></Button>
         </div>
         </>}
         
    </div>
    </div>
    
  )
}

export default LandingPage
