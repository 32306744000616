import {useState, useEffect,useRef} from "react";
import "./styles.scss";
import { ApiCall, ApiMethod } from '../../services/AxiosInstance';
import SearchIcon from '@mui/icons-material/Search';

export default function AppSearch({
  containerStyle,
  onDataReceived,
  searchVal,
  activeCat,
  restaurantData,
  sort,
  page,
  handleSearch
}) {
const [value, setValue] = useState();
const typingTimerRef = useRef(null);
const doneTypingInterval = 200; // in milliseconds
useEffect(() => {
    return () => {
      clearTimeout(typingTimerRef.current);
    };
}, []);

  return (
   <section id="AppSearch" style={containerStyle}>
    <div className="container">
      <a className="searchIcon" href="javascript:void(0)" onClick={handleSearch}>
        <SearchIcon />
      </a>
      <input
        placeholder="Search"
        className="searchBar"
        value={value}
        onChange={(e)=>{
          const value = e.target.value;
          setValue(value)
          setTimeout(() => {
            searchVal(value)
          }, doneTypingInterval);
        }
         }
      />
    </div>
   </section>
  )
}