import React from 'react'
import { Routes, Route, useNavigate } from "react-router-dom";
import { ActivationPage } from "../pages";
export default function ActivationNavigation() {
  return (
   <Routes>
      <Route path="/" element={<ActivationPage />} />
      <Route path="/activate/:MOD/:UUID" element={<ActivationPage />} />
      <Route path="*" element={<ActivationPage />} />  
    </Routes>
  )
}
