import * as React from "react";
import "./styles.scss";
import crimson from "../../assets/images/crimson.jpg";
import { Stack, Typography } from "@mui/material";
export default function ErrorPage(navigate) {

//   React.useEffect(() => {
        
//     ApiCall({
//         apiEndpoint: 'https://api.publicapis.org/entries', 
//         method: ApiMethod.GET
//     })
//     .then((response) => {
//         console.log(response.data)
//     })
//     .catch((error) => {
//         console.log(error)
//     })

// },[])


  return (
   <section id="page_not_found">
    <div className="my_container"> 
    <Stack>
      <Typography>
        <b>Page not found</b>
      </Typography>
    </Stack>
    <Stack sx={{mt:2}}>
      <Typography >
        <a href="http://" target="_blank" rel="noopener noreferrer" onClick={()=> window.location.href = "/"} >Go to Home</a>
      </Typography>
    </Stack>
    
   
    </div>
   </section>
  )
}