import React from 'react'
import { Routes, Route, useNavigate } from "react-router-dom";
import { ProtectedRoute } from "./ProtectedRoute";
import { CustomerMainPage,CustomerTestPage, SpashScreen,ReviewOrder,Payment,FoodPreview,ThankYouScreen,WaiterPage} from "../pages/CustomerPages";
import { ErrorPage } from "../pages";
export default function CustomerNavigation() {
  
const navigate = useNavigate();
  return (
   <Routes navigate={navigate}>
        <Route exact path="/" element={<ProtectedRoute><SpashScreen/></ProtectedRoute> }/>
        
        {/*original */}
        {/* <Route exact path="/" element={<ProtectedRoute><SpashScreen/></ProtectedRoute> }/> */}
        <Route exact path="/customer-main-page" element={<ProtectedRoute><CustomerMainPage/></ProtectedRoute> }/>
         
        <Route exact path="/customer-test-page" element={<ProtectedRoute><CustomerTestPage/></ProtectedRoute> }/>
        <Route exact path="/customer-review-order" element={<ProtectedRoute><ReviewOrder/></ProtectedRoute> }/>
        <Route exact path="/customer-payment/:order_id" element={<ProtectedRoute><Payment/></ProtectedRoute> }/>
        <Route exact path="/customer-food-preview/:menu_id" element={<ProtectedRoute><FoodPreview/></ProtectedRoute> }/>
        <Route exact path="/customer-food-preview-existing/:menu_data" element={<ProtectedRoute><FoodPreview/></ProtectedRoute> }/>
        <Route exact path="/customer-waiter" element={<ProtectedRoute><WaiterPage/></ProtectedRoute> }/>
        <Route exact path="/customer-thank-you-screen/:order_id" element={<ProtectedRoute><ThankYouScreen/></ProtectedRoute> }/>
         {/* if path not found */}
         <Route path="*" element={<ErrorPage />} />  
    </Routes>
  )
}
