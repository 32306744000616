import React, { useState } from 'react';
import { Button, Typography } from '@mui/material';
import "./styles.scss";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
import clockIcon from "../../assets/images/clock.png"
import {  appTheme } from '../../constants';
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";

const AppMenuOrder = ({data,onClick}) => {

  const themeCurrent = useSelector((state)=>state.user.currentTheme)
  console.log("themeee currenttttt",themeCurrent)
  const app_theme = appTheme(themeCurrent)
  const navigate = useNavigate();
  console.log("yourrr apthememmm",app_theme)
  return (

        <div className='app_menu_container' style={{border:`1px solid ${app_theme?.fontColor} `}} onClick={()=>{onClick()}}>

            {data?.isBestSeller &&   <Typography className='best_seller_badge' style={{backgroundImage:`linear-gradient(to right, ${app_theme?.themeGradient?.badge?.primary} 50%,${app_theme?.themeGradient?.badge?.secondary})`}}>{data?.isBestSeller}</Typography>}          
            <div className='app_menu_container_left'>
              <img src={data?.image}/>
            </div>
            <div className='app_menu_container_right'>
                <Typography className='menu_title'>{data?.title}</Typography>
                <Typography className='menu_desc'>{data?.description} </Typography>
                <div className='duration_container'><img src={clockIcon} /> <Typography className="menu_duration" >{data?.duration}</Typography></div>
                <Typography className="menu_price" style={{color:app_theme?.fontColor}}><b style={{fontSize:'8px',color:app_theme?.fontColor}}>$</b>{data?.price.toLocaleString()}</Typography>
            </div>
           
        </div>
  );
};

export default AppMenuOrder;
