import React from 'react'
import { Routes, Route, useNavigate } from "react-router-dom";
import { ProtectedRoute } from "./ProtectedRoute";
import HomePage from '../pages/StaffPage/HomePage';
import LandingPage from '../pages/StaffPage/LandingPage';

import { ErrorPage,ActivationPage } from "../pages";
export default function StaffPageNavigation() {
const navigate = useNavigate();

    


  return (
   <Routes navigate={navigate}>
        <Route exact path="/" element={<ProtectedRoute><LandingPage/></ProtectedRoute> }/>
        <Route exact path="/staff-homepage/:staff_id/:staff_name" element={<ProtectedRoute><HomePage/></ProtectedRoute> }/>
        
    </Routes>
  )
}
