import React, { useState, useEffect } from 'react';
import { FormControl, InputLabel, Select, MenuItem, Checkbox } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  formControl: {
    position: 'relative',
    width: '100%',
    borderRadius: '10px',
    backgroundColor: 'white',
    padding: '5px',
    paddingTop: "0px"
  },
  inputLabel: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    left: '12px',
    color: 'gray',
    background: 'white',
    paddingLeft: '5px',
    paddingRight: '5px',
    fontSize: '14px',
  },
  select: {
    color: 'white',
    '&:before': {
      borderColor: 'white',
    },
    '&:after': {
      borderColor: 'white',
    },
  },
  menuPaper: {
    backgroundColor: '#424242',
  },
}));

const MultiSelectDropdown = ({ data, setSelectedCat, defaultValue,addtionalStyles,disabled=false }) => {
  const classes = useStyles();
  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    if (defaultValue && defaultValue.length > 0) {
        const selected = defaultValue.map(defaultItem => {
            const matchingItem = data.find(item => item.id === defaultItem.restaurant_details.id);
            return matchingItem || null;
        });
        console.log("tieeeeeeeeeeee",selected)
        setSelectedOptions(selected);
    }   
}, [defaultValue, data]);


  const handleChange = (event) => {
    setSelectedOptions(event.target.value);
    setSelectedCat(event.target.value)
  };

  return (
    <FormControl disabled={disabled} fullWidth className={classes.formControl} style={addtionalStyles}>
      {selectedOptions.length === 0 && (
        <InputLabel id="multi-select-dropdown-label" className={classes.inputLabel} style={{ color: 'gray',backgroundColor:"transparent" }}>Select Category</InputLabel>
      )}
      <Select
        labelId="multi-select-dropdown-label"
        id="multi-select-dropdown"
        multiple
        style={{ marginTop: "5px" }}
        value={selectedOptions}
        onChange={handleChange}
        className={classes.select}
        MenuProps={{
          classes: { paper: classes.menuPaper },
        }}
        renderValue={(selected) => (
          <div style={{ display: 'flex', flexWrap: 'wrap', paddingLeft: '10px', paddingRight: '10px' }}>
            {selected.map((value) => (
              <span key={value.id} style={{ marginRight: '5px', marginTop: '5px', color: 'white', backgroundColor: "#F0522B", padding: '5px', fontSize: '10px', borderRadius: '5px' }}>{value.name}</span>
            ))}
          </div>
        )}
      >
        {data?.map((item) => (
          <MenuItem key={item.id} value={item} style={{color:"white"}} >
            {item.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default MultiSelectDropdown;
