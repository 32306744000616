import * as React from 'react';
import "./styles.scss";

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { Stack } from '@mui/material';
//add min height add 
//add more details
const AppKitchenCard = ({item,index,updateOrderItems,completeOrder}) => {
  // console.log("AppKitchenCard",item)
  const [more,setMore] =React.useState(false)
  return (
    <Card className={`apc ${more&&"more"}`}>
      <Stack style={{flexDirection:'row',alignItems:'stretch',justifyContent:"space-between"}}>
        <Stack sx={{justifyContent:'center'}}>
          <Typography className='apc_seq'>
            {Number(index) + 1}
          </Typography>
        </Stack>
        <Stack sx={{justifyContent:'center'}}>
          <Typography className='apc_tbl'>
          Table No. <b>{item.table_id.table_name}</b>
        </Typography>
        </Stack>
      
        <Stack sx={{justifyContent:'center',backgroundColor:"#E78716",borderRadius:2}}>
        <Typography className='apc_orderID'>
          Order ID: <b>{item?.order_id?.sequence}</b>
        </Typography>
        </Stack>
      </Stack>
      {
        item.items.map((order,index)=>
          <Stack className={order.status === 'published' ?'apc_orderListCom apc_orderList':'apc_orderList'} key={index}>
            <Stack>
              <Typography className='apc_order'>
                  {order.food_name}
              </Typography>
              <Typography className='apc_orderAddInf'>
                {
                order?.cart_items?.add_ons.map((data)=>{
                           //dapat dynamic nadi ang quantity
                   return <>{data?.description} {data?.quantity},</>
                })}
               
              </Typography>
              {
                order.note &&
                <Typography className='apc_orderRemarkTitle'>
                Customer Remarks
              </Typography>
              }
              {
                 order.note &&
                <TextField
                  id="outlined-multiline-static"
                  multiline
                  rows={5}
                  defaultValue={order.note}
                  InputProps={{
                    readOnly: true,
                  }}
                  className='apc_orderRemarkDesc'
                />
              }
              
              <Button variant="contained" onClick={()=>{
                const numberOfDraftItems = item.items.filter(obj => obj.status === 'draft').length;
                if(order.status === 'draft'){
                  updateOrderItems(order.id)
                  if(numberOfDraftItems===1){
                    completeOrder(item?.id)
                  }
                }
                //add check if the selected order serve is the last to complete
               
              }} className={order.status === 'published' ?'apc_order_serve_btnComp apc_order_serve_btn':'apc_order_serve_btn'}>{order.status === 'published' ? 'Completed':'Order Serve'}</Button>
            </Stack>
          </Stack>
        )
      }
     
     <Button variant="contained" onClick={()=>completeOrder(item?.id)} className='apc_com_btn complete_order'>Complete Order</Button>
      
        {
          
          item.items.length>1 &&
          <div className="more">
            <Button variant="contained" onClick={()=>{
              setMore(!more)
            }} className='apc_com_btn show_more'>{more?"Hide Some": "Show More"}</Button>
          </div>
        }
    
      
     
    </Card >
  );
}

export default AppKitchenCard;