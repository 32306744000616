import * as React from "react";
import "./styles.scss";
import { ApiCall, ApiMethod } from '../../services/AxiosInstance';
import EventEmitter from "reactjs-eventemitter";
export default function AppMenuBarList({
  containerStyle,
  onDataReceived,
  data = [
    // {
    //   id: 1,
    //   label: 'Appetizers'
    // },
    // {
    //   id: 2,
    //   label: 'Soups'
    // },
    // {
    //   id: 3,
    //   label: 'Sides'
    // },
    // {
    //   id: 4,
    //   label: 'Main Coursez'
    // },
    // {
    //   id: 5,
    //   label: 'Dessert'
    // },
  ],
}) {

  const [activeCat,setActiveCat]=React.useState()
  
  React.useEffect(()=>{
    if(data.length>0 && !activeCat ){
      setActiveCat(data[0])
      onDataReceived(data[0])
    }
  },[JSON.stringify(data)])
  
  EventEmitter.subscribe('selectedCat', event => {
    ClickActive(event)
  })
  const ClickActive = (item)=>{
    setActiveCat(item)
    onDataReceived(item)
  }
  return (
   <section id="appMenuBarList" style={containerStyle}>
    <div className="container">
      {
        data.map((item, index) => {
          const active = item.id === activeCat?.id;
          return (
            <div className="menuHolder" style={active ? {backgroundColor: '#380306'} : null}>
              <a href="javascript:void(0)" className="menuBarList" style={active ? {color: '#ffffff'} : null} onClick={()=>ClickActive(item)}>{item.description}</a>
            </div>
          )
        })
      }
    </div>
   </section>
  )
}