import React, { useState, useEffect } from 'react';
import "./styles.scss";
import { useNavigate } from "react-router-dom";
import AppKitchenCard from "../../../components/AppKitchenCard";
import { Stack, Typography } from "@mui/material";
import { orders } from "./dummy";
import companyLogo from "../../../assets/images/company_logo.png";
import powered_by  from "../../../assets/images/powered_by.png";
import Masonry from '@mui/lab/Masonry';
import { QrReader } from 'react-qr-reader';
import { useSelector,useDispatch } from 'react-redux';
import { ApiCall,ApiMethod } from '../../../services/AxiosInstance';
import { AppDropDown, AppLoader } from '../../../components';
import { getImageUpdload } from '../../../utilities';
export default function KitchenMainPage() {
  const navigate = useNavigate();
  const [time, setTime] = useState();
  const [activeOrders,setActiveOrders] = useState([])
  //const state = useSelector((state)=>state.user)
  const [loader,setLoader] = useState(false)
  const [stations,setStations] = useState([])
  const [selectedStation,setSelectedStation] = useState([])
  const [resDetails,setResDetails] = useState([])
  const restaurant_id = useSelector((state)=>state.kitchen.restaurant_id)
  console.log("restaurant_id",restaurant_id)
  //let restaurant_id = '58376f32-fab3-4329-9e1c-c905705ea973' //must be dynamic come from the login
    //ang kitchen dapat ma determin ta siya if ano ni siya na restaurant na belong
   

  const onDataReceived = async(data) =>{
    setSelectedStation(data)
    await  getActiveOrder(data?.id)
    console.log("+++++++++++++++data",data)
  }  

  // const getRestaurantStations = async() =>{
  //   try{
  //     const stations =  await ApiCall({
  //       apiEndpoint:`/items/stations?fields=*&filter[restaurant_id][_eq]=${restaurant_id}`,  //dynamic ta ang restaurant id
  //       method:ApiMethod.GET,
  //       service:true   
  //     })
  //     const result = stations.data.data
  //     const reconstuct =  result.map((item)=>{
  //           return {...item,
  //               label: item.description,
  //               icon: null,
  //               value: item.description
  //           }
  //     })
  //     setStations(reconstuct)
  //     await  getActiveOrder(reconstuct[0]?.id)
  //       console.log("result getRestaurantStations",result)
  //   }catch(err){
  //     console.log("errr getRestaurantStations",err)
  //   }
  // }

  const getInitialTime=()=> {
    const currentdate = new Date();
    const hours = currentdate.getUTCHours() + parseInt("+8");
  
    let correctedHours = hours >= 24 ? hours - 24 : hours;
    correctedHours = correctedHours < 0 ? correctedHours + 12 : correctedHours;

    let minutes = currentdate.getUTCMinutes();
    minutes = minutes < 10 ? '0' + minutes : minutes;

    const seconds = currentdate.getUTCSeconds();

    return {
      hours: correctedHours < 10 ? '0' + correctedHours : correctedHours,
      minutes: minutes,
      seconds: seconds
    };
  }
  const getCurrentTime =()=> {
    const currentdate = new Date();
    const hours = currentdate.getUTCHours() + parseInt("+8");
  

    let correctedHours = hours >= 24 ? hours - 24 : hours;
    correctedHours = correctedHours < 0 ? correctedHours + 12 : correctedHours;

    const period = hours >= 12 ? 'PM' : 'AM'; // Determine AM or PM

    correctedHours = correctedHours % 12;
    correctedHours = correctedHours === 0 ? 12 : correctedHours;

    let minutes = currentdate.getUTCMinutes();
    minutes = minutes < 10 ? '0' + minutes : minutes;

    const seconds = currentdate.getUTCSeconds();

    return {
      hours: correctedHours < 10 ? '0' + correctedHours : correctedHours,
      minutes: minutes,
      seconds: seconds,
      period: period
    };
  }


  const updateOrderItems = async(order_item_id,orders_id) =>{
      try{
        setLoader(true)
        const activeorders =  await ApiCall({
          apiEndpoint:`/items/order_items/${order_item_id}`,
          method:ApiMethod.PATCH,
          service:true,
          apiData:{
            status:'published'
          }   
        })
        console.log("updateOrderItems response",activeorders.data.data)
      
        const checkStatus =  await ApiCall({
          apiEndpoint:`/items/orders?fields=*,items.*&filter[restaurant][_eq]=${restaurant_id}`,  //dynamic ta ang restaurant id
          method:ApiMethod.GET,
          service:true   
        })
        const result = checkStatus.data.data
        console.log(result)
        let hasDraft = []

        for(const item of result){
            for(const data of item.items){
              console.log("dataaa status",data?.status)
              if(data?.status === 'draft'){
                hasDraft.push(data?.status)
              }
          }
        }

     
        if(hasDraft.length > 0){
          console.log("not done")
        }else{
          
          const activeorders =  await ApiCall({
            apiEndpoint:`/items/orders/${orders_id}`,
            method:ApiMethod.PATCH,
            service:true,
            apiData:{
              status:'published'
            }   
          })


        }
        hasDraft = []
       await  getActiveOrder()
     
      }catch(err){
          setLoader(false)
          console.log("updateOrderItems err",err)
      }
  }


  const completeOrder = async(order_id) =>{
      try{
        console.log("thiss is the completeee orderrr",order_id)
        setLoader(true)
        const checkStatus =  await ApiCall({
          apiEndpoint:`/items/orders/${order_id}?fields=*,items.*&filter[restaurant][_eq]=${restaurant_id}`,  //dynamic ta ang restaurant id
          method:ApiMethod.GET,
          service:true   
        })
        const result = checkStatus.data.data
        //patch all the items
        console.log("thiss is the resulttttt",result)
        for (const item of result.items){
            const checkStatus =  await ApiCall({
              apiEndpoint:`/items/order_items/${item.id}`,  //dynamic ta ang restaurant id
              method:ApiMethod.PATCH,
              service:true,
              apiData:{
                status:"published"
              }
            })
        }

        const activeorders =  await ApiCall({
          apiEndpoint:`/items/orders/${order_id}`,
          method:ApiMethod.PATCH,
          service:true,
          apiData:{
            status:'published'
          }   
        })

        await  getActiveOrder()

      }catch(err){
        setLoader(false)
        console.log("completeOrder errr",err)
      }
  }

  // const [data, setData] = useState('No result');
  const getResDetails =async()=>{

    await ApiCall({
        apiEndpoint:`/items/restaurant/${restaurant_id}`,
        method:ApiMethod.GET,
        service:true,
      }).then((res)=>{
        const restDet = res.data.data
        setResDetails(restDet)
        //
      }).catch((err)=>{
        console.log("Restaurant",err)
        setResDetails([])
      })

    //
  }
  useEffect(()=>{
    getActiveOrder()
  },[])
  useEffect(() => {
    getResDetails()
    getInitialTime()
    //getActiveOrder()
    setInterval(() => {
      getActiveOrder()
    }, 5000); 

    const interval = setInterval(() => {
      setTime(getCurrentTime());
    }, 1000);

    return () => clearInterval(interval);
  }, []);


  const getActiveOrder = async(station_id) =>{
          try{
            setLoader(true)
            const activeorders =  await ApiCall({
              apiEndpoint:`/items/orders?fields=*,table_id.*,items.*,items.cart_items.*,items.cart_items.add_ons.*,order_id.*&filter[restaurant][_eq]=${restaurant_id}&filter[status][_eq]=draft`,  //dynamic ta ang restaurant id //&deep[items][_filter][stations][_eq]=${station_id}
              method:ApiMethod.GET,
              sort:'order_id.sequence',
              service:true   
            })
            //filter order that order items count is zero
            const result = activeorders.data.data
            const filteredItems = result.filter((item)=>{
               return item.items.length > 0
            })
            console.log("responseeee getActiveOrder",result)
            setActiveOrders(filteredItems)
            setLoader(false)
          }catch(err){
            setLoader(false)
            console.log("getactiveorder err",err)
          }
   }




  return (
  <div style={{backgroundColor:"#000",height:"100vh"}}>
      {/* <QrReader
        constraints={ facingMode: 'environment' } 
        onResult={(result, error) => {
          if (!!result) {
            setData(result?.text);
          }

          if (!!error) {
            console.info(error);
          }
        }}
        style={{ width: '100%' }}
      /> */}
      {/* <p>{data}</p> */}
    <section id="kitchen_header">
      {/* <div className='station'>
        <p>select stations</p>
        <AppDropDown data={stations} onDataReceived={onDataReceived} defaultValue={stations[0]?.id}/>
      </div> */}
      <div className="kitchen_container">
          <div className="logo_con">
           {/* <p style={{color:'red'}}> {JSON.stringify(resDetails)}</p> */}
            {/* <img src={getImageUpdload(resDetails.res_logo)} alt="Client Company Logo"  className="client_logo"/> */}
            <div className="logo_con">
              <div className="logoImg" style={{backgroundImage:`url(${getImageUpdload(resDetails.res_logo)})`}}></div>
            </div>
            {/* <img src={powered_by} alt="Powered By EZ ODA" className="poweredby"/> */}
          </div>
          
          <div className="time_order_con">
            {
              time?.hours &&
                <Typography className="time">
                    <span>Time</span> <b>{time?.hours}:{time?.minutes}{time?.period}</b>
                </Typography>
            }
           
            <Typography className="active_order">
              <b>Active Order: {activeOrders.length}</b>
            </Typography>
          </div>
      </div>
             
    </section>
    <section id="kitchen_order">
      <div className="kitchen_container">
      {/* <h1 onClick={()=> navigate("/kitchen-test-page")}>KitchenMainPage</h1> */}
      <Typography className='nowPrep'>
        <b>Now Preparing</b>
      </Typography>
      <Masonry columns={{ xs: 1, sm: 2,md:3,lg:4}} spacing={{ xs: 2, sm: 2,md:2,lg:3}}>
        {loader && <AppLoader/>}
      {
          activeOrders.length>0 &&
          activeOrders.map((item,index)=>
          <AppKitchenCard item={item} key={index} completeOrder={(order_id)=>completeOrder(order_id)} index={index} updateOrderItems={(id)=>updateOrderItems(id,item.id)}/>
          )
        }
      </Masonry>
        {/* {loader && <AppLoader/>}
        <Stack sx={{flexDirection:'row',flexWrap:'wrap',gap:2,justifyContent:"flex-start"}}>
        {
          activeOrders.length>0 &&
          activeOrders.map((item,index)=>
          <AppKitchenCard item={item} key={index} completeOrder={(order_id)=>completeOrder(order_id)} index={index} updateOrderItems={(id)=>updateOrderItems(id,item.id)}/>
          )
        }
        </Stack> */}
      </div>
    </section>
  </div>
 
  )
}