import * as React from "react";
import "./styles.scss";
import { useNavigate } from "react-router-dom";
import { appTheme } from "../../../constants";
import { AppTab,AppMenuOrder ,AppTabBar, AppLoader} from "../../../components";
import Button from '@mui/material/Button';
import Badge from '@mui/material/Badge';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import dish1 from "../../../assets/images/dish1.png"
import dish2 from "../../../assets/images/dish2.png"
import dish3 from "../../../assets/images/dish3.png"
import dish4 from "../../../assets/images/dish4.png"
import { Typography } from "@mui/material";
import { useSelector } from 'react-redux';
import { ApiCall, ApiMethod } from "../../../services/AxiosInstance";
import { getCartData, getImageUpdload } from "../../../utilities";
import { useEffect } from "react";

export default function CustomerMainPage() {




  const navigate = useNavigate(); 
  const [menus,setMenus] = React.useState([])
  const [category,setCategories] = React.useState([])
  const [cartLength,setCartLength] = React.useState()
  const [loader,setLoader] = React.useState(false)
  // console.log(appTheme(2))
  const themeCurrent = useSelector((state)=>state.user.currentTheme)
  const restaurant_id = useSelector((state)=>state.user.restaurant_id)
  
  const table_id = useSelector((state)=>state.user.table_id.table_id)
  const table_name = useSelector((state)=>state.user.table_id.name)
  const order_id = useSelector((state)=>state.user.order_id)

  
  useEffect(()=>{
    getCart()
  },[])

  const getCart = async() =>{
     try{
      // setLoader(true)
      const cartdata = await getCartData(ApiCall,ApiMethod,order_id.order_id,table_id)
      console.log("cartttttttttttttttttttttttttttt",cartdata)
      setCartLength(cartdata?.items?.length)
      // setLoader(false)
     }catch(err){
      // setLoader(false)
      console.log("errrr",err)
     }
  }

  const getMenus = async()=>{
     try{
      setLoader(true)
      const result =  await ApiCall({
        apiEndpoint:`/items/menus?fields=*,status_type.*&filter[restaurant][_eq]=${restaurant_id}`,  //filter restaurant_id sa get_menus //published lang dapat na menus ari di
        method:ApiMethod.GET,
        service:true
      })
      
      const response  = result.data.data
    
      const restuctureData  =  response.map((data)=>{
        
          return {
              id:data?.id,
              image: getImageUpdload(data?.image) ,
              title:data?.name,
              description:data?.description,
              duration:`${data?.time_prep?.startTime} ${data?.time_prep?.startTimeType?.label} - ${data?.time_prep?.endTime} ${data?.time_prep?.endTimeType?.label}`,
              price:parseFloat(data?.price),
              category:data?.category,
              isBestSeller:data?.status_type?.description //get tani sa orders
          }
      })
      setMenus(restuctureData)
      setLoader(false)
     }catch(err){
      setLoader(false)
      console.log("getMenus err",err)
     }
  
  }

  // console.log("thiss is the restaurannnttt id",restaurant_id)
  const getCategory = async() =>{
   
      const result =  await ApiCall({
        apiEndpoint:`/items/category?filter[restaurant_id][_eq]=${restaurant_id}`,
        method:ApiMethod.GET,
        service:true
      })
      const response  = result.data.data
      console.log("thiss is the responseeeee",response)
      setCategories(response)
  }


  React.useEffect(()=>{
    getCategory()
    getMenus()
  },[])


  
 
  const app_theme = appTheme(themeCurrent)
  const [currentIndex,setCurrentIndex] = React.useState(0)


  const theme = createTheme({
    components: {
      MuiBadge: {
        styleOverrides: {
          badge: {
            backgroundColor: '#E71924',
            color: 'white',
          },
        },
      },
    },
  });



  return (
                                  //  {backgroundImage: `url("${theme?.appBg}")`, from json ni dapat ang 
    <div className="my_container" style={{backgroundColor:"rgba(233, 233, 233, 1)"}}>  
      {loader && <AppLoader/>} 
       <div className="cm_secondary_container">
        <AppTabBar isMain={true} tabIndex={0}  cartLength={cartLength} />
        
        <div  className="cm_content_container" style={{backgroundColor:app_theme?.themeGradient?.secondary?.color}} >
              <div className="cm_content_container_left" style={{backgroundColor:app_theme?.themeGradient?.secondary?.color}}>
                <AppTab tabs={category} setCurrentIndex={(data)=>{
                    setCurrentIndex(data)
                } }/>
              </div>
              
              <div className="cm_content_container_right">
                <div className="cat_tainer">
                    {menus.map((data,index)=>{
                        if(data.category === currentIndex){
                          return <AppMenuOrder  onClick={()=>navigate(`/customer-food-preview/${data?.id}`)}  data={data}/>
                        }
                    })}


                  <div className="fill_border_radius"></div>
                    
                 
                </div>
                <div className="cm_btn_container">
                  <ThemeProvider theme={theme}>
                  <Badge badgeContent={cartLength}  style={{width:"100%"}}>
                      <Button variant="contained" onClick={()=>navigate('/customer-review-order')} style={{backgroundColor:app_theme?.appBtn[0]?.bgColor,width:"100%",borderRadius:"8px",textTransform:'capitalize',padding:"10px",boxShadow: 'none',border:'none'}}><b>View Order</b></Button>
                  </Badge>  
                  </ThemeProvider>
                  </div>
              </div>
          </div>
       </div>
    </div>
  )
}
