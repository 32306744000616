import * as React from "react";
import "./styles.scss";
import { useNavigate } from "react-router-dom";
import { AppOrderCard, AppTabBar,AppLoader, AppModal, AppSnackBar } from "../../../components";
import { Button, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { appTheme } from "../../../constants";
import { ApiCall,ApiMethod } from "../../../services/AxiosInstance";
import QRCode from "react-qr-code";


import { useSelector } from 'react-redux';
import { getCartData } from "../../../utilities";
import { useState } from "react";
export default function ReviewOrder() {

 const [cartData,setCartData] = React.useState([])
 const [loader,setLoader] = React.useState(false)
 const [pendingOrders,setPendingOrders] = React.useState()
 const [deleteModal,setDeleteModal] = React.useState(false)
 const [currentSelected,setCurrentSelected] = React.useState()
 const table_id = useSelector((state)=>state.user.table_id.table_id)
 const order_id = useSelector((state)=>state.user.order_id)
 const table_name = useSelector((state)=>state.user.table_id.table_name)
 const [totalPendingAmount,setTotalPendingAmount] = React.useState(0)

 const list_order_id = useSelector((state)=>state.user.list_order_id)
 const [snackConfig,setSnackConfig]= useState({
    message:null,
    type:"success",//success,error
    show:false
  })


 React.useEffect(()=>{ 
    checkPendingTablePayment()  
    checkPendingOrders()
    getcart()
 },[])


 //fetch ta ang order nga under sa amo ni nga table_id nga indi pa paid
 //once ma paid na madula ni siya ang..
 const checkPendingTablePayment = async() =>{
    console.log("table_idtable_idtable_idtable_idtable_id",table_id)
        try{
            const checkpending = await ApiCall({
                apiEndpoint:`/items/orders?fields=*&filter[table_id][_eq]=${table_id}&filter[payment_status][_eq]=pending`, //once paid nani ..indi nani siya ma fetch ka table id
                method:ApiMethod.GET,
                service:true
            })
            const result = checkpending.data.data
            console.log("resultt-_____________________",result)

            if(result.length > 0){
                let totalAmount = 0
                for(const data of result){
                    totalAmount += parseFloat(data?.total_amount)
                }
                setTotalPendingAmount(totalAmount)
                totalAmount = 0
            }
          
        }catch(err){
            console.log("checkPendingTablePayment err",err)
        }
 }



 const checkPendingOrders = async()=>{
      try{
        //fetch tlng gid diri ang order under sa amo na table.. //butongon ang orders na indi pa bayad ?
        console.log("listttt orderrr id",list_order_id)
        const seperated_by_comma_id = list_order_id.join(',');

        console.log("thiss is the seperate_by_comma",seperated_by_comma_id)

        const checkpending = await ApiCall({
            apiEndpoint:`/items/orders?fields=*,items.*,items.cart_items.add_ons.sub_menu.*,items.cart_items.add_ons.menu_submenu.sub_menu.*,order_id.*,items.cart_items.add_ons.*&filter[table_id][_eq]=${table_id}&filter[status][_eq]=draft`, //&filter[order_id][_in]=${seperated_by_comma_id}
            method:ApiMethod.GET,
            service:true
        })
        if(checkpending.data.data.length > 0){
            setPendingOrders(checkpending.data.data)
        }else{
            setPendingOrders()
        }
       

      }catch(err){
        console.log("checkPendingOrders err",err)
      }
 }


 const getcart = async()=>{
       setLoader(true)
       const cartdata = await getCartData(ApiCall,ApiMethod,order_id.order_id,table_id) //table_number or order_id

       console.log("this iss the cart_dataaaa",cartdata)
       setCartData(cartdata)
    
       setLoader(false)
 }



  const navigate = useNavigate();
  const themeCurrent = useSelector((state)=>state.user.currentTheme)
  const app_theme = appTheme(themeCurrent)
  const [viewFullDetails,setIsviewDetails] = React.useState(true)


  const changeQuantity = async(qty,item_id,cart_id) =>{
        console.log("thisss is the dataaaaa",qty,item_id)
        //update quantity and patch again
        try{
            setLoader(true)
            const patchCartItem = await ApiCall({
                apiEndpoint:`/items/cart_items/${item_id}`,
                method:ApiMethod.PATCH,
                service:true,
                apiData:{
                    quantity:Number(qty), //total of quantity of exiting itemcart increase
                }
            })

            //total the updated values
            const getItems = await getCartData(ApiCall,ApiMethod,order_id.order_id,table_id)
            let total_quantity= 0;
            let total_amount = 0;
            let add_ons_total = 0;
            for(const data of getItems.items){
                if(data.add_ons.length > 0){
                    for(const item of data.add_ons){
                        console.log("thiss is the iteemmmm",item)
                        add_ons_total += parseFloat(item.price)
                    }
                 }
                  total_quantity += Number(data?.quantity)
                  total_amount += Number(data?.quantity) * Number(data?.unit_price) 
            }
  
            console.log("thisss is the total amountt",total_amount)
            console.log("thiss is the total quantity",total_quantity)
           //patch cart here the clear the values
            const patchCart = await ApiCall({
              apiEndpoint:`/items/carts/${cart_id}`,
              method:ApiMethod.PATCH,
              service:true,
              apiData:{
                  item_count:total_quantity, //total of quantity
                  item_total:total_amount + Number(add_ons_total),
                  amount_total:total_amount + Number(add_ons_total),
              }
            })
            console.log("success patch",patchCart.data.data)
            total_quantity = 0
            total_amount = 0
            add_ons_total = 0
            await getcart()

        }catch(err){
            setLoader(false)
            console.log("change qty err",err)
        }
  }


  const deleteItem = async(item_id,cart_id) =>{
    try{
        setLoader(true)
        const patchCartItem = await ApiCall({
            apiEndpoint:`/items/cart_items/${currentSelected?.id}`,
            method:ApiMethod.PATCH,
            service:true,
            apiData:{
                status:'archived'
            }
        })

        const getItems = await getCartData(ApiCall,ApiMethod,order_id.order_id,table_id)
        let total_quantity= 0;
        let total_amount = 0;
        let add_ons_total = 0;
        for(const data of getItems.items){
            if(data.add_ons.length > 0){
                for(const item of data.add_ons){
                    add_ons_total += parseFloat(item.price)
                }
             }
              total_quantity += Number(data?.quantity)
              total_amount += Number(data?.quantity) * Number(data?.unit_price) 
        }

        console.log("thisss is the total amountt",total_amount)
        console.log("thiss is the total quantity",total_quantity)
       //patch cart here the clear the values
        const patchCart = await ApiCall({
          apiEndpoint:`/items/carts/${currentSelected?.cart_id}`,
          method:ApiMethod.PATCH,
          service:true,
          apiData:{
              item_count:total_quantity, //total of quantity
              item_total:total_amount + Number(add_ons_total),
              amount_total:total_amount + Number(add_ons_total),
          }
        })
        console.log("success patch",patchCart.data.data)
        total_quantity = 0
        total_amount = 0
        add_ons_total = 0
        setDeleteModal(false)
        await getcart()

    }catch(err){
        setLoader(false)
        console.log("deleteItem err",err)
    }
  }




  return (
   <section id="">
    <div className="my_container">
        <AppTabBar isReview={true} tabIndex={1}/>
        <div className="ro_indicator"></div>
        {loader && <AppLoader/> }
        <div className="ro_secondary_container">
        
      
               
          {cartData.items?.length > 0 && <>
                   

                     {pendingOrders && <>
                        <hr style={{marginTop:'5px'}}></hr>
                        <div className="ro_new_order_container">
                        <div className="new_order_label_container">
                            <Typography className="first"><b>New Order</b></Typography>
                        </div>
                     </div>
                     </> }
                     <div className="ro_container_label">
                        <div>
                            <Typography className="first">Order ID: </Typography>
                            <Typography className="second">{order_id.sequence}</Typography>
                        </div>
                        <div>
                            <Typography className="first">Table No. </Typography>
                            <Typography className="second">{table_name}</Typography>
                        </div>
                     </div>
                    <div style={{backgroundColor:"white",paddingTop:"5px",paddingBottom:'5px', borderRadius:"8px"}}>  
                        {cartData?.items?.map((data,index)=>{
                            const restucture = {
                                ...data,
                                id:data?.id,
                                title:data?.menu?.name,
                                price:parseFloat(data?.unit_price),
                                quantity:data?.quantity,
                                add_ons:data?.add_ons,
                                cart_id:data?.cart
                            }    
                            
                            return <AppOrderCard setCurrentSelected={setCurrentSelected} setDeleteModal={setDeleteModal} data={restucture} deleteItem={(item_id,cart_id)=>deleteItem(item_id,cart_id)} changeQuantity={(qty,item_id,cart_id)=>changeQuantity(qty,item_id,cart_id)}/>
                        })}
                        </div>
           </>}
                  {cartData.items?.length > 0 &&    <div className="ro_total">
                    <Typography className="ro_label_order">Review & Order</Typography>
                    <div className="ro_sub_total">   
                        <Typography className="first">Subtotal</Typography>
                        <Typography className="second">${parseFloat(cartData?.amount_total).toLocaleString()}</Typography>
                    </div>
                    <div className="ro_dicount_total">
                        <Typography className="first"> Discount</Typography>
                        <Typography className="second">$0</Typography>
                    </div>
                    <div className="ro_vat">
                        <Typography className="first">Vat</Typography>
                        <Typography className="second">$0</Typography>
                    </div>
                    <div className="ro_total_price">
                        <Typography className="first" style={{color:app_theme?.fontColor}}><b>Total</b></Typography>
                        <Typography className="second"><b>${parseFloat(cartData?.amount_total).toLocaleString()}</b></Typography>
                    </div>
                </div>}
             
                <div style={{display:'flex',justifyContent:'center',flexWrap:"wrap",marginTop:"20px"}}>
                {cartData?.items?.length > 0 && <Button variant="contained" onClick={()=>{
                       
                       if(cartData?.items?.length > 0){
                           navigate(`/customer-waiter`)
                       }
                       // else{
                       //     setSnackConfig({
                       //         message:"Cart is empty",
                       //         type:"error",//success,error
                       //         show:true
                       //       })
                       // }
                  
                       // navigate(`/customer-payment/${order_id}`)
                       }} style={{backgroundColor:app_theme?.appBtn[0]?.bgColor,textTransform:'capitalize',padding:'10px',boxShadow: 'none',border:"none"}} className="btn-custom_order"><b>{} place order & scan qr code</b></Button>}
                      <Button variant="outlined" onClick={()=>navigate('/customer-main-page')} startIcon={<AddIcon/>} style={{width:'100%',marginTop:"10px",marginBottom:'10px',borderColor:app_theme?.fontColor,color:app_theme?.fontColor,textTransform:'capitalize',padding:'10px',boxShadow: 'none'}}>
                            <b>Exit for additional order</b>
                      </Button>
                      
                     
                    
                </div>
                <div className="pending_order_container">

                    {/* totalPendingAmount */}
                    {pendingOrders && <div className="qr_maincontainer" >
                    <Typography className="first_label">Total Bill</Typography>
                    <Typography className="second_label">${totalPendingAmount.toLocaleString()}</Typography>
                    <Typography className="third_label">Confirmed Order</Typography>
                    <Typography className="fourth_label">Scan Me</Typography>
                    <div className="qr_container">
                        <QRCode style={{ width: '100px', height: '100px' }} value={totalPendingAmount} />
                    </div>  
                    
                    </div>
                    }
                    {/* {
                        pendingOrders &&  <div className="po_label_container">
                        <Typography className="first" style={{color:app_theme?.fontColor}}><b>Preparing Order</b></Typography> 
                    {viewFullDetails ?    <KeyboardArrowUpIcon onClick={()=>{setIsviewDetails(!viewFullDetails)}} style={{fontSize:'25px'}}/> :  <KeyboardArrowDownIcon onClick={()=>{setIsviewDetails(!viewFullDetails)}} style={{fontSize:'25px'}}/>}
                    
                    
                    </div>
                    }
                    

                    {pendingOrders && pendingOrders.map((order)=>{
                    return ( <>
                        <div className="ro_container_label">
                        <div>
                            <Typography className="first">Order ID: </Typography>
                            <Typography className="second">{order?.order_id?.sequence}</Typography>
                        </div>
                        <div>
                            <Typography className="first">Table No. </Typography>
                            <Typography className="second">{table_name}</Typography>
                        </div>
                        </div>

                        <div className="po_content_container">
                        {viewFullDetails ? <>
                            {order.items.map((item,index)=>{
                            const data = {
                                    title:item.food_name,
                                    price:item.unit_price,
                                    quantity:item.quantity,
                                    add_ons:item.cart_items.add_ons //direct ta sa order items?
                            }


                            return <AppOrderCard data={data} isPending={true}/>
                            })}
                                <div className="ro_total_price">
                                <Typography className="first" style={{color:app_theme?.fontColor}}><b>Total</b></Typography>
                                <Typography className="second"><b>${parseFloat(order?.total_amount).toLocaleString()}</b></Typography>
                            </div>
                        </>
                        :  order.items.map((data,index)=>{
                            return <Typography className="title_only">{data?.food_name}</Typography>
                            })
                        }

                    </div>
                        
                        
                    </> )
                    })} */}
                    </div>
                
        </div>
       {/* {cartData.items?.length === 0  && !pendingOrders &&  <Typography style={{textAlign:'center',marginTop:'250px'}}>Cart is empty.</Typography>}   */}
    
    </div>
    <AppSnackBar snackConfig={snackConfig}/>
    <AppModal
      visible={deleteModal}
      handleClose={() => setDeleteModal(false)}
    >
     <div style={{display:"flex",alignItems:'center',justifyContent:'center',flexDirection:'column',paddingTop:"10px"}}>
        <Typography>Remove {currentSelected?.title}?</Typography>
        <Button variant="contained" onClick={()=>deleteItem()}  style={{backgroundColor:app_theme?.appBtn[0]?.bgColor,textTransform:'capitalize',padding:'10px',boxShadow: 'none',marginTop:'10px'}} className="btn-custom_order"><b>remove</b></Button>
                    
     </div>
    </AppModal>
   </section>
  )
}