import React, { useEffect, useState } from 'react';
import Lottie from 'react-lottie';
import animationData from './loader.json';
import { Modal } from '@material-ui/core';
import "./styles.scss";

const AppLoader = () => {
  const [height, setHeight] = useState(450);
  const [width, setWidth] = useState(450);
  const [open, setOpen] = useState(true); // State to control the modal open/close

  useEffect(() => {
    const windowWidth = window.innerWidth;
    if (windowWidth < 640) {
      setWidth(250);
      setHeight(250);
    } else {
      setWidth(450);
      setHeight(450);
    }
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <Modal
      open={true}
      // onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div className='loader_container'>
        <Lottie
          options={defaultOptions}
          height={height}
          width={width}
        />
      </div>
    </Modal>
  );
};

export default AppLoader;
