import {useState, useEffect} from "react";
import "./styles.scss";
import { ApiCall, ApiMethod } from '../../services/AxiosInstance';
import { Button } from "@mui/base";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import Product from '../../assets/images/product.jpg';
import KitchenIcon from '../../assets/images/kitchenIcon.png';
import GrillIcon from '../../assets/images/grillIcon.png';
import BarIcon from '../../assets/images/barIcon.png';
import { getImageUpdload } from "../../utilities";
export default function AppRestoCard({
  containerStyle,
  restaurantData,
  isModal,
  data={},
  handleEdit=() => {},
  handleDelete,
  handleHideMenu=() => {}
}) {
  const [isHide, setIsHide] = useState(false);
 
  const hideMenu = async(item) => {
    setIsHide(!isHide);
    handleHideMenu();
    // console.log(item)
    //update
    const updateStat = item.status =='published' ? 'draft':'published'
    await ApiCall({
      apiEndpoint:`/items/menus/${item.id}`,
      method:ApiMethod.PATCH,
      token:restaurantData.access_token,
      apiData:{
        status:updateStat
      }
    })
  }
  const deleteFood = async(item)=>{
    handleDelete()
    await ApiCall({
      apiEndpoint:`/items/menus/${item.id}`,
      method:ApiMethod.PATCH,
      token:restaurantData.access_token,
      apiData:{
        status:'archived'
      }
    })
  }
  useEffect(()=>{
    if(data.status=="draft"){
      setIsHide(true)
    }else{
      setIsHide(false)
    }
  },[JSON.stringify(data)])

  return (
   <section id="AppRestoCard" style={containerStyle}>
    <div className="container">
        <img className="image" src={getImageUpdload(data.image.id)} />
        <div className="cardItem">
          <div className="head">
            <p className="headTitle">{data.status_type?.description}</p>
            <div className="iconHandle">
              {
                data?.stations?.icon && <img className="iconImg" src={getImageUpdload(data?.stations?.icon)} />
              }
              
              <label>{data.stations?.description}</label>
            </div>
          </div>
          <h5 className="cardTitle">{data.name}</h5>
          <p className="cardPrice">${parseFloat(data.price).toFixed(2)}</p>
          <div className="footer">
            <a className="edit" href="javascript:void(0)" onClick={()=>handleEdit()}><EditIcon /> Edit</a>
            <a className="delete" href="javascript:void(0)" onClick={handleDelete}><DeleteIcon /> Delete</a>
            {
              !isModal &&  <a className="hideMenu" style={isHide ? {zIndex: 100} : null} href="javascript:void(0)" onClick={() => hideMenu(data)}><RemoveRedEyeIcon /> {isHide ? 'Show' : 'Hide'} From Menu</a>
            }
          </div>
        </div>
        {
          isHide ? (
            <div className="grayOut"></div>
          ) : null
        }
    </div>
    
   </section>
  )
}