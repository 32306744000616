import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { ApiCall, ApiMethod } from '../../../services/AxiosInstance'
import { AppRestoBar, AppStaffBar } from '../../../components'
import { useDispatch } from 'react-redux'
import ReservationPage from '../ReservationPage'
import { setStaffData, setType } from '../../../redux/reducer/User'
import TablePage from '../TablePage'
import "./styles.scss"
import OrderPage from '../OrderPage'
const HomePage = () => {
  const params = useParams()
  const staff_id = params?.staff_id  //'72b93eeb-4c3e-4564-a522-9d87a85fb25d'//
  const staff_name= params?.staff_name //'staff_name' //
  const dispatch = useDispatch()
  const [resDetail,setResDetails] = useState()
  const [currentSelection,SetCurrentSelection] = useState()
  
  useEffect(()=>{
    getStaffDetails()
  },[])

 const getStaffDetails = async()=>{
    try{
      
        const response = await ApiCall({
          apiEndpoint:`/items/staff/${staff_id}?fields=*,restaurant.*,restaurant.page_setting.*`,
          method:ApiMethod.GET,
          service:true
        })
        const result = response.data.data
        console.log("this is the resulttt+++",result)
        setResDetails(result)
        
    }catch(err){
      console.log("getStaffDetails",err)
    }
 }


  return (
    <div className='main_container'>
      <div className='HomePage'>
          <AppStaffBar data={resDetail} SetCurrentSelection={SetCurrentSelection} handleLogout={()=>{
              dispatch(setStaffData(null))
              dispatch(setType(null))
          }} />
          {currentSelection === "Reservations" && <ReservationPage resDetail={resDetail} staff_id={staff_id} staff_name={staff_name}/>}
          {currentSelection === "Tables" && <TablePage />}
          {currentSelection === "Order" && <OrderPage/>}
        </div>
    </div>
   
  )
}

export default HomePage
