import { Button, Typography } from '@mui/material'
import React, { useState } from 'react'
import "./styles.scss"
import QRCode from "react-qr-code";
import orderlogo from "../../../assets/images/order_logo.png"
import { QrReader } from 'react-qr-reader';
import { useSelector } from 'react-redux'

const OrderPage = () => {

  const [showScanner,setShowScanner] = useState(false)
  const staff_data = useSelector((state)=>state.user.staff_data) 

  return (
    <div className='order_page_container'>

        {showScanner ? <>
        <QrReader
        constraints={{facingMode: 'environment' }} 
        containerStyle={{width:'100%',height:"60vh",display:'flex',alignItems:'center',justifyContent:'center',marginTop:"30px"}}
        onResult={(result, error) => {
       
            if(result){
                alert(`SUCCESS - TRIGGER PAYMENT HERE ${result}`)
                //patch tadi ka order oh ..
                // placeOrder()
                // navigate(`/customer-thank-you-screen`)
                // setTimeout(()=>{
                
                // },2000)
            }
         
        

          if (!!error) {
            
            // alert(`success ${error}`)
            console.info("errorr",error);
          }
        }}
        style={{ width: '100%' }}
      /> 
      <Button variant="contained apply_theme" onClick={()=>setShowScanner(false)} style={{width:"100%",padding:"8px",backgroundColor:"#F0522B",color:"white",textTransform:"capitalize",maxWidth:"90%"}}><Typography><b>Cancel Payment</b></Typography></Button>
     
        </> 
        
      : <>
        <Typography className='first'>Scan QR Code</Typography>
        <Typography className='second'>To Confirm Order</Typography>
        <div className='label2'>
        <Typography className='third'>Confirm Order</Typography>
        <Typography className='fourth'>Scan Me</Typography>
        </div>
        <div className='qr_container'>
            <QRCode style={{ width: '100%', height: '100%' }} value={staff_data?.staff_id} />
        </div>
        
        <div className='button_container' onClick={()=>setShowScanner(true)}>
        {/* <Button variant="contained apply_theme" onClick={()=>setShowScanner(true)} style={{width:"100%",padding:"8px",backgroundColor:"#F0522B",color:"white",textTransform:"capitalize",maxWidth:"90%"}}><Typography><b>Cash</b></Typography></Button>
        <Button variant="contained apply_theme" onClick={()=>setShowScanner(true)} style={{width:"100%",padding:"8px",backgroundColor:"transparent",color:"white",textTransform:"capitalize",maxWidth:"90%",marginTop:"20px"}}><Typography style={{color:"#F0522B"}}><b>Credit Card</b></Typography></Button> */}
         <img src={orderlogo} style={{height:'120px',width:"120px"}}/>
         <Typography>Scan Customer Qr Code</Typography>
         <Typography>For Payment</Typography>
        </div>
      </>}
        
        

       
    </div>
  )
}

export default OrderPage
