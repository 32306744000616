import CrimsonBG from "../assets/images/crimson.jpg"
import compLogo from "../assets/images/company_logo.png"
import aquaBlue  from "../assets/images/Splashscreen_aqua.png"
import crimson from "../assets/images/crimson.png"
import black_yellow from "../assets/images/black_yellow.png"

export const PERSIST_CONFIG = {
    key: "EzodaRoot-Vbp82Vpq418w1A2eNRBz",
    secretKey: "k4CoKyofvaFBMeiqPEcvchmXIKXjEz7FNWrR16y14AjS1HJWf5"
}
export const appColorTheme = [
    {
        code:1,
        name:"Crimson Red",
        appBg:crimson,
        logo:compLogo,
        fontColor:"#F0522B",
        themeGradient:{
        primary: {
            color:"#6E1318",percent:"5%"
        },
        secondary:{
            color:"#380306",percent:"30%"
        },
        badge:{
            primary:"#FCAD48",
            secondary:"#F0522B"
        }
        }, 
        appBtn:[
            {
                bgColor:"#F0522B",
                textColor:"", 
                borderColor:"",
                label:'main buttons'
            },
            {
                bgColor:"#911A20",
                textColor:"", 
                borderColor:"",
                label:"thank you screen"
            },
            {
                bgColor:"",
                textColor:"#FCAD48", 
                borderColor:"",
                label:"back buttons"
            }
            
        ],
        appBtnHover :[
            {
                bgColor:"",
                textColor:"", 
                borderColor:""
            }
        ]
    },
    {
        code:2,
        name:"Aqua Blue",
        appBg:aquaBlue,
        logo:compLogo,
        fontColor:"#0395FF",
        themeGradient:{
        primary: {
            color:"#0395FF",percent:"5%"
        },
        secondary:{
            color:"#02152D",percent:"30%"
        },
        badge:{
            primary:"#0395FF",
            secondary:"#287BB8"
        }
        }, 
        appBtn:[
            {
                bgColor:"#0395FF",
                textColor:"", 
                borderColor:"",
                label:'main buttons'
            },
            {
                bgColor:"#003A64",
                textColor:"", 
                borderColor:"",
                label:"thank you screen"
            },
            {
                bgColor:"",
                textColor:"#FCAD48", 
                borderColor:"",
                label:"back buttons"
            }
        ],
        appBtnHover :[
            {
                bgColor:"",
                textColor:"", 
                borderColor:""
            }
        ]
    },
    {
        code:3,
        name:"Black Yellow",
        appBg:black_yellow,
        logo:compLogo,
        fontColor:"#FF9A03",
        themeGradient:{
        primary: {
            color:"#272727",percent:"5%"
        },
        secondary:{
            color:"#000000",percent:"30%"
        },
        badge:{
            primary:"#FF9A03",
            secondary:"#FF9A03"
        }
        }, 
        appBtn:[
            {
                bgColor:"#FF9A03",
                textColor:"", 
                borderColor:"",
                label:'main buttons'
            },
            {
                bgColor:"#483904",
                textColor:"", 
                borderColor:"",
                label:"thank you screen"
            },
            {
                bgColor:"",
                textColor:"#FFFFFF", 
                borderColor:"",
                label:"back buttons"
            }
        ],
        appBtnHover :[
            {
                bgColor:"",
                textColor:"", 
                borderColor:"",
                
            }
        ]
    }
] 

export const appTheme = (code=1)=>{
    for (const obj of appColorTheme) {
        if (obj.code === code) {
            return obj;
        }
    }
    return null; 
}

