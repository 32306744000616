import React, { useState,useEffect } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
import { appTheme } from '../../constants';
import { useSelector } from 'react-redux';
const AppTab = ({tabs,setCurrentIndex = ()=>{}}) => {



  const [value, setValue] = useState(0);
 

  useEffect(() => {
    setCurrentIndex(tabs[value]?.id);
  }, [setCurrentIndex, value]);

  const themeCurrent = useSelector((state)=>state.user.currentTheme)
  const app_theme = appTheme(themeCurrent)
  const handleChange = (event, newValue) => {
    const getCurrentTab = tabs[newValue]
    setCurrentIndex(getCurrentTab?.id)
    setValue(newValue);
  };

  const theme = createTheme({
    palette: {
      primary: {
        main: app_theme?.fontColor, // Set to your desired red color
      },
    },
  });

  // const tabs = ["Appetizer", "Main Course", "Beverage", "Soup", "Side", "Dessert"];

  return (
    <ThemeProvider theme={theme}>
      <Tabs
        value={value}
        onChange={handleChange}
        orientation="vertical"
        variant="scrollable"
        indicatorColor="primary"
        scrollButtons="auto"
        TabIndicatorProps={{ style: { display: 'none' } }}
        style={{ alignItems: 'center', maxHeight: '75vh', overflowY: 'auto' }}
      >
        {tabs.map((data) => (
          <Tab
            key={data?.description}
            label={data?.description}
            style={{ transform: 'rotate(-90deg)',minWidth:"103px", textTransform: "capitalize" }}
            sx={{
              padding: 0,
              textDecorationLine: 'underline',
              textTransform: "capitalize",
              marginTop: "90px",
              color: "gray",
              '&.Mui-selected': {
                color:  app_theme?.fontColor,
              },
            }}
          />
        ))}
      </Tabs>
    </ThemeProvider>
  );
};

export default AppTab;
