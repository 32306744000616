import * as React from "react";
import "./styles.scss";
import { useNavigate } from "react-router-dom";
import { AppLoader, AppTabBar } from "../../../components";
import { Button, Typography } from '@mui/material';
import { appTheme } from "../../../constants";
import { useDispatch, useSelector } from 'react-redux';
import { ApiCall,ApiMethod } from "../../../services/AxiosInstance";
import { getCartData } from "../../../utilities";
import { useParams } from 'react-router-dom';
import { setListOrderId, setOrderID } from "../../../redux/reducer/User";

export default function Payment() {


  const dispatch = useDispatch()
  const themeCurrent = useSelector((state)=>state.user.currentTheme)
  const app_theme = appTheme(themeCurrent)
  const navigate = useNavigate();
  const [loader,setLoader] = React.useState(false)
  const [cartData,setCartData] = React.useState()
  const params = useParams();
  const table_id = useSelector((state)=>state.user.table_id)
  const order_id = useSelector((state)=>state.user.order_id)
  const order_id_list = useSelector((state)=>state.user.list_order_id)
  // const order_id = params?.order_id

  const paymentMethods = ["Gcash","Bank Transfer","Credit Card","Cash or Physical Card"]
  
  React.useEffect(()=>{
    getCart()
  },[])

  const getCart = async() =>{
         const data =  await getCartData(ApiCall,ApiMethod,order_id,table_id)
         console.log("thiss is the data++++++++",data)
         setCartData(data)
  }


  const placeOrder = async() =>{
     try{
        setLoader(true)
        const isPaymentSuccess = true

        const insertOrder = await ApiCall({
            apiEndpoint:`/items/orders`,
            method:ApiMethod.POST,
            service:true,
            apiData:{
                cart_id:cartData?.id,
                // customer:current_user,
                item_total:Number(cartData?.item_total),
                total_amount:Number(cartData?.item_total),
                tax_amount:0,
                discount_total:0,
                item_count:cartData?.item_count,
                restaurant:cartData?.restaurant,
                // billing:'', JSON
                // shipping:'', JSON
                // payment_reference:"", JSON
                order_id:order_id,
                table_id:table_id,
                payment_status:'pending'
            }
        })

       const resultOrder = insertOrder.data.data
            for(const data of cartData?.items){
              const insertOrder = await ApiCall({
                apiEndpoint:`/items/order_items`,
                method:ApiMethod.POST,
                service:true,
                apiData:{
                    order:resultOrder?.id,
                    restaurant:data?.menu?.restaurant, //must have a value
                    menu:data?.menu?.id,
                    cart_items:data?.id,
                    item_desc:data?.description,
                    regular_price:Number(data?.regular_price),
                    quantity:data?.quantity,
                    unit_price:Number(data?.regular_price),
                    unit_discount_amount:0,
                    discount_total:0,
                    amount_total:Number(data?.regular_price) * data?.quantity,
                    image:data?.menu?.image,
                    food_name:data?.food_name,
                    note:data?.note,
                    stations:data?.stations
                }
            })
       }

       //then trigger the payment here.. if success patch cart_items to published  ..if all is served..patch the cart to published.
       if(isPaymentSuccess){
            //patch cart_items_status,
            //order payment_status  to paid
            //orders and order_items status will be patch in the kitchen side , same as cart once everythin is done
            
            //published cart
            const patch_cart = await ApiCall({
                apiEndpoint:`/items/carts/${cartData?.id}`,
                method:ApiMethod.PATCH,
                service:true,
                apiData:{
                    status:'published'
                }
            })


            //pull ta sa kitchen ang order na payment_status paid and status draft.. then ang order_items didto na ma patch
            for(const data of cartData?.items){
                const patchCartItems = await ApiCall({
                  apiEndpoint:`/items/cart_items/${data.id}`,
                  method:ApiMethod.PATCH,
                  service:true,
                  apiData:{
                      status:'published'
                  }
              })
            }

            const update_order = await ApiCall({
              apiEndpoint:`/items/orders/${resultOrder.id}`,
              method:ApiMethod.PATCH,
              service:true,
              apiData:{
                  payment_status:'paid'
              }
            })

            const generate_order_id = () =>{
              const prefix = 'eze_oda_';
            
              const now = new Date();
            
              const year = now.getFullYear().toString().slice(2); 
              const month = (now.getMonth() + 1).toString().padStart(2, '0'); 
              const day = now.getDate().toString().padStart(2, '0'); 
              const hours = now.getHours().toString().padStart(2, '0'); 
              const minutes = now.getMinutes().toString().padStart(2, '0'); 
              const seconds = now.getSeconds().toString().padStart(2, '0'); 
              const milliseconds = now.getMilliseconds().toString().padStart(4, '0'); 
            
              // Combine components to form the timestamp
              const timestamp = `${year}${month}${day}_${milliseconds}`;
            
              // Combine prefix and timestamp to form the final eze_oda_timestamp
              const ezeOdaTimestamp = `${prefix}${timestamp}`;
              
              return ezeOdaTimestamp;
            }


            dispatch(setOrderID(generate_order_id()))
            if(order_id_list.length > 0){
              dispatch(setListOrderId([...order_id_list,order_id]))
            }else{
              dispatch(setListOrderId([order_id]))
            }
           
            setLoader(false)
            navigate(`/customer-waiter`)
            // navigate(`/customer-thank-you-screen/${order_id}`)

       }else{
         //once user failed payment or cancelled change order status into archived
          const update_order = await ApiCall({
            apiEndpoint:`/items/orders/${resultOrder.id}`,
            method:ApiMethod.PATCH,
            service:true,
            apiData:{
                payment_status:'failed',
                status:'archived'
            }
          })
          setLoader(false)
       }


    }catch(err){
       setLoader(false)
        console.log("placeOrder err",err)
    }


  }


  return (
   <section id="">
    <div className="my_container3">
      <AppTabBar isPayment={true} tabIndex={1}/>
      <div className="payment_indicator"></div>
     
      {loader && <AppLoader/>}
      <div className="order_secondary_container">
           
            <Typography className="first">Total Bill</Typography>
            <Typography className="second">${parseFloat(cartData?.amount_total).toLocaleString()}</Typography>

            <div>
                <Typography style={{fontSize:"12px",color:"black"}}>Payment Method</Typography>
                {paymentMethods.map((data)=>{
                    return <Button variant="outlined"  style={{width:'100%',marginTop:"10px",marginBottom:'2px',borderColor:app_theme?.fontColor,color:app_theme?.fontColor,padding:"10px",borderRadius:"8px",textTransform:"capitalize",fontSize:"16px", textAlign: 'start',display:'flex',justifyContent:"flex-start",boxShadow: 'none'}}>
                                <Typography sx={{ textAlign: 'start' }}>
                                <b>{data}</b>
                              </Typography>
                          </Button>
                })}
                <Button variant="contained" onClick={()=>{
                  placeOrder()
                  // navigate("/customer-thank-you-screen")
                }} style={{backgroundColor:app_theme?.appBtn[0]?.bgColor,width:'100%',marginTop:"10px",padding:'10px',textTransform:"capitalize",fontSize:"16px",borderRadius:"8px",boxShadow: 'none'}} className="btn-custom_order">Continue</Button>
            </div>


      </div>
    </div>
   </section>
  )
}