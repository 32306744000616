import React, { useState } from 'react';
import { Button, Typography } from '@mui/material';
import "./styles.scss";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
import { appTheme } from '../../constants';
import { useSelector } from 'react-redux';

const AppTabIndicator = ({tabIndex}) => {
  const themeCurrent = useSelector((state)=>state.user.currentTheme)
  const app_theme= appTheme(themeCurrent)
  const tabCount = [0,1,2]
  return (
    <div className="indicator_container">
     {tabCount.map((data,index)=>{
            return  <div className='appTB_line_indicator' style={{width:tabIndex === index ? "45%" : "25%" , backgroundColor:tabIndex === index ? `${app_theme?.fontColor}` :"rgba(230,230,230,.3)"}}></div>
      })}
    </div>
  );
};

export default AppTabIndicator;
