import React,{useEffect, useState} from "react";
import "./styles.scss";
import { useNavigate } from "react-router-dom";
import { appTheme } from "../../../constants";
import compLogo from "../../../assets/images/company_logo.png"
import { Button, Typography } from '@mui/material';
import { Line, Circle } from 'rc-progress';
import { QrReader } from 'react-qr-reader';
import { setListOrderId, setOrderID } from "../../../redux/reducer/User";
import { useDispatch, useSelector } from 'react-redux';
import { ApiMethod,ApiCall } from "../../../services/AxiosInstance";
import { GenerateOrderID, getCartData } from "../../../utilities";
import { AppLoader, AppSnackBar } from "../../../components";

export default function WaiterPage() {
  const themeCurrent = useSelector((state)=>state.user.currentTheme)
  const [data,setData] = useState()
  const navigate = useNavigate();

  const dispatch = useDispatch()
  const [currenSeq,setCurrentSeq] = React.useState()
  const app_theme = appTheme(themeCurrent)
  const [loader,setLoader] = React.useState(false)
  const [cartData,setCartData] = React.useState()
  const table_id = useSelector((state)=>state.user.table_id.table_id)
  const order_id = useSelector((state)=>state.user.order_id)
  const order_id_list = useSelector((state)=>state.user.list_order_id)
  const restaurant_id = useSelector((state)=>state.user.restaurant_id)
  const branch_id =  useSelector((state)=>state.user.branch_id)
  const [extractedData,setExtractedData] = useState()
  const [isStaffValid,setIsStaffValid] = useState()
  
  const [snackConfig,setSnackConfig]= useState({
    message:null,
    type:"success",//success,error
    show:false
  })

  React.useEffect(()=>{
    setCurrentSeq(order_id)
    getCart()
  },[])

  const getCart = async() =>{
         setLoader(true)
         const data =  await getCartData(ApiCall,ApiMethod,order_id.order_id,table_id)
         console.log("thiss is the data++++++++",data)
         setCartData(data)
         setLoader(false)
  }

  const scanQrSimulate = () =>{
    const data = "https://e074-49-145-62-179.ngrok-free.app/?data=%7B%22staff_id%22%3A%2272b93eeb-4c3e-4564-a522-9d87a85fb25d%22%2C%22restaurant_id%22%3A%22a609dfb9-dd5c-405e-8733-e9b6d4576885%22%2C%22staff_name%22%3A%22Waiter%201%20(don't%20delete)%22%2C%22staff_type%22%3A%22waiter%22%2C%22branch%22%3A%222b416a25-e428-4f0b-bb39-7cc209cce4a8%22%2C%22user_type%22%3A%22staff%22%7D"
    const params = data.split("?data=")[1];
    const decoded = decodeURIComponent(params);
    const parseParams = JSON.parse(decoded);
    console.log("thiss is the parseee paramss",parseParams)
    setExtractedData(parseParams)
    validateStaff(parseParams)
  }

 //once waiter confirms trigger this one
  const placeOrder = async() =>{
     try{
  
        setLoader(true)
        const isPaymentSuccess = true

        const insertOrder = await ApiCall({
            apiEndpoint:`/items/orders`,
            method:ApiMethod.POST,
            service:true,
            apiData:{
                cart_id:cartData?.id,
                // customer:current_user,
                item_total:Number(cartData?.item_total),
                total_amount:Number(cartData?.item_total),
                tax_amount:0,
                discount_total:0,
                item_count:cartData?.item_count,
                restaurant:cartData?.restaurant,
                // billing:'', JSON
                // shipping:'', JSON
                // payment_reference:"", JSON
                order_id:order_id.order_id,
                table_id:table_id,
                payment_status:'pending',
                branch:branch_id
            }
        })

       const resultOrder = insertOrder.data.data
            for(const data of cartData?.items){
              const insertOrder = await ApiCall({
                apiEndpoint:`/items/order_items`,
                method:ApiMethod.POST,
                service:true,
                apiData:{
                    order:resultOrder?.id,
                    restaurant:data?.menu?.restaurant, //must have a value
                    menu:data?.menu?.id,
                    cart_items:data?.id,
                    item_desc:data?.description,
                    regular_price:Number(data?.regular_price),
                    quantity:data?.quantity,
                    unit_price:Number(data?.regular_price),
                    unit_discount_amount:0,
                    discount_total:0,
                    amount_total:Number(data?.regular_price) * data?.quantity,
                    image:data?.menu?.image,
                    food_name:data?.food_name,
                    note:data?.note,
                    stations:data?.stations
                }
            })
       }

            //published cart
            const patch_cart = await ApiCall({
                apiEndpoint:`/items/carts/${cartData?.id}`,
                method:ApiMethod.PATCH,
                service:true,
                apiData:{
                    status:'published'
                }
            })


            //pull ta sa kitchen ang order na payment_status paid and status draft.. then ang order_items didto na ma patch
            for(const data of cartData?.items){
                const patchCartItems = await ApiCall({
                  apiEndpoint:`/items/cart_items/${data.id}`,
                  method:ApiMethod.PATCH,
                  service:true,
                  apiData:{
                      status:'published'
                  }
              })
            }

            const update_order = await ApiCall({
              apiEndpoint:`/items/orders/${resultOrder.id}`,
              method:ApiMethod.PATCH,
              service:true,
              apiData:{
                  payment_status:'pending' //sa cashier pani pedeee
              }
            })

          
            if(order_id_list.length > 0){
              dispatch(setListOrderId([...order_id_list,order_id.order_id]))
            }else{
              dispatch(setListOrderId([order_id.order_id]))
            }
            const newOrderID = await GenerateOrderID(restaurant_id,ApiMethod,ApiCall)
            console.log("newwww orderrrr id+++++++++++++++++++++++++++++++++++++",newOrderID)
            dispatch(setOrderID({
              order_id:newOrderID.id,
              sequence:newOrderID.sequence                                
            }))
           
            setLoader(false)
            navigate(`/customer-thank-you-screen/${currenSeq.sequence}`)
            // navigate(`/customer-thank-you-screen/${order_id}`)
    }catch(err){
        setLoader(false)
        // alert("err",err)
        console.log("placeOrder err",err)
    }


  }

  const validateStaff = async(parseParams) =>{
     try{
        setLoader(true)
        const validateStaff = await ApiCall({
          apiEndpoint:`/items/staff/${parseParams}`,
          method:ApiMethod.GET,
          service:true,
        
        })
        if(validateStaff?.data?.data?.id){
          setIsStaffValid(validateStaff.data.data)
          setLoader(false)
          setSnackConfig({
            message:"Waiter Validation success",
            type:"success",//success,error
            show:true
          })
        }else{
          setIsStaffValid(validateStaff.data.data)
          setLoader(false)
          setSnackConfig({
            message:"Waiter is not valid",
            type:"error",//success,error
            show:true
          })
          
        }
      
     }catch(err){
      setLoader(false)
      console.log("this is the validateStaff errr",err)
     }
  }


  return (
    <div className="my_container" > 
    
    <div className="waiter_container">
        {loader && <AppLoader/>} 
        <Typography style={{marginBottom:'20px'}}>Scan waiter qr code</Typography>
        <QrReader
        constraints={{facingMode: 'environment' }} 
        containerStyle={{width:'100%',height:"60vh",display:'flex',alignItems:'center',justifyContent:'center'}}
        onResult={(result, error) => {
          if(!!result){
            const data = result.toString()
            // const params = data.split("?data=")[1];
            // const decoded = decodeURIComponent(params);
            // const parseParams = JSON.parse(decoded);
            // console.log("thiss is the parseee paramss",parseParams)
            setExtractedData(data)
            validateStaff(data)
            // alert(`this is the  result ${JSON.stringify() } , type ${typeof parseParams}`)
          }
         

          if (!!error) {
            // alert(`success ${error}`)
            console.info(error);
          }
        }}
        style={{ width: '100%' }}
      />
      <AppSnackBar snackConfig={snackConfig}/>
      {/* <Button onClick={()=>scanQrSimulate()}>Scan Qr simulate</Button> */}
      {isStaffValid &&   <Button variant="contained" onClick={()=>placeOrder()} style={{backgroundColor:app_theme?.appBtn[0]?.bgColor,width:"90%",borderRadius:"8px",textTransform:'capitalize',padding:"5px",boxShadow: 'none',border:"none",marginTop:"20px"}}><b>Confirm Order</b></Button>}
    </div>

    </div>
   
  )
}
