import * as React from 'react';
import "./styles.scss";
import { Button, Typography } from '@mui/material';

const AppTestComponent = () => {
  return (
    <>
      <Typography>
        AppTestComponent
      </Typography>
    </>
  );
}

export default AppTestComponent;