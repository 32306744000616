import * as React from "react";
import "./styles.scss";
import { useNavigate } from "react-router-dom";
import { appTheme } from "../../../constants";
import logo from "../../../assets/images/logo1.png"
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { Button, Typography } from '@mui/material';
import { AppTabBar, AppTabIndicator } from "../../../components";
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

export default function ThankYouScreen() {
  const navigate = useNavigate();
  const themeCurrent = useSelector((state)=>state.user.currentTheme)
    
   const app_theme = appTheme(themeCurrent)
   const params = useParams();
   const sequence = params?.order_id
   const order_id = useSelector((state)=>state.user.order_id)

  return (
   <section id="">
    <div className="my_container">
      <div style={{position:'absolute',top:0,padding:"20px", width:"100%",zIndex:10}}>
        <AppTabIndicator tabIndex={2}/>      
    </div>
 

    <div className="ty_container" style={{backgroundImage: `url("${app_theme?.appBg}")`}}>
     
            <div className="mid">
                <img src={logo} />
                <Typography className="desc">Thank you for your order,
                it's now being expertly
                crafted in our kitchen.</Typography>
                <div className="btn_container" style={{backgroundColor:app_theme?.appBtn[1]?.bgColor}}>
                    <Typography className="first">
                        Order ID: <b>{sequence}</b>
                    </Typography>
                </div>
            </div>
          
            <div className="ty_footer">
                {/* <div className="save_text">
                    <Typography>Save This To Phone</Typography>
                    <FileUploadOutlinedIcon style={{fontSize:'25px',marginLeft:'10px'}}/>
                </div> */}
                <Button variant="outlined" onClick={()=>navigate("/customer-main-page")} style={{borderColor:app_theme?.fontColor, color:app_theme?.fontColor,borderRadius:"8px",width:"100%", fontSize:"10px",padding:"10px",textTransform:"capitalize",fontSize:"16px",boxShadow: 'none'}}>
                                <b>Close</b> 
                </Button>
            </div>
    </div>
    </div>
   </section>
  )
}