import React, { useEffect, useState } from 'react';
import "./styles.scss";
import { useNavigate } from "react-router-dom";
import moment from 'moment';
import { Button, Input } from "@material-ui/core";
import Logo from "../../../assets/images/logo1.png"
import logoPlaceholder from "../../../assets/images/logo_placeholder.png"

import { appTheme } from "../../../constants";
import { useSelector } from 'react-redux';
import { ApiCall,ApiMethod } from '../../../services/AxiosInstance';
import { useDispatch } from 'react-redux';
import { setRestaurantData } from '../../../redux/reducer/Restaurant';
import {AppSnackBar,AppLoader, AppMultiSelectDropDown} from '../../../components';
import Autocomplete from '@mui/material/Autocomplete';
import { EmailValidator } from '../../../utilities';
import ImageIcon from '@mui/icons-material/Image';
import TextField from '@mui/material/TextField';
export default function RestaurantSignupPage() {
  const navigate = useNavigate();
  const themeCurrent = useSelector((state)=>state.user.currentTheme)
  const theme = appTheme(themeCurrent)
  const restaurantData = useSelector(state => state.restaurant.restaurantData);
  const [callingAPI,setCallingAPI]=useState(false)
  const [catergories,setCategories] = useState([])
  const [selectedCat,setSelectedCat] = useState([])
  const [selectedImg,setSelectedImg] = useState(null)
  const [selectedFile,setSelectedFile] = useState()
  const [snackConfig,setSnackConfig]= useState({
    message:null,
    type:"success",//success,error
    show:false
  })
  const dispatch = useDispatch()
  if(restaurantData){
    navigate("/restaurant-main-page");
  }
  useEffect(()=>{
    getRestaurantCategory()
  },[JSON.stringify(snackConfig)])


const getRestaurantCategory = async() =>{
   try{
   const response = await ApiCall({
      apiEndpoint:'/items/restaurant_category_list',
      method:ApiMethod.GET,
      service:true
    })
    const result = response.data.data
    setCategories(result)
   }catch(err){
      console.log("getRestaurantCategory err",err)
   }
}
const handleChangeImage = (e) => {
  if (e.target.files) {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64String = reader.result;
      setSelectedImg(base64String);
    }
    reader.readAsDataURL(file);
    setSelectedFile(e.target.files[0])
  }
};

  return (
   <section id="RestaurantSignupPage">
    <div className="my_container" style={{backgroundImage: `url("${theme?.appBg}")`, backgroundSize: 'cover', backgroundPosition: 'center center'}}>
      <img src={Logo} className="imageLogo"  onClick={()=>  navigate("/")} style={{cursor:'pointer'}}/>
      <form
      className="content"
      onSubmit={async(event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const formJson = Object.fromEntries((formData).entries());
        if(!formJson.fname){
          setSnackConfig({
            message:"Please provide your first name",
            show:true,
            type:"error"
          })
        }else if(!formJson.lname){
          setSnackConfig({
            message:"Please provide your last name",
            show:true,
            type:"error"
          })
        }
        // else if(!formJson.usern){
        //   setSnackConfig({
        //     message:"Please provide your username",
        //     show:true,
        //     type:"error"
        //   })
        // }
        else if(!formJson.passw){
          setSnackConfig({
            message:"Please provide your password",
            show:true,
            type:"error"
          })
        }else if(formJson.passw.length<8){
          setSnackConfig({
            message:"Please provide atleast 8 charaters for your password",
            show:true,
            type:"error"
          })
        }else if(!formJson.email){
          setSnackConfig({
            message:"Please provide your email",
            show:true,
            type:"error"
          })
        }else if(formJson.email && !EmailValidator(formJson.email)){
          setSnackConfig({
            message:"Please provide a valid email",
            show:true,
            type:"error"
          })
        }else if(!formJson.phone){
          setSnackConfig({
            message:"Please provide your contact number",
            show:true,
            type:"error"
          })
        }else if(isNaN(formJson.phone)){
          setSnackConfig({
            message:"Please provide valid number",
            show:true,
            type:"error"
          })
        }else if(!formJson.addr1){
          setSnackConfig({
            message:"Please provide your first address",
            show:true,
            type:"error"
          })
        }else if(!formJson.rname){
          setSnackConfig({
            message:"Please provide restaurant name",
            show:true,
            type:"error"
          })
        }else if(!formJson.remail){
          setSnackConfig({
            message:"Please provide restaurant email",
            show:true,
            type:"error"
          })
        }else if(formJson.remail && !EmailValidator(formJson.remail)){
          setSnackConfig({
            message:"Please provide a valid for restaurant email",
            show:true,
            type:"error"
          })
        }else if(selectedCat.length === 0){
          setSnackConfig({
            message:"Please Select Category",
            show:true,
            type:"error"
          })
        }else if(!selectedImg){
          setSnackConfig({
            message:"Please provide restaurant logo",
            show:true,
            type:"error"
          })
        }else{
          await ApiCall({
            apiEndpoint:'/roles',
            method:ApiMethod.GET,
            service:true,
            filter:{
              name:{
                _eq:'Api Users'
              }
            }
          }).then(async(roles)=>{
            const roleID = roles.data.data[0].id
            const today = new Date();
            today.setDate(today.getDate() + 30);
            const dayToday = moment(today)
            const todaysDate = dayToday.format(`YYYY-MM-DD HH:mm:ss`);
            setCallingAPI(true)
            await ApiCall({
              apiEndpoint:"/users",
              method:ApiMethod.POST,
              service:true,
              apiData:{
                first_name:formJson.fname,
                last_name:formJson.lname,
                user_name:formJson.email,
                password: formJson.passw,
                email: formJson.email,
                phone_number:formJson.phone,
                address_1:formJson.addr1,
                address_2:formJson.addr2,
                role: roleID,
                user_access_expiration:todaysDate,
                status:"active"//draft,active,suspended
              }
            }).then(async(newUsers)=>{
              const userId = newUsers.data.data.id
              await ApiCall({
                apiEndpoint:'/folders',
                method:ApiMethod.POST,
                service:true,
                apiData:{
                  name: userId
                }
              }).then(async(restauFolder)=> {

                
                //save ta di ang page_setting
                const pageSetting = await ApiCall({
                  apiEndpoint:"/items/page_setting",
                  method:ApiMethod.POST,
                  service:true,
                  apiData:{
                    account:userId,
                  }
                })

                const pageSettingId = pageSetting.data.data.id

                const restauFolderData = restauFolder.data.data
                //upload image first
                const fileFormData = new FormData();
                fileFormData.append("folder", restauFolderData.id);
                fileFormData.append("file", selectedFile);


                const imgUp = await ApiCall({
                  apiEndpoint: "/files",
                  method: ApiMethod.POST,
                  apiContentType: "multipart/form-data",
                  apiData: fileFormData,
                  service:true,
                })
                const resDetail = await ApiCall({
                  apiEndpoint:"/items/restaurant",
                  method:ApiMethod.POST,
                  service:true,
                  apiData:{
                    user_id:userId,
                    folder_id:restauFolderData.id,
                    page_setting:pageSettingId,
                    res_logo:imgUp.data.data.id,
                    res_name:formJson.rname,
                    res_email:formJson.remail,
                  }
                })

                //save the category here
                if(selectedCat.length > 0){
                    for(const data of selectedCat){
                      const catResponse = await ApiCall({
                        apiEndpoint:"/items/restaurant_category",
                        method:ApiMethod.POST,
                        service:true,
                        apiData:{
                           restaurant:resDetail.data.data.id,
                           restaurant_details:data.id
                        }
                      })
                    }
                }




                  await ApiCall({
                    apiEndpoint:"/auth/login",
                    method:ApiMethod.POST,
                    apiData:{
                      email:formJson.email ,
                      password:formJson.passw,
                    }
                  }).then(async(authLogin)=>{
                    await ApiCall({
                      apiEndpoint:"/users/me",
                      fields:"*.*",
                      method: ApiMethod.GET,
                      token: authLogin.data.data.access_token,
                    }).then((userMe)=>{
                      const userDetails = newUsers.data.data
                      const userAccess = authLogin.data.data
                      const resDetails = resDetail.data.data
                      const userDetailsAcess = { ...{userAccess:userAccess}, ...{userDetails:userDetails},...{resDetails:resDetails} }
                      dispatch(setRestaurantData(userDetailsAcess))
                      setCallingAPI(false)                      
                      // window.location.href = "/restaurant-main-page";
                      navigate("/restaurant-main-page")
                     
                    }).catch((errUserMe)=>{
                      setCallingAPI(false)
                      setSnackConfig({
                        message:"Failed: Something went wrong!",
                        show:true,
                        type:"error"
                      })
                      console.log("err User Me",errUserMe)
                    })
                  }).catch((errAuthLogin)=>
                    {
                      setCallingAPI(false)
                      console.log("err Auth Login",errAuthLogin)
                      setSnackConfig({
                        message:"Please provide email address",
                        show:true,
                        type:"error"
                      })
                    }
                  
                  )
                }).catch((restauFolderErr)=>{
                  setCallingAPI(false)
                  console.log("Error Create Folder",restauFolderErr)
                  setSnackConfig({
                    message:"Failed: Something went wrong!",
                    show:true,
                    type:"error"
                  })
                })
            }).catch((errNewuser)=>
              {
                setCallingAPI(false)
                console.log("Error New user",errNewuser)
                setSnackConfig({
                  message:"Email already exist!",
                  show:true,
                  type:"error"
                })
              }
            
            )
            
          }).catch((errorRoles)=>
            console.log("error Roles",errorRoles)
          )
        }
      }}
      >
        <h1 className="title">Create Account</h1>
        <input name="fname" className="inputs" placeholder="First Name" />
        <input name="lname" className="inputs" placeholder="Last Name" />
        {/* <input name="usern" className="inputs" placeholder="Username" /> */}
        <input name="passw"  className="inputs" placeholder="Password" type='password' />
        <input name="email" className="inputs" placeholder="Email" />
        <input name="phone" className="inputs" placeholder="phone e.g. xxxxxxxx" />
        <input name="addr1" className="inputs" placeholder="Address1" />
        <input name="addr2" className="inputs" placeholder="Address2" />
        <input name="rname" className="inputs" placeholder="Restaurant Name" />
        <input name="remail" className="inputs" placeholder="Restaurant Email" />
        <div className="inputs" style={{padding:0,border: "0px solid #000000",marginTop:5,marginBottom:40}}>
          {catergories.length > 0 && <Autocomplete  className='' style={{marginTop:10,backgroundColor:"white",border:"1px solid gray",borderRadius:10,width:'100%'}}
              multiple
              disableClearable
              id="tags-standard"
              options={catergories}
              getOptionLabel={(option) => option.name}
              //value={[ { "id": "8a59beb7-0aa4-4fc6-8d7e-9048f0bb3685", "status": "draft", "user_created": "704ed643-3a06-4f43-95d2-ab98bf384cb8", "date_created": "2024-03-25T12:12:26.388Z", "user_updated": "704ed643-3a06-4f43-95d2-ab98bf384cb8", "date_updated": "2024-03-27T06:43:01.914Z", "name": "Chinese", "description": "category 2 desc" }, { "id": "a11a94cb-000e-469c-8796-7dc9e51ff266", "status": "draft", "user_created": "704ed643-3a06-4f43-95d2-ab98bf384cb8", "date_created": "2024-03-25T12:12:12.250Z", "user_updated": "704ed643-3a06-4f43-95d2-ab98bf384cb8", "date_updated": "2024-03-27T06:43:08.788Z", "name": "Japanese", "description": "cat 1 desc" } ]}
              value={selectedCat}
              filterSelectedOptions
              onChange={(event, newValue) => {
                console.log("event",event)
                setSelectedCat(newValue)
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  // label="Restaurant Categories"
                  placeholder="Restaurant Categories"
                />
              )}
            /> }
        </div>
        
        {/* <div className="inputs" style={{padding:0,border: "0px solid #000000",marginTop:5,marginBottom:5}}>
          <AppMultiSelectDropDown data={catergories} setSelectedCat={setSelectedCat} style={{width:"100%"}}  />
        </div> */}
        <div className="imgSection">
            <div className="imgHolder" style={{position:'relative'}}>
               <div className="modalImgProd" style={{backgroundImage:`url(${selectedImg?selectedImg:logoPlaceholder})`}}/>
              {/* <img src={selectedImg} className="modalImgProd"/> */}
              

              <div className="changeImage">
                  <div className="imgIcon">
                    <ImageIcon />
                  </div>
                  <div className="imgContentHolder" style={{position:'relative'}}>
                    <a href="javascript:void(0)" className="changeLabel">{"Restaurant Logo"}</a>
                    <input id="file" type="file" accept="image/*" onChange={handleChangeImage} style={{position:'absolute',opacity:0,height:'100%',width:'100%'}}/>
                    <p className="changeDesc">(Max. File size: 25 MB)</p>
                  </div>
              </div>
            </div>
          </div>
        <Button className="btnSignup" type='submit' onClick={() => {}}>Create Account</Button>
      </form>
    </div>
    {
      callingAPI && <AppLoader/>
    }
    <AppSnackBar snackConfig={snackConfig}/>
   </section>
   
  )
}