import React, { useState } from 'react'
import { useEffect } from 'react'
import { ApiCall ,ApiMethod} from '../../../services/AxiosInstance';
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Typography } from '@mui/material';
import "./styles.scss"
import TableRestaurantIcon from '@mui/icons-material/TableRestaurant';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { AppLoader } from '../../../components';

const TablePage = () => {

    const [tables,setTables] = useState([])
    const [activeIndex,setActiveIndex] = useState(0)
    const [loading,setLoading] = useState(false)
    useEffect(()=>{
        getAllTables();
    },[])

    const branch_id = '2b416a25-e428-4f0b-bb39-7cc209cce4a8'
    const staff_data =  useSelector((state)=>state.user.staff_data)
    console.log("thiss is the staff dataaa",staff_data)
    const getAllTables = async()=>{
        try{
           setLoading(true)
            const response = await ApiCall({
              apiEndpoint:`/items/Tables?fields=*&filter[branch][_eq]=${staff_data?.branch}&filter[status][_eq]=published&sort=date_created`,
              method:ApiMethod.GET,
              service:true
            })
            const result = response.data.data
             
            const groupedResult = {};

            // Group items by category
            result.forEach(item => {
                const category = item.table_name.charAt(0); 
                
                if (!groupedResult[category]) {
                    groupedResult[category] = { category, data: [] };
                }
            
                groupedResult[category].data.push(item);
            });
            
            const groupedArray = Object.values(groupedResult);
            setTables(groupedArray)
            setLoading(false)
            console.log("this is the resulttt+++ ALL TABLES",groupedArray)
            
        }catch(err){
          setLoading(false)
          console.log("getStaffDetails",err)
        }
     }


  return (
    <div className='table_page_container'>
      {tables.length > 0 && <>
        <div className='column_header_container'>
                {tables.map((item,index)=>{
                      return  <Typography className='column_cat' style={{backgroundColor: index == activeIndex ? "#380306" : "transparent",color:index == activeIndex ? "white" : "black",fontWeight:"700",fontSize:"18px"}} onClick={()=>setActiveIndex(index)}>{item.category}</Typography>
                })}

               
            </div>
            <div className='content_container'>
               <div className='table_container'>
                {tables[activeIndex].data.map((item)=>{
                  return <div className='card' style={{backgroundColor:"white",position:'relative'}}>
                         <Typography style={{fontWeight:700,marginTop:"5px",marginBottom:"-10px",color:item?.is_occupied === 1 ? "red" : "#000000"}}>{item?.table_name}</Typography>
                         {item?.is_occupied === 1 &&  <Typography style={{
                            color: "white",
                            marginBottom: "15px",
                            width: "55px",
                            // boxShadow:"0 2px 7px gray",
                            textAlign: "center",
                            lineHeight: "12px",
                            fontSize: "8px",
                            fontWeight: "bold",
                            // backgroundColor:"orange",
                            position: "absolute",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap", // Ensures text remains on a single line
                          }}>
                         {item?.assinged_customer}
                         </Typography>
                         }
                         <TableRestaurantIcon style={{width:"90px",height:"90px", color:item?.is_occupied === 1 ? "#CE1C1C" : "lightgray"}}/>
                       
                      </div>
                      // return <div className='card' style={{backgroundColor:item?.is_occupied === 1 ? "#CE1C1C" : "#EEEAE9"}}>
                      //     <FiberManualRecordIcon style={{color:item?.is_occupied === 1 ? "#2B9DF0" : "#52F02B"}}/>
                      //     <Typography style={{fontWeight:700,marginTop:"5px",color:item?.is_occupied === 1 ? "#FFFFFF" : "#000000"}}>{item?.table_name}</Typography>
                      //      {item?.is_occupied === 1 &&  <Typography style={{color:"#FFFFFF",textAlign:"center",lineHeight:"12px",fontSize:"10px"}}>
                      //      {item?.assinged_customer}
                      //   </Typography>
                      //}
                      // </div>
                  })}
               </div>
             
            </div>
      </>}
      {loading &&  <AppLoader/>}
       
    </div>
  )
}

export default TablePage
