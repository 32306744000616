import React, { useEffect, useState } from "react";
import { ApiCall, ApiMethod } from "../../../services/AxiosInstance";
import { Typography } from "@mui/material";
import "./styles.scss";
import { AppLoader, AppSnackBar } from "../../../components";
import ScheduleIcon from "@mui/icons-material/Schedule";
import BadgeIcon from "@mui/icons-material/Badge";
import TodayIcon from "@mui/icons-material/Today";
import GroupsIcon from "@mui/icons-material/Groups";
import PhoneIcon from "@mui/icons-material/Phone";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import TableBarIcon from '@mui/icons-material/TableBar';
import { Button, IconButton, InputAdornment, TextField } from "@material-ui/core";
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import Modal from '@material-ui/core/Modal';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import InfoIcon from '@mui/icons-material/Info';
import CancelIcon from '@mui/icons-material/Cancel';
import TableRestaurantIcon from '@mui/icons-material/TableRestaurant';

const moment = require("moment");
const ReservationPage = ({ resDetail,staff_id,staff_name }) => {
  useEffect(() => {
    if(resDetail){
      getReservationList();
    }
  }, [resDetail]);
  console.log("thiss is the res detailsss",resDetail)
  const [reservationList, setReservationList] = useState([]);
  const [loading, setLoading] = useState();
  const [showModal,setShowModal] = useState(false)
  const [selectedReserve,setSelectedReserve] = useState(false)
  const [showTableList,setShowTableList] = useState(false)
  const [filteredTable,setFilteredTable] = useState([])
  const [searchResult,setSearchResult] = useState([])
  const [searchVal,setSearchVal] = useState("")
  const [searchableData,setSearchableData] = useState([])
  const [isCancelTable,setIsCancelTable] = useState(false)
  const [selectedCancel,setSelectedCancel] = useState()
  
  const [snackConfig,setSnackConfig]= useState({
    message:null,
    type:"error",//success,error
    show:false
  })
  const [showDialog, setShowDialog] = useState({show:false,details:null,data:null,isUpdate:false});

  //const staff_data =  {staff_id:'72b93eeb-4c3e-4564-a522-9d87a85fb25d'}

  const filterTimeAndDate = (response) => {
    const currentDate = moment();
    const currentDay = currentDate.date();
    const currentMonth = currentDate.month();
    const currentYear = currentDate.year();

    // Filter the response based on reservation_date matching the current date
    const filteredByDate = response.filter((item) => {
      const reservationDate = moment(item.reservation_date);
      return (
        reservationDate.date() === currentDay &&
        reservationDate.month() === currentMonth &&
        reservationDate.year() === currentYear
      );
    });


    //future and current date
    let current_and_future = [...filteredByDate];

    const futureReservations = response.filter((item) => {
        const reservationDate = moment(item.reservation_date);
        const isFuture = reservationDate.isAfter(currentDate, 'day');
        return isFuture; // Only return future reservations
    }).map(item => ({ ...item, isFuture: true })); // Add isFuture property to future reservations

    current_and_future = [...current_and_future, ...futureReservations];
    console.log("thiss is the current and futureeeeeeeee", current_and_future)
    setSearchableData(current_and_future)


    const startTime = moment(currentDate).subtract(2, "hours");

    const filteredResponse = filteredByDate.filter((item) => {
      const timeSlot = moment(item.time_slot, "hh:mm A");

      if (item.time_slot.includes("PM") && startTime.format("A") === "AM") {
        timeSlot.add(12, "hours");
      }

      // Compare the time_slot with the start time
      return timeSlot.isSameOrAfter(startTime);
    });
    return filteredResponse;
  };

//   const checkAvailableTable = async(range) =>{
//         try{
//             const checkTable = await ApiCall({
//                 apiEndpoint:`/items/Tables?fields=*&filter[is_occupied][_eq]=0&sort=date_created`,
//                 method:ApiMethod.GET,
//                 service:true
//             })
//             const result = checkTable.data.data
//             const filterResult = result.filter((item)=>{
//                     return item.range
//             })
            
//             console.log("thiss is the checkTable",filterResult)
//             const filterRange = filterResult.filter((item)=>{
//                 console.log("item.range.startingPoint",item.range.startingPoint)
//                 return item.range.startingPoint <= range && item.range.endPoint >= range;
//             })
//             // alert(JSON.stringify(filterRange))

//             return filterRange[0]
            
//         }catch(err){
//             console.log("checkAvailableTable err",err)
//         }
//   }


const cancelTable = async(data) =>{
    try{
     
       setLoading(true)
       const patchReserve =  await ApiCall({
          apiEndpoint: `/items/Reservations/${data?.id}?fields=*,table.*`,
          method: ApiMethod.PATCH,
          service: true,
          apiData:{
              table:null,
              waiter_confirmation:null,
              // branch:null,
          }
      });
      const patchTable =  await ApiCall({
        apiEndpoint: `/items/Tables/${data?.table?.id}`,
        method: ApiMethod.PATCH,
        service: true,
        apiData:{
              is_occupied:0,
              assinged_customer:null
        }
    });
    setIsCancelTable(false);
    setSelectedCancel()
    await getReservationList()
    

    }catch(err){
      setLoading(false)
      console.log("cancelTable err",err)
    }
}


  const getReservationList = async () => {
    try {
      setLoading(true);
      const response = await ApiCall({
        apiEndpoint: `/items/Reservations?fields=*,table.*&filter[branch][_eq]=${resDetail?.branch}&limit=50&sort=date_created`,
        method: ApiMethod.GET,
        service: true,
      });

      const result = response.data.data;

      //sort by today date
      const filteredResponse = filterTimeAndDate(result);
      //FIND CORRESPONDING TABLE TTHAT MATCHES THE NUMBER OF PERSON RANGE
        //   for(const data of filteredResponse){
        //          const tableAvailable = await checkAvailableTable(data.number_of_person)
        //          data.table_id = tableAvailable.index
        //          data.table_name = tableAvailable.table_name
        //   }
     
      setReservationList(filteredResponse);
      setLoading(false);
      console.log("thiss is ther resulttt list resravations", filteredResponse);
    } catch (err) {
      setLoading(false);
      console.log("getReservationList err", err);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { month: "long", day: "numeric", year: "numeric" };
    return date.toLocaleDateString("en-US", options);
  };


 const getFilterTables = async(personCount) =>{
          console.log("this is the person caounttt",personCount.number_of_person)
          try{
            setLoading(true)
            const response = await ApiCall({
                apiEndpoint: `/items/Tables?fields=*&filter[status][_eq]=published&filter[starting_range][_lte]=${personCount.number_of_person}&filter[closing_range][_gte]=${personCount.number_of_person}
                &sort=date_created`,
                method: ApiMethod.GET,
                service: true,
            });

            const result = response.data.data
            console.log("resulttt filtereed tablesssssssssssssss",result)
            setFilteredTable(result)
            //filter the starting point and endpoint based on the range of the person
            //if person is >= to starting point and <= to endpoint 


            setLoading(false)
        }catch(err){
            setLoading(false)
            console.log("getFilterTables err",err)
        }
 }

 const updateTable = async(data) =>{
        try{
            //patch table status
            console.log("tableesss",data)
            console.log("reservation",selectedReserve)
            //if ang selected reserve ga exist sa may 
            //if may existing nasa na table then ma change table..para ma 1 is to 1 ta ang table
            if(selectedReserve.table){
                //unselect ta iya table
                setShowDialog({show:true,details:`Change table from ${selectedReserve?.table?.table_name} to ${data?.table_name} ?`,data:data,isUpdate:true})
                // setLoading(true)
                // const patchTable = await ApiCall({
                //     apiEndpoint: `/items/Tables/${selectedReserve?.table?.id}`,
                //     method: ApiMethod.PATCH,
                //     service: true,
                //     apiData:{
                //         is_occupied:0
                //     }
                // });

                // //then patch the update one
                // const patchTableNew = await ApiCall({
                //     apiEndpoint: `/items/Tables/${data?.id}`,
                //     method: ApiMethod.PATCH,
                //     service: true,
                //     apiData:{
                //         is_occupied:1
                //     }
                // }); 

                // const patchReserve =  await ApiCall({
                //     apiEndpoint: `/items/Reservations/${selectedReserve?.id}`,
                //     method: ApiMethod.PATCH,
                //     service: true,
                //     apiData:{
                //         table:data?.id,
                //         waiter_confirmation:staff_data?.staff_id
                //     }
                // });

                // setSelectedReserve(patchReserve.data.data)
                // //patch reservation table,and confirmation (which is the waiter id)
                // await getFilterTables(selectedReserve)
                // setSnackConfig({
                //     message:"Table Confirmed.",
                //     type:"success",//success,error
                //     show:true
                // })

            }else{
                setShowDialog({show:true,details:"Confirm Table?",data:data,isUpdate:false})
                // setLoading(true)
                // const patchTable = await ApiCall({
                //     apiEndpoint: `/items/Tables/${data?.id}`,
                //     method: ApiMethod.PATCH,
                //     service: true,
                //     apiData:{
                //         is_occupied:1
                //     }
                // });
                // const table_id = patchTable?.data.data
                // console.log("thiss is the tablee id",table_id)
    
                // const patchReserve =  await ApiCall({
                //     apiEndpoint: `/items/Reservations/${selectedReserve?.id}`,
                //     method: ApiMethod.PATCH,
                //     service: true,
                //     apiData:{
                //         table:data?.id,
                //         waiter_confirmation:staff_data?.staff_id
                //     }
                // });
    
                // setSelectedReserve(patchReserve.data.data)
                // //patch reservation table,and confirmation (which is the waiter id)
                // await getFilterTables(selectedReserve)
                // setSnackConfig({
                //     message:"Table Confirmed.",
                //     type:"success",//success,error
                //     show:true
                // })
            }
            
            
        }catch(err){
            setLoading(false)
            console.log("updateTable err",err)
        }
 }

 const handleChange = (e) =>{
    try{    
        
        const value = e.nativeEvent.target.value.toLowerCase();
        setSearchVal(value)
        if(value === ''){
            setSearchResult([])
            return
        }
        const duplicate = [...searchableData]
        const filteredList = duplicate.filter(item => {
          const lowercaseContact = item.contact_number.toLowerCase();
          const lowercaseEmail = item.email_address.toLowerCase();
          const lowercaseName = item.name.toLowerCase();
            return (
              lowercaseContact.includes(value) ||
              lowercaseEmail.includes(value) ||
              lowercaseName.includes(value)
            );
        });
        setSearchResult(filteredList)
        
       

    }catch(err){
        console.log("thiss is the errr",err)
    }

 }


  return (
    <div className="ReservationPage_container">
        
      {reservationList.length > 0 ? (
        <>
          <Typography className="reservation_list">Reservation List</Typography>
                         <TextField
                                onChange={(e)=>handleChange(e)}
                                onKeyDown={(event) => {
                                    if (event.key === 'Enter') {
                                        console.log("hit enter")
                                    }
                                }}
                                style={{width:"100%",backgroundColor: "#FFFFFF",borderRadius:"5px",padding:"5px",marginBottom:"20px"}}
                                placeholder="e.g. Name ,Email, or Contact Number"
                                InputProps={{
                                    startAdornment: (
                                    <InputAdornment position="start" style={{padding:0}}>
                                        <IconButton edge="start" aria-label="search" style={{padding:0,marginLeft:"5px"}}>
                                        <SearchIcon style={{fontSize:"30px",marginRight:'-10px'}}/>
                                        </IconButton>
                                    </InputAdornment>
                                    ),
                                }}
                            />
          
          {searchResult.length > 0 ? <div className="card_main_cont">
          {searchResult.map((data, index) => {
            return <>
               {data?.isFuture ? <div className="card">
                <div style={{display:"flex",alignItems:'center',marginBottom:"10px"}}>
                <InfoIcon style={{padding:0,marginRight:"5px",color:"red"}}/>
                <Typography style={{fontWeight:"700",color:"red",fontSize:"12px"}}> This reservation is for tomorrow/future.</Typography>
                </div>
                 
               <div className="cont">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <BadgeIcon
                      style={{
                        fontSize: "20px",
                        color: "#F0522B",
                        marginRight: "5px",
                      }}
                    />
                    <Typography className="label">Name </Typography>
                  </div>

                  <Typography className="value">{data?.name}</Typography>
                </div>

                <div className="cont">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <TodayIcon
                      style={{
                        fontSize: "20px",
                        color: "#F0522B",
                        marginRight: "5px",
                      }}
                    />
                    <Typography className="label">Date</Typography>
                  </div>
                  <Typography className="value">
                    {formatDate(data?.reservation_date)}{" "}
                  </Typography>
                </div>
                <div className="cont">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <ScheduleIcon
                      style={{
                        fontSize: "20px",
                        color: "#F0522B",
                        marginRight: "5px",
                      }}
                    />
                    <Typography className="label">Time</Typography>
                  </div>

                  <Typography className="value">{data?.time_slot} </Typography>
                </div>

                </div>
               : <div style={{position:"relative",width:"100%",maxWidth:"450px"}}>
                
                {data?.table &&  <div onClick={()=>{setSelectedCancel(data);setIsCancelTable(true)  }} style={{position:"absolute",right:"10px",top:"13px",display:"flex",alignItems:"center",flexDirection:"column",cursor:"pointer",zIndex:4}}>
                  <CancelIcon style={{fontSize:"16px",color:"red"}}/>
                  <Typography style={{fontSize:"10px",color:"red"}}>Cancel</Typography>
                 </div> 
                }

                <div className="card" key={index} onClick={()=> {
                console.log("whole data",data)
                setSelectedReserve(data)
                setShowModal(true)
                }}>


                <div className="cont">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <TableBarIcon
                      style={{
                        fontSize: "20px",
                        color: "#F0522B",
                        marginRight: "5px",
                      }}
                    />
                    <Typography className="label">Table Type </Typography>
                  </div>

                  <Typography className="value">{data?.table ? data?.table?.table_name : "Not set"}</Typography>
                </div>
                <div className="cont">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <BadgeIcon
                      style={{
                        fontSize: "20px",
                        color: "#F0522B",
                        marginRight: "5px",
                      }}
                    />
                    <Typography className="label">Name </Typography>
                  </div>

                  <Typography className="value">{data?.name}</Typography>
                </div>
                <div className="cont">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <TodayIcon
                      style={{
                        fontSize: "20px",
                        color: "#F0522B",
                        marginRight: "5px",
                      }}
                    />
                    <Typography className="label">Date</Typography>
                  </div>
                  <Typography className="value">
                    {formatDate(data?.reservation_date)}{" "}
                  </Typography>
                </div>
                <div className="cont">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <ScheduleIcon
                      style={{
                        fontSize: "20px",
                        color: "#F0522B",
                        marginRight: "5px",
                      }}
                    />
                    <Typography className="label">Time</Typography>
                  </div>

                  <Typography className="value">{data?.time_slot} </Typography>
                </div>
                <div className="cont">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <GroupsIcon
                      style={{
                        fontSize: "20px",
                        color: "#F0522B",
                        marginRight: "5px",
                      }}
                    />
                    <Typography className="label">No. of Person</Typography>
                  </div>

                  <Typography className="value">
                    {data?.number_of_person}{" "}
                    {data?.number_of_person === 1 ? "Person" : "Persons"}{" "}
                  </Typography>
                </div>
                <div className="cont">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <PhoneIcon
                      style={{
                        fontSize: "20px",
                        color: "#F0522B",
                        marginRight: "5px",
                      }}
                    />
                    <Typography className="label">Contact No.</Typography>
                  </div>
                  <Typography className="value">
                    {data?.contact_number}{" "}
                  </Typography>
                </div>

                <div className="cont">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <ContactMailIcon
                      style={{
                        fontSize: "20px",
                        color: "#F0522B",
                        marginRight: "5px",
                      }}
                    />
                    <Typography className="label">Email</Typography>
                  </div>

                  <Typography
                    className="value"
                    style={{ textTransform: "none" }}
                  >
                    {data?.email_address}{" "}
                  </Typography>
                </div>
                <div className="cont">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <FmdGoodIcon
                      style={{
                        fontSize: "20px",
                        color: "#F0522B",
                        marginRight: "5px",
                      }}
                    />
                    <Typography className="label">Address</Typography>
                  </div>

                  <Typography className="value">
                    {data?.address_line_1}, {data?.address_line_2}{" "}
                  </Typography>
                </div>
              </div>
               </div>
               
             
               
               }
            
            </>
             
             
            
          })}
          </div> : searchVal !== "" &&  searchResult.length === 0 ?
            <div
            style={{
              height: "65vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography style={{ fontSize: "18px" ,fontWeight:"bold"}}>
              No reservation found.
            </Typography>
          </div>
         : <div className="card_main_cont">
          {reservationList.map((data, index) => {
            return ( <div style={{position:"relative",width:"100%",maxWidth:"450px"}}>
             {data?.table &&  <div onClick={()=>{setSelectedCancel(data);setIsCancelTable(true)  }} style={{position:"absolute",right:"10px",top:"13px",display:"flex",alignItems:"center",flexDirection:"column",cursor:"pointer",zIndex:4}}>
                  <CancelIcon style={{fontSize:"16px",color:"red"}}/>
                  <Typography style={{fontSize:"10px",color:"red"}}>Cancel</Typography>
                 </div> 
              }
             <div className="card" key={index} onClick={()=> {
                console.log("whole data",data)
                setSelectedReserve(data)
                setShowModal(true)
                }}>
                
               
                
                <div className="cont">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <TableBarIcon
                      style={{
                        fontSize: "20px",
                        color: "#F0522B",
                        marginRight: "5px",
                      }}
                    />
                    <Typography className="label">Table Type </Typography>
                  </div>

                  <Typography className="value">{data?.table ? data?.table?.table_name : "Not set"}</Typography>
                
                </div>
                <div className="cont">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <BadgeIcon
                      style={{
                        fontSize: "20px",
                        color: "#F0522B",
                        marginRight: "5px",
                      }}
                    />
                    <Typography className="label">Name </Typography>
                  </div>

                  <Typography className="value">{data?.name}</Typography>
                </div>
                <div className="cont">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <TodayIcon
                      style={{
                        fontSize: "20px",
                        color: "#F0522B",
                        marginRight: "5px",
                      }}
                    />
                    <Typography className="label">Date</Typography>
                  </div>
                  <Typography className="value">
                    {formatDate(data?.reservation_date)}{" "}
                  </Typography>
                </div>
                <div className="cont">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <ScheduleIcon
                      style={{
                        fontSize: "20px",
                        color: "#F0522B",
                        marginRight: "5px",
                      }}
                    />
                    <Typography className="label">Time</Typography>
                  </div>

                  <Typography className="value">{data?.time_slot} </Typography>
                </div>
                <div className="cont">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <GroupsIcon
                      style={{
                        fontSize: "20px",
                        color: "#F0522B",
                        marginRight: "5px",
                      }}
                    />
                    <Typography className="label">No. of Person</Typography>
                  </div>

                  <Typography className="value">
                    {data?.number_of_person}{" "}
                    {data?.number_of_person === 1 ? "Person" : "Persons"}{" "}
                  </Typography>
                </div>
                <div className="cont">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <PhoneIcon
                      style={{
                        fontSize: "20px",
                        color: "#F0522B",
                        marginRight: "5px",
                      }}
                    />
                    <Typography className="label">Contact No.</Typography>
                  </div>
                  <Typography className="value">
                    {data?.contact_number}{" "}
                  </Typography>
                </div>

                <div className="cont">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <ContactMailIcon
                      style={{
                        fontSize: "20px",
                        color: "#F0522B",
                        marginRight: "5px",
                      }}
                    />
                    <Typography className="label">Email</Typography>
                  </div>

                  <Typography
                    className="value"
                    style={{ textTransform: "none" }}
                  >
                    {data?.email_address}{" "}
                  </Typography>
                </div>
                <div className="cont">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <FmdGoodIcon
                      style={{
                        fontSize: "20px",
                        color: "#F0522B",
                        marginRight: "5px",
                      }}
                    />
                    <Typography className="label">Address</Typography>
                  </div>

                  <Typography className="value">
                    {data?.address_line_1}, {data?.address_line_2}{" "}
                  </Typography>
                </div>
              </div>
            </div>
             
            );
          })}
          </div> }
          
        </>
      ) : (
        <div
          style={{
            height: "80vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography style={{ fontSize: "18px" ,fontWeight:"bold"}}>
            Revervation list is empty.
          </Typography>
        </div>
      )}

      {loading && <AppLoader />}

      <Modal
        open={showModal}
        onClose={async()=>{
                setShowModal(false);
           
           }}
        className="custom-modal"
        // style={{padding:filteredTable.length > 0 && "20px"}}
      >
        <div className="modal-content" style={{overflow:"auto",maxHeight:"100vh", background: filteredTable.length > 0 ?   'white' :'rgba(0,0,0,.8)', outline:"none", padding: '20px',width:"100%",minHeight:"250px",position:filteredTable.length > 0 ? "relative" : "absolute",borderRadius: filteredTable.length > 0 && "8px"}}>
           {filteredTable.length > 0 && <div style={{display:"flex",justifyContent:"flex-end",width:"100%"}}>
              <CloseIcon  style={{color:"red",fontSize:"30px"}} onClick={async()=>{
              await getReservationList()
              setFilteredTable([])
              setShowDialog(prevState => ({
                  ...prevState,
                  show: false,
                  details: null,
                  data: null,
                  isUpdate: false
                }))
            }} />

           </div>  }
         
          {filteredTable.length > 0 && <Typography style={{textAlign:'center',padding:"20px",width:"100%",fontWeight:"600"}}>Table Range : {filteredTable[0]?.starting_range + "-" + filteredTable[0]?.closing_range} Persons</Typography>} 
          <div className="table_list_container">
              {filteredTable.map((item)=>{
                 return <div className="table_card" style={{backgroundColor: "white"}} onClick={()=>{
                    if(item?.is_occupied === 1){
                        setSnackConfig({
                            message:"Table is occupied.",
                            type:"error",//success,error
                            show:true
                        })
                    }else{
                        //alert danay confirmation
                        updateTable(item)
                    }
                   
                    }}>

                      <Typography style={{fontWeight:700,marginTop:"5px",marginBottom:"-10px",color:item?.is_occupied === 1 ? "red" : "#000000"}}>{item?.table_name}</Typography>
                         {item?.is_occupied === 1 &&  <Typography style={{
                            color: "white",
                            marginBottom: "15px",
                            width: "55px",
                            // boxShadow:"0 2px 7px gray",
                            textAlign: "center",
                            lineHeight: "12px",
                            fontSize: "8px",
                            fontWeight: "bold",
                            // backgroundColor:"orange",
                            position: "absolute",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap", // Ensures text remains on a single line
                          }}>
                         {item?.assinged_customer}
                         </Typography>
                       }

                     <TableRestaurantIcon style={{width:"90px",height:"90px",color:item?.is_occupied === 1 ? "#CE1C1C" : "lightgray"}}/>

                        {/* <FiberManualRecordIcon style={{color:item?.is_occupied === 0 ? "#52F02B" : "#2B9DF0",marginBottom:"5px"}}/>
                        <Typography className="table_text" style={{color:item?.is_occupied === 0 ? "#000000" : "#FFFFFF"}}>
                         {item?.table_name}
                        </Typography>
                         {item?.is_occupied === 1 &&  <Typography style={{color:"#FFFFFF",textAlign:"center",lineHeight:"12px",fontSize:"10px"}}>
                           {item?.assinged_customer}
                        </Typography>} */}
                       
                    </div>  
              })}
          </div>

         {filteredTable?.length === 0 && <>
            <div className="label_cont">
            <Typography className="lab_1">Table Type</Typography>
            <Typography className="lab_2">{selectedReserve?.table ? selectedReserve?.table?.table_name : "Not set"}</Typography>
          </div>
          <div className="label_cont">
            <Typography className="lab_1">Waiter</Typography>
            <Typography className="lab_2">{staff_name}</Typography>
          </div>
      
          <div className="label_cont">
            <Typography className="lab_1">Time</Typography>
            <Typography className="lab_2">{selectedReserve?.time_slot}</Typography>
          </div>
      
          <div className="label_cont">
            <Typography className="lab_1">No of Seats</Typography>
            <Typography className="lab_2">{selectedReserve?.number_of_person} {selectedReserve?.number_of_person === 1 ? "Person" : "Persons"} </Typography>
          </div>
      
          <Button variant="contained apply_theme" style={{width:"100%",padding:"8px",backgroundColor:"#F0522B",color:"white",marginTop:"40px",textTransform:"capitalize"}} onClick={()=>{getFilterTables(selectedReserve);setShowTableList(true)}}><Typography>Confirm Customer Arrival</Typography></Button>
      
         </>}
          
      
        </div>
      </Modal>
      <AppSnackBar snackConfig={snackConfig} position={"top"} />
      <Dialog
            open={showDialog.show}
            onClose={()=> setShowDialog(prevState => ({
                ...prevState,
                show: false,
                details: null,
                data: null,
                isUpdate: false
              }))}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
         
          >
            <DialogTitle id="alert-dialog-title" style={{paddingRight:"80px"}}>
              {"Table Confirmation"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description" style={{fontWeight:"700",color:"black"}}>
                {showDialog?.details}
              </DialogContentText>
            </DialogContent>
            <DialogActions>

              <Button variant="outlined" onClick={()=>  setShowDialog(prevState => ({
                ...prevState,
                show: false,
                details: null,
                data: null,
                isUpdate: false
                }))}>No</Button>
              <Button variant="contained"onClick={async()=>{
                if(showDialog?.isUpdate){
                // setLoading(true)\
                console.log("thiss selectedReserve ",selectedReserve)
                console.log("thiss is the showw dialoggg",showDialog)
                
               
                const patchTable = await ApiCall({
                    apiEndpoint: `/items/Tables/${selectedReserve?.table?.id}`,
                    method: ApiMethod.PATCH,
                    service: true,
                    apiData:{
                        is_occupied:0,
                        assinged_customer:null
                    }
                });

                //then patch the update one
                const patchTableNew = await ApiCall({
                    apiEndpoint: `/items/Tables/${showDialog?.data?.id}`,
                    method: ApiMethod.PATCH,
                    service: true,
                    apiData:{
                        is_occupied:1,
                        assinged_customer:selectedReserve.name
                    }
                }); 

                const patchReserve =  await ApiCall({
                    apiEndpoint: `/items/Reservations/${selectedReserve?.id}?fields=*,table.*`,
                    method: ApiMethod.PATCH,
                    service: true,
                    apiData:{
                        table:showDialog?.data?.id,
                        waiter_confirmation:staff_id
                    }
                });
                console.log("patch reeserrveee",patchReserve.data.data)
                setSelectedReserve(patchReserve.data.data)
                //patch reservation table,and confirmation (which is the waiter id)
    
                await getFilterTables(selectedReserve) 
                setShowDialog(prevState => ({
                    ...prevState,
                    show: false,
                    details: null,
                    data: null,
                    isUpdate: false
                  }))
                setSnackConfig({
                    message:"Table Successfully Changed.",
                    type:"success",//success,error
                    show:true
                })
                }
                
                else{
                setLoading(true)
                const patchTable = await ApiCall({
                    apiEndpoint: `/items/Tables/${showDialog?.data?.id}`,
                    method: ApiMethod.PATCH,
                    service: true,
                    apiData:{
                        is_occupied:1,
                        assinged_customer:selectedReserve.name
                    }
                });
                const table_id = patchTable?.data.data
                console.log("thiss is the tablee id",table_id)
    
                const patchReserve =  await ApiCall({
                    apiEndpoint: `/items/Reservations/${selectedReserve?.id}?fields=*,table.*`,
                    method: ApiMethod.PATCH,
                    service: true,
                    apiData:{
                        table:showDialog?.data?.id,
                        waiter_confirmation:staff_id
                    }
                });
    
                setSelectedReserve(patchReserve.data.data)
                //patch reservation table,and confirmation (which is the waiter id)
                 await getFilterTables(selectedReserve)
                 setShowDialog(prevState => ({
                    ...prevState,
                    show: false,
                    details: null,
                    data: null,
                    isUpdate: false
                  }))
                setSnackConfig({
                    message:"Table Confirmed.",
                    type:"success",//success,error
                    show:true
                })
            
                }
               
                }}
                >
                Yes
              </Button>
            </DialogActions>
      </Dialog>

      {/* cancel dialogue */}
        <Dialog
            open={isCancelTable}
            onClose={()=>{ setIsCancelTable(false);setSelectedCancel()}}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
         
          >
            <DialogTitle id="alert-dialog-title" style={{paddingRight:"80px",color:"red"}}>
              {"Cancel Table"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description" style={{fontWeight:"700",color:"black"}}>
                  Are you sure to cancel {selectedCancel?.name} reservation ?
              </DialogContentText>
            </DialogContent>
            <DialogActions>

              <Button variant="outlined" onClick={()=> {setIsCancelTable(false);;setSelectedCancel()}}>No</Button>
              <Button variant="contained"onClick={async()=>{
                 await cancelTable(selectedCancel)
               
                }}
                >
                Yes
              </Button>
            </DialogActions>
      </Dialog>
    </div>
  );
};

export default ReservationPage;
