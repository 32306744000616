import React, { useEffect, useState,useRef  } from 'react';
import "./styles.scss";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
//import { useNavigate } from "react-router-dom";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import { Checkbox} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ClearIcon from '@mui/icons-material/Clear';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Grid from '@mui/material/Grid';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ImageIcon from '@mui/icons-material/Image';
import TextField from '@mui/material/TextField';
import { Stack } from "@mui/material";
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import aqua_blue from "../../../assets/images/aqua_blue.png"
import { appColorTheme } from '../../../constants';
import { useSelector } from "react-redux";
import { ApiCall, ApiMethod } from '../../../services/AxiosInstance';
import { useDispatch } from 'react-redux';
import { setRestaurantData } from '../../../redux/reducer/Restaurant';
import { getImageUpdload } from '../../../utilities';
import { AppLoader, AppModal, AppTableCard ,AppSnackBar ,Footer} from '../../../components';
import { useReactToPrint } from 'react-to-print';
import { appTheme } from '../../../constants';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Autocomplete from '@mui/material/Autocomplete';
import EventEmitter from "reactjs-eventemitter";
import QRCode from "react-qr-code";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { getEnvVars } from '../../../services/Environment';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  maxWidth:"450",
  bgcolor: 'rgba(0,0,0,.5)',
  // border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};




export default function RestaurantSettingsPage () {
  //const navigate = useNavigate();
  const restaurantData = useSelector(state => state.restaurant.restaurantData);
  console.log(restaurantData)
  // const tables= [1,2,3]
  const [tables,setTables]= useState([])
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [resName,setResName] = useState()
  const [resEmail,setResEmail] = useState()
  const [resNum,setResNum] = useState()
  const [resAddr,setResAddr] = useState()
  const [tableModal,setTableModal]=  useState(false)
  const [tblQty,setTableQty] = useState(0)
  const [selectedImg,setSelectedImg] = useState(null)
  const [selectedFile,setSelectedFile] = useState()
  const [selectedTheme,setSelectedTheme] = useState()
  const [selectedItems,setSelectedItems] = useState([])
  const [generatedQRCodes, setGeneratedQRCodes] = useState(false);
  const [renderApp,setRenderApp] =useState({'update':false})
  const [isLoading,setIsLoading] = useState(false)
  const themeCurrent = useSelector((state)=>state.user.currentTheme)
  const [selectedRange,setSelectedRange] = useState()
  const [showIcon,setShowIcon] = useState(true)
  const [showStaff,setShowStaff] = useState(true)
  const [defaultExpanded,setdefaultExpanded] = useState(false)
  const [showDialog, setShowDialog] = useState({show:false,details:null});
  const [galleryImage,setGalleryImage] = useState([])
  const [selectedImage,setSelectedImage] = useState([])
  const [resImage,setResImages] = useState([])
  const [showDialogTable,setShowDialogTable] = useState(false)
  const [currentTable,setCurrentTable] = useState()
  const [selectedTableBranch,setSelectedTableBranch] = useState()

 const [selectedStaff,setSelectedStaff] = useState()
 const [staffName,setStaffName] = useState()
 const [selectedBranch,setSelectedBranch] = useState()
 const [isShowStaff,setIsShowStaff] = useState(false)
 const [staffLoading,setStaffLoading] = useState(false)
 const [staffList,setStaffList] = useState([])
 const staffOptions = ["Waiter","Cashier"]
 const [currentTypePrint,setCurrentTypePrint]  = useState("")

  const [snackConfig,setSnackConfig]= useState({
    message:null,
    type:"success",//success,error
    show:false
  })
  const app_theme = appTheme(themeCurrent)
  const dispatch = useDispatch()
  
  const { apiUrl,serviceAccountApi,redirect_url} = getEnvVars();
 const [branches,setBranches] = useState([])

  const getRestaurantDetails = async() =>{
     try{
      const result = await ApiCall({
        apiEndpoint:`/items/restaurant/${restaurantData?.resDetails?.id}?fields=*,page_setting.branches.*`,
        method:ApiMethod.GET,
        // token:restaurantData.userAccess.access_token,
        service:true
     }) 
     const response = result.data.data
     setBranches(response.page_setting.branches)
     console.log("responseeeee getRestaurantDetails",result.data.data)
     }catch(err){
      console.log("getRestaurantDetails err",err)
     }
  }



 const getRestaurantImage = async() =>{
      try{  
        const result = await ApiCall({
          apiEndpoint:`/items/restaurant_image?fields=*&filter[restaurant][_eq]=${restaurantData?.resDetails?.id}`,
          method:ApiMethod.GET,
          // token:restaurantData.userAccess.access_token,
          service:true
       }) 
      const response = result.data.data
      setGalleryImage(response)

      
      }catch(err){
        console.log("getRestaurantImage err",err)
      }
 }


  const handleChangeImage = (e) => {
    if (e.target.files) {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result;
        setSelectedImg(base64String);
      }
      reader.readAsDataURL(file);
      setSelectedFile(e.target.files[0])
    }
  };



  const handleAdditionalImage = async(e) =>{
      try{
        if (e.target.files) {
          setIsLoading(true)
           const file = e.target.files[0];
           const fileFormData = new FormData();
           fileFormData.append("folder", restaurantData.resDetails.folder_id);
           fileFormData.append("file", file);
  
           const imageResult = await ApiCall({
                            apiEndpoint: "/files",
                            method: ApiMethod.POST,
                            apiContentType: "multipart/form-data",
                            token:restaurantData.userAccess.access_token,
                            apiData: fileFormData,
            })
            const image_id = imageResult.data.data.id
            console.log("thiss is the imagee id")
             await ApiCall({
             apiEndpoint: `/items/restaurant_image`,
             method: ApiMethod.POST,
             // token:restaurantData.userAccess.access_token,
             service:true,
             apiData:{
             image:image_id,
                restaurant:restaurantData?.resDetails?.id
              }
              })
            await getRestaurantImage()
            setIsLoading(false)

        }
      }catch(err){
        setIsLoading(false)
        console.log("handleAdditionalImage err",err)
      }
  }

  const deleteImage = async(id) =>{
    console.log("thiss is the image id",id)
        try{  
          setIsLoading(true)
          await ApiCall({
          apiEndpoint: `/items/restaurant_image/${id}`,
          method: ApiMethod.DELETE,
          // token:restaurantData.userAccess.access_token,
          service:true,
          })
          await getRestaurantImage()
          setIsLoading(false)
        }catch(err){
          setIsLoading(false)
          console.log("this is the delete image errr",err)
        }
  }


  EventEmitter.subscribe('updateTables', event => { 
    console.log("update")
    setRenderApp({'update':!renderApp.update})
   })

  const getTables = async()=>{
    await ApiCall({
      apiEndpoint:'/items/Tables?fields=*,branch.*',
      method:ApiMethod.GET,
      token:restaurantData.userAccess.access_token,
      sort: 'date_created', //'-date_created',
      filter:{
        _and:[
          {
            restaurant_id:{
              _eq:restaurantData.resDetails.id
            },
            status:{
              _in:['draft','published']
            }
          }
        ]
      }
    }).then((tableData)=>{
      const result = tableData.data.data
      // const restructure = result.map((item)=>{
      //       return {...item,isEdit:false}
      // })

      // setTables(restructure)
      //restructure by branch here

      //segragate by branch and segreagate items of that branch by their corresponding cat
      const groupedData = {};
          result.forEach(item => {
            const branchId = item.branch.id;
            const branchName = item.branch.name;
            const isSelected = false

            if (!groupedData[branchId]) {
                groupedData[branchId] = {
                    branch_id: branchId,
                    branch_name: branchName,
                    isSelected:isSelected,
                    id:Math.random(),
                    data: {
                        A: [],
                        B: [],
                        C: []
                    }
                };
            }
            
            // Push item into respective category based on table name
            if (item.table_name.includes("A")) {
                groupedData[branchId].data.A.push(item);
            } else if (item.table_name.includes("B")) {
                groupedData[branchId].data.B.push(item);
            } else if (item.table_name.includes("C")) {
                groupedData[branchId].data.C.push(item);
            }
        });

        // Convert data object to array for each branch and remove categories with no items
        for (const branchId in groupedData) {
            groupedData[branchId].data = Object.entries(groupedData[branchId].data)
                .filter(([category, data]) => data.length > 0) // Remove categories with no items
                .map(([category, data]) => ({
                    category,
                    data,
                    branch:data[0]?.branch?.name,
                    id:Math.random(),
                    isSelected:false
                }));
        }

        const groupedArray = Object.values(groupedData);
        console.log("this is the result", groupedArray);
        setTables(groupedArray);
    
    
    }).catch((errTableData)=>{
      console.log("error table data",errTableData)
    })
  }
 
 const [enablePrint,setEnablePrint] = useState(false)

  const checkSelected = () =>{
    const isAnySelected = tables.some(table => 
      table.data.some(data => data.isSelected)
    );
    setEnablePrint(!isAnySelected)
   
  }

  useEffect(()=>{
    checkSelected()
  },[tables])

  useEffect(()=>{
    getRestaurantDetails()
    getTables()
  },[JSON.stringify(renderApp)]) //JSON.stringify(tables)




  useEffect(()=>{
   
    if(restaurantData.resDetails.res_logo){
      setSelectedImg(getImageUpdload(restaurantData.resDetails.res_logo))
    }
    console.log(restaurantData.resDetails)
    setResName(restaurantData.resDetails.res_name)
    setResEmail(restaurantData.resDetails.res_email)
    setResNum(restaurantData.resDetails.res_phone)
    setResAddr(restaurantData.resDetails.res_addr)
    setSelectedTheme(restaurantData.resDetails.theme)
  },[JSON.stringify(restaurantData)])

  useEffect(()=>{
    getStaff()
    getRestaurantImage()
  },[])

  const handleCheckboxChange = (data, table_id) => {
    const updatedTables = tables.map(table => {
      if (table.id === table_id) {
        const updatedData = table.data.map(item => {
          if (item.id === data.id) {
            return {
              ...item,
              isSelected: !item.isSelected // Toggle isSelected value
            };
          }
          return item;
        });
  
        // Check if all items are selected
        const allSelected = updatedData.every(item => item.isSelected);
  
        return {
          ...table,
          data: updatedData,
          isSelected: allSelected // Update table isSelected based on allSelected value
        };
      }
      return table;
    });
    console.log("thiss is the updateedd tableess",updatedTables)
    setTables(updatedTables);
  };
  

 const selectAll = (allData,table,index) =>{

     console.log("thisss is the alll dataa",allData)
     console.log("thiss is the tableee",table)
     let duplicate = [...tables]
     if(!table.isSelected){
        for(const item of duplicate){
           if(item.id === table.id){
              item.isSelected = true
              
              for(const data of item.data){
                data.isSelected = true
              }

           }
        }
     }else{

      for(const item of duplicate){
         if(item.id === table.id){
            item.isSelected = false
            
            for(const data of item.data){
              data.isSelected = false
            }

         }
      }

       
     }
     setTables(duplicate)
     
 }

 const [inputValues, setInputValues] = useState();


 const getStaff = async() =>{
    try{
      setStaffLoading(true)
      const staffData = await ApiCall({
        apiEndpoint:`/items/staff?fields=*,branch.*&filter[restaurant][_eq]=${restaurantData.resDetails.id}&sort=-date_created`,
        method:ApiMethod.GET,
        service:true
        // token:restaurantData.userAccess.access_token,
      })

      const result = staffData.data.data
      const restructure = result.map((item)=>{
          return  {...item,isEdit:false,isSelected:false}
      })
      setStaffList(restructure)
      setInputValues(Array(restructure.length).fill(''))

      setStaffLoading(false)
    }catch(err){
      setStaffLoading(false)
      console.log(" getStaff err",err)
    }
 }

 const [staffNameError, setStaffNameError] = useState('');
 const [selectedStaffError, setSelectedStaffError] = useState('');
  const [selectedBranchErr,setSelectedBranchErr] = useState('')

 const  addStaff = async() =>{
    try{
      let isError = false
      if (!staffName || staffName.trim() === '') {
        setStaffNameError('Staff name is required.');
        isError = true
      } else {
        isError =false
          setStaffNameError('');
      }

      if (!selectedStaff || selectedStaff.trim() === '') {
          setSelectedStaffError('Staff type is required.');
          isError = true
      } else {
        isError =false
          setSelectedStaffError('');
      }
      if(!selectedBranch || selectedStaff.trim() === ''){
        setSelectedBranchErr('Please select branch')
         isError = true
      }else{
         isError =false
         setSelectedBranchErr('')
      }
      if(isError){
         return
      }
      if (staffName && staffName.trim() !== '' && selectedStaff && selectedStaff.trim() !== '') {
        // setStaffLoading(true)
        const staff = await ApiCall({
          apiEndpoint:`/items/staff`,
          method:ApiMethod.POST,
          token:restaurantData.userAccess.access_token,
          apiData:{
            staff_name:staffName,
            staff_type:selectedStaff,
            restaurant:restaurantData.resDetails.id,
            branch:selectedBranch.id
          }
        })
  
        console.log("this is the stafffff",staff.data.data)
        setStaffName()
        setSelectedStaff()
        setSelectedBranch()
        setIsShowStaff(false)
        setStaffLoading(false)
        await getStaff()
        setShowStaff(true)
    
     }

    }catch(err){
      setStaffLoading(false)
      console.log("addStaff err",err)
    }
 }


const updateStaff =async(item) =>{
  try{
    setStaffLoading(true)
    const staff = await ApiCall({
      apiEndpoint:`/items/staff/${item.id}`,
      method:ApiMethod.PATCH,
      token:restaurantData.userAccess.access_token,
      apiData:{
        staff_name:item.staff_name,
        staff_type:item.staff_type,
        branch:item.branch.id
      }
    })

    console.warn("this is the stafffff+++++++++++++",staff.data.data)
    setStaffName()
    setSelectedStaff()
    await getStaff()
    setdefaultExpanded(false)
    setShowStaff(true)
  }catch(err){
    setStaffLoading(false)
    console.log("updateStaff err",err)
  }
}


const deleteStaff =async(item) =>{
   try{
    setStaffLoading(true)
    const staff = await ApiCall({
      apiEndpoint:`/items/staff/${item.id}`,
      method:ApiMethod.DELETE,
      token:restaurantData.userAccess.access_token,
   
    })

    // console.warn("this is the stafffff+++++++++++++",staff.data.data)
    await getStaff()
    setShowStaff(true)
   }catch(err){
    console.log("deleteStaff err",err)
   }
}

const [tableQtyErr,setTableQtyErr] = useState(false)
const [rangeErr,setRangeErr] = useState(false)
const [branchErr,setBranchErr] = useState(false)


const addTable = async(range) => {
  const tables = [];
  if(!selectedTableBranch && selectedBranchErr === ''){
    setBranchErr(true)
    
  } if(!selectedRange){
    setRangeErr(true)
    
  } if(!tblQty){
    setTableQtyErr(true)
    
  }

  if(!selectedTableBranch || !selectedRange || !tblQty){
      return
  }

                                  //filter by branch here
  const getExistingTables = await ApiCall({
    apiEndpoint:`/items/Tables?fields=*&filter[status][_eq]=published&filter[branch][_eq]=${selectedTableBranch.id}&limit=1&sort=-date_created&filter[capacity][_eq]=${selectedRange?.endpoint}`,
    method:ApiMethod.GET,
    token:restaurantData.userAccess.access_token,
  });
  
  const result = getExistingTables.data.data;
  console.log("thiss is the resultttttttttttttttttt",result)
   
  if(result.length > 0){
   
    const tableName = result[0]?.table_name;
    const letter = tableName.substring(0, 1);
    let startNumber = 1;
  
    console.log("suloddd akdiii??????+++++++++++++++++++++++++", range.table_prefix , "leeterr",letter)
    if (letter === range.table_prefix) {
      console.log("sykiiiidd ka sa letter??",letter)
      startNumber = parseInt(tableName.substring(1)) + 1;
    }
  
    for (let i = startNumber; i <= Number(tblQty) + Number(startNumber) - 1 ; i++) {
      const table = {
        table_name: `${range.table_prefix}${i}`,
        capacity: range?.endpoint,
        range: { startingPoint: range?.starting_point, endPoint: range?.endpoint }
      };
  
      tables.push(table);
    }
  }else{
    console.log("ma rektaa kapa diiiiiiii???????????")
    for (let i = 1; i <= Number(tblQty) ; i++) {
      const table = {
        table_name: `${range.table_prefix}${i}`,
        capacity: range?.endpoint,
        range: { startingPoint: range?.starting_point, endPoint: range?.endpoint }
      };

      tables.push(table);
    }
  }

  setTableModal(false);
  setIsLoading(true);

  for (const table of tables) {
    await ApiCall({
          apiEndpoint:"/items/Tables",
          method:ApiMethod.POST,
          token:restaurantData.userAccess.access_token,
          apiData:{
            user_id:restaurantData.user_id,
            restaurant_id:restaurantData.resDetails.id,
            table_id:table.table_id,
            table_name:table.table_name,
            capacity:table.capacity,
            range:table.range,
            branch:selectedTableBranch?.id,
            starting_range:Number(table?.range?.startingPoint),
            closing_range:Number(table?.range?.endPoint)
          },
          filter:{
            status:{
              _in:['draft','published']
            }
          }
      
    }).then(async() => {
      setSelectedTableBranch()
      // setSelectedBranch()
      setSelectedRange()
      setTableQty()
      await getTables();
      setShowIcon(true);
    }).catch((err) =>
      console.log('Error adding new tables')
    );
  }

  setIsLoading(false);
  return tables;
};


  const printQrCode = () =>{   
      handlePrintButtonClick();
  }

  const componentRef = useRef();

  const handlePrintButtonClick = useReactToPrint({
    content: () => componentRef.current,
  });

  const PrintComponent = React.forwardRef(({ tables }, ref) => (
    
    <div ref={ref} className="print-container" style={{margin: "0", padding: "0"}} >
      <div style={{padding:'20px',display:'flex',flexWrap:'wrap',alignItems:"center",justifyContent:"center",paddingBottom:"20px",gap:"20px"}}>
      
      {tables.map((data, index) => {
        const isAnySelected = data.data.some(table => table.isSelected);

        if (isAnySelected) {
          return (
            <React.Fragment key={index}>
              <Typography style={{ width: "100%", textAlign: "center",fontWeight:"700",fontSize:"20px" ,marginTop:"40px",textTransform:"uppercase"}}>Branch : {data?.branch_name}</Typography>
              {data.data.map((item, itemIndex) => {
                if (item.isSelected) {
                  return item.data.map((info, infoIndex) => {
                    const qrdata = {
                      table_id: info.id,
                      restaurant_id: info.restaurant_id,
                      table_name: info.table_name,
                      user_type: "customer",
                      branch: info?.branch?.id
                    };
                    const jsonData = JSON.stringify(qrdata);
                    const url = `${redirect_url}?data=${encodeURIComponent(jsonData)}`;
                    console.log("table qr valueeee",url)
                    return (
                      <div key={infoIndex}>
                        <h4 style={{ marginBottom: "10px", marginTop: "20px" }}>{info?.table_name}</h4>
                        <QRCode style={{ width: '200px', height: '200px' }} value={url} />
                      </div>
                    );
                  });
                }
                return null; // If item is not selected, return null
              })}
            </React.Fragment>
          );
        } else {
          return null; // If no item is selected, return null
        }
      })}
           
            
      </div>
      
    </div>
  ));
  
  const componentRef2 = useRef();

  const handlePrintButtonClick2 = useReactToPrint({
    content: () => componentRef2.current,
  });
  
  const PrintComponent2 = React.forwardRef(({ staffList }, ref) => (
    
    <div ref={ref} className="print-container" style={{margin: "0", padding: "0"}} >
      <div style={{padding:'20px',display:'flex',flexWrap:'wrap',alignItems:"center",justifyContent:"center",paddingBottom:"20px",gap:"30px"}}>
    
          {staffList.map((staff,index)=>{
              if(staff.isSelected){
                const qrdata = {
                  staff_id: staff.id,
                  restaurant_id: restaurantData.resDetails.id,
                  staff_name: staff.staff_name,
                  staff_type: staff.staff_type,
                  branch:staff?.branch?.id,
                  user_type:"staff"
                };
                const jsonData = JSON.stringify(qrdata);
                const url = `${redirect_url}?data=${encodeURIComponent(jsonData)}`;
                return (
                  <div key={index}>
                     <h4 style={{marginBottom:"10px"}}>{staff?.staff_name}</h4>
                    <QRCode style={{ width: '200px', height: '200px',marginRight:'30px' }} value={url} />
                  </div>
                );
              }
          
            }) 
          }
            
      </div>
      
    </div>
  ));
  

  const handleMultipleImage = (e,index) =>{
      if (e.target.files) {
        
        const copyData = [...selectedImage]
        
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64String = reader.result;
          copyData[index] = {image:base64String,file:e.target.files[0]}
          setSelectedImage(copyData)
        }
        reader.readAsDataURL(file);
        // setSelectedFile(e.target.files[0])
      }
  };
  const handleFileSelect = (event) => {
    const files = event.target.files;
    
  };
  

  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([
    { id: 1, name: 'Option 1' },
    { id: 2, name: 'Option 2' },
    { id: 3, name: 'Option 3' },
    { id: 4, name: 'Option 4' },
    { id: 5, name: 'Option 5' }
  ]);
  

  const [tableInput,setTableInput] = useState()


  const handleInputChange = (index, newInputValue) => {
    const newInputValues = [...inputValues];
    newInputValues[index] = newInputValue;
    setInputValues(newInputValues);
  };
  const filterOptions = (options, selected) => {
    const opt = options.filter(item => item.id !== selected.id);
    return opt
  };

 const deleteTable = async(data) =>{
     try{
      setIsLoading(true)
      for(const item of data){
        await ApiCall({
          apiEndpoint:`/items/Tables/${item.id}`,
          method:ApiMethod.DELETE,
          service:true,
        })
      }
      setShowDialogTable(false)
      await getTables()
      setIsLoading(false)
     }catch(err){
      console.log("deleteTable err",err)
     }
 }


  return (
   <section id="setting">
    <div className="my_container">
     {staffLoading && <AppLoader/>}
 
       <div style={{ display: 'none' }}>
          <PrintComponent tables={tables} ref={componentRef} />
      </div>
      <div style={{ display: 'none' }}>
          <PrintComponent2 staffList={staffList} ref={componentRef2} />
      </div>
      <Card className="table_settings">
     
         <Typography className="heading">
                <div style={{display:'flex',alignItems:'center'}}>
                <b>Tables</b>
                {showIcon ? <KeyboardArrowDownIcon onClick={()=>setShowIcon(!showIcon)} style={{fontSize:"30px",color:"black",cursor:'pointer'}}/> : <KeyboardArrowUpIcon style={{fontSize:"30px",color:"black",cursor:'pointer'}} onClick={()=>setShowIcon(!showIcon)}/> }
    
                </div>
               
                <span className='add_table' style={{cursor:'pointer'}} onClick={async()=>{
                  setTableModal(true)
                  // setShowIcon(!showIcon)
                }}>Add Tables
              
                </span>
              

         </Typography>
         {showIcon && <div style={{width:"100%"}}>
                  <Button  style={{backgroundColor:enablePrint && 'lightgray',border:enablePrint && 'none'}} onClick={()=>{
                    
                  printQrCode()
                  }} variant="contained print_Qr"
                  disabled={enablePrint}
                  ><b>Print QR Labels</b></Button>
                
          </div>
          }
      

        {showIcon && <>
          {tables.length > 0 && tables.map((table,index)=>{
            
          return <div className='table_container'>
                   <div className='branch_name_container'>
                     <Checkbox
                              style={{color:"#F0522B"}}
                              checked={table?.isSelected}
                              onChange={()=>selectAll(table.data,table,index)}
                      />
                      <Typography className='first'>Branch Name  </Typography>
                      <Typography className='branch_name_text'>{table.branch_name}</Typography>
                   </div>
                  
                  {table.data.map((item)=>{
                      return <div className='cat_container'>
                           <Checkbox
                              style={{color:"#F0522B"}}
                              checked={item?.isSelected}
                              onChange={()=>handleCheckboxChange(item,table.id)}
                           />
                          <div style={{position:"absolute",right:"10px",top:"20px"}}>
                            <DeleteOutlineIcon style={{color:"red",cursor:"pointer",fontSize:"25px"}} onClick={()=>{setCurrentTable(item.data); setShowDialogTable(true)}} />
                          </div>
                           <div>
                              <Typography className='lab'>Table Section  </Typography>
                                <Typography className='lab_value'>
                                {item.category} {"(" + item.data[0].range.startingPoint + "-" + item.data[0].range.endPoint + ")" } seats
                                
                               </Typography>
                           </div>
                           <div>
                             <Typography className='lab'>Table Count  </Typography>
                              <Typography className='lab_value'>
                                  {item.data.length}
                              </Typography>
                           </div>
                            
                      </div>
                  })}

          </div>
        })}
        </>}
        
         
      </Card>


      <Card className="staff_settings">
        <Typography className="heading">
                  <div  style={{display:'flex',alignItems:'center'}}>
                      <b>Staff</b>
                      {showStaff ? <KeyboardArrowDownIcon onClick={()=>setShowStaff(!showStaff)} style={{fontSize:"30px",color:"black",cursor:'pointer'}}/> : <KeyboardArrowUpIcon style={{fontSize:"30px",color:"black",cursor:'pointer'}} onClick={()=>setShowStaff(!showStaff)}/> }
                  </div>
                  
                  <span className='add_table' style={{cursor:'pointer'}} onClick={async()=>{
                    //check danay if may existing branch if wala dont allow
                    if(branches.length === 0){
                      setSnackConfig({
                        message:"Unable to add staff. No exiting branch. Add branch in the account tab first.",
                        show:true,
                        type:"error"
                      })
                      return
                    }

                    setIsShowStaff(true)
                    setShowStaff(!showStaff)
                  }}>Add Staff</span>

                  
        </Typography>
        
        {showStaff && <>
          <Button  style={{width:"100%",border:"none"}}  disabled={!staffList.some(item => item.isSelected)} onClick={()=>{handlePrintButtonClick2()}} variant="contained print_Qr" ><b>Print QR Code</b></Button>   
     
        {staffList.length > 0 && <div style={{display:"flex",alignItems:'center',marginTop:'10px'}}>
            <Checkbox
                  style={{color:"#F0522B",marginRight:'10px'}}
                  // checked={data?.isSelected}
                  onChange={()=>{
                      const restructure =  staffList.map((item)=>{
                          return {...item,isSelected:!item.isSelected}
                      })
                      setStaffList(restructure)
                  }}
              />
              <Typography>select all</Typography>
          </div>}
        {staffList.map((data,index)=>{
          
          return  <div style={{marginTop:"40px",borderTop:"1px dotted lightgray",paddingTop:"20px"}}>

        
          <Checkbox
                  style={{color:"#F0522B",marginRight:'10px',marginBottom:"10px"}}
                  checked={data?.isSelected}
                  onChange={()=>{
                    const duplicate = [...staffList]
                    duplicate[index] = {...duplicate[index],isSelected:!duplicate[index].isSelected}
                    setStaffList(duplicate)
                  }}
          />
           {/* <Typography sx={{color:"#939190"}}>{data?.staff_name}</Typography> */}
           {
            data?.isEdit ? 
            <TextField style={!data?.isEdit ? {width:"100%"} : {width:"100%",backgroundColor:"white",border:"1px solid gray" } }id="outlined-basic"  disabled={!data?.isEdit ? true : false} className='form_fields'  variant="outlined" size="medium" defaultValue={data?.staff_name}  onChange={(e)=>{
              const duplicate = [...staffList]
              duplicate[index] = {...duplicate[index],staff_name:e.nativeEvent.target.value}
              console.warn("thiss is the dupliicatedddddd",duplicate)
              setStaffList(duplicate)
            }}/> : <Typography className="form_fields typo" sx={{color:"#939190"}}>{data?.staff_name} </Typography>
           }
          
          
          <Accordion defaultExpanded={false}>
           <AccordionSummary
             expandIcon={<ExpandMoreIcon />}
             aria-controls="panel1-content"
             id="panel1-header"
             className='app_accordion'
             disabled={!data?.isEdit ? true : false}
           >
            {data?.staff_type}
           {/* Staff Type  */}
         </AccordionSummary>
          <AccordionDetails>
           {
             staffOptions.map((item)=>{
               return  <Typography className="option" onClick={()=>{
                   const duplicate = [...staffList]
                   duplicate[index] = {...duplicate[index],staff_type:item}
                   console.warn("thiss is the dupliicatedddddd",duplicate)
                   setStaffList(duplicate)
                   setSelectedStaff(item)
               }} sx={{cursor:"pointer",backgroundColor:data?.staff_type == item ?'#eeeae9':'#ffffff',marginY:0.5}}>
                {item}
              </Typography>
             }
              
             )
           }
         </AccordionDetails>
          </Accordion>

          <Autocomplete
              disabled={!data?.isEdit ? true : false}
              disableClearable
              // options={branches}
              options={filterOptions(branches, data?.branch)}
              getOptionLabel={(option) => option.name}
              value={data?.branch}
              onChange={(event, value) => {
                const duplicate = [...staffList]
                duplicate[index] = {...duplicate[index],branch:value}
                setStaffList(duplicate)
                console.log("Selected Branch:", value);
              }}
              inputValue={inputValues[index]}
              onInputChange={(event, newInputValue) => handleInputChange(index, newInputValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  id={`outlined-basic-${index}`}
                  className='form_fields'
                  placeholder='Branch'
                  style={{ width: "100%", marginTop: "10px", border: "1px solid #ced4da",backgroundColor:!data?.isEdit ?"#eeeae9":'#FFF' }} // adjust border styles as needed
                  variant="outlined"
                  size="medium"
                />
              )}
            />
        
           

         <div style={{display:'flex',justifyContent:'space-between'}}>
           <Button variant="contained apply_theme" style={{width:"48%",maxWidth:"100%"}} onClick={()=>{
              const duplicate = [...staffList]
              duplicate[index] = {...duplicate[index],isEdit:true}
              console.warn("thiss is the dupliicatedddddd",duplicate)
              setStaffList(duplicate)
               if(data?.isEdit){
                 updateStaff(data)
               }else{
                  console.log("++++++++++++++++++++++++++++++++++++")
               }
            
            }}><b>{data?.isEdit ? "Update" : "Edit"}</b></Button>
             <Button variant="contained apply_theme" style={{width:"48%",maxWidth:"100%"}} onClick={()=> {
              setShowDialog({show:true,details:data})
              //deleteStaff(data)
            }
            }><b>Remove</b></Button>
            
         </div>
       </div>
        })}
        
        
        </>}

      

      </Card>
      
    </div>

        {/* for the table deletions */}
        <Dialog
            open={showDialogTable}
            onClose={()=>setShowDialogTable(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Are you sure you want to delete this table section?"}
            </DialogTitle>
         
            <DialogActions>

              <Button variant="outlined" onClick={()=>setShowDialogTable(false)}>No</Button>
              <Button variant="contained"onClick={async()=>{
               await deleteTable(currentTable)
              
                }}>
                Yes
              </Button>
            </DialogActions>
          </Dialog>


        <Dialog
            open={showDialog.show}
            onClose={()=>setShowDialog({show:false,details:null})}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Are you sure you want to delete this Staff?"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Staff Name: {showDialog?.details?.staff_name}
              </DialogContentText>
            </DialogContent>
            <DialogActions>

              <Button variant="outlined" onClick={()=>setShowDialog({show:false,details:null})}>No</Button>
              <Button variant="contained"onClick={async()=>{
               await deleteStaff(showDialog.details)
                setShowDialog({show:false,details:null})
                }}>
                Yes
              </Button>
            </DialogActions>
          </Dialog>
    <AppModal
      visible={isShowStaff}
      handleClose={() => {
        setStaffName()
        setSelectedStaff()
        
        setIsShowStaff(false)
      
      }}
      containerStyle={{width:'unset'}}
    >
     <div style={{display:'flex',justifyContent:"flex-end"}}>
          <CloseIcon onClick={()=>{
             setStaffName()
             setSelectedStaff()
             setIsShowStaff(false)
           
            }} style={{cursor:'pointer'}}/>
     </div>
     <div>
           <TextField id="outlined-basic" className='form_fields' placeholder='Staff Name' style={{width:"100%",backgroundColor:"white", marginTop:"10px",border:staffNameError !== '' ? '1px solid red' :"1px solid gray" }} variant="outlined" size="medium"   onChange={(e)=>{
             if(e.nativeEvent.target.value !== ''){
                setStaffNameError('')
             }
             setStaffName(e.nativeEvent.target.value)
           }}/>
           {staffNameError !== '' && <Typography style={{color:'red'}}>{staffNameError}</Typography>} 

           <div style={{marginBottom:"20px"}}></div>
           <Accordion defaultExpanded={defaultExpanded}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
              className='app_accordion2'
              style={{paddingLeft:"10px",paddingRight:"10px",border:selectedStaffError !== '' && '1px solid red'}}
            >
            {selectedStaff ? selectedStaff : "Staff Type "} 
          </AccordionSummary>
           <AccordionDetails>
            {
              staffOptions.map((item)=>
                <Typography className="option" onClick={()=> {
                  setSelectedStaff(item)
                  if(item){
                        setSelectedStaffError('')
                  }
                }} sx={{cursor:"pointer",backgroundColor:selectedStaff==item ?'#eeeae9':'#ffffff',marginY:0.5}}>
                  {item}
                </Typography>
              )
            }
          </AccordionDetails>
          </Accordion>
          {selectedStaffError !== '' &&   <Typography style={{color:'red'}}>{selectedStaffError}</Typography>}

             <Autocomplete
              options={branches}
              disableClearable
              getOptionLabel={(option) => option.name}
              onChange={(event, value) => {
                setSelectedBranchErr('')
                // value here will be the entire selected object
                setSelectedBranch(value);
                console.log("Selected Branch:", value);
              }}
              inputValue={inputValue}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  id="outlined-basic"
                  className='form_fields'
                  placeholder='Branch'
                  style={{ width: "100%", marginTop: "10px", backgroundColor:"white",border:"1px solid gray",border: "1px solid #ced4da" }} // adjust border styles as needed
                  variant="outlined"
                  size="medium"
                />
              )}
            />
           {selectedBranchErr !== '' &&   <Typography style={{color:'red'}}>{selectedBranchErr}</Typography>}
           {/* <TextField id="outlined-basic" className='form_fields' placeholder='Branch' style={{width:"100%",marginTop:"10px",border:staffNameError !== '' && '1px solid red'}} variant="outlined" size="medium"   onChange={(e)=>{
          
           }}/> */}
          
          
        

          <Button variant="contained apply_theme" style={{width:"100%",maxWidth:"100%"}} onClick={()=>{
            console.log("theme")
            addStaff()
            // setStaffList([...staffList,{id:Math.random,staff_name:staffName,staff_type:selectedStaff}])
            }}><b>Add Staff</b></Button>
      </div>
    </AppModal>

    
   
    <AppModal
      visible={tableModal}
      handleClose={() =>{
        setTableQtyErr(false)
        setRangeErr(false)
        setBranchErr(false)
        setSelectedTableBranch();
        setTableModal(false);
        setShowIcon(true)} }
      containerStyle={{width:'90%',maxWidth:"400px"}}
    >
     <div style={{display:'flex',justifyContent:"flex-end"}}>
          <CloseIcon onClick={()=>{
            setTableQtyErr(false)
            setRangeErr(false)
            setBranchErr(false)
            setSelectedTableBranch();
            setTableModal(false);
            setShowIcon(true)}}
             style={{cursor:'pointer'}}/>
     </div>
     <div style={{display:"flex",alignItems:'center',justifyContent:'center',flexDirection:'column',paddingTop:"10px"}}>
        <Typography>Number of tables to generate:</Typography>
        <Stack sx={{}}>
          <Stack sx={{mt:1.5}}>
            <TextField id="outlined-basic" type={"number"} style={{backgroundColor:'white',border:"1px solid lightgray"}}  className='form_fields' placeholder='Table Count' value={tblQty} variant="outlined" size="medium" 
            onChange={(e)=>{
              setTableQtyErr(false)
              setTableQty(e.nativeEvent.target.value)
            }} 
            />
            {tableQtyErr && <Typography style={{color:"red",fontWeight:"700",fontSize:"10px"}}>This field is required </Typography>} 
          </Stack>

          <Stack sx={{mt:1.5}}>
            <BasicSelect setSelectedRange={setSelectedRange} setRangeErr={setRangeErr}/>
            {rangeErr && <Typography style={{color:"red",fontWeight:"700",fontSize:"10px"}}>This field is required </Typography>} 
          </Stack>
          <Stack sx={{mt:1.5}}>
            <Autocomplete
                // disabled={!data?.isEdit ? true : false}
                disableClearable
                options={branches}
                // options={filterOptions(branches, data?.branch)}
                getOptionLabel={(option) => option.name}
                // value={data?.branch}
                onChange={(event, value) => {
                  console.log("Selected Branch:", value);
                  setBranchErr(false)
                  setSelectedTableBranch(value)
                }}
                inputValue={tableInput}
                onInputChange={(event, newInputValue) => {
                  setBranchErr(false)
                  setTableInput(newInputValue)
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    id={`outlined-basic`}
                    className='form_fields'
                    placeholder='Branch'
                    style={{ width: "100%", marginTop: "10px", border: "1px solid #ced4da" ,backgroundColor:'white',border:"1px solid lightgray"}} // adjust border styles as needed
                    variant="outlined"
                    size="medium"
                  />
                )}
              />
             {branchErr &&<Typography style={{color:"red",fontWeight:"700",fontSize:"10px"}}>This field is required </Typography> } 
          </Stack>
          <Stack sx={{mt:1.5}}>
            <Button variant="contained" onClick={()=>addTable(selectedRange)}  style={{backgroundColor:"#F0522B",textTransform:'capitalize',padding:'10px',boxShadow: 'none',width:'100%'}} className="btn-custom_order"><b>Save</b></Button>
          </Stack>

          
        </Stack>
       
       
                    
     </div>
    </AppModal>
    {isLoading &&   <AppLoader/>}
    <AppSnackBar snackConfig={snackConfig}/>
    <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Stack sx={{justifyContent:'center',alignItems:'center'}}>
          <img src={aqua_blue} alt=""  className='theme_img'/>
          <Button variant="contained apply_theme" onClick={handleClose}><b>Apply Theme</b></Button>
          </Stack>
          
        </Box>
      </Modal>
   </section>
  )
}


function BasicSelect({setSelectedRange,setRangeErr}) {
  const range = [
    {
      starting_point: 1,
      endpoint: 5,
      table_prefix: 'A',
    },
    {
      starting_point: 6,
      endpoint: 10,
      table_prefix: 'B',
    },
    {
      starting_point: 11,
      endpoint: 15,
      table_prefix: 'C',
    },
  ];

  const [selectedRange, setSelecteRange] = React.useState('');

  const handleChange = (event) => {
    console.log('changeee ', event.target.value);
    setSelectedRange(event.target.value)
    setSelecteRange(event.target.value);
    setRangeErr(false)
  };

  const renderValue = (value) => {
    if (value === '') {
      return 'Select';
    }
    return `${value.starting_point} - ${value.endpoint}`;
  };

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label" sx={{top:"-5px"}}> Range</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectedRange}
          label="Range"
          onChange={handleChange}
          renderValue={renderValue}
        >
          {range.map((item) => (
            <MenuItem key={item.starting_point} value={item} style={{width:"100%"}}>
              {`${item.starting_point} - ${item.endpoint}`}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}