import React,{useEffect, useState} from "react";
import "./styles.scss";
import { useNavigate } from "react-router-dom";
import { appTheme } from "../../../constants";
import compLogo from "../../../assets/images/company_logo.png"
import { Button, Typography } from '@mui/material';
import { Line, Circle } from 'rc-progress';

import { useSelector } from 'react-redux';

export default function SpashScreen() {
  const themeCurrent = useSelector((state)=>state.user.currentTheme)
  const navigate = useNavigate();
  console.log(appTheme(1))
  const app_theme = appTheme(themeCurrent)
  const [loading,setLoading] = useState(10)

  useEffect(() => {
    const intervalId = setInterval(() => {
      setLoading((prevLoading) => {
        const newLoading = prevLoading + 10;
        if (newLoading >= 100) {
          clearInterval(intervalId);
          navigate("/customer-main-page")
        }
        return newLoading;
      });
    }, 200);

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="my_container" > 
    <div className="splash_container" style={{backgroundImage: `url("${app_theme?.appBg}")`}}>
     <div className="splash_comp_logo" style={{backgroundImage: `url("${app_theme?.logo}")`}}>
     </div>
     {/* <div className="splash_underline"></div> */}
     <Line percent={loading} strokeWidth={3} strokeColor="#F0522B" style={{width:"200px"}} trailColor="#7A1D22" trailWidth={3} />
     <footer className="splash_footer">
      
         <Typography className="first">Powered by</Typography>
         <Typography><b>EZ ODA</b></Typography>
    </footer>
    </div>

    </div>
   
  )
}
