const GenerateOrderID = async(restaurant_id,ApiMethod,ApiCall) =>{
    console.log("resstaurannttt id",restaurant_id)
      try{
        const order_id =  await ApiCall({
          apiEndpoint:`/items/order_id?fields=*&filter[restaurant_id][_eq]=${restaurant_id}&sort=-date_created`,   
          method:ApiMethod.GET,
          service:true 
        })
        const result = order_id.data.data
     
        const currentDate = new Date(); 
        const currentDay = currentDate.toISOString().split('T')[0]; 
   
        const filteredOrders = result.filter(order => {
            const orderDate = new Date(order.date_created);
            const orderDay = orderDate.toISOString().split('T')[0]; 
            return orderDay === currentDay; 
        });
       
        if(filteredOrders.length > 0){
            const increase_sequence = filteredOrders[0].sequence + 1
            console.log("thiss is the increase_sequence",increase_sequence)
            const order_id =  await ApiCall({
              apiEndpoint:`/items/order_id`,  
              method:ApiMethod.POST,
              service:true,
              apiData:{
                  restaurant_id:restaurant_id,
                  sequence:increase_sequence
              }   
            })
            
            const response = order_id.data.data
            console.log("responseresponseresponse",response)
            return response

        }else{
            const order_id =  await ApiCall({
              apiEndpoint:`/items/order_id`,  
              method:ApiMethod.POST,
              service:true,
              apiData:{
                  restaurant_id:restaurant_id,
                  sequence:1
              }   
            })
            const result = order_id.data.data
            console.log("succeesssssss",result)
            return result
        }

      }catch(err){
        console.log("get_order_id err",err)
      }
  }

  export default GenerateOrderID