import React, { useState } from "react";
import { Button, Typography } from "@mui/material";
import "./styles.scss";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { ThemeProvider } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";
import clockIcon from "../../assets/images/clock.png";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { appTheme } from "../../constants";
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";

const AppOrderCard = ({ data, isPending,changeQuantity,deleteItem ,setCurrentSelected,setDeleteModal}) => {
  const themeCurrent = useSelector((state)=>state.user.currentTheme)
  const app_them = appTheme(themeCurrent)
  const [current_qty,setCurrentQty] = useState(data?.quantity)
  const navigate = useNavigate();
  const encodedMenuData = encodeURIComponent(JSON.stringify(data));
  return (
    <div className="items_container">
      <div className="_per_item_container">
        <div className="start">
          <Typography className="first">
            <b>{data?.title}</b>
          </Typography>
        </div>
        <div className="mid">
          {!isPending ? (
            <>
              <Typography className="first">
                <RemoveCircleOutlineIcon onClick={()=>{
                     if(current_qty > 1){
                        setCurrentQty(current_qty - 1)
                        changeQuantity(current_qty - 1,data?.id,data?.cart_id)
                      }
                }} />
              </Typography>
              <Typography className="second">{current_qty}</Typography>
              <Typography className="first">
                <AddCircleOutlineIcon  onClick={()=>{     
                        setCurrentQty(current_qty + 1)
                        changeQuantity(current_qty + 1,data?.id,data?.cart_id)
                }} />
              </Typography>
            </>
          ) : (
            <Typography className="second">x{data?.quantity}</Typography>
          )}
        </div>
        <div className="end">
          <Typography className="first">
            <b>${parseFloat(data?.price).toLocaleString()}</b>
          </Typography>
          {!isPending && (
            <div>
              <DeleteOutlineIcon
                onClick={()=>{
                  setCurrentSelected(data)
                  setDeleteModal(true)
                }}
                style={{ color: app_them?.fontColor, fontSize: "23px" }}
              />
            </div>
          )}
        </div>
      </div>
      {data?.add_ons.map((item) => {
        return (
          <div className="extra_order_container">
            <Typography className="first">{item?.description} </Typography>
                                      {/* {Number(item?.price).toFixed(2)} x */}
            <Typography className="mid">x {item?.quantity}</Typography>
            
            <Typography
              className="second"
              style={{ marginRight: !isPending ? "30px" : 0 }}
            >
              <b>${parseFloat(item?.price).toLocaleString()}</b>
            </Typography>
          </div>
        );
      })}

      {!isPending && (
        <Typography className="change_food" onClick={()=>navigate(`/customer-food-preview-existing/${encodedMenuData}`)} style={{color:app_them?.fontColor}}>Change Food</Typography>
      )}
    </div>
  );
};

export default AppOrderCard;
