import React, { useEffect, useState } from 'react';
import "./styles.scss";
//import { useNavigate } from "react-router-dom";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ClearIcon from '@mui/icons-material/Clear';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Grid from '@mui/material/Grid';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import TextField from '@mui/material/TextField';
import { Stack } from "@mui/material";
import { useSelector } from "react-redux";
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { ApiCall, ApiMethod } from '../../../services/AxiosInstance';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { setRestaurantData } from '../../../redux/reducer/Restaurant';
import { useDispatch } from 'react-redux';
import EventEmitter from "reactjs-eventemitter";
import { appColorTheme } from '../../../constants';
import ImageIcon from '@mui/icons-material/Image';
import { AppDatePicker, AppDropDown, AppLoader, AppModal, AppMultiSelectDropDown, AppSrcollView } from '../../../components';
import { getImageUpdload,EmailValidator } from '../../../utilities';
import { Editor } from 'react-notion-wysiwyg';
import moment from 'moment';
import {AppSnackBar} from '../../../components';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import CKEditorInspector from '@ckeditor/ckeditor5-inspector';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Autocomplete from '@mui/material/Autocomplete';
export default function RestaurantAccountPage() {
  //const navigate = useNavigate();
  const list = ["Lorem ipsum dolor sit amet magni","Sed ut perspiciatis unde omnis","Ut enim ad minima veniam nostrum","Neque porro quisquam est, qui dolorem"]
  const restaurantData = useSelector(state => state.restaurant.restaurantData);

  console.log("restaurantData",restaurantData)
  const dispatch = useDispatch()
  const [fname,setFName] =useState()
  const [lname,setLName]=useState()
  const [pass,setPass]=useState()
  const [email,setEmail]=useState()
  const [contactNum,setContactNUm]=useState()
  const [addr1,setAddr1]=useState()
  const [addr2,setAddr2]=useState()
  const [editMode,setEditmode] = useState(false)
  const [showBranchForm,setBranchForm] = useState(false)
  const [vouchers,setVouchers] = useState([])
  const [branches,setBranches] = useState([])  
  const [isEdit,setIsEdit] = useState(true)
  const [voucherEdit,setVoucherEdit] = useState(false)
  const [branchEdit,setBranchEdit] =  useState(false)
  const [originalDetails,setOriginalDetails] = useState([])
  const [isLoading,setIsLoading] = useState(false)
  const [promoDealImage,setPromoDealImage] = useState()
  const [selectedImg,setSelectedImg] = useState()
  const [selectedFile,setSelectedFile] = useState()
  const [category,setCategory] = useState([])
  const [restaurantDetails,setRestaurantDetails] = useState()
  const [selectedCat,setSelectedCat] = useState([])
  const [catergories,setCategories] = useState([])
  const [snackConfig,setSnackConfig]= useState({
    message:null,
    type:"success",//success,error
    show:false
  })
  const [pageSetting,setPageSetting] = useState({
    image:null,
    opening_time:'',
    closing_time:'',
    details:''
 })


 //new
 const [resName,setResName] = useState()
 const [resEmail,setResEmail] = useState()
 const [resNum,setResNum] = useState()
 const [resAddr,setResAddr] = useState()
 const [selectedTheme,setSelectedTheme] = useState()
 const [selectedLogo,setSelectedLogo] = useState()
 const [selectedLogoFile,setSelectedLogoFile] = useState()
 const [galleryImage,setGalleryImage] = useState([])
 const [showDialog, setShowDialog] = useState({show:false,details:null});
 useEffect(()=>{
   
  if(restaurantData.resDetails.res_logo){
    setSelectedLogo(getImageUpdload(restaurantData.resDetails.res_logo))
  }
  console.log(restaurantData.resDetails)
  setResName(restaurantData.resDetails.res_name)
  setResEmail(restaurantData.resDetails.res_email)
  setResNum(restaurantData.resDetails.res_phone)
  setResAddr(restaurantData.resDetails.res_addr)
  setSelectedTheme(restaurantData.resDetails.theme)
},[JSON.stringify(restaurantData),JSON.stringify(selectedCat)])
 
 const getRestaurantImage = async() =>{
  try{  
    const result = await ApiCall({
      apiEndpoint:`/items/restaurant_image?fields=*&filter[restaurant][_eq]=${restaurantData?.resDetails?.id}`,
      method:ApiMethod.GET,
      // token:restaurantData.userAccess.access_token,
      service:true
   }) 
  const response = result.data.data
  setGalleryImage(response)

  
  }catch(err){
    console.log("getRestaurantImage err",err)
  }
}

const deleteImage = async(id) =>{
  console.log("thiss is the image id",id)
      try{  
        setIsLoading(true)
        await ApiCall({
        apiEndpoint: `/items/restaurant_image/${id}`,
        method: ApiMethod.DELETE,
        // token:restaurantData.userAccess.access_token,
        service:true,
        })
        await getRestaurantImage()
        setIsLoading(false)
      }catch(err){
        setIsLoading(false)
        console.log("this is the delete image errr",err)
      }
}


const handleAdditionalImage = async(e) =>{
  try{
    if (e.target.files) {
      setIsLoading(true)
       const file = e.target.files[0];
       const fileFormData = new FormData();
       fileFormData.append("folder", restaurantData.resDetails.folder_id);
       fileFormData.append("file", file);

       const imageResult = await ApiCall({
                        apiEndpoint: "/files",
                        method: ApiMethod.POST,
                        apiContentType: "multipart/form-data",
                        token:restaurantData.userAccess.access_token,
                        apiData: fileFormData,
        })
        const image_id = imageResult.data.data.id
        console.log("thiss is the imagee id")
         await ApiCall({
         apiEndpoint: `/items/restaurant_image`,
         method: ApiMethod.POST,
         // token:restaurantData.userAccess.access_token,
         service:true,
         apiData:{
         image:image_id,
            restaurant:restaurantData?.resDetails?.id
          }
          })
        await getRestaurantImage()
        setIsLoading(false)

    }
  }catch(err){
    setIsLoading(false)
    console.log("handleAdditionalImage err",err)
  }
}


 const getRestaurantCategory = async() =>{
  try{
  const response = await ApiCall({
     apiEndpoint:'/items/restaurant_category_list',
     method:ApiMethod.GET,
     service:true
   })
   const result = response.data.data
   setCategories(result)
  }catch(err){
     console.log("getRestaurantCategory err",err)
  }
}



  //promotion_deals
  const [promotion,setPromotion] = useState({
      image:null,
      discount:"",
      restaurant_name:"",
      details:"",
      eff_from:'',
      eff_to:'',
      page_setting: ''//id dapat nipage setting
  })

  const [branchDetails,setBranchDetails] = useState({
    name:'',
    details:'',
    addr_line_1:'',
    addr_line_2:'',
    addr_line_3:'',
    opening_time:"",
    closing_time:"",
    page_setting: ''//id dapat nipage setting

   }
)


const [voucherDetails,setVoucherDetails] = useState({
  discount:"",
  promo_code:"",
  eff_from:"",
  eff_to:"",
  menu:"",
  purchase_limit:"",
  usage_limit:""
})



const addBranch = () =>{
 
  //{ name: "", details: "", addr_line_1: "", addr_line_2: "", addr_line_3: "", opening_time: "", closing_time: "", page_setting: "" }
  // console.log(branchDetails,branches)
  const opening = new Date(branchDetails.opening_time);
  const closing = new Date(branchDetails.closing_time);
  if(!branchDetails.name){
    setSnackConfig({
      message:"Please fill branch name",
      type:'error',
      show:true
    })
  }else if(!branchDetails.details){
    setSnackConfig({
      message:"Please fill details",
      type:'error',
      show:true
    })
  }else if(!branchDetails.addr_line_1){
    setSnackConfig({
      message:"Please fill address 1",
      type:'error',
      show:true
    })
  }else if(!branchDetails.addr_line_2){
    setSnackConfig({
      message:"Please fill address 2",
      type:'error',
      show:true
    })
  }else if(!branchDetails.addr_line_3){
    setSnackConfig({
      message:"Please fill address 2",
      type:'error',
      show:true
    })
  }else if (!branchDetails.opening_time) {
    setSnackConfig({
      message:"Please fill opening time",
      type:'error',
      show:true
    })
  }else if (!branchDetails.closing_time) {
    setSnackConfig({
      message:"Please fill closing time",
      type:'error',
      show:true
    })
  }else if (closing <= opening) {
    setSnackConfig({
      message:"Closing time cannot be earlier than or equal to opening time.",
      type:'error',
      show:true
    })
  } else if (opening >= closing) {
    setSnackConfig({
      message:"Opening time cannot be later than or equal to closing time.",
      type:'error',
      show:true
    })
  }else{
    let originaData = [...branches]
    setBranches([...originaData,{...branchDetails,id:Math.random()}])
    setBranchDetails({
      name:'',
      details:'',
      addr_line_1:'',
      addr_line_2:'',
      addr_line_3:'',
      details:'',
      opening_time:'',
      closing_time:''
     })
     setBranchForm(false)
  }
      

}


const addVoucher = () =>{
    let originaData = [...vouchers]
    setVouchers([...originaData,{...voucherDetails,id:Math.random()}])
    setVoucherDetails({
      discount:"",
      promo_code:"",
      eff_from:"",
      eff_to:"",
      menu:"",
      purchase_limit:"",
      usage_limit:"",
      details:''
    })
}


  const getRestaurantDetails = async() =>{
      try{
        console.log("thhiss is the restaurantt iddd",restaurantData.resDetails.id)
        const details = await ApiCall({
          apiEndpoint:`/items/restaurant/${restaurantData.resDetails.id}?fields=*,page_setting.*,res_category.restaurant_details.*,page_setting.promotion_deals.*,page_setting.branches.*,page_setting.vouchers.*,page_setting.promotion_deals.restaurant_name.*,page_setting.vouchers.menu.*`,
          method:ApiMethod.GET,
          token:restaurantData.userAccess.access_token,
          service:true,
        })


        const result = details.data.data
        const resCategory = result?.res_category
        setOriginalDetails(result)
        let toSetCurCat = []
        if(resCategory.length>0){
          resCategory.map((item)=>{
            toSetCurCat.push(item.restaurant_details)
          }
            
          )
        }


        setSelectedCat(toSetCurCat)
        const pageImage =  getImageUpdload(result.page_setting.image)
        console.warn("-------------------+++++++++++++++++++++++++++page result",result)
        setPageSetting({...pageSetting,
          image:pageImage,
          opening_time:result.page_setting.opening_time,
          closing_time:result.page_setting.closing_time,
          details:result.page_setting.details,

        })
        const promotionImage =  getImageUpdload(result.page_setting.promotion_deals[0]?.image)
        setPromotion({
          ...promotion,
          image: result.page_setting.promotion_deals[0]?.image ? promotionImage : null,
          discount:result.page_setting.promotion_deals[0]?.discount,
          restaurant_name:result.page_setting.promotion_deals[0]?.restaurant_name,
          details:result.page_setting.promotion_deals[0]?.details,
          eff_from:result.page_setting.promotion_deals[0]?.eff_from,
          eff_to:result.page_setting.promotion_deals[0]?.eff_to,
          // page_setting: ''//id dapat nipage setting
        })
        const pageSettingBranches = result.page_setting.branches
        const filteredpageSettingBranchesData = pageSettingBranches.filter(obj => obj.status !== "archived");
        setBranches(filteredpageSettingBranchesData)
        setVouchers(result.page_setting.vouchers)
        setRestaurantDetails(result)
        console.log("resultresultresult",result)


      }catch(err){
        console.log("err getRestaurantDetails",err)
      }
  }


  const updateResDetails = async() =>{
     
     try{
        if(selectedLogoFile !==undefined){
          const fileFormData = new FormData();
          fileFormData.append("folder", restaurantData.resDetails.folder_id);
          fileFormData.append("file", selectedLogoFile);
          if(!selectedLogoFile){
            setSnackConfig({
              message:"Please provide retaurant logo",
              show:true,
              type:"error"
            })
          }else if(!resName){
            setSnackConfig({
              message:"Please provide retaurant name",
              show:true,
              type:"error"
            })
          }else{
            
            await ApiCall({
              apiEndpoint: "/files",
              method: ApiMethod.POST,
              apiContentType: "multipart/form-data",
              token:restaurantData.userAccess.access_token,
              apiData: fileFormData,
            }).then(async(imgUp)=>
                ApiCall({
                  apiEndpoint:`/items/restaurant/${restaurantData.resDetails.id}`,
                  method:ApiMethod.PATCH,
                  token:restaurantData.userAccess.access_token,
                  apiData:{
                    res_logo:imgUp.data.data.id,
                    res_name:resName,
                    res_email:resEmail,
                    res_phone:resNum,
                    res_addr:resAddr,
                    theme:selectedTheme
                  }
                }).then((udpatedRes)=>{
                  const resDetails =udpatedRes.data.data
                  //const userDetailsAcess = { ...restaurantData, ...resDetails }
                  const userDetailsAcess = { ...{userAccess:restaurantData.userAccess}, ...{userDetails:restaurantData.userDetails},...{resDetails:resDetails} }
                  dispatch(setRestaurantData(userDetailsAcess))
                  setSnackConfig({
                    message:"Restaurant settings successfully updated.",
                    show:true,
                    type:"success"
                  })
                  EventEmitter.dispatch('renderRestaurant')
                }).catch((err)=>{
                    console.log("Error update",err)
                })
            ).catch((errUpImg)=>
              console.log(errUpImg)
            )
          }
          
          }else{
            if(!resName){
              setSnackConfig({
                message:"Please provide retaurant name",
                show:true,
                type:"error"
              })
            }else{
              ApiCall({
                apiEndpoint:`/items/restaurant/${restaurantData.resDetails.id}`,
                method:ApiMethod.PATCH,
                token:restaurantData.userAccess.access_token,
                apiData:{
                  res_name:resName,
                  res_email:resEmail,
                  res_phone:resNum,
                  res_addr:resAddr,
                  theme:selectedTheme
                }
              }).then((udpatedRes)=>{
                const resDetails =udpatedRes.data.data
                const userDetailsAcess = { ...{userAccess:restaurantData.userAccess}, ...{userDetails:restaurantData.userDetails},...{resDetails:resDetails} }
                dispatch(setRestaurantData(userDetailsAcess))
                setSnackConfig({
                  message:"Restaurant settings successfully updated.",
                  show:true,
                  type:"success"
                })
                EventEmitter.dispatch('renderRestaurant')
              }).catch((err)=>{
                  console.log("Error update",err)
              })
            }
            
          }

      }catch(err){
        console.log("updateResDetails err",err)
      }
  }



  useEffect(()=>{
    getRestaurantImage()
    getRestaurantCategory()
    getRestaurantDetails()
    getCategory()
    setFName(restaurantData.userDetails.first_name)
    setLName(restaurantData.userDetails.last_name)
    // setPass(restaurantData.password)
    setEmail(restaurantData.userDetails.email)
    setContactNUm(restaurantData.userDetails.phone_number)
    setAddr1(restaurantData.userDetails.address_1)
    setAddr2(restaurantData.userDetails.address_2)
  },[JSON.stringify(restaurantData)])

  const handleChangeImage = (e,promo) => {
    if (e.target.files) {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result;
        setSelectedImg(base64String);
      }
      reader.readAsDataURL(file);
      if(promo){
        setPromoDealImage(e.target.files[0])
        setPromotion({...promotion,image:e.target.files[0]})
      }else{
        setSelectedFile(e.target.files[0])
        setPageSetting({...pageSetting,image:e.target.files[0]})
      }
      // setSelectedFile(e.target.files[0])
      //console.log(e.target.files[0]);
    }
  };

  const handleChangeImageLogo = (e) => {
    if (e.target.files) {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result;
        
        setSelectedLogo(base64String);
      }
      reader.readAsDataURL(file);
      setSelectedLogoFile(e.target.files[0])
    }
  };


  const getCategory = async()=>{
    await ApiCall({
      apiEndpoint:'/items/category',
      method:ApiMethod.GET,
      token:restaurantData.userAccess.access_token,
      sort:'-date_created',
      filter:{
        restaurant_id:{
          _eq:restaurantData.resDetails.id
        }
      }
    }).then((res)=>
    {
      let categoryLists = []
      const categoryData = res.data.data
      if(categoryData.length >0){
        categoryData.map((item)=>
        categoryLists.push({
            id: item.id,
            label: item.description,
            icon: null,
            value:item.description,
            
        })
        )
        setCategory(categoryLists)
      }      
    }      
    ).catch((err)=>
      console.log(err)
    )
  }


 const convertData = (inputDateString) =>{
      const inputDate = new Date(inputDateString);

    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true, // Use 24-hour format
    };

    const formattedDateString = inputDate.toLocaleString('en-US', options);
    return formattedDateString
 }


//  const savePageSetting = async() =>{
  
//     try{
//       setIsLoading(true)

//       if(effFromErr){
//         return
//       }else if(effToErr){
//         return
//       }else if(errTimeOpen){
//         return
//       }else if(errTimeClose){
//         return
//       }else if(branchCloseErr){
//         return
//       }else if(branchOpenErr){
//         return
//       }else{
//             let pageSettingNewImage 
//             if(selectedFile){
//               const fileFormData = new FormData();
//               fileFormData.append("folder",restaurantData.resDetails.folder_id);
//               fileFormData.append("file", selectedFile);
//               const detailImage =  await ApiCall({
//                   apiEndpoint: "/files",
//                   method: ApiMethod.POST,
//                   apiContentType: "multipart/form-data",
//                   token:restaurantData.userAccess.access_token,
//                   apiData: fileFormData,
//               })
//               console.log("thisss is hte promoooo imageeee",detailImage.data.data)
//               pageSettingNewImage = detailImage.data.data.id
//             }

//             let params
//             if(pageSettingNewImage){
//                 params = {
//                   image:pageSettingNewImage,
//                   opening_time:pageSetting?.opening_time,
//                   closing_time:pageSetting?.closing_time,
//                   details:pageSetting?.details
//                 }
//             }else{
//                 params = {
//                   opening_time:pageSetting?.opening_time,
//                   closing_time:pageSetting?.closing_time,
//                   details:pageSetting?.details
//                 }
//             }

//             const saveSetting = await ApiCall({
//               apiEndpoint:`/items/page_setting/${originalDetails?.page_setting?.id}`,
//               method:ApiMethod.PATCH,
//               // token:restaurantData.userAccess.access_token,
//               service:true,
//               apiData:{
//                 ...params
//               }

//             })

//             //const save promotion image 
//             let promoImageNew
//             if(promoDealImage){
//               const fileFormData = new FormData();
//               fileFormData.append("folder",restaurantData.resDetails.folder_id);
//               fileFormData.append("file", promoDealImage);
//               const promoImage =  await ApiCall({
//                   apiEndpoint: "/files",
//                   method: ApiMethod.POST,
//                   apiContentType: "multipart/form-data",
//                   token:restaurantData.userAccess.access_token,
//                   apiData: fileFormData,
//               })
//               console.warn("thisss is hte promoooo imageeee",promoImage.data.data)
//               promoImageNew = promoImage.data.data.id
//             }
            
//             let paramsPromotion;
//             if(promoImageNew){
//               paramsPromotion = { 
//                   image:promoImageNew,
//                   discount:Number(promotion?.discount),
//                   restaurant_name:promotion?.restaurant_name,
//                   details:promotion?.details,
//                   eff_from:promotion?.eff_from,
//                   eff_to:promotion?.eff_to,
//                   page_setting:originalDetails?.page_setting?.id
//                 }
//             }else{
//               paramsPromotion = {
//                 discount:Number(promotion?.discount),
//                 restaurant_name:promotion?.restaurant_name,
//                 details:promotion?.details,
//                 eff_from:promotion?.eff_from,
//                 eff_to:promotion?.eff_to,
//                 page_setting:originalDetails?.page_setting?.id
//               }
//             }
//             //loop promotion deals..prep if ever mag multiple (pero single lang man siya for now)
//             if(originalDetails?.page_setting?.promotion_deals.length > 0 ){
//               console.log("hahhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhh")
//                       for(const data of originalDetails?.page_setting?.promotion_deals){
//                         const promotion_deals = await ApiCall({
//                         apiEndpoint:`/items/promotion_deals/${data?.id}`,
//                         method:ApiMethod.PATCH,
//                         // token:restaurantData.userAccess.access_token,
//                         service:true,
//                         apiData:{
//                           ...paramsPromotion
//                         }
//                       })
//                   }
//             }else{
//               console.log("diriiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiii",paramsPromotion)
//                   const promotion_deals = await ApiCall({
//                     apiEndpoint:`/items/promotion_deals`,
//                     method:ApiMethod.POST,
//                     // token:restaurantData.userAccess.access_token,
//                     service:true,
//                     apiData:{
//                       ...paramsPromotion
//                     }
//                   })
//             }
          
            
//             //branches loop check sa array if ga exist then patch else add?
//             //mean state is empty remove all 
//             if(branches.length === 0){
//               for(const item of originalDetails?.page_setting?.branches){
//                 const removeVoucher = await ApiCall({
//                   apiEndpoint: `/items/Vouchers/${item.id}`,
//                   method: ApiMethod.DELETE,
//                   service:true
//                   // Additional parameters if needed
//                 });
//               }
//           }

//             for (const data of branches) {
//               const existingBranch = originalDetails?.page_setting?.branches.find(info => info.id === data.id);

//               const branchToRemove = originalDetails?.page_setting?.branches.filter(
//                 existingBranch => !branches.some(data => data.id === existingBranch.id)
//               );

//               //mean existing sa response na gin remove na sa state
//               for(const item of branchToRemove){
//                 const removeVoucher = await ApiCall({
//                   apiEndpoint: `/items/branches/${item.id}`,
//                   method: ApiMethod.DELETE,
//                   service:true
//                   // Additional parameters if needed
//                 });
//               }

//               console.log("thisss is the voucherrss to removeeeeee",branchToRemove)
//               console.log("thisss is the exisstingg voucherrrr",existingBranch)
//               if (existingBranch) {
//                 // If the branch exists, update it
//                 const branchUpdate = await ApiCall({
//                   apiEndpoint:`/items/branches/${existingBranch?.id}`,
//                   method:ApiMethod.PATCH,
//                   // token:restaurantData.userAccess.access_token,
//                   service:true,
//                   apiData:{
//                     name:data?.name,
//                     details:data?.details,
//                     addr_line_1:data?.addr_line_1,
//                     addr_line_2:data?.addr_line_2,
//                     addr_line_3:data?.addr_line_3,
//                     opening_time:data?.opening_time,
//                     closing_time:data?.closing_time
//                   }
//                 })
//                 // Perform the update logic here
//               } else {
//                 // If the branch doesn't exist, add it
//                 console.log(`Add new branch with ID ${data.id}`);
//                 const branchInsert = await ApiCall({
//                   apiEndpoint:`/items/branches`,
//                   method:ApiMethod.POST,
//                   // token:restaurantData.userAccess.access_token,
//                   service:true,
//                   apiData:{
//                     name:data?.name,
//                     details:data?.details,
//                     addr_line_1:data?.addr_line_1,
//                     addr_line_2:data?.addr_line_2,
//                     addr_line_3:data?.addr_line_3,
//                     page_setting:originalDetails?.page_setting?.id,
//                     opening_time:data?.opening_time,
//                     closing_time:data?.closing_time
//                   }
//                 })
//               }
//             }
            
          
//             //promotions same logic sa branches
//             //mean state is empty remove all 
//             if(vouchers.length === 0){
//                 for(const item of originalDetails?.page_setting?.vouchers){
//                   const removeVoucher = await ApiCall({
//                     apiEndpoint: `/items/Vouchers/${item.id}`,
//                     method: ApiMethod.DELETE,
//                     service:true
//                     // Additional parameters if needed
//                   });
//                 }
//             }
//             for (const data of vouchers) {
//               const existingVoucher = originalDetails?.page_setting?.vouchers.find(info => info.id === data.id);

//               const  VoucherToRemove = originalDetails?.page_setting?.vouchers.filter(
//                 existingVoucher => !vouchers.some(data => data.id === existingVoucher.id)
//               );
//               //mean existing sa response na gin remove na sa state
//               for(const item of VoucherToRemove){
//                 const removeVoucher = await ApiCall({
//                   apiEndpoint: `/items/Vouchers/${item.id}`,
//                   method: ApiMethod.DELETE,
//                   service:true
//                   // Additional parameters if needed
//                 });
//               }
//               // console.log("____________________voucherDetails",voucherDetails)
//               if (existingVoucher) {
//                 // If the branch exists, update it
//                 const voucherUpdate = await ApiCall({
//                   apiEndpoint:`/items/Vouchers/${existingVoucher?.id}`,
//                   method:ApiMethod.PATCH,
//                   // token:restaurantData.userAccess.access_token,
//                   service:true,
//                   apiData:{
//                     discount:Number(data?.discount),
//                     promo_code:data?.promo_code,
//                     eff_from:data?.eff_from,
//                     eff_to:data?.eff_to,
//                     // menu:null,
//                     purchase_limit:Number(data?.purchase_limit),
//                     usage_limit:Number(data?.usage_limit)
//                   }
//                 })
//                 // Perform the update logic here
//               } else {
//                 // If the branch doesn't exist, add it
//                 console.log(`Add new branch with ID ${data.id}`);
//                 const voucherInsert = await ApiCall({
//                   apiEndpoint:`/items/Vouchers`,
//                   method:ApiMethod.POST,
//                   // token:restaurantData.userAccess.access_token,
//                   service:true,
//                   apiData:{
//                     discount:Number(data?.discount),
//                     promo_code:data?.promo_code,
//                     eff_from:data?.eff_from,
//                     eff_to:data?.eff_to,
//                     // menu:null,
//                     purchase_limit:Number(data?.purchase_limit),
//                     usage_limit:Number(data?.usage_limit),
//                     page_setting:originalDetails?.page_setting?.id
//                   }
//                 })
//               }
//             }
//             // //after done..then refetch lang ang details
//             await getRestaurantDetails()
//             // setIsEdit(false)
//             setIsLoading(false)
         
//       }
//     }catch(err){
//       setIsLoading(false)
//       console.log("savePageSetting err",err)
//     }

      
//  }

 const handleInputChange = (index, field, value) => {
  // Create a new array with the updated data for the specific branch
  const updatedBranches = [...branches];
  updatedBranches[index][field] = value;

  // Update the state with the new array
  setBranches(updatedBranches);
 };
 

 const removeVoucher = (id) =>{
      const filterVouchers = vouchers.filter((item)=>{
          return item.id !== id
      })
      setVouchers(filterVouchers)
 }


 const removeBranch = (id) =>{
  const filterBranches = branches.filter((item)=>{
      return item.id !== id
  })
  setBranches(filterBranches)
}


const updateBranch = ()=>{
    let checkIfErr = {
      have:false,
      message:null
    }
 const updateBranches =  branches.map((item)=>{
    if(item.id === branchDetails?.id){
      const opening = new Date(branchDetails.opening_time);
      const closing = new Date(branchDetails.closing_time);
      console.log(branchDetails)
      if(!branchDetails.name){
        checkIfErr = {
          have:true,
          message:"Please fill branch name"
        }
      }else if(!branchDetails.details){
        checkIfErr = {
          have:true,
          message:"Please fill branch details"
        }
      }else if(!branchDetails.addr_line_1){
        checkIfErr = {
          have:true,
          message:"Please fill address 1"
        }
      }else if(!branchDetails.addr_line_2){
        checkIfErr = {
          have:true,
          message:"Please fill address 2"
        }
      }else if(!branchDetails.addr_line_3){
        checkIfErr = {
          have:true,
          message:"Please fill address 3"
        }
      }else if (!branchDetails.opening_time) {
        checkIfErr = {
          have:true,
          message:"Please fill opening time"
        }
      }else if (!branchDetails.closing_time) {
        checkIfErr = {
          have:true,
          message:"Please fill closing time"
        }
      }else if (closing <= opening) {
        checkIfErr = {
          have:true,
          message:"Closing time cannot be earlier than or equal to opening time."
        }
      } else if (opening >= closing) {
        checkIfErr = {
          have:true,
          message:"Opening time cannot be later than or equal to closing time."
        }
        
      }else{
        return {...branchDetails}        
      }
    }else{
        return item
        
    }
  })
if(checkIfErr.have){
  setSnackConfig({
    message:checkIfErr.message,
    type:'error',
    show:true
  })
}else{
  console.log('proceed')
    setBranches(updateBranches)
    setBranchDetails({
      name:'',
      details:'',
      addr_line_1:'',
      addr_line_2:'',
      addr_line_3:'',
    })
    setBranchEdit(false)
    setBranchForm(false)
}



}

const updateVoucher = () =>{
  const updateVoucher =  vouchers.map((item)=>{
    if(item.id === voucherDetails?.id){
        return {...voucherDetails}
    }else{
        return item
    }
  })

  setVouchers(updateVoucher)
  setVoucherDetails({
    discount:"",
    promo_code:"",
    eff_from:"",
    eff_to:"",
    menu:"",
    purchase_limit:"",
    usage_limit:"",
    details:''
  })
  setVoucherEdit(false)

}

console.log("branchesss",branches)

const updateCategory = async() =>{
   try{
    if(selectedCat.length >= 0){
      const getCategory = await ApiCall({
        apiEndpoint: `/items/restaurant_category?fields=*&filter[restaurant][_eq]=${restaurantData.resDetails.id}`,
        method: ApiMethod.GET,
        service:true
      });
      console.log("gettt category",getCategory.data.data)
      for(const data of getCategory.data.data){
        const getCategory = await ApiCall({
          apiEndpoint: `/items/restaurant_category/${data.id}`,
          method: ApiMethod.DELETE,
          service:true
        });
      }

      //INSERT NEW CAT
      for(const data of selectedCat){
        const getCategory = await ApiCall({
          apiEndpoint: `/items/restaurant_category`,
          method: ApiMethod.POST,
          service:true,
          apiData:{
            restaurant:restaurantData.resDetails.id,
            restaurant_details:data.id
          }
        });
      }

   }
    }catch(err){
      console.log("updateCategory err",err)
  }
}

const [effFromErr,setEffFromErr] = useState(false)
const [effToErr,setEffToErr] = useState(false)


const [errTimeOpen,setErrTimeOpen] = useState(false)
const [errTimeClose,setErrTimeClose] = useState(false)

const [branchOpenErr,setBranchOpenErr] = useState(false)
const [branchCloseErr,setBranchCloseErr] = useState(false)

const handleEffectiveFromChange = (data, isTime, isBranch) => {
  if (isTime) {
    if (isBranch) {
      if (!branchDetails.opening_time || moment(data).isBefore(branchDetails.closing_time)) {
        setBranchDetails({...branchDetails, opening_time: data});
        setBranchOpenErr(false);
      } else {
        setBranchOpenErr(true);
        console.error('Effective from date must be earlier than Effective to date.');
      }
    } else {
      if (!pageSetting.opening_time || moment(data).isBefore(pageSetting.closing_time)) {
        setPageSetting({...pageSetting, opening_time: data});
        setErrTimeOpen(false);
      } else {
        setErrTimeOpen(true);
        console.error('Effective from date must be earlier than Effective to date.');
      }
    }
  } else {
    if (promotion.eff_from && promotion.eff_to || !promotion.eff_to || moment(data).isBefore(promotion.eff_to)) {
      setPromotion({...promotion, eff_from: data});
      setEffFromErr(false);
    }else {
      setEffFromErr(true);
      console.error('Effective from date must be earlier than Effective to date.');
    }
  }
};

const handleEffectiveToChange = (data, isTime, isBranch) => {
  if (isTime) {
    if (isBranch) {
      if (!branchDetails.closing_time || moment(data).isAfter(branchDetails.opening_time)) {
        setBranchDetails({...branchDetails, closing_time: data});
        setBranchCloseErr(false);
        console.log("Success!");
      } else {
        setBranchCloseErr(true);
        console.error('Closing time must be later than opening time.');
      }
    } else {
      if (!pageSetting.closing_time || moment(data).isAfter(pageSetting.opening_time)) {
        setPageSetting({...pageSetting, closing_time: data});
        setErrTimeClose(false);
        console.log("Success!");
      } else {
        setErrTimeClose(true);
        console.error('Closing time must be later than opening time.');
      }
    }
  } else {
    console.error("eff_from eff_to->>>>",{"eff_from":promotion.eff_from,"eff_to":promotion.eff_to });
    if (promotion.eff_from && promotion.eff_to || !promotion.eff_from || moment(data).isAfter(promotion.eff_from)) {
      setPromotion({...promotion, eff_to: data});
      setEffToErr(false);
    }else {
      setEffToErr(true);
      console.error('Effective to date must be later than Effective from date.');
      
    }
  }
};


const [isUserChange, setIsUserChange] = useState(false);
const [isUser2Change,setIsUser2Change] = useState(false)

const handleChange = (event, editor,promo) => {

  if(promo){
    if (isUser2Change) {
      const data = editor.getData();
      setPromotion({...promotion,details:data})
      console.warn("triggered only by user interaction");
    }
  }else{
    if (isUserChange) {
      const data = editor.getData();
      setPageSetting({...pageSetting,details:data})
      console.warn("triggered only by user interaction");
    }
  }


 
};
const handleCategoryChange = (newValue) => {
  setSelectedCat(newValue);
};
const filterOptions = (options, selected) => {
  const selectedIds = selected.map((item) => item.id);
  const opt =  options.filter((option) => !selectedIds.includes(option.id));
  return opt
};
  return (
   <section id="account_plan">
    <div className="my_container">
      {/* <Card className="plan_con">
          <Typography className="heading">
              <b>Pro Version</b>
          </Typography>
          <Typography className="price openSans" >
              $10
          </Typography>
          <div className="btns">
            <Button variant="contained openSans">Monthly</Button>
            <Button variant="outlined openSans">Yearly</Button>
            <Button variant="outlined openSans">Lifetime</Button>
          </div>
          <Divider sx={{mt:3}}/>
          <Grid item xs={12} md={6}>
            <List className="desc_lists">
              {
                list.map((item,index)=>
                <ListItem key={index}>
                  <ListItemIcon>
                    <CheckCircleIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={item}
                  />
                </ListItem>
                )
              }
                
            </List>
        </Grid>
        <Button variant="contained change_btn"><b>Change</b></Button>
      </Card> */}
      <Card className="acctn_settings">
        <Typography className="heading">
                <b>Account Settings </b>
                {/* {
                  editMode && 
                  <span className="edit" onClick={()=>setEditmode(false)}>Edit</span>
                } */}
                 
        </Typography>
        <Stack sx={{mt:3}}>
          <Stack sx={{mt:1.5}} >
              <TextField id="outlined-basic" className='form_fields' style={{backgroundColor:"white",border:"1px solid gray"}} variant="outlined" size="small" value={fname}  placeholder={"First Name"} disabled={editMode}  onChange={(e)=>setFName(e.nativeEvent.target.value)}/>
          </Stack>
          <Stack sx={{mt:1.5}} >
              <TextField id="outlined-basic" className='form_fields' style={{backgroundColor:"white",border:"1px solid gray"}} variant="outlined" size="small" value={lname}  placeholder={"Last Name"} disabled={editMode}  onChange={(e)=>setLName(e.nativeEvent.target.value)}/>
          </Stack>
          {/* <Stack sx={{mt:1.5}} >
              <TextField id="outlined-basic" className='form_fields' variant="outlined" size="small" value={pass}  placeholder={"Password"} disabled={true}  onChange={(e)=>setPass(e.nativeEvent.target.value)}/>
          </Stack> */}
          <Stack sx={{mt:1.5}} >
              <TextField id="outlined-basic" className='form_fields' style={{backgroundColor:"white",border:"1px solid gray"}} variant="outlined" size="small" value={email}  placeholder={"Email"} disabled={editMode}  onChange={(e)=>setEmail(e.nativeEvent.target.value)}/>
          </Stack>
          <Stack sx={{mt:1.5}} >
              <TextField id="outlined-basic" className='form_fields' style={{backgroundColor:"white",border:"1px solid gray"}} variant="outlined" size="small" value={contactNum}  placeholder={"Contact Number"} disabled={editMode}  onChange={(e)=>setContactNUm(e.nativeEvent.target.value)}/>
          </Stack>
          {catergories.length > 0 && 
            <Autocomplete style={{marginTop:10,backgroundColor:"white",border:"1px solid gray",borderRadius:10}}
              multiple
              disableClearable
              id="tags-standard"
              getOptionLabel={(option) => option.name}
              value={selectedCat}
              options={filterOptions(catergories, selectedCat)}
              onChange={(event, newValue) => {
                console.log("event",event)
                setSelectedCat(newValue)
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  placeholder="Restaurant Categories"
                />
              )}
            />
           }
          <Stack sx={{mt:1.5}} >
              <TextField id="outlined-basic" className='form_fields' style={{backgroundColor:"white",border:"1px solid gray"}} variant="outlined" size="small" value={addr1}  placeholder={"Address 1"} disabled={editMode}  onChange={(e)=>setAddr1(e.nativeEvent.target.value)}/>
          </Stack>
          <Stack sx={{mt:1.5}} >
              <TextField id="outlined-basic" className='form_fields' style={{backgroundColor:"white",border:"1px solid gray"}} variant="outlined" size="small" value={addr2}  placeholder={"Address 2"} disabled={editMode}  onChange={(e)=>setAddr2(e.nativeEvent.target.value)}/>
          </Stack>
            {
              !editMode && 
                <Button variant="contained save_btn" 
                onClick={async()=>{
                  setIsLoading(true)
                  if(!fname){
                    setSnackConfig({
                      message:"Please provide your first name",
                      show:true,
                      type:"error"
                    })
                    setIsLoading(false)
                  }else if(!lname){
                    setSnackConfig({
                      message:"Please provide your last name",
                      show:true,
                      type:"error"
                    })
                    setIsLoading(false)
                  }else if(!email){
                    setSnackConfig({
                      message:"Please provide your email",
                      show:true,
                      type:"error"
                    })
                    setIsLoading(false)
                  }else if(email && !EmailValidator(email)){
                    setSnackConfig({
                      message:"Please provide a valid email",
                      show:true,
                      type:"error"
                    })
                    setIsLoading(false)
                  }else if(!contactNum){
                    setSnackConfig({
                      message:"Please provide your contact number",
                      show:true,
                      type:"error"
                    })
                    setIsLoading(false)
                  }else if(isNaN(contactNum)){
                    setSnackConfig({
                      message:"Please provide valid number",
                      show:true,
                      type:"error"
                    })
                    setIsLoading(false)
                  }else if(!addr1){
                    setSnackConfig({
                      message:"Please provide your first address",
                      show:true,
                      type:"error"
                    })
                    setIsLoading(false)
                  }else{
                    await  updateCategory()
                    // setEditmode(true)
                    await ApiCall({
                      apiEndpoint:`/users/${restaurantData.userDetails.id}`,
                      method:ApiMethod.PATCH,
                      token:restaurantData.userAccess.access_token,
                      apiData:{
                        first_name:fname,
                        last_name:lname,
                        email:email,
                        //password:"",
                        phone_number:contactNum,
                        address_1:addr1,
                        address_2:addr2
  
                      }
                    }).then((res)=>{
                      const resDetails =res.data.data
                      const userDetailsAcess = { ...restaurantData, ...{userDetails:resDetails} }
                      dispatch(setRestaurantData(userDetailsAcess))
                      EventEmitter.dispatch('renderRestaurant')
                      setSnackConfig({
                        message:"Account settings updated successfully",
                        show:true,
                        type:"success"
                      })
                      setIsLoading(false)
                    }).catch((err)=>{
                        //console.log("Error Updating Account settings",err)
                        setSnackConfig({
                          message:"Updating account settings failed",
                          show:true,
                          type:"error"
                        })
                        setIsLoading(false)
                    })
                   
                  }
                 
                }
                }><b>Update Account Settings</b></Button>
            }
            
        </Stack>
               
      </Card>
      
      <Typography className='setting_label'>Landing page settings</Typography>
      <Card className='landing_page_setting'> 
        
          <div className='container'>
            <div className='clocks'>
              <Typography className='promotion'>Restaurant Details</Typography>
             </div>
             
             <div className="restaurant_settings" style={{backgroundColor:""}}>
              <Stack sx={{mt:3}} style={{backgroundColor:""}}>
            <div className="imgSection">
           
            <div className="imgHolder" style={{position:'relative'}}>
              {
                  selectedLogo && <div  style={{backgroundImage: `url('${selectedLogo}')`,width:"200px",height:"70px" ,backgroundSize:'contain',backgroundRepeat:"no-repeat",backgroundPosition:'center'}}></div>
                  }
                  
                  <div className="changeImage">
                      <div className="imgIcon">
                        <ImageIcon />
                      </div>
                      <div className="imgContentHolder" style={{position:'relative'}}>
                        <a href="javascript:void(0)" className="changeLabel">{"Restaurant Logo"}</a>
                        <input id="file" type="file" disabled={!isEdit && true} accept="image/*" onChange={handleChangeImageLogo} style={{position:'absolute',opacity:0,height:'100%',width:'100%'}}/>
                        <p className="changeDesc">(Max. File size: 25 MB)</p>
                      </div>
                  </div>
                </div>
              </div>
              <Typography style={{marginTop:"20px"}}>Restaurant Gallery</Typography>
              <div className='restaurant_gallery'>
             
                {galleryImage.map((item,index)=>{
                    return <div className="imgGal" style={{backgroundImage:`url(${getImageUpdload(item.image)})`}}>
                       <ClearIcon style={{position:'absolute',right:"-10px",top:"-5px",color:"#FFF",cursor:'pointer'}} onClick={()=>deleteImage(item.id)}/>
                      </div>
                  

                })}
                 <div style={{ cursor: 'pointer', border: '1px solid #F0522B', borderRadius: '5px', backgroundColor: '#f0f0f0', color: '#333', width:'100px' ,height: '40px', marginTop: '10px', borderRadius: '8px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <input
                    type="file"
                    accept="image/*"
                    style={{ display: 'none' }}
                    onChange={handleAdditionalImage}
                    multiple={false} 
                    id="file-upload"
                    disabled={!isEdit && true}
                    
                  />
                  <label htmlFor="file-upload" style={{display:'flex',justifyContent:'center',alignItems:'center',fontSize:"15px",fontWeight:"bold",color:"#F0522B",height:'100%',width:'100%',textAlign:'center',cursor:'pointer'}} >
                    +  Image
                  </label>
                </div>
              </div>
            
            <Stack sx={{mt:1.5}}>
              <TextField id="outlined-basic" disabled={!isEdit && true} style={isEdit ? {backgroundColor:"white",border:"1px solid gray"} : {}} className='form_fields' placeholder='Restaurant Name' value={resName} variant="outlined" size="medium" onChange={(e)=>setResName(e.nativeEvent.target.value)} />
            </Stack>
            <Stack sx={{mt:1.5}}>
              <TextField id="outlined-basic" disabled={!isEdit && true}  style={isEdit ? {backgroundColor:"white",border:"1px solid gray"} : {}}   className='form_fields' placeholder='Restaurant Email' value={resEmail} variant="outlined" size="medium" onChange={(e)=>setResEmail(e.nativeEvent.target.value)} />
            </Stack>
            <Stack sx={{mt:1.5}}>
              <TextField id="outlined-basic" disabled={!isEdit && true} style={isEdit ? {backgroundColor:"white",border:"1px solid gray"} : {}}  className='form_fields' placeholder='Restaurant Number' value={resNum} variant="outlined" size="medium" onChange={(e)=>setResNum(e.nativeEvent.target.value)} />
            </Stack>
            <Stack sx={{mt:1.5}}>
              <TextField id="outlined-basic" disabled={!isEdit && true} style={isEdit ? {backgroundColor:"white",border:"1px solid gray"} : {}}  className='form_fields' placeholder='Restaurant Address' value={resAddr} variant="outlined" size="medium" onChange={(e)=>setResAddr(e.nativeEvent.target.value)} />
            </Stack>
            
            <div style={{marginTop:"15px"}}></div>
            <Accordion defaultExpanded > 
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
              style={{paddingLeft:"10px",paddingRight:"10px",width:"100%",color:'gray'}}
            >
              Choose Theme
            </AccordionSummary>
              <AccordionDetails>
                {
                  appColorTheme.map((item)=>
                    <Typography className="option" onClick={()=> setSelectedTheme(item.code)} sx={{cursor:"pointer",backgroundColor:selectedTheme==item.code?'#eeeae9':'#ffffff',marginY:0.5}}>
                      {item.name}
                    </Typography>
                  )
                }
              </AccordionDetails>
            </Accordion> 
            <div className='time' style={{marginBottom:'30px',marginTop:"15px"}}>
                {/* <Typography style={{color:"#F0522B"}}>Set Time:</Typography> */}
                <div style={{display:"flex",alignItems:'flex-start',flexDirection:'column'}}> 

                <div style={{width:"100%",marginBottom:'20px'}}>
               
                    {/* <Typography style={{color:"#F0522B",fontSize:"12px"}}>Opening</Typography> */}
                    <AppDatePicker  isTimeOnly={true} disabled={!isEdit && true} label={"Opening Time"} views={["hours", "minutes", "seconds"]} defaultDate={pageSetting?.opening_time} 
                    onSelectedTime={(data)=>{
                      handleEffectiveFromChange(data,true)
                        // console.log("AppDatePicker console",data)
                        // setPageSetting({...pageSetting,opening_time:data})
                    }}
                    
                    />
                    
                    {errTimeOpen && <Typography style={{color:'red'}}>Invalid Time.</Typography>}
                </div>
                  
                 <div style={{width:"100%"}}>
                    {/* <Typography style={{color:"#F0522B",fontSize:"12px"}}>Closing</Typography> */}
                    <AppDatePicker isTimeOnly={true} disabled={!isEdit && true} label={"Closing Time"} views={["hours", "minutes", "seconds"]} defaultDate={pageSetting?.closing_time} onSelectedTime={(data)=>{
                       console.log("AppDatePicker console",data)
                       handleEffectiveToChange(data,true)
                      //  setPageSetting({...pageSetting,closing_time:data})

                    }}/>
                    {errTimeClose && <Typography style={{color:'red'}}>Invalid Time.</Typography>}  
                </div>
                
                </div>
              
             </div>
          
            </Stack>       
    </div>
                <div className='label_1' style={{marginTop:20}}>
                   <Typography className='promotion'>Promotion & Deals</Typography>
                   {/* <Typography className='edit' onClick={()=>setIsEdit(!isEdit)}>Edit</Typography> */}
                </div>
                <Stack sx={{mt:1.5}} style={{width:"100%",}} >
                {/* <TextField id="outlined-basic" className='form_fields' variant="outlined" size="small" value={convertData(promotion?.eff_from)} onChange={(e)=>setPromotion({...promotion,eff_from:e.nativeEvent.target.value})}  placeholder={"From"} style={{width:"100%"}}/> */}
                 <AppDatePicker  clearable={true} label={"Effective from"}  disabled={!isEdit && true} views={['year', 'day', 'hours', 'minutes']} defaultDate={promotion?.eff_from} 
                 
                   onSelectedTime={(data)=>{
                        console.log("AppDatePicker console",data)
                        setPromotion({...promotion,eff_from:data})
                        handleEffectiveFromChange(data)
                  }}
                  // onSelectedTime={(data)=>handleEffectiveFromChange(data)}
                  
                  />
                  {effFromErr  && !promotion.eff_from ? <Typography style={{color:'red'}}>Invalid Date.</Typography>:null}
                  
             </Stack>
             
             <Stack sx={{mt:1.5}} style={{width:"100%"}} >
                {/* <TextField id="outlined-basic" className='form_fields' variant="outlined" size="small" value={convertData(promotion?.eff_to)} onChange={(e)=>setPromotion({...promotion,eff_to:e.nativeEvent.target.value})}  placeholder={"Until"} style={{width:"100%"}}/> */}
                <AppDatePicker clearable={true} label={"Effective to"} disabled={!isEdit && true} views={['year', 'day', 'hours', 'minutes']} defaultDate={promotion?.eff_to} 
                  onSelectedTime={(data)=>{
                        console.log("AppDatePicker console",data)
                        setPromotion({...promotion,eff_to:data})
                        handleEffectiveToChange(data)
                  }}
                  // onSelectedTime={(data)=>handleEffectiveToChange(data)}
                  
                  />
                  {effToErr && !promotion.eff_to ? <Typography style={{color:'red'}}>Invalid Date.</Typography>:null}
                   
             </Stack>
            <div className="imgSection" style={{marginTop:20}}>
            <label className="imgTitle">Promotion Background (Transparent Background)</label>

            <div className="imgHolder">
              
              {
                
              <div className="changeImage" >
                 
                <div className="imgIcon" style={{width:"25%",backgroundColor:selectedImg || promotion?.image && 'transparent'}}>
                 
                  {promotion?.image && !selectedImg ? <img src={promotion?.image} className="modalImgProd"/>  : selectedImg ? <img src={selectedImg} className="modalImgProd"/> : <ImageIcon />}    
                </div>
               <div className="imgContentHolder" style={{width:"75%"}}>
                 <a href="javascript:void(0)" className="changeLabel">{"Click to Upload"}</a>
                 <input id="file" type="file" disabled={!isEdit} accept="image/*" onChange={(e)=>handleChangeImage(e,true)} />
                 <p className="changeDesc">(Max. File size: 25 MB)</p>
               </div>
               </div>
               
              }
              
              
            </div>
            </div>

            <div>
              <Stack sx={{mt:1.5}} style={{display:'flex',alignItems:'center',flexDirection:'row',justifyContent:"flex-start"}} >
                <TextField id="outlined-basic" className='form_fields' type='number' disabled={!isEdit && true} variant="outlined" size="small" value={Number(promotion?.discount).toFixed(0)} onChange={(e)=>setPromotion({...promotion,discount:e.nativeEvent.target.value})}  placeholder={"0"} style={isEdit ?  {backgroundColor:"white",border:"1px solid gray"} :{} }/>
                <Typography style={{width:"15%",color:"#959595",fontWeight:'400',fontSize:'18px',marginLeft:"10px"}}>% Off</Typography>
             </Stack>
             {/* <Stack sx={{mt:1.5}} style={{display:'flex',alignItems:'center',flexDirection:'row',justifyContent:"space-between"}} >
                <TextField id="outlined-basic" className='form_fields' disabled={!isEdit && true} variant="outlined" size="small" value={promotion?.restaurant_name} onChange={(e)=>setPromotion({...promotion,restaurant_name:e.nativeEvent.target.value})}   placeholder={"Restaurant Name"} style={{width:"100%"}}/>
             </Stack> */}
             <div className='label_2'>
                   <Typography className='promotion'>Promo Offer Details</Typography>
                 
              </div>
              <CKEditor
             
              editor={ClassicEditor}
              data={promotion?.details ? promotion?.details : ``}
              onReady={(editor) => {
                console.log('CKEditor React Component is ready to use!', editor);
              }}
              onChange={(event, editor) => {
                setIsUser2Change(true); // Set to true when the change is caused by user interaction
                handleChange(event, editor,true);
              }}
            />
              {/* <Stack sx={{mt:1.5}} style={{display:'flex',alignItems:'flex-end',flexDirection:'column',justifyContent:"flex-end"}} >
                <TextField id="outlined-basic" multiline rows={5} className='form_fields' disabled={!isEdit && true} variant="outlined" size="small" value={promotion?.details} onChange={(e)=>setPromotion({...promotion,details:e.nativeEvent.target.value})}   placeholder={"Details"} style={{width:"100%"}}/>
                <Typography className='edit' style={{marginTop:'3px',color:"#C0C0C0",fontSize:'10px',fontWeight:"500"}}>{promotion?.details?.length}/1500</Typography>
              </Stack> */}
             
              
           
            

             
          
       



             
             {/* <Editor defaultValue={pageSetting?.details}/> */}
             <CKEditor
              editor={ClassicEditor}
              data={pageSetting?.details ? pageSetting.details : ``}
              onReady={(editor) => {
                console.log('CKEditor React Component is ready to use!', editor);
              }}
              onChange={(event, editor) => {
                setIsUserChange(true); // Set to true when the change is caused by user interaction
                handleChange(event, editor);
              }}
            />
             {/* <CKEditor
                editor={ClassicEditor}
                data={pageSetting?.details? pageSetting?.details: ``}
                onReady={(editor) => {
                  console.log('CKEditor React Component is ready to use!', editor);
                  // CKEditorInspector.attach(editor);
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  console.warn("trigiggerrr agaddddddd")
                  // setPageSettingDetails(data);
                  // setPageSetting({...pageSetting,details:data})
                  //console.log("data",{ event, editor, data });
                }}
              /> */}
             {/* <Stack sx={{mt:1.5}} style={{display:'flex',alignItems:'flex-end',flexDirection:'column',justifyContent:"flex-end"}} >
                <TextField id="outlined-basic"  multiline rows={5} disabled={!isEdit && true} className='form_fields' variant="outlined" size="small" value={pageSetting?.details} onChange={(e)=>setPageSetting({...pageSetting,details:e.nativeEvent.target.value})}  placeholder={"Details"} style={{width:"100%"}}/>
                <Typography className='edit' style={{marginTop:'3px',color:"#C0C0C0",fontSize:'10px',fontWeight:"500"}}>0/1500</Typography>
             </Stack> */}
             {/* <div className="imgSection">
             <label className="imgTitle">Restaurant Logo (Transparent Background)</label>

            <div className="imgHolder">
              {!isEdit && pageSetting?.image && <img src={pageSetting?.image} className="modalImgProd"/>}
              
              {isEdit && <div className="changeImage">
                 <div className="imgIcon">
                  <ImageIcon />
                </div>
                  <div className="imgContentHolder">
                    <a href="javascript:void(0)" className="changeLabel">{"Click to Upload"}</a>
                    <input id="file" type="file" accept="image/*" onChange={handleChangeImage} />
                    <p className="changeDesc">(Max. File size: 25 MB)</p>
                  </div>
              </div>}
              
            </div>
            </div> */}
            {/* diri ta ma key in details */}



            <div className='clocks'>
              <Typography className='promotion' style={{marginTop:"10px"}}>Branches</Typography>
             </div>

            {branches.map((data,index)=>{
            
                return <>   <div className='clocks' style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                <Typography className='promotion'>{data?.name}</Typography>
                <div>
                <EditIcon
                 onClick={()=>{
                  setBranchDetails({
                    id:data?.id,
                    name:data?.name,
                    details:data?.details,
                    addr_line_1:data?.addr_line_1,
                    addr_line_2:data?.addr_line_2,
                    addr_line_3:data?.addr_line_3,
                    opening_time:data?.opening_time,
                    closing_time:data?.closing_time,
                   })
                  setBranchEdit(true)
                  setBranchForm(true)
                 }}
                 style={{ color: "#F0522B", fontSize: "23px",cursor:'pointer' }}
                />
                <DeleteOutlineIcon
                // onClick={()=>removeBranch(data?.id)}
                // const [showDialog, setShowDialog] = useState({show:false,details:null});
                onClick={()=> setShowDialog({show:true,details:data})}
                style={{ color: "#F0522B", fontSize: "23px",cursor:'pointer' }}
                />
                </div>
                <Dialog
                  open={showDialog.show}
                  onClose={()=>setShowDialog({show:false,details:null})}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    {"Are you sure you want to delete this branch?"}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      Branch Name : {showDialog?.details?.name}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>

                    <Button variant="outlined" onClick={()=>setShowDialog({show:false,details:null})}>No</Button>
                    <Button variant="contained"onClick={()=>{
                      removeBranch(showDialog?.details?.id)
                      setShowDialog({show:false,details:null})
                      }}>
                      Yes
                    </Button>
                  </DialogActions>
                </Dialog>
               </div> 
               <Stack sx={{mt:1.5}} style={{display:'flex',alignItems:'center',flexDirection:'row',justifyContent:"space-between"}} >
                  <TextField id="outlined-basic" onChange={(e)=>{
                    console.log("eeeee")
                    handleInputChange(index, 'addr_line_1', e.target.value)
                  }} disabled={!isEdit && true} className='form_fields' value={data?.addr_line_1}   variant="outlined" size="small"  placeholder={"Restaurant Address"}  style={{backgroundColor:"white",border:"1px solid gray",width:"100%"}}/>
               </Stack>
               <Stack sx={{mt:1.5}} style={{display:'flex',alignItems:'center',flexDirection:'row',justifyContent:"space-between"}} >
                  <TextField id="outlined-basic" onChange={(e)=>{
                     handleInputChange(index, 'addr_line_2', e.target.value)
                  }} disabled={!isEdit && true} className='form_fields' value={data?.addr_line_2}  variant="outlined" size="small"   placeholder={""} style={{backgroundColor:"white",border:"1px solid gray",width:"100%"}}/>
               </Stack>
               <Stack sx={{mt:1.5}} style={{display:'flex',alignItems:'center',flexDirection:'row',justifyContent:"space-between"}} >
                  <TextField id="outlined-basic" onChange={(e)=>{
                     handleInputChange(index, 'addr_line_3', e.target.value)
                  }} disabled={!isEdit && true} className='form_fields' value={data?.addr_line_3}  variant="outlined" size="small"   placeholder={""} style={{backgroundColor:"white",border:"1px solid gray",width:"100%"}}/>
               </Stack>
               <div style={{marginTop:"10px"}}>
                
                 <AppDatePicker label={"Opening from"} isTimeOnly={true}  views={['year', 'day', 'hours', 'minutes']} defaultDate={data?.opening_time} onSelectedTime={(data)=>{
                        console.log("AppDatePicker console",data)
                        handleInputChange(index, 'opening_time', data)
                  }}/>
               </div>
               
                <div style={{marginTop:"10px"}}>
                  
                <AppDatePicker label={"Opening to"} isTimeOnly={true}  views={['year', 'day', 'hours', 'minutes']} defaultDate={data?.closing_time} onSelectedTime={(data)=>{
                        console.log("AppDatePicker console",data)
                        handleInputChange(index, 'closing_time',data)
                       
                  }}/>
                </div>
                 
               </>
            })}

         

             <div className='label_2' style={{cursor:'pointer'}}>
                  
                 {isEdit &&  <Typography className='edit' onClick={()=>{
                  setBranchForm(true)
                  setBranchEdit(false)
                  setBranchDetails({
                    name:'',
                    details:'',
                    addr_line_1:'',
                    addr_line_2:'',
                    addr_line_3:'',
                    details:'',
                    opening_time:'',
                    closing_time:''
                   })
                  }}>+ Add Another Branch</Typography>} 
              </div>
              {/* <div className='clocks'>
              <Typography className='promotion'>Vouchers & promotion</Typography>
             </div> */}
              {/* <Stack sx={{mt:1.5}} style={{display:'flex',alignItems:'center',flexDirection:'row',justifyContent:"space-between"}} >
                <TextField id="outlined-basic" type='number' disabled={!isEdit && true} className='form_fields' variant="outlined" size="small" value={voucherDetails?.discount} onChange={(e)=>setVoucherDetails({...voucherDetails,discount:e.nativeEvent.target.value})}  placeholder={"0"} style={{width:"80%"}}/>
                <Typography style={{width:"15%",color:"#959595",fontWeight:'400',fontSize:'18px'}}>% Off</Typography>
             </Stack> */}
             {/* <div style={{marginTop:"10px"}}>
             <AppDropDown className="inputField" addExtra={true}  data={category}  placeholder={"Category"} collection={'category'}/>
             </div> */}
             {/* <Stack sx={{mt:1.5}} style={{display:'flex',alignItems:'flex-end',flexDirection:'column',justifyContent:"flex-end"}} >
                <TextField id="outlined-basic" disabled={!isEdit && true} className='form_fields' variant="outlined" size="small" value={voucherDetails?.promo_code} onChange={(e)=>setVoucherDetails({...voucherDetails,promo_code:e.nativeEvent.target.value})}  placeholder={"promo code"} style={{width:"100%"}}/>
             </Stack>
             <Stack sx={{mt:1.5}} style={{display:'flex',alignItems:'flex-end',flexDirection:'column',justifyContent:"flex-end"}} >
                <TextField id="outlined-basic" type='number' disabled={!isEdit && true} className='form_fields' variant="outlined" size="small" value={voucherDetails?.purchase_limit} onChange={(e)=>setVoucherDetails({...voucherDetails,purchase_limit:e.nativeEvent.target.value})}   placeholder={"purchase limit"} style={{width:"100%"}}/>
             </Stack>
             <Stack sx={{mt:1.5}} style={{display:'flex',alignItems:'flex-end',flexDirection:'column',justifyContent:"flex-end"}} >
                <TextField id="outlined-basic" className='form_fields' disabled={!isEdit && true} type='number' variant="outlined" size="small" value={voucherDetails?.usage_limit} onChange={(e)=>setVoucherDetails({...voucherDetails,usage_limit:e.nativeEvent.target.value})}   placeholder={"usage limit"} style={{width:"100%"}}/>
             </Stack>
             <Stack sx={{mt:1.5}} style={{width:'100%'}} >
                 <AppDatePicker label={"Effective from"} disabled={!isEdit && true} views={['year', 'day', 'hours', 'minutes']}  onSelectedTime={(data)=>{
                        console.log("AppDatePicker console",data)
          
                        setVoucherDetails({...voucherDetails,eff_from:data})
                  }}/>
             </Stack>
             <Stack sx={{mt:1.5}} style={{width:'100%'}} >
                 <AppDatePicker label={"Effective from"} disabled={!isEdit && true} views={['year', 'day', 'hours', 'minutes']}  onSelectedTime={(data)=>{
                        console.log("AppDatePicker console",data)
                        setVoucherDetails({...voucherDetails,eff_to:data})
                  }}/>
             </Stack>
              */}
             {/* <Stack sx={{mt:1.5}} style={{display:'flex',alignItems:'flex-end',flexDirection:'column',justifyContent:"flex-end"}} >
                <TextField id="outlined-basic" value={voucherDetails?.details} onChange={(e)=>setVoucherDetails({...voucherDetails,details:e.nativeEvent.target.value})} disabled={!isEdit && true} className='form_fields' variant="outlined" size="small"  placeholder={"Details"} style={{width:"100%",height:"200px"}}/>
                <Typography className='edit' style={{marginTop:'3px',color:"#C0C0C0",fontSize:'10px',fontWeight:"500"}}>0/1500</Typography>
             </Stack>
             <Button variant="outlined" onClick={()=>addVoucher()} style={{marginBottom:"10px",color:"#F0522B",marginTop:'5px',marginBottom:"20px"}} ><b>Add Voucher</b></Button> 

              {vouchers.length > 0 && <div className='clocks'>
                  <Typography className='promotion'>Preview</Typography>
              </div>} */}
             

              {/* {vouchers.map((item)=>{
                return <>
                    <div className='preview'>
                      <div style={{display:'flex',alignItems:'center',justifyContent:'flex-end'}}>
                      <EditIcon
                      onClick={()=>{
                        setVoucherDetails({
                          id:item?.id,
                          discount:item?.discount,
                          promo_code:item?.promo_code,
                          eff_from:item?.eff_from,
                          eff_to:item?.eff_to,
                          // menu:"",
                          purchase_limit:item?.purchase_limit,
                          usage_limit:item?.usage_limit,
                          details:item?.details
                        })
                        setVoucherEdit(true)

                      }}
                      style={{ color: "#F0522B", fontSize: "23px" }}
                      />
                      <DeleteOutlineIcon
                        onClick={()=>removeVoucher(item?.id)}
                        style={{ color: "#F0522B", fontSize: "23px" }}
                        />
                      </div>
                      <Typography className='off'>{item?.discount}% OFF</Typography>
                      <div className='header'>
                        <Typography style={{color:"#F0522B"}}>Code: {item?.promo_code}</Typography>
                        <Typography>Download</Typography>
                      </div>
                      <div className='listing'>
                        <div><Typography>{convertData(item.eff_from)} - {convertData(item.eff_to)}</Typography></div>
                        <div><Typography>Voucher usage limit - {item.usage_limit}</Typography></div>
                        <div><Typography>Applicable for {item?.menu?.name} menu</Typography></div>
                        {item?.purchase_limit && 
                        <div><Typography>Combinations: Get {item?.discount}% off when you spend over ${item?.purchase_limit} or get {item?.discount}% off when you spend over ${item?.purchase_limit}.</Typography></div>}
                       
                      </div>
                    </div>
                </>
              })}
            */}

             <Stack className="btn_action">
              {/* <Button variant="outlined" style={{marginBottom:"10px",color:"#F0522B",marginTop:'30px'}} ><b>Generate Code</b></Button> */}
              <Button variant="contained save_btn"   onClick={async()=>{
              //  await savePageSetting()
              console.log("effFromErr",promotion.eff_from)
              console.log("effToErr",promotion.eff_to)
              const effTo = new Date(promotion.eff_to);
              const effFrom = new Date(promotion.eff_from);
              console.log("compare",effFrom ,effTo)
              console.log("compare",effTo < effFrom)
              try{
                setIsLoading(true)
                //!promotion.eff_from || moment(data).isAfter(promotion.eff_from)
                //check effective from and effective to
                
                
                
                if(promotion.eff_from !=="Invalid Date" && promotion.eff_to !=="Invalid Date" && effTo < effFrom){
                  setSnackConfig({
                    message:"Effective from date must be earlier than Effective to date",
                    type:'error',
                    show:true
                  })
                  setIsLoading(false)
                  return
                }
                else if(effFromErr){
                  setSnackConfig({
                    message:"Effective from date must be earlier than Effective to date.x",
                    type:'error',
                    show:true
                  })
                  setIsLoading(false)
                  return
                }else if(effToErr  ){
                  setSnackConfig({
                    message:"Effective from date must be earlier than Effective to date.",
                    type:'error',
                    show:true
                  })
                  setIsLoading(false)
                  return
                }
                else if(errTimeOpen){
                  setSnackConfig({
                    message:"Opening time must be earlier than closing time.",
                    type:'error',
                    show:true
                  })
                  setIsLoading(false)
                  return
                }else if(errTimeClose){
                  setSnackConfig({
                    message:"Opening time must be earlier than closing time.",
                    type:'error',
                    show:true
                  })
                  setIsLoading(false)
                  return
                }else if(branchCloseErr){
                  setSnackConfig({
                    message:"Opening time must be earlier than opening to.",
                    type:'error',
                    show:true
                  })
                  setIsLoading(false)
                  return
                }else if(branchOpenErr){
                  setSnackConfig({
                      message:"Opening time must be earlier than opening to.",
                      type:'error',
                      show:true
                    })
                    setIsLoading(false)
                    return
                }else{
                  console.log("else")
                      let pageSettingNewImage 
                      if(selectedFile){
                        const fileFormData = new FormData();
                        fileFormData.append("folder",restaurantData.resDetails.folder_id);
                        fileFormData.append("file", selectedFile);
                        const detailImage =  await ApiCall({
                            apiEndpoint: "/files",
                            method: ApiMethod.POST,
                            apiContentType: "multipart/form-data",
                            token:restaurantData.userAccess.access_token,
                            apiData: fileFormData,
                        })
                        console.log("thisss is hte promoooo imageeee",detailImage.data.data)
                        pageSettingNewImage = detailImage.data.data.id
                      }
          
                      let params
                      if(pageSettingNewImage){
                          params = {
                            image:pageSettingNewImage,
                            opening_time:pageSetting?.opening_time,
                            closing_time:pageSetting?.closing_time,
                            details:pageSetting?.details
                          }
                      }else{
                          params = {
                            opening_time:pageSetting?.opening_time,
                            closing_time:pageSetting?.closing_time,
                            details:pageSetting?.details
                          }
                      }
          
                      const saveSetting = await ApiCall({
                        apiEndpoint:`/items/page_setting/${originalDetails?.page_setting?.id}`,
                        method:ApiMethod.PATCH,
                        // token:restaurantData.userAccess.access_token,
                        service:true,
                        apiData:{
                          ...params
                        }
          
                      })
          
                      //const save promotion image 
                      let promoImageNew
                      if(promoDealImage){
                        const fileFormData = new FormData();
                        fileFormData.append("folder",restaurantData.resDetails.folder_id);
                        fileFormData.append("file", promoDealImage);
                        const promoImage =  await ApiCall({
                            apiEndpoint: "/files",
                            method: ApiMethod.POST,
                            apiContentType: "multipart/form-data",
                            token:restaurantData.userAccess.access_token,
                            apiData: fileFormData,
                        })
                        console.warn("thisss is hte promoooo imageeee",promoImage.data.data)
                        promoImageNew = promoImage.data.data.id
                      }
                      
                      let paramsPromotion;
                      if(promoImageNew){
                        paramsPromotion = { 
                            image:promoImageNew,
                            discount:Number(promotion?.discount),
                            restaurant_name:promotion?.restaurant_name,
                            details:promotion?.details,
                            eff_from:promotion?.eff_from !=="Invalid Date"?promotion.eff_from:null,
                            eff_to:promotion?.eff_to !=="Invalid Date"?promotion.eff_to:null,
                            page_setting:originalDetails?.page_setting?.id
                          }
                      }else{
                        paramsPromotion = {
                          discount:Number(promotion?.discount),
                          restaurant_name:promotion?.restaurant_name,
                          details:promotion?.details,
                          eff_from:promotion?.eff_from!=="Invalid Date"?promotion.eff_from:null,
                          eff_to:promotion?.eff_to !=="Invalid Date"?promotion.eff_to:null,
                          page_setting:originalDetails?.page_setting?.id
                        }
                      }
                      //loop promotion deals..prep if ever mag multiple (pero single lang man siya for now)
                     console.log("paramsPromotion",paramsPromotion)
                      if(originalDetails?.page_setting?.promotion_deals.length > 0 ){
                        //console.log("hahhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhh")
                        //existing promo
                                for(const data of originalDetails?.page_setting?.promotion_deals){
                                  const promotion_deals = await ApiCall({
                                  apiEndpoint:`/items/promotion_deals/${data?.id}`,
                                  method:ApiMethod.PATCH,
                                  // token:restaurantData.userAccess.access_token,
                                  service:true,
                                  apiData:{
                                    ...paramsPromotion
                                  }
                                })
                            }
                      }else{
                        //console.log("diriiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiii",paramsPromotion)
                            const promotion_deals = await ApiCall({
                              apiEndpoint:`/items/promotion_deals`,
                              method:ApiMethod.POST,
                              // token:restaurantData.userAccess.access_token,
                              service:true,
                              apiData:{
                                ...paramsPromotion
                              }
                            })
                      }
                    
                      
                      //branches loop check sa array if ga exist then patch else add?
                      //mean state is empty remove all 
                      if(branches.length === 0){
                        try {
                          for(const item of originalDetails?.page_setting?.branches){
                            const removeVoucher = await ApiCall({
                              apiEndpoint: `/items/Vouchers/${item.id}`,
                              method: ApiMethod.DELETE,
                              service:true
                              // Additional parameters if needed
                            });
                          }
                        } catch (error) {
                          console.log("delete voucher",error)
                        }
                        
                    }
                   
                      for (const data of branches) {
                        const existingBranch = originalDetails?.page_setting?.branches.find(info => info.id === data.id);
          
                        const branchToRemove = originalDetails?.page_setting?.branches.filter(
                          existingBranch => !branches.some(data => data.id === existingBranch.id)
                        );
          
                        //mean existing sa response na gin remove na sa state
                        // for(const item of branchToRemove){
                        //   const removeVoucher = await ApiCall({
                        //     apiEndpoint: `/items/branches/${item.id}`,
                        //     method: ApiMethod.DELETE,
                        //     service:true
                        //     // Additional parameters if needed
                        //   });
                        // }
                        for(const item of branchToRemove){
                          const removeVoucher = await ApiCall({
                            apiEndpoint: `/items/branches/${item.id}`,
                            method: ApiMethod.PATCH,
                            service:true,
                            apiData:{
                              status:'archived'
                            }
                            // Additional parameters if needed
                          });
                        }
                      
                        console.log("thisss is the voucherrss to removeeeeee",branchToRemove)
                        console.log("thisss is the exisstingg voucherrrr",existingBranch)
                      
                        if (existingBranch) {
                          // If the branch exists, update it
                           const opening = new Date(data?.opening_time);
                           const closing = new Date(data?.closing_time);
                          if(!data.addr_line_1){
                            setSnackConfig({
                              message:`Please fill address 1 of ${data.name}` ,
                              type:'error',
                              show:true
                            })
                            return setIsLoading(false)
                          }else if(!data.addr_line_2){
                            setSnackConfig({
                              message:`Please fill address 2 of ${data.name}`,
                              type:'error',
                              show:true
                            })
                            return setIsLoading(false)
                          }else if(!data.addr_line_3){
                            setSnackConfig({
                              message:`Please fill address 2 of ${data.name}`,
                              type:'error',
                              show:true
                            })
                            return setIsLoading(false)
                          }else if (!data.opening_time) {
                            setSnackConfig({
                              message:`Please fill opening time of ${data.name}`,
                              type:'error',
                              show:true
                            })
                            return setIsLoading(false)
                          }else if (!data.closing_time) {
                            setSnackConfig({
                              message:`Please fill closing time of ${data.name}`,
                              type:'error',
                              show:true
                            })
                            return setIsLoading(false)
                          }else if (closing <= opening) {
                            setSnackConfig({
                              message:`Closing time cannot be earlier than or equal to opening time of ${data.name}.`,
                              type:'error',
                              show:true
                            })
                            return setIsLoading(false)
                          } else if (opening >= closing) {
                            setSnackConfig({
                              message:`Opening time cannot be later than or equal to closing time of ${data.name}.`,
                              type:'error',
                              show:true
                            })
                            return setIsLoading(false)
                          }else{
                            const branchUpdate = await ApiCall({
                              apiEndpoint:`/items/branches/${existingBranch?.id}`,
                              method:ApiMethod.PATCH,
                              // token:restaurantData.userAccess.access_token,
                              service:true,
                              apiData:{
                                name:data?.name,
                                details:data?.details,
                                addr_line_1:data?.addr_line_1,
                                addr_line_2:data?.addr_line_2,
                                addr_line_3:data?.addr_line_3,
                                opening_time:data?.opening_time,
                                closing_time:data?.closing_time
                              }
                            })
                          }
                          
                          // Perform the update logic here
                        } else {
                          // If the branch doesn't exist, add it
                          console.log(`Add new branch with ID ${data.id}`);
                          const opening = new Date(data?.opening_time);
                          const closing = new Date(data?.closing_time);
                         if(!data.addr_line_1){
                           setSnackConfig({
                             message:`Please fill address 1 of ${data.name}` ,
                             type:'error',
                             show:true
                           })
                           return setIsLoading(false)
                         }else if(!data.addr_line_2){
                           setSnackConfig({
                             message:`Please fill address 2 of ${data.name}`,
                             type:'error',
                             show:true
                           })
                           return setIsLoading(false)
                         }else if(!data.addr_line_3){
                           setSnackConfig({
                             message:`Please fill address 2 of ${data.name}`,
                             type:'error',
                             show:true
                           })
                           return setIsLoading(false)
                         }else if (!data.opening_time) {
                           setSnackConfig({
                             message:`Please fill opening time of ${data.name}`,
                             type:'error',
                             show:true
                           })
                           return setIsLoading(false)
                         }else if (!data.closing_time) {
                           setSnackConfig({
                             message:`Please fill closing time of ${data.name}`,
                             type:'error',
                             show:true
                           })
                           return setIsLoading(false)
                         }else if (closing <= opening) {
                           setSnackConfig({
                             message:`Closing time cannot be earlier than or equal to opening time of ${data.name}.`,
                             type:'error',
                             show:true
                           })
                           return setIsLoading(false)
                         } else if (opening >= closing) {
                           setSnackConfig({
                             message:`Opening time cannot be later than or equal to closing time of ${data.name}.`,
                             type:'error',
                             show:true
                           })
                           return setIsLoading(false)
                         }else{
                          const branchInsert = await ApiCall({
                            apiEndpoint:`/items/branches`,
                            method:ApiMethod.POST,
                            // token:restaurantData.userAccess.access_token,
                            service:true,
                            apiData:{
                              name:data?.name,
                              details:data?.details,
                              addr_line_1:data?.addr_line_1,
                              addr_line_2:data?.addr_line_2,
                              addr_line_3:data?.addr_line_3,
                              page_setting:originalDetails?.page_setting?.id,
                              opening_time:data?.opening_time,
                              closing_time:data?.closing_time
                            }
                          })
                         }
                          
                        }
                      }
                      
                    
                      //promotions same logic sa branches
                      //mean state is empty remove all 
                      if(vouchers.length === 0){
                          for(const item of originalDetails?.page_setting?.vouchers){
                            const removeVoucher = await ApiCall({
                              apiEndpoint: `/items/Vouchers/${item.id}`,
                              method: ApiMethod.DELETE,
                              service:true
                              // Additional parameters if needed
                            });
                          }
                      }
                  
                      for (const data of vouchers) {
                        const existingVoucher = originalDetails?.page_setting?.vouchers.find(info => info.id === data.id);
          
                        const  VoucherToRemove = originalDetails?.page_setting?.vouchers.filter(
                          existingVoucher => !vouchers.some(data => data.id === existingVoucher.id)
                        );
                        //mean existing sa response na gin remove na sa state
                        for(const item of VoucherToRemove){
                          const removeVoucher = await ApiCall({
                            apiEndpoint: `/items/Vouchers/${item.id}`,
                            method: ApiMethod.DELETE,
                            service:true
                            // Additional parameters if needed
                          });
                        }
                        // console.log("____________________voucherDetails",voucherDetails)
                        if (existingVoucher) {
                          // If the branch exists, update it
                          const voucherUpdate = await ApiCall({
                            apiEndpoint:`/items/Vouchers/${existingVoucher?.id}`,
                            method:ApiMethod.PATCH,
                            // token:restaurantData.userAccess.access_token,
                            service:true,
                            apiData:{
                              discount:Number(data?.discount),
                              promo_code:data?.promo_code,
                              eff_from:data?.eff_from,
                              eff_to:data?.eff_to,
                              // menu:null,
                              purchase_limit:Number(data?.purchase_limit),
                              usage_limit:Number(data?.usage_limit)
                            }
                          })
                          // Perform the update logic here
                        } else {
                          // If the branch doesn't exist, add it
                          console.log(`Add new branch with ID ${data.id}`);
                          const voucherInsert = await ApiCall({
                            apiEndpoint:`/items/Vouchers`,
                            method:ApiMethod.POST,
                            // token:restaurantData.userAccess.access_token,
                            service:true,
                            apiData:{
                              discount:Number(data?.discount),
                              promo_code:data?.promo_code,
                              eff_from:data?.eff_from,
                              eff_to:data?.eff_to,
                              // menu:null,
                              purchase_limit:Number(data?.purchase_limit),
                              usage_limit:Number(data?.usage_limit),
                              page_setting:originalDetails?.page_setting?.id
                            }
                          })
                        }
                      }
                      // console.log("test lang")
                      // //after done..then refetch lang ang details
                      await getRestaurantDetails()
                      // setIsEdit(false)
                      setIsLoading(false)
                   
                }
              }catch(err){
                setIsLoading(false)
                console.log("savePageSetting err",err)
              }
          
              await updateResDetails()
                }} disabled={!isEdit && true} style={{outline:"none",border:'none',marginTop:'30px',marginBottom:"15px"}}><b>Update Restaurant Details</b></Button>
           
            </Stack>
            </div>
          </div>
      </Card>

      <AppModal
        visible={showBranchForm}
        handleClose={()=>setBranchForm(false)}
      >
        <Stack sx={{mt:3}}>
         <div style={{display:'flex',justifyContent:"flex-end"}}>
            <CloseIcon onClick={()=>setBranchForm(false)} style={{cursor:'pointer'}}/>
         </div>
          <Typography>Branch Details</Typography>
           <Stack sx={{mt:1.5}} >
                 <TextField id="outlined-basic" style={isEdit ? {backgroundColor:"white",border:"1px solid gray"} : {}} className='form_fields' variant="outlined" size="small"   placeholder={"Branch Name"}  value={branchDetails?.name}   onChange={(e)=>setBranchDetails({...branchDetails,name:e.nativeEvent.target.value})}/>
            </Stack>
            <Stack sx={{mt:1.5}} >
                <TextField id="outlined-basic" style={isEdit ? {backgroundColor:"white",border:"1px solid gray"} : {}} className='form_fields' variant="outlined" size="small"   placeholder={"Address Line 1"}  value={branchDetails?.addr_line_1}   onChange={(e)=>setBranchDetails({...branchDetails,addr_line_1:e.nativeEvent.target.value})}/>
            </Stack>
            <Stack sx={{mt:1.5}} >
                <TextField id="outlined-basic" style={isEdit ? {backgroundColor:"white",border:"1px solid gray"} : {}} className='form_fields' variant="outlined" size="small"  placeholder={"Address Line 2"}   value={branchDetails?.addr_line_2}   onChange={(e)=>setBranchDetails({...branchDetails,addr_line_2:e.nativeEvent.target.value})}/>
            </Stack>
            <Stack sx={{mt:1.5}} >
                <TextField id="outlined-basic" style={isEdit ? {backgroundColor:"white",border:"1px solid gray"} : {}} className='form_fields' variant="outlined" size="small"  placeholder={"Address Line 3"}   value={branchDetails?.addr_line_3}   onChange={(e)=>setBranchDetails({...branchDetails,addr_line_3:e.nativeEvent.target.value})}/>
            </Stack>
            <Stack sx={{mt:1.5}} style={{display:'flex',alignItems:'flex-end',flexDirection:'column',justifyContent:"flex-end"}} >
                <TextField id="outlined-basic"  className='form_fields' variant="outlined" size="small"  value={branchDetails?.details} onChange={(e)=>setBranchDetails({...branchDetails,details:e.nativeEvent.target.value})}  placeholder={"Details"} multiline rows={4} style={isEdit ? {backgroundColor:"white",border:"1px solid gray",width:"100%"} : {}}/> 
                <Typography className='edit' style={{marginTop:'3px',color:"#C0C0C0",fontSize:'10px',fontWeight:"500"}}>{branchDetails?.details?.length}/1500</Typography>
             </Stack>
             <div style={{marginTop:"10px"}}>
                 <AppDatePicker label={"Opening from"}  isTimeOnly={true}  views={['year', 'day', 'hours', 'minutes']} defaultDate={branchDetails?.opening_time} onSelectedTime={(data)=>{
                        console.log("AppDatePicker console",data)
                        handleEffectiveFromChange(data,false,true)
                        setBranchDetails({...branchDetails,opening_time:data})
                  }}/>
                   {branchOpenErr && <Typography style={{color:'red'}}>Invalid Time.</Typography>}  
               </div>
               
                <div style={{marginTop:"10px"}}>
                <AppDatePicker label={"Opening to"} isTimeOnly={true}  views={['year', 'day', 'hours', 'minutes']} defaultDate={branchDetails?.closing_time} onSelectedTime={(data)=>{
                        console.log("AppDatePicker console",data)
                        handleEffectiveToChange(data,false,true)
                        setBranchDetails({...branchDetails,closing_time:data})
                       
                  }}/>
                   {branchCloseErr && <Typography style={{color:'red'}}>Invalid Time.</Typography>}  
                </div>

             <Button variant="contained" onClick={()=>{
              branchEdit ? updateBranch() : addBranch()
             } }  style={{backgroundColor:"#F0522B",textTransform:'capitalize',padding:'10px',boxShadow: 'none',marginTop:'10px',width:'100%'}} className="btn-custom_order"><b>{branchEdit ? "Update Branch" : "Add Branch"} </b></Button>
        </Stack>


      </AppModal>

        <AppModal
          visible={voucherEdit}
          handleClose={()=>setVoucherEdit(false)} 
        >
             <>
             <div style={{display:'flex',justifyContent:"flex-end"}}>
              <CloseIcon onClick={()=>setVoucherEdit(false)}/>
            </div>
             <div className='clocks'>
              <Typography className='promotion'>Vouchers & promotion</Typography>
             </div>
              <Stack sx={{mt:1.5}} style={{display:'flex',alignItems:'center',flexDirection:'row',justifyContent:"space-between"}} >
                <TextField id="outlined-basic" type='number' disabled={!isEdit && true} className='form_fields' variant="outlined" size="small" value={voucherDetails?.discount} onChange={(e)=>setVoucherDetails({...voucherDetails,discount:e.nativeEvent.target.value})}  placeholder={"0"} style={{width:"80%"}}/>
                <Typography style={{width:"15%",color:"#959595",fontWeight:'400',fontSize:'18px'}}>% Off</Typography>
             </Stack>
             {/* <div style={{marginTop:"10px"}}>
             <AppDropDown className="inputField" addExtra={true}  data={category}  placeholder={"Category"} collection={'category'}/>
             </div> */}
             <Stack sx={{mt:1.5}} style={{display:'flex',alignItems:'flex-end',flexDirection:'column',justifyContent:"flex-end"}} >
                <TextField id="outlined-basic" disabled={!isEdit && true} className='form_fields' variant="outlined" size="small" value={voucherDetails?.promo_code} onChange={(e)=>setVoucherDetails({...voucherDetails,promo_code:e.nativeEvent.target.value})}  placeholder={"promo code"} style={{width:"100%"}}/>
             </Stack>
             <Stack sx={{mt:1.5}} style={{display:'flex',alignItems:'flex-end',flexDirection:'column',justifyContent:"flex-end"}} >
                <TextField id="outlined-basic" type='number' disabled={!isEdit && true} className='form_fields' variant="outlined" size="small" value={voucherDetails?.purchase_limit} onChange={(e)=>setVoucherDetails({...voucherDetails,purchase_limit:e.nativeEvent.target.value})}   placeholder={"purchase limit"} style={{width:"100%"}}/>
             </Stack>
             <Stack sx={{mt:1.5}} style={{display:'flex',alignItems:'flex-end',flexDirection:'column',justifyContent:"flex-end"}} >
                <TextField id="outlined-basic" className='form_fields' disabled={!isEdit && true} type='number' variant="outlined" size="small" value={voucherDetails?.usage_limit} onChange={(e)=>setVoucherDetails({...voucherDetails,usage_limit:e.nativeEvent.target.value})}   placeholder={"usage limit"} style={{width:"100%"}}/>
             </Stack>
             <Stack sx={{mt:1.5}} style={{width:'100%'}} >
                 <AppDatePicker label={"Effective from"} disabled={!isEdit && true} views={['year', 'day', 'hours', 'minutes']}  onSelectedTime={(data)=>{
                        console.log("AppDatePicker console",data)
          
                        setVoucherDetails({...voucherDetails,eff_from:data})
                  }}/>
             </Stack>
             <Stack sx={{mt:1.5}} style={{width:'100%'}} >
                 <AppDatePicker label={"Effective from"} disabled={!isEdit && true} views={['year', 'day', 'hours', 'minutes']}  onSelectedTime={(data)=>{
                        console.log("AppDatePicker console",data)
                        setVoucherDetails({...voucherDetails,eff_to:data})
                  }}/>
             </Stack>
             
             <Stack sx={{mt:1.5}} style={{display:'flex',alignItems:'flex-end',flexDirection:'column',justifyContent:"flex-end"}} >
                <TextField id="outlined-basic" value={voucherDetails?.details} onChange={(e)=>setVoucherDetails({...voucherDetails,details:e.nativeEvent.target.value})} disabled={!isEdit && true} className='form_fields' variant="outlined" size="small"  placeholder={"Details"} style={{width:"100%",height:"200px"}}/>
                <Typography className='edit' style={{marginTop:'3px',color:"#C0C0C0",fontSize:'10px',fontWeight:"500"}}>0/1500</Typography>
             </Stack>
             <Button variant="outlined" onClick={()=>updateVoucher()} style={{marginBottom:"10px",color:"#F0522B",marginTop:'10px'}} ><b>Update Voucher</b></Button> 
             </>
      </AppModal>
      {isLoading && <AppLoader/>}
      <AppSnackBar snackConfig={snackConfig}/>
    </div>
   </section>
  )
}