import * as React from "react";
import "./styles.scss";
import { useNavigate } from "react-router-dom";
export default function CustomerTestPage() {
  const navigate = useNavigate();
  return (
   <section id="">
    <div className="my_container">
    <h1 onClick={()=> navigate("/")}>CustomerTestPage</h1>
    </div>
   </section>
  )
}