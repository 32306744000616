import * as React from "react";
import "./styles.scss";
import { useNavigate } from "react-router-dom";
import { AppTabBar,AppLoader, AppSnackBar } from "../../../components";
import { Button, Typography } from "@mui/material";
import TextareaAutosize from "react-textarea-autosize";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import imagePrev from "../../../assets/images/prev.png";
import { appTheme } from "../../../constants";
import { ApiCall ,ApiMethod} from "../../../services/AxiosInstance";
import { useParams } from 'react-router-dom';
import { getCartData, getImageUpdload } from "../../../utilities";
import { useSelector,useDispatch } from 'react-redux';

export default function FoodPreview({route}) {
  const table_id = useSelector((state)=>state.user.table_id.table_id)
  const table_name = useSelector((state)=>state.user.table_id.name)
  const order_id = useSelector((state)=>state.user.order_id)
  const branch_id =  useSelector((state)=>state.user.branch_id)


  const list_order_id = useSelector((state)=>state.user.list_order_id)
  const [menuDetails,setMenuDetails] = React.useState()
  const [selectedSubmenu,setSelectedSubmenu] = React.useState([])
  const [loader,setLoader] = React.useState(false)
  const [notes,setNotes] = React.useState('')
  const [quantity,setQuantity] = React.useState(1)
  const [cartData,setCartData] = React.useState()
  const [menuData,setMenuData] = React.useState()
  const [pendingOrders,setPendingOrders] = React.useState([])
  const params = useParams();
  const [selectedAddons,setSelectedAddons] = React.useState([])
  const [defaultAO,setDefaultAO] = React.useState([])
  const menu_id = params?.menu_id
 
  React.useEffect(()=>{
     checkPendingOrders()
     if(menu_id){
      getDetail(menu_id)
     }else{
      
      const parseData =  JSON.parse(decodeURIComponent(params?.menu_data));
      setMenuData(parseData)
      if(parseData.add_ons.length > 0){
    
        const uniqueIds = new Set();

        for (const data of parseData.add_ons) {
          const item = {...data?.menu_submenu,quantity:data?.quantity}
          uniqueIds.add(item);
        }
        // setDefaultAO([...uniqueIds])
        const uniqueArray = [...uniqueIds];
        // console.log("thiss sis the parseData..addoneee",uniqueArray)
        setDefaultAO(parseData.add_ons)
        setSelectedAddons(uniqueArray);
      }

      getDetail(parseData?.menu?.id) 
   
      setQuantity(parseData?.quantity)
      setNotes(parseData?.note)
      // setMenuDetails(existingData)
     }

  },[params])

  const [snackConfig,setSnackConfig]= React.useState({
    message:null,
    type:"success",//success,error
    show:false
  })
  
  
 const checkPendingOrders = async()=>{
  try{

    const seperated_by_comma_id = list_order_id.join(',');

    console.log("thiss is the seperate_by_comma",seperated_by_comma_id)

    const checkpending = await ApiCall({
        apiEndpoint:`/items/orders?fields=*,items.*,items.cart_items.add_ons.sub_menu.*&filter[order_id][_in]=${seperated_by_comma_id}&filter[table_id][_eq]=${table_id}&filter[status][_eq]=draft`,
        method:ApiMethod.GET,
        service:true
    })
    if(checkpending.data.data.length > 0){
        setPendingOrders(checkpending.data.data)
    }else{
        setPendingOrders()
    }
   

  }catch(err){
    console.log("checkPendingOrders err",err)
  }
}



  const getDetail = async(menu_id) =>{
    console.warn("thisss is the meenyuuuuuuuuuuuuuuuuuuuu id",menu_id)
      try{
        setLoader(true)
        const result =  await ApiCall({
          apiEndpoint:`/items/menus/${menu_id}?fields=*,sub_menus.*,menu_submenus.*,menu_submenus.sub_menu.*`,
          method:ApiMethod.GET,
          service:true
        })
        const response  = result.data.data
        response.image = getImageUpdload(response.image)
        response.image_id = response.image
 
        if(response.sub_menus.length > 0){
          for(const data of response.sub_menus){
              if(selectedAddons.length > 0){
                selectedAddons.map((item)=>{
                  if(item === data?.id ){
                        data.isSelected = true
                  }else{
                       data.isSelected = false
                  }
                })
              }
              data.isSelected = false
             
          }
        }      
        console.log("thisss is the responseee",response)
        setMenuDetails(response)
        setLoader(false)
      }catch(err){
        setLoader(false)
        console.log("err get detail",err)
      }
  }

  const navigate = useNavigate();
  const themeCurrent = useSelector((state)=>state.user.currentTheme)
  const app_theme = appTheme(themeCurrent)
  const [orderAdded,setOrderAdded] = React.useState(false)



  const addToCart = async() =>{
     try{
     
      setLoader(true)
      const check_existing = await getCartData(ApiCall,ApiMethod,order_id.order_id,table_id)
      //  return
      if(check_existing?.id){
        //if cart exist insert the item
        const filterExistingItems =  check_existing.items.filter((item)=>{
          return item.menu.id ===  menuDetails?.id
        })

        if(filterExistingItems.length > 0){
            const patchCartItem = await ApiCall({
              apiEndpoint:`/items/cart_items/${filterExistingItems[0]?.id}`,
              method:ApiMethod.PATCH,
              service:true,
              apiData:{
                  quantity:Number(filterExistingItems[0]?.quantity) + quantity, //total of quantity of exiting itemcart increase
              }
            })

            
            if(filterExistingItems[0]?.add_ons.length > 0){
              
              const existingAddonsIds = filterExistingItems[0]?.add_ons.map(addon => addon.menu_submenu.id);

              for (const data of selectedAddons) {
                if (existingAddonsIds.includes(data.id)) {
                    // Patch the existing addon
                    const existingAddon = filterExistingItems[0]?.add_ons.find(addon => addon.menu_submenu.id === data.id);
                  
                    const updatedAddon = {
                        ...existingAddon,
                        quantity: existingAddon.quantity + data.quantity,
                        total_price: parseFloat(existingAddon.total_price) + parseFloat(data.price) * data.quantity
                    };
                    
                    await ApiCall({
                        apiEndpoint: `/items/menu_add_ons/${existingAddon.id}`,
                        method: ApiMethod.PATCH,
                        service: true,
                        apiData: updatedAddon
                    });
                } else {
                    // Add the new addon
                    console.log("no chancee to enterrr?")
                    const filterOriginalData = menuDetails?.menu_submenus.filter(item => item.id === data.id);
                    const price = Number(data?.price);
                    const saveSubmenu = await ApiCall({
                        apiEndpoint: `/items/menu_add_ons`,
                        method: ApiMethod.POST,
                        service: true,
                        apiData: {
                            cart_items: filterExistingItems[0]?.id,
                            menu_submenu: data.id,
                            quantity: data.quantity,
                            price: price,
                            total_price: parseFloat(data.price) * data.quantity,
                            description: filterOriginalData[0]?.sub_menu?.name
                        }
                    });
                }
            }
          
            }else{
              for(const data of selectedAddons){
                const filterOriginalData = menuDetails?.menu_submenus.filter(item => item.id === data.id);
                const price = Number(data?.price);
                const saveSubmenu = await ApiCall({
                    apiEndpoint: `/items/menu_add_ons`,
                    method: ApiMethod.POST,
                    service: true,
                    apiData: {
                        cart_items: filterExistingItems[0]?.id,
                        menu_submenu: data.id,
                        quantity: data.quantity,
                        price: price,
                        total_price: parseFloat(data.price) * data.quantity,
                        description: filterOriginalData[0]?.sub_menu?.name
                    }
                });
              }
            }

            const getItems = await getCartData(ApiCall,ApiMethod,order_id.order_id,table_id)
            let total_quantity= 0;
            let total_amount = 0;
            let add_ons_total = 0;
            for(const data of getItems.items){
                 //total the add ons here
                 if(data.add_ons.length > 0){
                      for(const item of data.add_ons){  //this should check in the add_ons directly since add ons of the menu
                           add_ons_total += parseFloat(item.total_price)
                      }
                 }
                  total_quantity += Number(data?.quantity) 
                  total_amount += Number(data?.quantity) * Number(data?.unit_price) 
            }
  
            console.log("thisss is the total amountt",total_amount)
            console.log("thiss is the total quantity",total_quantity)
           //patch cart here the clear the values
            const patchCart = await ApiCall({
              apiEndpoint:`/items/carts/${check_existing?.id}`,
              method:ApiMethod.PATCH,
              service:true,
              apiData:{
                  item_count:total_quantity, //total of quantity
                  item_total:total_amount + Number(add_ons_total),
                  amount_total:total_amount + Number(add_ons_total),
              }
            })
            console.log("success patch",patchCart.data.data)
            total_quantity = 0
            total_amount = 0
            add_ons_total = 0
            
        }
        
        
        
        
        
        
        else{
          const saveCartItems = await ApiCall({
            apiEndpoint:`/items/cart_items`,
            method:ApiMethod.POST,
            service:true,
            apiData:{
                cart:check_existing?.id, // id ni cart 
                restaurant:menuDetails?.restaurant, //id ni restaurant
                regular_price:Number(menuDetails?.price),
                quantity:quantity,
                unit_price:Number(menuDetails?.price),
                description:menuDetails?.description,
                menu:menuDetails?.id,
                note:notes,
                food_name:menuDetails?.name,
                stations:menuDetails?.stations
            }
          })
    
          const cart_item_id = saveCartItems.data.data.id
          console.log("thiss is the carttt item",cart_item_id)
    
          if(selectedAddons.length > 0){
                
                for(const item of defaultAO){
                  // console.log("thisss is the dfaulleeee defaultAO",defaultAO)
                  //after removing the existine re insert the new one
                    const getAllSumMenus =  await ApiCall({
                      apiEndpoint:`/items/menu_add_ons/${item.id}`,
                      method:ApiMethod.DELETE,
                      service:true
                    })
                }
             
                
                for(const data of selectedAddons){
                  const filterOriginalData = menuDetails?.menu_submenus.filter((item)=>item.id === data.id)
                  const price = Number(filterOriginalData[0]?.sub_menu.price) //price of the add ons related to the menu
                  const saveSubmenu = await ApiCall({
                    apiEndpoint:`/items/menu_add_ons`,
                    method:ApiMethod.POST,
                    service:true,
                    apiData:{
                      cart_items:cart_item_id,
                      menu_submenu:data.id,
                      quantity:data.quantity,
                      price:price,
                      total_price:parseFloat(price) * Number(data.quantity),
                      description:filterOriginalData[0]?.sub_menu?.name
                      // sub_menu:data
                    }
                  })
                }
          }
          //after added ang new item sa existing cart ..recalculate //need ta filter ang mga draft items lang
          const getItems = await getCartData(ApiCall,ApiMethod,order_id.order_id,table_id)
          let total_quantity= 0;
          let total_amount = 0;
          let add_ons_total = 0;
          for(const data of getItems.items){
                  if(data.add_ons.length > 0){
                    for(const item of data.add_ons){
                        add_ons_total += parseFloat(item?.total_price)
                    }
                 }
                total_quantity += Number(data?.quantity)
                total_amount += Number(data?.quantity) * Number(data?.unit_price) 
          }

          console.log("thisss is the total amountt",total_amount)
          console.log("thiss is the total quantity",total_quantity)
          //patch cart here the clear the values
          const patchCart = await ApiCall({
            apiEndpoint:`/items/carts/${check_existing?.id}`,
            method:ApiMethod.PATCH,
            service:true,
            apiData:{
                item_count:total_quantity, //total of quantity
                item_total:total_amount + Number(add_ons_total),
                amount_total:total_amount + Number(add_ons_total),
            }
          })
          console.log("success patch",patchCart.data.data)
          total_quantity = 0
          total_amount = 0
          add_ons_total = 0
        }
       
     
      
      }else{
        const saveCart = await ApiCall({
          apiEndpoint:`/items/carts`,
          method:ApiMethod.POST,
          service:true,
          apiData:{
              // customer:current_user, //id ni customer
              restaurant:menuDetails?.restaurant,
              order_id:order_id.order_id,
              table_id:table_id,
              item_count:quantity, //total of quantity
              item_total:Number(menuDetails?.price) * Number(quantity),
              amount_total:Number(menuDetails?.price) * Number(quantity),
              branch:branch_id
           }
         })
         const result_cart = saveCart.data.data
         const cart_id = saveCart.data.data.id
         
         const saveCartItems = await ApiCall({
          apiEndpoint:`/items/cart_items`,
          method:ApiMethod.POST,
          service:true,
          apiData:{
              cart:cart_id, // id ni cart 
              restaurant:menuDetails?.restaurant, //id ni restaurant
              regular_price:Number(menuDetails?.price),
              quantity:quantity,
              unit_price:Number(menuDetails?.price),
              description:menuDetails?.description,
              menu:menuDetails?.id,
              note:notes,
              food_name:menuDetails?.name,
              stations:menuDetails?.stations
           }
         })
  
         const cart_item_id = saveCartItems.data.data.id
         console.log("thiss is the carttt item",cart_item_id)
  
         if(selectedAddons.length > 0){
              let total_add_ons_price = 0
              for(const data of selectedAddons){
                const filterOriginalData = menuDetails?.menu_submenus.filter((item)=>item.id === data.id)
                const price = Number(data?.price)
                total_add_ons_price += price

                const saveSubmenu = await ApiCall({
                  apiEndpoint:`/items/menu_add_ons`,
                  method:ApiMethod.POST,
                  service:true,
                  apiData:{
                     cart_items:cart_item_id,
                     menu_submenu:data.id,
                     quantity:data.quantity,
                     price:price,
                     total_price:parseFloat(data.price) * data.quantity,
                     description:filterOriginalData[0]?.sub_menu?.name
                     ///SAVE TADI QUANTITY KAG PRICE GID

                    //  sub_menu:data
                   }
                 })
              }

              const patchCartItem = await ApiCall({
                apiEndpoint:`/items/carts/${cart_id}`,
                method:ApiMethod.PATCH,
                service:true,
                apiData:{
                   item_total:Number(result_cart?.item_total) + Number(total_add_ons_price),
                   amount_total:Number(result_cart?.amount_total) + Number(total_add_ons_price),
                }
             })
             total_add_ons_price = 0

         }
      }
      setLoader(false)
     }catch(err){
      setLoader(false)
       console.log("addToCart err",err)
     }

   }


  const updateOrder = async() =>{
      try{
        setLoader(true)
        const patchCartItem = await ApiCall({
            apiEndpoint:`/items/cart_items/${menuData?.id}`,
            method:ApiMethod.PATCH,
            service:true,
            apiData:{
                quantity:quantity,
                note:notes,
            }
        })

          //remove first existing add ons the enter new values
          const existing_add_ons = patchCartItem.data.data.add_ons
          for(const data of existing_add_ons){
            const remove_addons = await ApiCall({
              apiEndpoint:`/items/menu_add_ons/${data}`,
              method:ApiMethod.DELETE,
              service:true,
             })
          }


          for(const data of selectedAddons){
            const filterOriginalData = menuDetails?.menu_submenus.filter((item)=>item.id === data.id)
            const price = Number(filterOriginalData[0]?.sub_menu.price)

              const saveSubmenu = await ApiCall({
                apiEndpoint:`/items/menu_add_ons`,
                method:ApiMethod.POST,
                service:true,
                apiData:{
                  cart_items:menuData?.id,
                  menu_submenu:data.id,
                  quantity:data.quantity,
                  price:data.price,
                  total_price:Number(data.price) * data.quantity,
                  description:filterOriginalData[0]?.sub_menu?.name
                  // sub_menu:data
                }
              })
            
          }
      

        //patch man ang add ons
        const getItems = await getCartData(ApiCall,ApiMethod,order_id.order_id,table_id)
        // console.log("gettItemssssssssssss",getItems.items)
          let total_quantity= 0;
          let total_amount = 0;
          let add_ons_total = 0;
          for(const data of getItems.items){
                  if(data.add_ons.length > 0){
                    for(const item of data.add_ons){
                        add_ons_total += parseFloat(item?.total_price)
                    }
                 }
                total_quantity += Number(data?.quantity)
                total_amount += Number(data?.quantity) * Number(data?.unit_price) 
          }

          // console.log("thiss is thee taollltalsss",{
          //    total_quantity:total_quantity,
          //    total_amount:total_amount,
          //    add_ons_total:add_ons_total
          // })

        //patch cart here the clear the values
          const patchCart = await ApiCall({
            apiEndpoint:`/items/carts/${menuData?.cart_id}`,
            method:ApiMethod.PATCH,
            service:true,
            apiData:{
                item_count:total_quantity, //total of quantity
                item_total:total_amount + Number(add_ons_total),
                amount_total:total_amount + Number(add_ons_total),
                branch:branch_id
            }
          })
          total_quantity = 0
          total_amount = 0
          add_ons_total = 0

          setLoader(false)



      }catch(err){
        setLoader(false)
        console.log("updateOrder err",err)
      }
  } 
 

  return (
    <section id="">
      <div className="my_container2">
        <AppTabBar tabIndex={0} isPreview={true} />
      <div className="fp_indicator"></div>  

      {loader ?  <AppLoader/> :       <div className="fp_secondary_container">
      <div className="fp_main_container">
          <div style={{ display: "flex", alignItems: "center" }}>
            <KeyboardArrowLeftIcon
              style={{ marginRight: "3px" ,color:app_theme?.appBtn[2]?.textColor }}
            />
            <Typography className="back" style={{color:app_theme?.appBtn[2]?.textColor}} onClick={() => navigate(-1)}>
              Back
            </Typography>
          </div>
          <Typography className="payment_label" >{menuDetails?.name}</Typography>
          <div></div>
        </div>
        <div className="fp_container">
          <Typography className="best_seller_badge" style={{backgroundImage:`linear-gradient(to right, ${app_theme?.themeGradient?.badge?.primary} 50%,${app_theme?.themeGradient?.badge?.secondary})`}}>Best Seller</Typography>
          <Typography className="fp_price">${parseFloat(menuDetails?.price).toLocaleString()}</Typography>
          <img src={menuDetails?.image} />
          <div className="detail_container">
            <Typography className="first">
              <b>Details:</b> {menuDetails?.description}
            </Typography>
            <Typography className="first">
              <b>Preparation Time:</b> {menuDetails?.time_prep?.startTime} {menuDetails?.time_prep?.startTimeType?.label} - {menuDetails?.time_prep?.endTime} {menuDetails?.time_prep?.endTimeType?.label}
            </Typography>
            <Typography className="first">
              <b>Servings:</b> {menuDetails?.serving_count} Persons
            </Typography>
            <div className="add_ons">
              
            {menuDetails?.menu_submenus && menuDetails?.menu_submenus.map((data) => {
                  const isSelected = selectedAddons.some((addon) => addon.id === data?.id);
                  // console.log("thiss is the eee is selectedddddddddddd",data)
                  console.log("thisss is the selecteddd add onsss",selectedAddons)
                  const selectedAddon = selectedAddons.find((addon) => addon.id === data?.id);
                  const quantityAdd = selectedAddon ? selectedAddon.quantity : 0;

                  return (
                      <div style={{display:"flex",flexDirection:'column',marginBottom:"10px"}}>
                          <Button
                              variant="outlined"
                              onClick={() => {
                                  if (isSelected) {
                                      setSelectedAddons((prevSelectedAddons) =>
                                          prevSelectedAddons.filter((addon) => addon.id !== data?.id)
                                      );
                                  } else {
                                      setSelectedAddons((prevSelectedAddons) => [...prevSelectedAddons, {...data, quantity: 1}]);
                                  }
                              }}
                              style={{
                                  borderColor: app_theme?.fontColor,
                                  backgroundColor: isSelected ? app_theme?.fontColor : 'white',
                                  color: isSelected ? 'white' : app_theme?.fontColor,
                                  borderRadius: "8px",
                                  width: "100%",
                                  fontSize: "10px",
                                  fontWeight: "700",
                                  boxShadow: 'none'
                              }}
                          >
                              {data.sub_menu.description} - ${parseFloat(data.sub_menu.price)}
                          </Button>
                          {isSelected && (
                              <div style={{display:"flex",alignItems:"center",justifyContent:'center',marginTop:"-5px"}}>
                                  <div style={{display:"flex",alignItems:"center"}}>
                                      <div style={{padding:"5px"}}>
                                          {/* <RemoveCircleOutlineIcon style={{color:"#F0522B"}} onClick={()=>{
                                              const updatedSelectedAddons = selectedAddons.map((addon) => {
                                                  if (addon.id === data.id) {
                                                      if (addon.quantity > 1) {
                                                          return {...addon, quantity: addon.quantity - 1};
                                                      }
                                                  }
                                                  return addon;
                                              });
                                              setSelectedAddons(updatedSelectedAddons);
                                          }}/> */}
                                          <RemoveCircleOutlineIcon style={{color:"#F0522B"}} onClick={()=>{
                                              const updatedSelectedAddons = selectedAddons.map((addon) => {
                                                  if (addon.id === data.id) {
                                                      if (addon.quantity > 1) {
                                                          return {...addon, quantity: addon.quantity - 1};
                                                      } else {
                                                          return null; // Return null to remove the addon from selectedAddons
                                                      }
                                                  }
                                                  return addon;
                                              }).filter(Boolean); // Filter out null entries
                                              setSelectedAddons(updatedSelectedAddons);
                                          }}/>
                                        </div>
                                      <Typography style={{fontSize:"14px",marginTop:"2px",color:"#F0522B"}}>
                                          {quantityAdd}
                                      </Typography>
                                      <div style={{padding:"5px"}}>
                                          <AddCircleOutlineIcon style={{color:"#F0522B"}} onClick={()=>{
                                            
                                              const updatedSelectedAddons = selectedAddons.map((addon) => {
                                                  if (addon.id === data.id) {
                                                      // if(addon.quantity >= quantity){
                                                      //   setSnackConfig(({
                                                      //     message:"Addon limit per menu reached.",
                                                      //     type:"error",//success,error
                                                      //     show:true
                                                      //   }))
                                                      // }else{
                                                      //   return {...addon, quantity: addon.quantity + 1};
                                                      // }
                                                      return {...addon, quantity: addon.quantity + 1};
                                                  }
                                                  return addon;
                                              });
                                              setSelectedAddons(updatedSelectedAddons);
                                          }} />
                                      </div>
                                  </div>
                              </div>
                          )}
                      </div>
                  );
              })}

            </div>

            <div className="note_container">
              <Typography className="first">
                <b> Note to kitchen:</b> Optional
              </Typography>
              <TextareaAutosize
                placeholder="Add your request..."
                minRows={5}
                value={notes}
                onChange={(text)=>{setNotes(text.target.value)}}
                style={{
                  width: "100%",
                  padding: "10px",
                  borderRadius: "8px",
                  borderColor: "#BFBFBF",
                }}
              />
              <Typography style={{ textAlign: "end", color: "#BFBFBF" }}>
                0/500
              </Typography>
            </div>

            <div className="_per_item_container">
              <div className="mid">
                <div className="first">
                  <RemoveCircleOutlineIcon onClick={()=>{
                     if(quantity > 1){
                      setQuantity(quantity - 1)
                     }
                     
                  }}/>
                </div>
                <Typography className="second">{quantity}</Typography>
                <div className="first">
                  <AddCircleOutlineIcon  onClick={()=>{
                      setQuantity(quantity + 1)
                     
                  }} />
                </div>
              </div>
            </div>
            {/* {orderAdded &&  <Typography className="order_added"><b>Order  {!menu_id ? "Updated!" : "Added!"}</b></Typography>} */}
        
            <Button
              variant="contained"
              onClick={async()=>{
                if(!menu_id){
                  await updateOrder()
                }else{
                  await addToCart()
                }
                setSnackConfig({
                  message:!menu_id ? "Order Updated" : "Order Added",
                  type:"success",//success,error
                  show:true
                })
                // setOrderAdded(true)
                // setTimeout(()=>{
                //   setOrderAdded(false)
                // },2000)
               
              }}
              style={{
                backgroundColor: app_theme?.appBtn[0]?.bgColor,
                width: "100%",
                marginTop: orderAdded ? "10px" : "20px",
                textTransform: "capitalize",
                padding: "10px",
                boxShadow: 'none'
              }}
              className="btn-custom_order"
            >
              <b>{!menu_id ? "Update Dish" : "Order This Dish"} </b>
            </Button>
          </div>
        </div>
      </div>
}

        </div>
      <AppSnackBar snackConfig={snackConfig}/>
        {/* <h1 onClick={()=> navigate("/")}>FoodPreview</h1> */}
     
    </section>
  );
}
