import React, { useEffect, useState } from 'react';
import "./styles.scss";
import Logo from "../../assets/images/logo1.png"
import { useSelector } from 'react-redux';
import { useParams } from "react-router-dom";
import { Routes, Route, useNavigate } from "react-router-dom";
import { Stack, Typography } from "@mui/material";
import { ApiCall, ApiMethod } from '../../services/AxiosInstance';
import { useDispatch } from 'react-redux';
import { setType,setTableID,setRestaurentId } from '../../redux/reducer/User';
import { AppLoader,AppSnackBar } from '../../components';
import { setKitRestaurentId } from '../../redux/reducer/Kitchen';
export default function ActivationPage() {
  const userType = useSelector((state)=>state.user.user_type)
  const { MOD,UUID } = useParams();
  const [loader,setLoader] = useState(false)
  const navigate = useNavigate()
  const [snackConfig,setSnackConfig]= useState({
    message:null,
    type:"error",//success,error
    show:false
  })
  const dispatch = useDispatch()

  const getModule = (mode)=>{
    switch (mode) {
      case "res":
        return "restaurant";
      case "cus":
        return "customer";
      case "que":
        return "queuing";
      case "kit":
        return "kitchen";
      case "stat":
        return "station";
      case "sta":
        return "staff"; 
      default:
        break;
    }
  }
  const checkActivate =async()=>{
    try {
      setLoader(true)
        await ApiCall({
          apiEndpoint:'/items/license',
          method:ApiMethod.GET,
          service:true,
          filter:{
            _and:[
              {
                key:{
                  _eq:UUID
                }
              },
              {
                license_status:1
              },
              {
                restauarant_id:{
                  _neq:'null'
                }
              }
              
            ]
            
          }
        }).then((res)=>{
          const license = res.data.data
          console.log(license)
          if(license.length>0){
            const userType = getModule(`${MOD}`)
            //const licenseMod = license[0].module
            console.log(userType)
            if(userType==="kitchen"){
              dispatch(setKitRestaurentId(license[0].restauarant_id))
            }
            if(userType==="restaurant"){
              dispatch(setRestaurentId(license[0].restauarant_id))
            }
            dispatch(setType(userType))
            navigate("/");
           
          }else{
            setSnackConfig({
                message:"Invalid Activation",
                type:"error",//success,error
                show:true
            })
          }
          setLoader(false)
        })
        .catch((err)=>{
          console.log(err)
          setLoader(false)
            setSnackConfig({
              message:"Invalid Activation",
              type:"error",//success,error
              show:true
          })
        })

    } catch (error) {
      setLoader(false)
      console.log("Error",error)
        setSnackConfig({
          message:"Invalid Activation",
          type:"error",//success,error
          show:true
      })
    }
    
   
  }
  useEffect(()=>{
    if(UUID){
      checkActivate() 
    }
        
  },[UUID])
  return (
   <section id="restoLoginPage">
    <div className="my_container">
      <img src={Logo} className="imageLogo" /> 
      {userType ===null &&  <Typography style={{color:"#ea1b24"}}>EZ ODA Not Activated</Typography> }
      
      <Stack sx={{mt:2}}>
      {snackConfig.type=='error' && <Typography style={{color:"#ea1b24"}}>Invalid activation link</Typography> }
        <Typography sx={{textAlign:"center",width:"100%"}} >
          <a href="http://" target="_blank" rel="noopener noreferrer"  style={{color:"#FFF"}}  onClick={()=> window.location.href = "/"} >Go to Home</a>
        </Typography>
      </Stack>
    </div>
   {
     loader &&  <AppLoader/>
   } 
    <AppSnackBar snackConfig={snackConfig}/>
   </section>
  )
}