import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import DeleteIcon from '@mui/icons-material/Delete'
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Stack } from '@mui/material';
import Button from '@mui/material/Button';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
export default function AppDatePicker({ label, defaultDate = null, onSelectedTime, views, isTimeOnly = false, disabled = false,clearable= false }) {

  const handleDateTimeChange = (newDate) => {
    if (onSelectedTime) {
      const formattedDate = dayjs(newDate).format('YYYY-MM-DDTHH:mm:ss');
      onSelectedTime(formattedDate);
    }else{
      onSelectedTime(null)
    }
  };

  return (
    <Stack sx={{flexDirection:'row'}}>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={['MobileDateTimePicker', 'DateTimePicker']}>
            {isTimeOnly ? (
              <MobileTimePicker
                onChange={handleDateTimeChange}
                disabled={disabled}
                value={defaultDate !== null ? dayjs(defaultDate) : null}
                label={label}
                openTo="hours"
                error={false}
              />
            ) : (
              <>
              <DateTimePicker
                value={defaultDate !== null && defaultDate !=='Invalid Date'  ? dayjs(defaultDate) : null}
                disabled={disabled}
                label={label}
                // viewRenderers={{
                //   hours: renderTimeViewClock,
                //   minutes: renderTimeViewClock,
                //   seconds: renderTimeViewClock,
                // }}
                onChange={handleDateTimeChange}
              />
              </>
              
            )}
        
          </DemoContainer>
        </LocalizationProvider>
        {
          clearable  &&  defaultDate ?  
          <IconButton aria-label="delete" style={{backgroundColor:'transparent'}} onClick={()=>handleDateTimeChange(null)}>
            <CloseIcon style={{color:'red'}}/> Clear
          </IconButton>:null
        }
        
    </Stack>
   
  );
}

